import React, { useContext } from 'react';
import InterventionsImg from '../../assets/Home/intervention-icon.png';
import ContributorsImg from '../../assets/Home/contributors-icon.png';
import VolunteersImg from '../../assets/Home/volunteers-icon.png';
import UsersImg from '../../assets/Home/users-icon.png';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { GlobalContext } from '../GlobalState/GlobalState';

function Countdown() {
  const { language } = useContext(GlobalContext);

  const convertToBanglaNumeral = (number) => {
    const digitMap = {
      '0': '০',
      '1': '১',
      '2': '২',
      '3': '৩',
      '4': '৪',
      '5': '৫',
      '6': '৬',
      '7': '৭',
      '8': '৮',
      '9': '৯',
    };

    return number.toString().replace(/\d/g, (digit) => digitMap[digit]);
  };
  
  return (
    <section className='max-w-7xl lg:mx-auto md:mx-5 mx-5 lg:px-8 py-12'>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8'>
            <div className='grid grid-cols-1 gap-3 justify-items-center py-8 rounded-2xl shadow-lg counterCardBg hover:-translate-y-2 transition duration-500'>
                <div className='border-4 border-red-200 h-24 w-24 rounded-full flex justify-center items-center'>
                    <img src={InterventionsImg} alt='Interventions Icon' />
                </div>
                { language === 'en' ?
                  <p className='text-red-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <div>{isVisible ? <CountUp end={5} duration={1} /> : 0}</div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  :
                  <p className='text-red-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? (
                            <CountUp start={0} end={5} duration={1} formattingFn={convertToBanglaNumeral} />
                          ) : (
                            convertToBanglaNumeral(0)
                          )}
                        </div>
                      )}
                    </VisibilitySensor>
                    +
                  </p>
                }
                { language === 'en' ?
                  <p className='text-base text-gray-600 font-semibold'>Total Interventions</p>
                  :
                  <p className='text-base text-gray-600 font-semibold banglaFont'>প্রকল্প</p>
                }
            </div>
            <div className='grid grid-cols-1 gap-3 justify-items-center py-8 rounded-2xl shadow-lg counterCardBg hover:-translate-y-2 transition duration-500'>
                <div className='border-4 border-yellow-200 h-24 w-24 rounded-full flex justify-center items-center'>
                    <img src={ContributorsImg} alt='Interventions Icon' />
                </div>
                { language === 'en' ?
                  <p className='text-yellow-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                      >
                      {({ isVisible }) => (
                          <div>
                          {isVisible ? <CountUp end={10} duration={1} /> : 0}
                          </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  :
                  <p className='text-yellow-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? (
                            <CountUp start={0} end={10} duration={1} formattingFn={convertToBanglaNumeral} />
                          ) : (
                            convertToBanglaNumeral(0)
                          )}
                        </div>
                      )}
                    </VisibilitySensor>
                    +
                  </p>
                }
                { language === 'en' ?
                  <p className='text-base text-gray-600 font-semibold'>Contributors</p>
                  :
                  <p className='text-base text-gray-600 font-semibold banglaFont'>কন্ট্রিবিউটর</p>
                }
            </div>
            <div className='grid grid-cols-1 gap-3 justify-items-center py-8 rounded-2xl shadow-lg counterCardBg hover:-translate-y-2 transition duration-500'>
                <div className='border-4 border-blue-200 h-24 w-24 rounded-full flex justify-center items-center'>
                    <img src={VolunteersImg} alt='Interventions Icon' />
                </div>
                { language === 'en' ?
                  <p className='text-blue-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? <CountUp end={500} duration={2} /> : 0}
                        </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  :
                  <p className='text-blue-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? (
                            <CountUp start={0} end={500} duration={1} formattingFn={convertToBanglaNumeral} />
                          ) : (
                            convertToBanglaNumeral(0)
                          )}
                        </div>
                      )}
                    </VisibilitySensor>
                    +
                  </p>
                }
                <p className='text-base text-gray-600 font-semibold'></p>
                
                { language === 'en' ?
                  <p className='text-base text-gray-600 font-semibold'>Our volunteers</p>
                  :
                  <p className='text-base text-gray-600 font-semibold banglaFont'>স্বেচ্ছাসেবী</p>
                }
            </div>
            <div className='grid grid-cols-1 gap-3 justify-items-center py-8 rounded-2xl shadow-lg counterCardBg hover:-translate-y-2 transition duration-500'>
                <div className='border-4 border-violet-200 h-24 w-24 rounded-full flex justify-center items-center'>
                    <img src={UsersImg} alt='Interventions Icon' />
                </div>
                { language === 'en' ?
                  <p className='text-violet-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? <CountUp end={5000} duration={3} /> : 0}
                        </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  :
                  <p className='text-violet-500 font-bold text-4xl flex items-center'>
                    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? (
                            <CountUp start={0} end={5000} duration={1} formattingFn={convertToBanglaNumeral} />
                          ) : (
                            convertToBanglaNumeral(0)
                          )}
                        </div>
                      )}
                    </VisibilitySensor>
                    +
                  </p>
                }
                { language === 'en' ?
                  <p className='text-base text-gray-600 font-semibold'>Families served</p>
                  :
                  <p className='text-base text-gray-600 font-semibold banglaFont'>সেবাগ্রহণকারী পরিবার</p>
                }
            </div>
        </div>
    </section>
  );
};

export default Countdown