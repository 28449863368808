import React, { useContext } from "react";
import Footer from "./Footer";
import { GlobalContext } from "../GlobalState/GlobalState";
// import { Link } from "react-router-dom";
// import Footer from "./Footer";
// import Navbar from "./Navbar";

const NotFound = () => {
  const { language } = useContext(GlobalContext);
  return (
    <div>
        {/* <Navbar></Navbar> */}
        <section className="lg:pt-56 md:pt-44 pt-56 lg:pb-56 md:pb-44 pb-28 mx-5">
          <div className="flex flex-col justify-center items-center">
            <p className="text-center font-bold lg:text-9xl md:text-9xl text-6xl dark:text-gray-300 text-gray-900">404</p>
            { language === 'en' ?
              <p className="text-center dark:text-gray-400 text-gray-700 lg:text-4xl md:text-4xl text-2xl font-semibold my-4">Not Found</p>
              :
              <p className="text-center dark:text-gray-400 text-gray-700 lg:text-4xl md:text-4xl text-2xl font-semibold my-4 banglaFont">খুঁজে পাওয়া যায়নি</p>
            }
            { language === 'en' ?
              <p className="text-center dark:text-gray-400 text-gray-800">The resource requested would not be found on this website!</p>
              :
              <p className="text-center dark:text-gray-400 text-gray-800 banglaFont">পৃষ্ঠাটি খুঁজে পাওয়া যায়নি।</p>
            }
          </div>
        </section>
        <Footer></Footer>
    </div>
  );
};

export default NotFound;