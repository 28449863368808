import React from 'react';
// import Navbar from '../Common/Navbar';

function Nepal() {
  return (
    <div>
      {/* <Navbar></Navbar> */}
      <div className='max-w-7xl mx-auto h-screen'>
        <p>Nepal Page</p>
      </div>
  </div>
  );
};

export default Nepal