import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
// import Avatar from '../../assets/avatar.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
// import DeleteIcon from '../../assets/delete.png';
// import DeleteIcon from '../../assets/delete.svg';
import BaseUrl from '../BaseURL';
import { BsSearch } from 'react-icons/bs';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { IoSettingsOutline } from 'react-icons/io5';
// import { MdAdminPanelSettings } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { BiHide, BiShow } from 'react-icons/bi';
import ScrollToTop from '../Common/ScrollToTop';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SiAddthis } from 'react-icons/si';
import EditBlog from './EditBlog';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function ManageBlogs() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();

  // const blogs = [
  //   {
  //     id: 1,
  //     title: "First post",
  //     description: "This is the description for the first post",
  //     postTime: "2023-02-28T10:30:00.000Z"
  //   },
  //   {
  //     id: 2,
  //     title: "Second post",
  //     description: "This is the description for the second post",
  //     postTime: "2023-02-28T11:15:00.000Z"
  //   },
  //   {
  //     id: 3,
  //     title: "Third post",
  //     description: "This is the description for the third post",
  //     postTime: "2023-02-28T12:00:00.000Z"
  //   },
  //   {
  //     id: 4,
  //     title: "Fourth post",
  //     description: "This is the description for the fourth post",
  //     postTime: "2023-02-28T13:45:00.000Z"
  //   },
  //   {
  //     id: 5,
  //     title: "Fifth post",
  //     description: "This is the description for the fifth post",
  //     postTime: "2023-02-28T14:30:00.000Z"
  //   },
  //   {
  //     id: 6,
  //     title: "Sixth post",
  //     description: "This is the description for the sixth post",
  //     postTime: "2023-02-28T15:15:00.000Z"
  //   },
  //   {
  //     id: 7,
  //     title: "Seventh post",
  //     description: "This is the description for the seventh post",
  //     postTime: "2023-02-28T16:00:00.000Z"
  //   },
  //   {
  //     id: 8,
  //     title: "Eighth post",
  //     description: "This is the description for the eighth post",
  //     postTime: "2023-02-28T17:45:00.000Z"
  //   },
  //   {
  //     id: 9,
  //     title: "Ninth post",
  //     description: "This is the description for the ninth post",
  //     postTime: "2023-02-28T18:30:00.000Z"
  //   },
  //   {
  //     id: 10,
  //     title: "Tenth post",
  //     description: "This is the description for the tenth post",
  //     postTime: "2023-02-28T19:15:00.000Z"
  //   },
  //   {
  //     id: 11,
  //     title: "Eleventh post",
  //     description: "This is the description for the eleventh post",
  //     postTime: "2023-02-28T20:00:00.000Z"
  //   },
  //   {
  //     id: 12,
  //     title: "Twelfth post",
  //     description: "This is the description for the twelfth post",
  //     postTime: "2023-02-28T21:45:00.000Z"
  //   },
  //   {
  //     id: 13,
  //     title: "Thirteenth post",
  //     description: "This is the description for the thirteenth post",
  //     postTime: "2023-02-28T22:30:00.000Z"
  //   },
  //   {
  //     id: 14,
  //     title: "Fourteenth post",
  //     description: "This is the description for the fourteenth post",
  //     postTime: "2023-02-28T23:15:00.000Z"
  //   },
  //   {
  //     id: 15,
  //     title: "Fifteenth post",
  //     description: "This is the description for the Fifteenth post",
  //     postTime: "2023-02-28T23:15:00.000Z"
  //   }
  // ]  

  // Search
  const [searchTerm, setSearchTerm] = useState("");
  const [blogs, setBlogs] = useState([]);
  // console.log(reversedBlogs)

  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
  
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState(blogs);

  useEffect(() => {
    const results = blogs.filter(val =>
      (val.title || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.description || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    setFilteredItems(results);
    setCurrentPage(1);

  }, [searchTerm, blogs]);
  
  const reversedBlogs = filteredItems.slice().reverse();

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[]);

  // Get blogs
  useEffect(()=>{
    Axios.get(`${BaseUrl}/blogs`).then((response) => {
      setBlogs(response.data);
    });
  },[]);

  // Update Blog
  // const inputRefs = {
  //   newTitle: useRef(null),
  //   newContent: useRef(null),
  // };

  // const handleEditBlog = (e) => {
  //   // e.preventDefault();
  //   // const id = clickedPost?.id;
  //   // // console.log(id)
  //   // const formData = {
  //   //   newTitle: inputRefs.newTitle.current.value,
  //   //   newContent: inputRefs.newContent.current.value,
  //   // };

  //   //   Axios.put(`${BaseUrl}/updatePost`, { 
  //   //     title:formData.newTitle, 
  //   //     content:formData.newContent,
  //   //     id: id 
  //   //   }).then(
  //   //       (response) => {
  //   //         setPosts(
  //   //           posts.map((val) => {
  //   //             return val.id === id
  //   //               ? {
  //   //                   id: val.id,
  //   //                   title: val.newTitle,
  //   //                   content: val.newContent,
  //   //                 }
  //   //               : val;
  //   //           })
  //   //         );
  //   //         Axios.get(`${BaseUrl}/posts`).then((response) => {
  //   //           setPosts(response.data);
  //   //         });
  //   //         setIsOpenPopup(false);
  //   //       }
  //   //     );
  //   } ;

  // make Visible blog
  const handleVisibleBlog = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateBlogVisibility`, { 
        visibility:"visible", 
        id: id 
      }).then(
          (response) => {
            setBlogs(
              blogs.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      visibility: val.visibility,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/blogs`).then((response) => {
              setBlogs(response.data);
            });
            toast.success("This blog is visible now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // make Invisible blog
  const handleInvisibleBlog = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateBlogVisibility`, { 
        visibility:"invisible", 
        id: id 
      }).then(
          (response) => {
            setBlogs(
              blogs.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      visibility: val.visibility,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/blogs`).then((response) => {
              setBlogs(response.data);
            });
            toast.success("This blog is private now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  } ;
  // Delete Blog
  const handleDeleteBlog = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      const proceed = window.confirm("Are you sure?");
      if(proceed){
        Axios.delete(`${BaseUrl}/blogs/${id}`).then((response) => {
          setBlogs(
            blogs.filter((val) => {
              return val.id !== id;
            })
          );
          Axios.get(`${BaseUrl}/blogs`).then((response) => {
            setBlogs(response.data);
          });
          toast.success("Blog Deleted!");
        });
      }
    }else{
      toast.warn("You don't have sufficient privilege!")
    }
  };

    // Pagination
    const itemsPerPage = 10;
    const urlParams = new URLSearchParams(location.search);
    const pageFromURL = Number(urlParams.get('page')) || 1;
    
    const [currentPage, setCurrentPage] = useState(pageFromURL);
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
      navigate(`?page=${page}`);
    };
    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const page = urlParams.get('page');
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    }, [location, currentPage]);

    const getVisiblePageNumbers = (currentPage, totalPages) => {
      const visiblePagesLimit = 4; // Adjust this number based on your preference
  
      if (totalPages <= visiblePagesLimit) {
        return Array.from(Array(totalPages).keys()).map((i) => i + 1);
      }
  
      const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
      const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);
  
      const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
      return visiblePages;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalPages = Math.ceil(reversedBlogs.length / itemsPerPage);
    const paginatedItems = reversedBlogs.slice(startIndex, endIndex);
    const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

  return (
    <div>
      <Helmet>
        <title>Manage Blogs - Telementoring</title>
        <meta name='title' content='Manage Blogs - Telementoring' />
        <meta name="description" content="Manage all blogs of Telementoring." />
        <meta name="keywords" content="telementoring, manage blogs" />
      </Helmet>

      <div className="container mx-auto">
        <div>
          <div className='lg:hidden md:block hidden'>
            <div className='flex justify-end mx-5'>
              {/* { language === 'en' ?
                <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2'><SiAddthis/> Add Blog</Link>
                :
                <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/>নতুন ব্লগ</Link>
              } */}
              { language === 'en' ?
                <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add Blog</div>
                :
                <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/>নতুন ব্লগ</div>
              }
            </div>
          </div>
          <div className='md:flex items-center grid grid-cols-1 justify-between gap-5 px-5 py-4 rounded-2xl shadow-md'>
            <div className='md:hidden block'>
              <div className='flex justify-between items-center gap-3 mt-8'>
                { language === 'en' ?
                  <h2 className="text-xl font-semibold ">Manage Blogs</h2>
                  :
                  <h2 className="text-xl font-semibold banglaFont">ম্যানেজ ব্লগ</h2>
                }
                {/* { language === 'en' ?
                  <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2'><SiAddthis/> Add Blog</Link>
                  :
                  <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/>নতুন ব্লগ</Link>
                } */}
                { language === 'en' ?
                  <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add Blog</div>
                  :
                  <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/>নতুন ব্লগ</div>
                }
              </div>
            </div>

            { language === 'en' ?
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden">Manage Blogs</h2>
              :
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden banglaFont">ম্যানেজ ব্লগ</h2>
            }

            <div className='flex md:flex-row flex-col items-center gap-5'>
              <div className='lg:block hidden'>
                {/* { language === 'en' ?
                  <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2'><SiAddthis/> Add <span>Blog</span></Link>
                  :
                  <Link to='/newsfeed/add-blog' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/> নতুন <span>ব্লগ</span></Link>
                } */}
                { language === 'en' ?
                  <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add <span>Blog</span></div>
                  :
                  <div onClick={() => navigate("/admin-settings/add-blog", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/> নতুন <span>ব্লগ</span></div>
                }
              </div>
              <div className='flex items-center w-full'>
                {/* <input type="text" placeholder='Search User' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none" onChange={(event)=>{
                  setSearchTerm(event.target.value);
                }}/> */}
                { language === 'en' ?
                  <input type="text" placeholder='Search Blog' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                  :
                  <input type="text" placeholder='সার্চ করুন' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none banglaFont" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                }
                <label className='font-semibold text-xl text-[#0082C4] border border-l-0 border-blue-500 border-opacity-20 bg-[#ECF4FF] rounded-r-xl py-3.5 px-4'><BsSearch/></label>
              </div>
            </div>
          </div>
          { paginatedItems?.length < 1 ?
            <div>
              { language === 'en' ?
                <p className='text-center text-2xl font-semibold py-12'>No blogs found.</p>
                :
                <p className='text-center text-2xl font-semibold py-12 banglaFont'>কোনো ব্লগ পাওয়া যায়নি ।</p>
              }
            </div>
            :
            <div className="pt-4 overflow-x-auto">
              <div className="inline-block min-w-full lg:mx-0 md:mx-5 mx-0 shadow-md rounded-lg overflow-hidden border-x">
                <table className="min-w-full leading-normal">
                  <thead className='bg-[#F6FAFF]'>
                    { language === 'en' ?
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Index
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Title
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Short description
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Post Time
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >Actions</th>
                      </tr>
                      :
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          ইনডেক্স
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          টাইটেল
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          সংক্ষিপ্ত বর্ণনা
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          পোস্টের সময়
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >এ্যাকশন</th>
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {paginatedItems.map((blog, index)=>{
                      return(
                        <tr key={blog?.id} className=''>
                          <td className="px-5 py-5 text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{index+1}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <div>
                              <p className="text-gray-900 font-semibold whitespace-no-wrap">
                              {blog && blog?.title && blog?.title?.length >= 50 ? blog?.title?.slice(0,50) + "..." : blog?.title}
                              </p>
                            </div>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <div className='text-gray-900 whitespace-no-wrap' dangerouslySetInnerHTML={{__html: blog && blog?.description && blog?.description.length >= 80 ? blog?.description?.slice(0, 80) + "..." : blog?.description}}></div>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{blog?.post_time}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm flex items-center justify-center relative">
                            <div className='group flex flex-col gap-2'>
                              <div 
                                className='text-lg hover:text-white transition-all bg-white hover:bg-[#0082C4] border border-[#F1F7FF] rounded-full shadow-md lg:p-3 md:p-2 p-2 flex justify-center items-center cursor-pointer'
                              >
                                <IoSettingsOutline />
                              </div>
                              <div>
                                  <div className="absolute pt-8 md:top-10 top-8 md:-left-24 -left-28 w-40  hidden group-hover:block transition-all z-50">
                                    { language === 'en' ?
                                      <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                        <Link to={`/admin-settings/edit-blog/${blog?.id}`} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" 
                                        >
                                          <FaRegEdit />Edit Blog
                                        </Link>
                                        {/* <Link to={`/newsfeed/edit-blog/${blog?.id}`} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" 
                                        >
                                          <FaRegEdit />Edit Blog
                                        </Link> */}
                                        {/* <div onClick={() => navigate((`/admin-settings?edit=blog?${blog?.id}`), { replace: true })} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" 
                                        >
                                          <FaRegEdit />Edit Blog
                                        </div> */}
                                        {
                                          blog?.visibility === "visible" ?
                                            <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleInvisibleBlog(blog?.id)}>
                                            <BiHide />Make Private
                                            </button>
                                          :
                                            <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleVisibleBlog(blog?.id)}>
                                            <BiShow />Make Public
                                            </button>
                                        }
                                        <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeleteBlog(blog?.id)}>
                                          <RiDeleteBin6Line />Delete
                                        </button>
                                      </div>
                                      :
                                      <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                          <Link to={`/newsfeed/edit-blog/${blog?.id}`} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" 
                                          >
                                            <FaRegEdit />ইডিট ব্লগ
                                          </Link>
                                          {
                                            blog?.visibility === "visible" ?
                                              <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleInvisibleBlog(blog?.id)}>
                                              <BiHide />প্রাইভেট করুন
                                              </button>
                                            :
                                              <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleVisibleBlog(blog?.id)}>
                                              <BiShow />পাবলিক করুন
                                              </button>
                                          }
                                        <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeleteBlog(blog?.id)}>
                                          <RiDeleteBin6Line />বাতিল করুন
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              {/* <div className='absolute lg:-left-14 md:-left-24 -left-24 top-[5%] pr-4'>
                                <div className='bg-white px-3 py-3 rounded-xl shadow-md hidden group-hover:block transition-all z-10'>
                                  <div className='flex flex-col gap-1'>
                                    <button className='text-xs px-2 py-1 hover:bg-blue-50 hover:text-blue-500 flex items-center gap-1'><FaRegEdit />Edit Post</button>
                                    <button className='text-xs px-2 py-1 hover:bg-blue-50 hover:text-blue-500 flex items-center gap-1'><BiHide />Private Post</button>
                                    <button 
                                      className='px-2 text-left hover:bg-red-50 text-red-400 flex items-center gap-1'
                                    ><RiDeleteBin6Line />Delete</button>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      )
                      })
                    }
                  </tbody>
                </table>

                {/* Pagination button */}
                <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                  <nav className="block">
                    <ul className="flex pl-0 rounded list-none gap-3">
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === 1}
                          onClick={() => handlePageChange(Number(currentPage) - 1)}
                        >
                          <CgChevronDoubleLeft />
                        </button>
                      </li>
                      {visiblePageNumbers.map((page) => (
                        <li key={page}>
                          <button
                            className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                              Number(currentPage) === page ? "font-bold bg-[#0082C4] text-white" : ""
                            }  focus:outline-none`}
                            onClick={() => handlePageChange(page)}
                          >
                          {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === totalPages}
                          onClick={() => handlePageChange(Number(currentPage) + 1)}
                        >
                          <CgChevronDoubleRight />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default ManageBlogs