import React, { useContext, useEffect, useRef, useState } from 'react';
import GetInvolvedHeroBg from '../../assets/GetInvolved/GetInvolvedHeroBg.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import VolunteerImg from '../../assets/GetInvolved/volunteer-img.jpg';
import PartnerImg from '../../assets/GetInvolved/partner-img.png';
import Review from '../Review/Review';
import Countdown from '../Countdown/Countdown';
import { CgArrowRight } from 'react-icons/cg';
import ScrollToTop from '../Common/ScrollToTop';
import Footer from '../Common/Footer';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function GetInvolved() {
    const { language } = useContext(GlobalContext);

    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();

    // Custom url
    const urlParams = new URLSearchParams(location.search);
    const joinFromURL = urlParams.get('join');
    const [joinType, setJoinType] = useState(joinFromURL);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const join = urlParams.get('join');
        if (join !== joinType) {
            setJoinType(join);
        }
    }, [location, joinType]);

    const handleJoinClick = (join) => {
        setJoinType(join);
        navigate(`?join=${join}`);
    };

    function handleClickVolunteer() {
        if (user) {
            toast.error("You have already signedUp!");
        } else {
            navigate("/signup?type=volunteer", { replace: true });
        }
    }
    function handleClickPartner() {
        if (user) {
            toast.error("You have already signedUp!");
        } else {
            navigate("/signup?type=partner", { replace: true });
        }
    }

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

    const VolunteerRef = useRef(null);
    const PartnerRef = useRef(null);

    useEffect(() => {
      if (window.location.hash === '#volunteer') {
        VolunteerRef.current.scrollIntoView({ behavior: 'smooth' });
      }else if(window.location.hash === '#partner') {
        PartnerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

  return (
    <div>
        <Helmet>
            <title>Get Involved - Telementoring</title>
            <meta name='title' content='Get Involved - Telementoring' />
            <meta name="description" content="Find out how you can get involved with Telementoring. Learn about our current opportunities, training, and support." />
            <meta name="keywords" content="telementoring, get involved, volunteer" />
        </Helmet>

        {/* Hero */}
        <section>
            <div className='relative' style={{"backgroundImage": `url(${GetInvolvedHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                    <div className='TextInnerBg w-full'>
                        { language === 'en' ?
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Get <span className='text-[#FDBE44]'>Involved</span></p>
                            :
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'>যুক্ত <span className='text-[#FDBE44]'>হোন</span></p>
                        }
                        <div className='flex items-center gap-2 mt-4'>
                            { language === 'en' ?
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                                :
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                            }
                            <span><img src={NextIcon} alt='Next Icon' /></span>
                            { language === 'en' ?
                                <Link to='/get-involved?join=volunteer' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Get Involved</Link>
                                :
                                <Link to='/get-involved?join=volunteer' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>যুক্ত হোন</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
                <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
            </div>
        </section>

        {/* Getting Involved */}
        <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12'>
            <div className='flex flex-col gap-2 mb-12'>
                <div className='flex justify-center items-center'>
                    { language === 'en' ?
                        <p ref={VolunteerRef} className='lg:text-base md:text-sm text-xs font-bold py-1.5 px-6 rounded-full text-center text-[#F74F22] bg-[#F9EEEE]'>Join Telementoring</p>
                        :
                        <p ref={VolunteerRef} className='lg:text-base md:text-sm text-xs font-bold py-1.5 px-6 rounded-full text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>টেলিমেন্টরিং এ যোগ দিন</p>
                    }
                </div>
                { language === 'en' ?
                    <p className='text-[#2E3033] md:text-4xl text-2xl text-center font-bold'>Get Involved</p>
                    :
                    <p className='text-[#2E3033] md:text-4xl text-2xl text-center font-bold banglaFont'>যুক্ত হোন</p>
                }
                <div className='flex justify-center'>
                    { language === 'en' ?
                        <p ref={PartnerRef} className='text-[#777B84] text-lg text-center lg:w-2/3 md:w-4/5 w-full'> Join us to make impact on people's lives.</p>
                        :
                        <p ref={PartnerRef} className='text-[#777B84] text-lg text-center lg:w-2/3 md:w-4/5 w-full banglaFont'> মানুষের জীবনে প্রভাব ফেলতে আমাদের সাথে যোগ দিন।</p>
                    }
                </div>
            </div>
            <div className='md:block hidden'>
                <div className='flex items-center lg:gap-8 gap-5 w-full'>
                    <div className='flex flex-col gap-8 lg:w-3/5 w-1/2'>
                        <div className={`border border-blue-500 rounded-2xl shadow-sm lg:p-8 p-4 cursor-pointer ${joinType === "volunteer" ? "bg-blue-50" : "border-opacity-20"}`} onClick={() => handleJoinClick('volunteer')}>
                            { language === 'en' ?
                                <p className='text-[#2E3033] lg:text-3xl text-xl font-bold mb-2'>Volunteers</p>
                                :
                                <p className='text-[#2E3033] lg:text-3xl text-xl font-bold mb-2 banglaFont'>স্বেচ্ছাসেবক</p>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] lg:text-base text-sm'>If you are interested in volunteering as a mentor, sign up as a telementor and nominate your preferred areas of volunteer mentoring. We will match you with an organization needing volunteer mentors to provide services to individuals and households via mobile phones.</p>
                                :
                                <p className='text-[#777B84] lg:text-base text-sm banglaFont'>আপনি যদি একজন পরামর্শদাতা হিসাবে স্বেচ্ছাসেবী করতে আগ্রহী হন, তাহলে একজন টেলিমেন্টর হিসাবে সাইন আপ করুন এবং পরামর্শদানের আপনার পছন্দের ক্ষেত্রগুলিকে মনোনীত করুন। মোবাইল ফোনের মাধ্যমে ব্যক্তি ও পরিবারকে  সেবা প্রদানের জন্য স্বেচ্ছাসেবক পরামর্শদাতা প্রয়োজন এমন একটি প্রতিষ্ঠানের সাথে আমরা আপনার মেলবন্ধন করিয়ে দিব।</p>
                            }
                        </div>
                        <div className={`border border-blue-500 rounded-2xl shadow-sm lg:p-8 p-4 cursor-pointer ${joinType === 'partner' ? "bg-blue-50" : "border-opacity-20"}`} onClick={() => handleJoinClick('partner')}>
                            { language === 'en' ?
                                <p className='text-[#2E3033] lg:text-3xl text-xl font-bold mb-2'>Partners</p>
                                :
                                <p className='text-[#2E3033] lg:text-3xl text-xl font-bold mb-2 banglaFont'>অংশীদার</p>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] lg:text-base text-sm'>If you are from an NGO that needs volunteers to help provide mentoring support to people in need, or you are looking for opportunities to fund projects that positively impact people&apos;s lives, please contact us to explore the options and become our partner.</p>
                                :
                                <p className='text-[#777B84] lg:text-base text-sm banglaFont'>আপনি যদি এমন একটি এনজিও বা সংগঠনের প্রতিনিধিত্ব করেন, যাদের ব্যক্তি বা পরিবারকে পরামর্শমূলক সেবা প্রদানে সহায়তা করার জন্য স্বেচ্ছাসেবকদের প্রয়োজন তাহলে অংশীজন হিসেবে নিবন্ধন করুন। অথবা আপনি যদি অলাভজক প্রকল্পতে অর্থায়ন করতে চান এবং আমাদের অংশীদার হতে চান দয়া করে আমাদের সাথে যোগাযোগ করুন।</p>
                            }
                        </div>
                    </div>
                    <div className='lg:w-2/5 w-1/2 relative'>
                        <div className={`${joinType === "volunteer" ? "block" : "hidden"}`}>
                            <img src={VolunteerImg} alt="Volunteer Img" className='rounded-2xl' />
                            <div className='flex justify-center'>
                                { language === 'en' ?
                                    <button onClick={handleClickVolunteer} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10'>Join as a Volunteer <span className="text-white"><CgArrowRight /></span></button>
                                    :
                                    <button onClick={handleClickVolunteer} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10 banglaFont'>স্বেচ্ছাসেবক হিসাবে যোগ দিন<span className="text-white"><CgArrowRight /></span></button>
                                }
                            </div>
                        </div>
                        <div className={`${joinType === "partner" ? "block" : "hidden"}`}>
                            <img src={PartnerImg} alt="Volunteer Img" className='rounded-2xl' />
                            <div className='flex justify-center'>
                                { language === 'en' ?
                                    <button onClick={handleClickPartner} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10'>Join as a Partner <span className="text-white"><CgArrowRight /></span></button>
                                    :
                                    <button onClick={handleClickPartner} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10 banglaFont'>পার্টনার হিসাবে যোগ দিন<span className="text-white"><CgArrowRight /></span></button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden block'>
                <div className='flex flex-col gap-12'>
                    <div className='flex justify-center items-center gap-3'>
                        { language === 'en' ?
                            <div onClick={() => handleJoinClick('volunteer')} className={`bg-white px-3 py-2 border border-blue-500 border-opacity-20 rounded-xl ${joinType === 'volunteer' && 'bg-[#0082C4] text-white'}`}>
                                Volunteer
                            </div>
                            :
                            <div onClick={() => handleJoinClick('volunteer')} className={`bg-white px-3 py-2 border border-blue-500 border-opacity-20 rounded-xl banglaFont ${joinType === 'volunteer' && 'bg-[#0082C4] text-white'}`}>
                                স্বেচ্ছাসেবক
                            </div>
                        }
                        { language === 'en' ?
                            <div onClick={() => handleJoinClick('partner')} className={`bg-white px-5 py-2 border border-blue-500 border-opacity-20 rounded-xl ${joinType === 'partner' && 'bg-[#0082C4] text-white'}`}>
                                Partner
                            </div>
                            :
                            <div onClick={() => handleJoinClick('partner')} className={`bg-white px-5 py-2 border border-blue-500 border-opacity-20 rounded-xl ${joinType === 'partner' && 'bg-[#0082C4] text-white'}`}>
                                পার্টনার
                            </div>
                        }
                    </div>
                    <div className={`${joinType === "volunteer" ? "block" : "hidden"}`}>
                        <div className="border border-blue-500 border-opacity-20 rounded-2xl shadow-sm px-8 py-8 cursor-pointer">
                            { language === 'en' ?
                                <p className='text-[#2E3033] md:text-3xl text-xl font-bold mb-2'>Volunteers</p>
                                :
                                <p className='text-[#2E3033] md:text-3xl text-xl font-bold mb-2 banglaFont'>স্বেচ্ছাসেবক</p>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] md:text-base text-sm'>If you are interested in volunteering as a mentor, sign up as a telementor and nominate your preferred areas of volunteer mentoring. We will match you with an organization needing volunteer mentors to provide services to individuals and households via mobile phones.</p>
                                :
                                <p className='text-[#777B84] lg:text-base text-sm banglaFont'>আপনি যদি একজন পরামর্শদাতা হিসাবে স্বেচ্ছাসেবী করতে আগ্রহী হন, তাহলে একজন টেলিমেন্টর হিসাবে সাইন আপ করুন এবং পরামর্শদানের আপনার পছন্দের ক্ষেত্রগুলিকে মনোনীত করুন। মোবাইল ফোনের মাধ্যমে ব্যক্তি ও পরিবারকে  সেবা প্রদানের জন্য স্বেচ্ছাসেবক পরামর্শদাতা প্রয়োজন এমন একটি প্রতিষ্ঠানের সাথে আমরা আপনার মেলবন্ধন করিয়ে দিব।</p>
                            }
                        </div>
                        <div className='relative mt-4'>
                            <img src={VolunteerImg} alt="Volunteer Img" className='rounded-2xl'  />
                            <div className='flex justify-center'>
                                { language === 'en' ?
                                    <button onClick={handleClickVolunteer} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10'>Join as a Volunteer <span className="text-white"><CgArrowRight /></span></button>
                                    :
                                    <button onClick={handleClickVolunteer} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10 banglaFont'>স্বেচ্ছাসেবক হিসাবে যোগ দিন<span className="text-white"><CgArrowRight /></span></button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${joinType === "partner" ? "block" : "hidden"}`}>
                        <div className="border border-blue-500 border-opacity-20 rounded-2xl shadow-sm px-8 py-8 cursor-pointer">
                            { language === 'en' ?
                                <p className='text-[#2E3033] md:text-3xl text-xl font-bold mb-2'>Partners</p>
                                :
                                <p className='text-[#2E3033] md:text-3xl text-xl font-bold mb-2 banglaFont'>অংশীদার</p>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] md:text-base text-sm'>If you are from an NGO that needs volunteers to help provide mentoring support to people in need, or you are looking for opportunities to fund projects that positively impact people&apos;s lives, please contact us to explore the options and become our partner.</p>
                                :
                                <p className='text-[#777B84] md:text-base text-sm banglaFont'>আপনি যদি এমন একটি এনজিও বা সংগঠনের প্রতিনিধিত্ব করেন, যাদের ব্যক্তি বা পরিবারকে পরামর্শমূলক সেবা প্রদানে সহায়তা করার জন্য স্বেচ্ছাসেবকদের প্রয়োজন তাহলে অংশীজন হিসেবে নিবন্ধন করুন। অথবা আপনি যদি অলাভজক প্রকল্পতে অর্থায়ন করতে চান এবং আমাদের অংশীদার হতে চান দয়া করে আমাদের সাথে যোগাযোগ করুন।</p>
                            }
                        </div>
                        <div className='relative mt-4'>
                            <img src={PartnerImg} alt="Volunteer Img" className='rounded-2xl' />
                            <div className='flex justify-center'>
                                { language === 'en' ?
                                    <button onClick={handleClickPartner} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10'>Join as a Partner <span className="text-white"><CgArrowRight /></span></button>
                                    :
                                    <button onClick={handleClickPartner} className='text-white font-semibold bg-opacity-80 hover:bg-opacity-100 transition-all bg-green-500 border-2 border-white border-opacity-25 backdrop-blur-sm w-4/5 flex justify-center items-center gap-2 py-2 rounded-md absolute bottom-10 banglaFont'>পার্টনার হিসাবে যোগ দিন<span className="text-white"><CgArrowRight /></span></button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <Countdown />
        </section>

        <section>
            <Review />
        </section>

        <ScrollToTop></ScrollToTop>
        <Footer/>
    </div>
  );
};

export default GetInvolved