import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import Axios from "axios";
import './UpdateInfo.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import BaseUrl from '../BaseURL';
import ScrollToTop from '../Common/ScrollToTop';
import activeDot from '../../assets/activeDot.png';
import { MdAddAPhoto } from 'react-icons/md';
import { toast } from 'react-toastify';
import Footer from '../Common/Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';
import { useUserContext } from '../GlobalState/UserContext';

function UpdateVolunteerInfo() {
    const { language } = useContext(GlobalContext);
    const { signedInUser } = useUserContext();
    const { volunteerId } = useParams();

    const [user] = useAuthState(auth);
    const location = useLocation();
    const navigate = useNavigate();
    const [userName, setUserName]=useState("");
    const [activeLink, setActiveLink] = useState("personalInfo");
    const [userInfos, setUserInfos] = useState([]);
    const [personalInfo, setPersonalInfo] = useState([]);
    // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
    const volunteerInfos = userInfos?.find(userInfo => userInfo?.user_id?.toString() === volunteerId);
    // console.log(volunteerInfos);

    // For opening the other input based on backend data
    const isOtherOrganizations = JSON.stringify(signedInUser?.volunteeredOrganizations);
    const isOtherOrganizationsFound = isOtherOrganizations && isOtherOrganizations.indexOf && isOtherOrganizations.indexOf('Other') !== -1;

    const [phone, setPhone] = useState(signedInUser?.mobile || '');
    // Get Checkbox's value
    const [checkedValues, setCheckedValues] = useState([]);
    const [checkedValues2, setCheckedValues2] = useState([]);
    const [checkedValues3, setCheckedValues3] = useState([]);
    const [other, setOther] = useState(false);
    const [volunteer, setVolunteer] = useState({});
    // Get users
    useEffect(()=>{
      Axios.get(`${BaseUrl}/infos`).then((response) => {
        setPersonalInfo(response.data);
      });
    },[]);
    // Get user_infos
    useEffect(()=>{
      Axios.get(`${BaseUrl}/userInfos`).then((response) => {
        setUserInfos(response.data);
      });
    },[]);
    // get volunteer details
    useEffect(()=>{
        Axios.get(`${BaseUrl}/volunteer-profile-update/${volunteerId}`).then((response) => {
            setVolunteer(response.data);
        });
    },[volunteerId]);

    useEffect(() => {
        setPhone(volunteerInfos?.mobile || '');
        setUserName(user?.displayName);
        setCheckedValues(volunteerInfos?.tutoredStudentsLevel?.split(",") || []);
        setCheckedValues2(volunteerInfos?.volunteeredOrganizations?.split(",") || []);
        setCheckedValues3(volunteerInfos?.areaOfInterest?.split(",") || []);
    },[volunteerInfos]);

    const [options, setOptions] = useState([
        { id: 1, value: "Pre-primary school level (early childhood education and development)", banglaValue: "প্রাক-প্রাথমিক বিদ্যালয় স্তর (প্রাথমিক শৈশব শিক্ষা এবং বিকাশ)" },
        { id: 2, value: "Primary school level", banglaValue: "প্রাথমিক বিদ্যালয় স্তর" },
        { id: 3, value: "Lower secondary school level", banglaValue: "নিম্ন মাধ্যমিক বিদ্যালয় স্তর" },
        { id: 4, value: "Upper secondary school level", banglaValue: "উচ্চ মাধ্যমিক বিদ্যালয় স্তর" },
        { id: 5, value: "College level and above", banglaValue: "কলেজ স্তর এবং তার উপরে" }
    ]);
    const [options2, setOptions2] = useState([
        { id: 1, value: "United Nations", banglaValue: "জাতিসংঘ" },
        { id: 2, value: "International NGOs", banglaValue: "আন্তর্জাতিক এনজিও" },
        { id: 3, value: "Local NGOs", banglaValue: "স্থানীয় এনজিও" },
        { id: 4, value: "University based organizations", banglaValue: "বিশ্ববিদ্যালয় ভিত্তিক সংগঠন" },
        { id: 5, value: "Other", banglaValue: "অন্যান্য" }
    ]);
    const [options3, setOptions3] = useState([
        { id: 1, value: "Early childhood education and development", banglaValue: "প্রাথমিক শৈশব শিক্ষা এবং বিকাশ" },
        { id: 2, value: "Educating primary graders", banglaValue: "প্রাথমিক গ্রেডারদের শিক্ষা দেওয়া" },
        { id: 3, value: "Educating secondary graders", banglaValue: "মাধ্যমিক গ্রেডার্স শিক্ষিত" },
        { id: 4, value: "Educating SSC students", banglaValue: "এসএসসি শিক্ষার্থীদের শিক্ষাদান" },
        { id: 5, value: "Educating HSC students", banglaValue: "এইচএসসি শিক্ষার্থীদের শিক্ষিত করা" },
        { id: 6, value: "Volunteering for environmental issues", banglaValue: "পরিবেশগত সমস্যাগুলির জন্য স্বেচ্ছাসেবক" },
        { id: 7, value: "Volunteering for counseling services", banglaValue: "কাউন্সেলিং পরিষেবার জন্য স্বেচ্ছাসেবক" },
        { id: 8, value: "Any other volunteering activities", banglaValue: "অন্য কোন স্বেচ্ছাসেবী কার্যক্রম" },
        { id: 9, value: "No preference", banglaValue: "কোন পছন্দ নেই" }
    ]);

    const handleOptionChange = (option) => {
        if (checkedValues.includes(option)) {
            setCheckedValues(checkedValues.filter((item) => item !== option));
        } else {
            setCheckedValues([...checkedValues, option]);
        }
    };
    const handleOptionChange2 = (option) => {
        if (option === "Other") {
            setOther(!other);
        }
        if (checkedValues2.includes(option)) {
            setCheckedValues2(checkedValues2.filter((item) => item !== option));
        } else {
            setCheckedValues2([...checkedValues2, option]);
        }
    }; 
    const handleOptionChange3 = (option) => {
        if (checkedValues3.includes(option)) {
            setCheckedValues3(checkedValues3.filter((item) => item !== option));
        } else {
            setCheckedValues3([...checkedValues3, option]);
        }
    }; 

    const [formData2, setFormData2]=useState({
      newGender: "",
      newEconomicStatus: "",
      newBackground: "",
      newProfileType: "",

      newStudyLevel: "",
      newStudyArea: "",
      newEnglishProficiency: "",
      newMentoringExperience: "",
      newTutoringExperience: "",
      newVolunteeringExperience: "",
      newVolunteeringActivities: "",

      newBio: "",
    });
    
    useEffect(()=>{
      setFormData2({
        newGender: volunteerInfos?.gender, 
        newEconomicStatus: volunteerInfos?.economicStatus, 
        newBackground: volunteerInfos?.background, 
        // newProfileType: volunteer?.profileType, 

        newStudyLevel: volunteerInfos?.studyLevel, 
        newStudyArea: volunteerInfos?.studyArea, 
        newEnglishProficiency: volunteerInfos?.englishProficiency, 
        newMentoringExperience: volunteerInfos?.mentoringExperience, 
        newTutoringExperience: volunteerInfos?.tutoringExperience, 
        newVolunteeringExperience: volunteerInfos?.volunteeringExperience, 
        newVolunteeringActivities: volunteerInfos?.volunteeringActivities, 
        
        // newBio: volunteer?.bio, 
      });
    },[volunteer]);

    const inputChangeHandler=(e,key)=>{
      setFormData2({
        ...formData2, [key]:e.target.value
      })
    };
    const inputChangeHandler2 = (e, key) => {
        const value = e.target.value;
        const wordCount = value.trim().split(/\s+/).length;

        if (wordCount <= 100) {
            setFormData2({
              ...formData2,
              newBio: value
            });
          }
    };

    const inputRefs = {
        // newFirstName: useRef(null),
        // newLastName: useRef(null),
        // newEmail: useRef(null),
        newBirthDate: useRef(null),
        newNationality: useRef(null),
        newDistrict: useRef(null),
        newCity: useRef(null),
        newMobile: useRef(null),

        newInstitute: useRef(null),
        newStudyAreaOfOther: useRef(null),
        newDetailActivities: useRef(null),
        newExperienceDescription: useRef(null),
        newOtherVolunteeredOrganizations: useRef(null),
    };
    // Submit form
    const handleSubmit = (e, id) => {
        e.preventDefault();
        const newStudyAreaOfOtherValue = inputRefs.newStudyAreaOfOther.current ? inputRefs.newStudyAreaOfOther.current.value : '';
        const newOtherVolunteeredOrganizationsValue = inputRefs.newOtherVolunteeredOrganizations.current ? inputRefs.newOtherVolunteeredOrganizations.current.value : '';
        const formData = {
            // newFirstName: inputRefs.newFirstName.current.value,
            // newLastName: inputRefs.newLastName.current.value,
            // newEmail: inputRefs.newEmail.current.value,
            newBirthDate: inputRefs.newBirthDate.current.value,
            newNationality: inputRefs.newNationality.current.value,
            newDistrict: inputRefs.newDistrict.current.value,
            newCity: inputRefs.newCity.current.value,
            newMobile: phone,

            newInstitute: inputRefs.newInstitute.current.value,
            newStudyAreaOfOther: newStudyAreaOfOtherValue,
            newDetailActivities: inputRefs.newDetailActivities.current.value,
            newExperienceDescription: inputRefs.newExperienceDescription.current.value,
            newOtherVolunteeredOrganizations: newOtherVolunteeredOrganizationsValue,
        };

        let dataToUpdate = {
            // firstName: formData.newFirstName, 
            // lastName: formData.newLastName, 
            // email: formData.newEmail, 
            birthDate: formData.newBirthDate, 
            gender: formData2.newGender, 
            nationality: formData.newNationality, 
            district: formData.newDistrict, 
            city: formData.newCity, 
            economicStatus: formData2.newEconomicStatus, 
            mobile: formData.newMobile, 
            background: formData2.newBackground, 
            // profileType: formData2.newProfileType,
            // bio: formData2.newBio,

            studyLevel: formData2.newStudyLevel, 
            institute: formData.newInstitute, 
            studyArea: formData2.newStudyArea, 
            otherStudyArea: formData.newStudyAreaOfOther, 
            englishProficiency: formData2.newEnglishProficiency, 
            mentoringExperience: formData2.newMentoringExperience, 
            tutoringExperience: formData2.newTutoringExperience,
            tutoredStudentsLevel: checkedValues,
            volunteeringExperience: formData2.newVolunteeringExperience,
            volunteeredOrganizations: checkedValues2,
            otherVolunteeredOrganizations: formData.newOtherVolunteeredOrganizations,
            detailActivities: formData.newDetailActivities, 
            experienceDescription: formData.newExperienceDescription, 
            areaOfInterest: checkedValues3,
            volunteeringActivities: formData2.newVolunteeringActivities,
            id: id 
        };
        
        if(signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin'){
            Axios.put(`${BaseUrl}/update`, dataToUpdate).then(
            (response) => {
                setPersonalInfo(
                personalInfo.map((val) => {
                    return val.id === id
                    ? {
                        id: val.id,
                        // firstName: val.newFirstName,
                        // lastName: val.newLastName,
                        // email: val.newEmail,
                        birthDate: val.newBirthDate,
                        gender: val.newGender,
                        nationality: val.newNationality,
                        district: val.newDistrict,
                        city: val.newCity,
                        economicStatus: val.newEconomicStatus,
                        mobile: val.newMobile,
                        background: val.newBackground,
                        // profileType: val.newProfileType,
                        // bio: val.newBio,
            
                        studyLevel: val.newStudyLevel, 
                        institute: val.newInstitute, 
                        studyArea: val.newStudyArea, 
                        otherStudyArea: val.newStudyAreaOfOther, 
                        englishProficiency: val.newEnglishProficiency, 
                        mentoringExperience: val.newMentoringExperience, 
                        tutoringExperience: val.newTutoringExperience,
                        tutoredStudentsLevel: val.checkedValues,
                        volunteeringExperience: val.newVolunteeringExperience,
                        volunteeredOrganizations: val.checkedValues2,
                        otherVolunteeredOrganizations: val.newOtherVolunteeredOrganizations,
                        detailActivities: val.newDetailActivities,   
                        experienceDescription: val.newExperienceDescription, 
                        areaOfInterest: val.checkedValues3,
                        volunteeringActivities: val.newVolunteeringActivities, 
                        }
                    : val;
                })
                );
            }
            );
            Axios.get(`${BaseUrl}/volunteer-profile-update/${volunteerId}`).then((response) => {
                setVolunteer(response.data);
            });
            toast.success("Profile updated!");
            navigate(`/volunteer-profile/${volunteer?.id}`);
        }else{
            toast.error("You don't have sufficient privilege!");
        }
    };

    // Upload Profile Image
    const [file, setFile] = useState(null);
    const id = volunteer?.id;
    useEffect(() => {
        if (file) {
        let formData = new FormData();
        formData.append("profileImg", file);
        formData.append("id", id);
        Axios.post(`${BaseUrl}/upload-profile-image`, formData, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        }).then(() => {
            Axios.get(`${BaseUrl}/volunteer-profile-update/${volunteerId}`).then((response) => {
                setVolunteer(response.data);
            });
            toast.success("Successfully uploaded!");
        });
        };
    }, [file, id]);
    
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Update Info - Telementoring</title>
            <meta name='title' content='Update Info - Telementoring' />
            <meta name="description" content="Update your user information for the Telementoring platform." />
            <meta name="keywords" content="telementoring, update info, personal information" />

        </Helmet>
        <div>
            <img src={ProfileBg} alt='profile page bg' className='w-screen h-44' />
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 lg:flex md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-5 mb-12'>
            <div className='lg:w-[25%] md:w-[30%] w-[100%] -mt-12 md:px-0 px-5'>
                <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-8 shadow-md sticky top-20'>
                    <div className='flex justify-center lg:-mt-20 -mt-[4.5rem] relative'>
                        <input type="file" id="fileInput" className="hidden" onChange={(e) => {
                            setFile(e.target.files[0]);
                        }} />
                        <label htmlFor="fileInput" className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center group cursor-pointer'>
                            <span className='hidden group-hover:block transition-all'>
                                { language === 'en' ?
                                    <span className='flex flex-col font-semibold opacity-40'>
                                        <span>Select</span>
                                        <span>Photo</span>
                                    </span>
                                    :
                                    <span className='flex flex-col font-semibold opacity-40 text-sm'>
                                        <span>ছবি</span>
                                        <span>নির্বাচন</span>
                                        <span>করুন</span>
                                    </span>
                                }
                            </span>
                            <span className='block group-hover:hidden transition-all'>
                                { volunteer?.profileImageUrl ?
                                    <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${volunteer?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                    :
                                    <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                }
                            </span>
                            <span className='text-white text-lg absolute bottom-0 lg:right-14 md:right-12 right-24 bg-slate-400 rounded-full p-2'><MdAddAPhoto/></span>
                        </label>
                    </div>
                    <div className='text-center mt-2'>
                        {volunteer?.firstName
                        ?
                            <p className='text-xl font-bold'>{volunteer?.firstName} {volunteer?.lastName}</p>
                        :
                            <p className='text-xl font-bold'>{userName}</p>
                        }
                        <p className='lg:text-base text-sm text-[#777B84]'>{volunteer?.email}</p>
                    </div>
                    
                    { language === 'en' ?
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "personalInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "personalInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#personalInformation" onClick={() => {setActiveLink("personalInfo")}}>Personal Information</a>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "academicInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "academicInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#academicInformation" onClick={() => {setActiveLink("academicInfo")}}>Academic Information</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "skillsExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "skillsExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#skillsExperience" onClick={() => {setActiveLink("skillsExperience")}}>Skills / Experience</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "tutoringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "tutoringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#tutoringExperience" onClick={() => {setActiveLink("tutoringExperience")}}>Tutoring Experience</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "volunteeringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "volunteeringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#volunteeringExperience" onClick={() => {setActiveLink("volunteeringExperience")}}>Volunteering Experience</a>
                            </li>
                            
                        </ul>
                        :
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "personalInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "personalInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#personalInformation" className='banglaFont' onClick={() => {setActiveLink("personalInfo")}}>ব্যক্তিগত তথ্য</a>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "academicInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "academicInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#academicInformation" className='banglaFont' onClick={() => {setActiveLink("academicInfo")}}>একাডেমিক তথ্য</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "skillsExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "skillsExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#skillsExperience" className='banglaFont' onClick={() => {setActiveLink("skillsExperience")}}>দক্ষতা/অভিজ্ঞতা</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "tutoringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "tutoringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#tutoringExperience" className='banglaFont' onClick={() => {setActiveLink("tutoringExperience")}}>টিউটরিং অভিজ্ঞতা</a>
                            </li>
                            
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "volunteeringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "volunteeringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#volunteeringExperience" className='banglaFont' onClick={() => {setActiveLink("volunteeringExperience")}}>স্বেচ্ছাসেবক অভিজ্ঞতা</a>
                            </li>
                        </ul>
                    }
                </div>
            </div>
            <div className='lg:w-[75%] md:w-[70%] w-[100%] md:px-0 px-5 py-10'>
                { language === 'en' ?
                    <form onSubmit={(e) => handleSubmit(e, volunteerInfos?.id)} >
                    {/* Personal Information */}
                        <div id='personalInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Personal Information</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                {/* <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>First Name </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='First Name'
                                    ref={inputRefs.newFirstName}
                                    defaultValue={volunteer?.firstName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Last Name</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Last Name'
                                    ref={inputRefs.newLastName}
                                    defaultValue={volunteer?.lastName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Email </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="email"
                                    ref={inputRefs.newEmail}
                                    defaultValue={volunteer?.email}
                                    placeholder='hello@gmail.com'
                                    />
                                </div> */}
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Date of Birth </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="date"
                                    ref={inputRefs.newBirthDate}
                                    defaultValue={volunteerInfos?.birthDate}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033]'>Gender </label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newGender}
                                    onChange={(e)=>{inputChangeHandler(e,"newGender")}}
                                    >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Nationality </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your Nationality'
                                    ref={inputRefs.newNationality}
                                    defaultValue={volunteerInfos?.nationality}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>District, state, or province </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your District, state, or province'
                                    ref={inputRefs.newDistrict}
                                    defaultValue={volunteerInfos?.district}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Subdistrict, city or town </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your Subdistrict, city or town'
                                    ref={inputRefs.newCity}
                                    defaultValue={volunteerInfos?.city}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2'>How would you classify your family&apos;s economic status?</label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    
                                        value={formData2?.newEconomicStatus}
                                        onChange={(e)=>{inputChangeHandler(e,"newEconomicStatus")}}
                                    >
                                        <option value="High income">High income</option>
                                        <option value="Upper middle income">Upper middle income</option>
                                        <option value="Lower middle income">Lower middle income</option>
                                        <option value="Low income">Low income</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] my-2'>Mobile Number </label><br/>
                                    <PhoneInput 
                                    ref={inputRefs.newMobile}
                                    className='mt-2 w-full'
                                    country={'bd'}
                                    enableSearch={true}
                                    placeholder="+880"
                                    value={phone}
                                    onChange={(value) => {
                                        setPhone(value);
                                        inputRefs.newMobile.current.value = value;
                                    }}
                                />
                                </div>
                                <div>
                                    <label htmlFor="whereFrom" className='text-base font-semibold text-[#2E3033] my-2'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)? </label><br/>
                                    <p className='text-[#777B84] text-xs pb-2 pt-1'>If you grew up in a village area, choose &apos;Rural&apos;. If you grew up in a Thana or District center, choose &apos;Semi urban&apos;. If you grew up in a metropolitan area (e.g., Dhaka, Chattogram, etc.), choose &apos;Urban&apos;.</p>
                                    <select 
                                    id='whereFrom' name='whereFrom'
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    ref={inputRefs.newBackground}
                                    value={formData2?.newBackground}
                                    onChange={(e)=>{inputChangeHandler(e,"newBackground")}}
                                    >
                                        <option value="Rural area">Rural area</option>
                                        <option value="Semi urban area">Semi urban area</option>
                                        <option value="Urban area">Urban area</option>
                                    </select>
                                </div>
                                {/* <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033]'>Profile Type </label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newProfileType}
                                    onChange={(e)=>{inputChangeHandler(e,"newProfileType")}}
                                    >
                                        <option value="Public">Public</option>
                                        <option value="Private">Private</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="bio" className='text-base font-semibold text-[#2E3033]'>
                                        Add Bio <span className='text-sm'>[Maximum 100 words]</span>
                                    </label>
                                    <br/>
                                    <textarea
                                        id="bio"
                                        name="bio"
                                        rows="4"
                                        cols="50"
                                        placeholder='Write something about yourself'
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        value={formData2?.newBio}
                                        onChange={inputChangeHandler2}
                                    />
                                    <p className={`text-xs text-right ${formData2?.newBio?.trim().split(/\s+/).length >= 100 ? 'text-red-500' : 'text-gray-800'}`}>
                                        {formData2?.newBio?.trim().split(/\s+/).length}/100
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        {/* Academic Information */}
                        <div className='my-12' id='academicInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Academic Information</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div>
                                    <label htmlFor="studyLevel" className='text-base font-semibold text-[#2E3033]'>What is your highest level of educational attainment? </label><br/>
                                    <select id="studyLevel" name="studyLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newStudyLevel}
                                    onChange={(e)=>{inputChangeHandler(e,"newStudyLevel")}}
                                    >
                                        <option value="Less than high school completion">Less than high school completion</option>
                                        <option value="High school completion (e.g., HSC/ A-Levels)">High school completion (e.g., HSC/ A-Levels)</option>
                                        <option value="Some college education but did not complete college">Some college education but did not complete college</option>
                                        <option value="Complete college education">Complete college education</option>
                                        <option value="More than college/university education">More than college/university education</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Name of the institution that you obtained your highest level of education </label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Ex: University of Dhaka'
                                    
                                    ref={inputRefs.newInstitute}
                                    defaultValue={volunteerInfos?.institute}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="studyArea" className='text-base font-semibold text-[#2E3033]'>Area of study (if you have attended college) </label><br/>
                                    <select id="studyArea" name="studyArea" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newStudyArea}
                                    onChange={(e)=>{inputChangeHandler(e,"newStudyArea")}}
                                    >
                                        <option value="Medical Sciences">Medical Science</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="Humanities">Humanities</option>
                                        <option value="Social Sciences">Social Sciences</option>
                                        <option value="Business Studies">Business Studies</option>
                                        <option value="Science">Science</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {formData2?.newStudyArea === 'Other' && (
                                        <input
                                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            placeholder='Type other area'
                                            type="text"
                                            ref={inputRefs.newStudyAreaOfOther}
                                            defaultValue={volunteerInfos?.otherStudyArea}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Skills and Experience */}
                        <div id='skillsExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Skills / Experience</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
                                <div>
                                    <label htmlFor="englishProficiency" className='text-base font-semibold text-[#2E3033]'>Your English fluency/proficiency is: </label><br/>
                                    <select id="englishProficiency" name="englishProficiency" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'

                                    value={formData2?.newEnglishProficiency}
                                    onChange={(e)=>{inputChangeHandler(e,"newEnglishProficiency")}}
                                    >
                                        <option value="No proficiency">No proficiency</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Advanced">Advanced</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="mentoringExperience" className='text-base font-semibold text-[#2E3033]'>Have you had any experience mentoring or tutoring others? </label><br/>
                                    <select id="mentoringExperience" name="mentoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        value={formData2?.newMentoringExperience}
                                        onChange={(e)=>{inputChangeHandler(e,"newMentoringExperience")}}
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tutoring Experience */}
                        <div className={`my-12 ${formData2.newMentoringExperience === 'No' ? 'hidden':'block'}`} id='tutoringExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Tutoring Experience</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div className='mt-5'>
                                    <label htmlFor="tutoringExperience" className='text-base font-semibold text-[#2E3033]'>How many years of tutoring experience do you have? </label><br/>
                                    <select id="tutoringExperience" name="tutoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    
                                    value={formData2?.newTutoringExperience}
                                    onChange={(e)=>{inputChangeHandler(e,"newTutoringExperience")}}
                                    >
                                        <option value="Less than 1 year">Less than 1 year</option>
                                        <option value="at least 1 year and less than 2 years">at least 1 year and less than 2 years</option>
                                        <option value="at least 2 years and less than 3 years">at least 2 years and less than 3 years</option>
                                        <option value="at least 3 years and less than 4 years">at least 3 years and less than 4 years</option>
                                        <option value="at least 4 years">at least 4 years</option>
                                    </select>
                                </div>
                                <div className='mt-5'>
                                    <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033]'>What grade level(s) of students have you tutored? </label>
                                    <div className='mt-2'>
                                        {options.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues.includes(option?.value)}
                                                    onChange={() => handleOptionChange(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.value}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering experience */}
                        <div id='volunteeringExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Volunteering experience</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div className='mt-5'>
                                    <label htmlFor="volunteeringExperience" className='text-base font-semibold text-[#2E3033]'>Do you have any volunteering experience? </label><br/>
                                    <select id="volunteeringExperience" name="volunteeringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newVolunteeringExperience}
                                    onChange={(e)=>{inputChangeHandler(e,"newVolunteeringExperience")}}
                                    >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                {/* This empty div is using as a grid item */}
                                <div></div>
                                <div className={`${formData2.newVolunteeringExperience === 'No' ? 'hidden':'block'}`}>
                                    <label htmlFor="volunteeredOrganizations" className='text-base font-semibold text-[#2E3033]'>What organization(s) have you volunteered for? </label>
                                    <div className='mt-2'>
                                        {options2.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues2.includes(option?.value)}
                                                    onChange={() => handleOptionChange2(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.value}
                                            </div>
                                        ))}
                                        {other === true || isOtherOrganizationsFound ? (
                                            <input 
                                            // {...register('otherValue')} 
                                            ref={inputRefs.newOtherVolunteeredOrganizations}
                                            defaultValue={volunteerInfos?.otherVolunteeredOrganizations}
                                            type="text" placeholder="Please specify" 
                                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' />)
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className={`${formData2.newVolunteeringExperience === 'No' ? 'hidden':'block'}`}>
                                    <label htmlFor="detailActivities" className='text-base font-semibold text-[#2E3033]'>Please provide some details of the volunteering activities/services that you have given: </label><br/>
                                    <textarea 
                                    id="detailActivities" name="detailActivities" 
                                    rows="4" cols="50" placeholder='Your answer' 
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                    ref={inputRefs.newDetailActivities}
                                    defaultValue={volunteerInfos?.detailActivities}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Other experiences</p>
                            </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='mt-5'>
                                    <label htmlFor="experienceDescription" className='text-base font-semibold text-[#2E3033]'>If you have any other relevant experiences, please list them here: </label><br/>
                                    <p className='text-[#777B84] pb-2 pt-1'>Give a short description</p>
                                    <textarea 
                                        id="experienceDescription" 
                                        name="experienceDescription" 
                                        rows="4" cols="50" 
                                        placeholder='Your answer' 
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                        
                                        ref={inputRefs.newExperienceDescription}
                                        defaultValue={volunteerInfos?.experienceDescription}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Volunteering (or Mentoring) preferences</p>
                            </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='mt-5'>
                                    <label htmlFor="areaOfInterest" className='text-base font-semibold text-[#2E3033]'>What is your area of interest for volunteering or mentoring? </label>
                                    <p className='text-[#777B84] text-sm pb-2 pt-1'>When there is an opportunity for volunteering or mentoring with us, which area would you like to be involved with? You can choose more than one or all options.</p>
                                    <div className='mt-2'>
                                        {options3.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues3.includes(option?.value)}
                                                    onChange={() => handleOptionChange3(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.value}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <label htmlFor="volunteeringActivities" className='text-base font-semibold text-[#2E3033]'>How many hours would you prefer to participate in volunteering/mentoring activities? </label><br/>
                                    <select id="volunteeringActivities" name="volunteeringActivities" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    
                                    value={formData2?.newVolunteeringActivities}
                                    onChange={(e)=>{inputChangeHandler(e,"newVolunteeringActivities")}}
                                    // {...register("volunteeringActivities")}
                                    >
                                        <option value="Less than 1 hour per week">Less than 1 hour per week</option>
                                        <option value="1 hour per week">1 hour per week</option>
                                        <option value="More than 1 hour and less than 2 hours per week">More than 1 hour and less than 2 hours per week</option>
                                        <option value="2 hours per week">2 hours per week</option>
                                        <option value="More than 2 hours and less than 3 hours per week">More than 2 hours and less than 3 hours per week</option>
                                        <option value="3 hours per week">3 hours per week</option>
                                        <option value="More than 3 hours and less than 4 hours per week">More than 3 hours and less than 4 hours per week</option>
                                        <option value="4 hours per week">4 hours per week</option>
                                        <option value="More than 4 hours per week">More than 4 hours per week</option>
                                        <option value="No preference">No preference</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className='w-full flex justify-end'>
                            <input type="submit" value='Update' className='bg-blue-500 rounded-md text-white hover:bg-blue-600 transition-all px-12 py-2 inline-block my-5 mr-2 cursor-pointer' />
                        </div>
                    </form>
                    :
                    <form onSubmit={(e) => handleSubmit(e, volunteerInfos?.id)} >
                        {/* Personal Information */}
                        <div id='personalInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>ব্যক্তিগত তথ্য</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                {/* <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের প্রথম অংশ</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='নামের প্রথম অংশ'
                                    ref={inputRefs.newFirstName}
                                    defaultValue={volunteer?.firstName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের শেষ অংশ</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='নামের শেষ অংশ'
                                    ref={inputRefs.newLastName}
                                    defaultValue={volunteer?.lastName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>ইমেইল</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="email"
                                    ref={inputRefs.newEmail}
                                    defaultValue={volunteer?.email}
                                    placeholder='hello@gmail.com'
                                    />
                                </div> */}
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জন্ম তারিখ</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="date"
                                    ref={inputRefs.newBirthDate}
                                    defaultValue={volunteerInfos?.birthDate}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033] banglaFont'>লিঙ্গ </label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newGender}
                                    onChange={(e)=>{inputChangeHandler(e,"newGender")}}
                                    >
                                        <option value="Male">পুরুষ</option>
                                        <option value="Female">মহিলা</option>
                                        <option value="Prefer not to say">বলতে অনিচ্ছুক</option>
                                        <option value="Other">অন্যান্য</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জাতীয়তা</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='আপনার জাতীয়তা'
                                    ref={inputRefs.newNationality}
                                    defaultValue={volunteerInfos?.nationality}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জেলা</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='আপনার জেলা'
                                    ref={inputRefs.newDistrict}
                                    defaultValue={volunteerInfos?.district}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>উপজেলা</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='আপনার উপজেলা'
                                    ref={inputRefs.newCity}
                                    defaultValue={volunteerInfos?.city}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2 banglaFont'>আপনার পরিবারের অর্থনৈতিক অবস্থা</label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        value={formData2?.newEconomicStatus}
                                        onChange={(e)=>{inputChangeHandler(e,"newEconomicStatus")}}
                                    >
                                        <option value="High income">উচ্চবিত্ত</option>
                                        <option value="Upper middle income">উচ্চ মধ্যবিত্ত</option>
                                        <option value="Lower middle income">নিম্ন মধ্যবিত্ত</option>
                                        <option value="Low income">নিম্নবিত্ত</option>
                                        <option value="Prefer not to say">বলতে অনিচ্ছুক</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] my-2 banglaFont'>মোবাইল</label><br/>
                                    <PhoneInput 
                                    ref={inputRefs.newMobile}
                                    className='mt-2 w-full'
                                    country={'bd'}
                                    enableSearch={true}
                                    placeholder="+880"
                                    value={phone}
                                    onChange={(value) => {
                                        setPhone(value);
                                        inputRefs.newMobile.current.value = value;
                                    }}
                                />
                                </div>
                                <div>
                                    <label htmlFor="whereFrom" className='text-base font-semibold text-[#2E3033] my-2 banglaFont'>আপনি প্রাথমিকভাবে আপনার শৈশব এবং কৈশোরকালে (অর্থাৎ, 18 বছর বয়সের আগে) কোথায় বড় হয়েছেন?</label><br/>
                                    <p className='text-[#777B84] text-xs pb-2 pt-1 banglaFont'>আপনি যদি গ্রামে বড় হয়ে থাকেন তবে গ্রামীণ এলাকা নির্বাচন করুন। আপনি যদি একটি থানা বা জেলা কেন্দ্রে বেড়ে ওঠেন, আধা শহুরে এলাকা বেছে নিন। আপনি যদি একটি মেট্রোপলিটন এলাকায় বড় হয়ে থাকেন (যেমন, ঢাকা, চট্টগ্রাম, ইত্যাদি), শহুরে এলাকা বেছে নিন।</p>
                                    <select 
                                    id='whereFrom' name='whereFrom'
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    ref={inputRefs.newBackground}
                                    value={formData2?.newBackground}
                                    onChange={(e)=>{inputChangeHandler(e,"newBackground")}}
                                    >
                                        <option value="Rural area">গ্রামীণ এলাকা</option>
                                        <option value="Semi urban area">আধা শহুরে এলাকা</option>
                                        <option value="Urban area">শহুরে এলাকা</option>
                                    </select>
                                </div>
                                {/* <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033] banglaFont'>প্রোফাইলের ধরণ</label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newProfileType}
                                    onChange={(e)=>{inputChangeHandler(e,"newProfileType")}}
                                    >
                                        <option value="Public">পাবলিক</option>
                                        <option value="Private">প্রাইভেট</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="bio" className='text-base font-semibold text-[#2E3033] banglaFont'>বায়ো যুক্ত করুন <span className='text-sm'>[সর্বোচ্চ ১০০ শব্দ]</span>
                                    </label>
                                    <br/>
                                    <textarea
                                        id="bio"
                                        name="bio"
                                        rows="4"
                                        cols="50"
                                        placeholder='আপনার সম্পর্কে কিছু লিখুন'
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        value={formData2?.newBio}
                                        onChange={inputChangeHandler2}
                                    />
                                    <p className={`text-xs text-right ${formData2?.newBio?.trim().split(/\s+/).length >= 100 ? 'text-red-500' : 'text-gray-800'}`}>
                                        {formData2?.newBio?.trim().split(/\s+/).length}/100
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        {/* Academic Information */}
                        <div className='my-12' id='academicInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>একাডেমিক তথ্য</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div>
                                    <label htmlFor="studyLevel" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার শিক্ষাগত অর্জনের সর্বোচ্চ স্তর কি?</label><br/>
                                    <select id="studyLevel" name="studyLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newStudyLevel}
                                    onChange={(e)=>{inputChangeHandler(e,"newStudyLevel")}}
                                    >
                                        <option value="Less than high school completion">উচ্চ বিদ্যালয়ের চেয়ে কম</option>
                                        <option value="High school completion (e.g., HSC/ A-Levels)">উচ্চ বিদ্যালয় শেষ (যেমন, এইচএসসি/ এ-লেভেল)</option>
                                        <option value="Some college education but did not complete college">কলেজে লেখাপড়া করলেও কলেজ শেষ করেননি</option>
                                        <option value="Complete college education">কলেজ শিক্ষা সম্পূর্ণ</option>
                                        <option value="More than college/university education">কলেজ/বিশ্ববিদ্যালয় শিক্ষার চেয়েও বেশি</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি আপনার সর্বোচ্চ স্তরের শিক্ষা অর্জন করেছেন এমন প্রতিষ্ঠানের নাম</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='উদাহরণঃ ঢাকা বিশ্ববিদ্যালয়'
                                    ref={inputRefs.newInstitute}
                                    defaultValue={volunteerInfos?.institute}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="studyArea" className='text-base font-semibold text-[#2E3033] banglaFont'>অধ্যয়নের ক্ষেত্র (যদি আপনি কলেজে পড়ে থাকেন)</label><br/>
                                    <select id="studyArea" name="studyArea" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newStudyArea}
                                    onChange={(e)=>{inputChangeHandler(e,"newStudyArea")}}
                                    >
                                        <option value="Medical Sciences">চিকিৎসা বিজ্ঞান</option>
                                        <option value="Engineering">প্রকৌশল</option>
                                        <option value="Humanities">মানবিক</option>
                                        <option value="Social Sciences">সামাজিক বিজ্ঞান</option>
                                        <option value="Business Studies">ব্যাবসা শিক্ষা</option>
                                        <option value="Science">বিজ্ঞান</option>
                                        <option value="Other">অন্যান্য</option>
                                    </select>
                                    {formData2?.newStudyArea === 'Other' && (
                                        <input
                                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            placeholder='অন্যান্য ক্ষেত্র লিখুন'
                                            type="text"
                                            ref={inputRefs.newStudyAreaOfOther}
                                            defaultValue={volunteerInfos?.otherStudyArea}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Skills and Experience */}
                        <div id='skillsExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>দক্ষতা/অভিজ্ঞতা</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
                                <div>
                                    <label htmlFor="englishProficiency" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার ইংরেজি দক্ষতা:</label><br/>
                                    <select id="englishProficiency" name="englishProficiency" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'

                                    value={formData2?.newEnglishProficiency}
                                    onChange={(e)=>{inputChangeHandler(e,"newEnglishProficiency")}}
                                    >
                                        <option value="No proficiency">দক্ষতা নেই</option>
                                        <option value="Basic">প্রাথমিক</option>
                                        <option value="Intermediate">মধ্যবর্তী</option>
                                        <option value="Advanced">উন্নত</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="mentoringExperience" className='text-base font-semibold text-[rgb(46,48,51)] banglaFont'>আপনার কি অন্যদের পরামর্শ দেওয়ার বা টিউটর করার অভিজ্ঞতা আছে?</label><br/>
                                    <select id="mentoringExperience" name="mentoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        value={formData2?.newMentoringExperience}
                                        onChange={(e)=>{inputChangeHandler(e,"newMentoringExperience")}}
                                    >
                                        <option value="Yes">হ্যাঁ</option>
                                        <option value="No">না</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tutoring Experience */}
                        <div className={`my-12 ${formData2.newMentoringExperience === 'No' ? 'hidden':'block'}`} id='tutoringExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>টিউটরিং অভিজ্ঞতা</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div className='mt-5'>
                                    <label htmlFor="tutoringExperience" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার কত বছরের টিউটরিং অভিজ্ঞতা আছে?</label><br/>
                                    <select id="tutoringExperience" name="tutoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    
                                    value={formData2?.newTutoringExperience}
                                    onChange={(e)=>{inputChangeHandler(e,"newTutoringExperience")}}
                                    // {...register("tutoringExperience")}
                                    >
                                        <option value="Less than 1 year">১ বছরের কম</option>
                                        <option value="at least 1 year and less than 2 years">কমপক্ষে ১ বছর এবং ২ বছরের কম</option>
                                        <option value="at least 2 years and less than 3 years">কমপক্ষে 2 বছর এবং 3 বছরের কম</option>
                                        <option value="at least 3 years and less than 4 years">কমপক্ষে 3 বছর এবং 4 বছরের কম</option>
                                        <option value="at least 4 years">কমপক্ষে 4 বছর</option>
                                    </select>
                                </div>
                                <div className='mt-5'>
                                    <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি ছাত্রদের কোন গ্রেড স্তরগুলি টিউটরিং করেছেন?</label>
                                    <div className='mt-2 banglaFont'>
                                        {options.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues.includes(option?.value)}
                                                    onChange={() => handleOptionChange(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.banglaValue}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering experience */}
                        <div id='volunteeringExperience'>
                            <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>স্বেচ্ছাসেবক অভিজ্ঞতা</p>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                <div className='mt-5'>
                                    <label htmlFor="volunteeringExperience" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার কোনো স্বেচ্ছাসেবক অভিজ্ঞতা আছে?</label><br/>
                                    <select id="volunteeringExperience" name="volunteeringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newVolunteeringExperience}
                                    onChange={(e)=>{inputChangeHandler(e,"newVolunteeringExperience")}}
                                    >
                                        <option value="Yes">হ্যাঁ</option>
                                        <option value="No">না</option>
                                    </select>
                                </div>
                                {/* This empty div is using as a grid item */}
                                <div></div>
                                <div className={`${formData2.newVolunteeringExperience === 'No' ? 'hidden':'block'}`}>
                                    <label htmlFor="volunteeredOrganizations" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি কোন সংস্থায় স্বেচ্ছাসেবক ছিলেন?</label>
                                    <div className='mt-2'>
                                        {options2.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues2.includes(option?.value)}
                                                    onChange={() => handleOptionChange2(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.banglaValue}
                                            </div>
                                        ))}
                                        {other === true || isOtherOrganizationsFound ? (
                                            <input 
                                            // {...register('otherValue')} 
                                            ref={inputRefs.newOtherVolunteeredOrganizations}
                                            defaultValue={volunteerInfos?.otherVolunteeredOrganizations}
                                            type="text" placeholder="উল্লেখ করুন" 
                                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'  />
                                            )
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                                <div className={`${formData2.newVolunteeringExperience === 'No' ? 'hidden':'block'}`}>
                                    <label htmlFor="detailActivities" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি যে স্বেচ্ছাসেবী কার্যক্রম/পরিষেবা দিয়েছেন তার কিছু বিশদ বিবরণ দিন:</label><br/>
                                    <textarea 
                                    id="detailActivities" name="detailActivities" 
                                    rows="4" cols="50" placeholder='আপনার বিবরণ' 
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                    ref={inputRefs.newDetailActivities}
                                    defaultValue={volunteer?.detailActivities}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>অন্যান্য অভিজ্ঞতা</p>
                            </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='mt-5'>
                                    <label htmlFor="experienceDescription" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার যদি অন্য কোন প্রাসঙ্গিক অভিজ্ঞতা থাকে, অনুগ্রহ করে সেগুলি এখানে তালিকাভুক্ত করুন: </label><br/>
                                    <p className='text-[#777B84] pb-2 pt-1 banglaFont'>একটি সংক্ষিপ্ত বিবরণ দিন</p>
                                    <textarea 
                                        id="experienceDescription" 
                                        name="experienceDescription" 
                                        rows="4" cols="50" 
                                        placeholder='আপনার বিবরণ' 
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                        
                                        ref={inputRefs.newExperienceDescription}
                                        defaultValue={volunteer?.experienceDescription}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>স্বেচ্ছাসেবী (বা পরামর্শ) পছন্দ</p>
                            </div>
                            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='mt-5'>
                                    <label htmlFor="areaOfInterest" className='text-base font-semibold text-[#2E3033] banglaFont'>স্বেচ্ছাসেবক বা পরামর্শদানের জন্য আপনার আগ্রহের ক্ষেত্র কী?</label>
                                    <p className='text-[#777B84] text-sm pb-2 pt-1 banglaFont'>যখন আমাদের সাথে স্বেচ্ছাসেবক বা পরামর্শ দেওয়ার সুযোগ থাকে, আপনি কোন এলাকার সাথে জড়িত হতে চান? আপনি একাধিক বা সব বিকল্প চয়ন করতে পারেন.</p>
                                    <div className='mt-2'>
                                        {options3.map((option) => (
                                            <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                <input
                                                    type="checkbox"
                                                    id={option}
                                                    name={option?.value}
                                                    checked={checkedValues3.includes(option?.value)}
                                                    onChange={() => handleOptionChange3(option?.value)}
                                                    className='mr-2'
                                                />
                                                {option.banglaValue}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <label htmlFor="volunteeringActivities" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি কত ঘন্টা স্বেচ্ছাসেবী/পরামর্শদান কার্যক্রমে অংশগ্রহণ করতে পছন্দ করবেন?</label><br/>
                                    <select id="volunteeringActivities" name="volunteeringActivities" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    
                                    value={formData2?.newVolunteeringActivities}
                                    onChange={(e)=>{inputChangeHandler(e,"newVolunteeringActivities")}}
                                    // {...register("volunteeringActivities")}
                                    >
                                        <option value="Less than 1 hour per week">প্রতি সপ্তাহে 1 ঘন্টার কম</option>
                                        <option value="1 hour per week">প্রতি সপ্তাহে 1 ঘন্টা</option>
                                        <option value="More than 1 hour and less than 2 hours per week">প্রতি সপ্তাহে 1 ঘন্টার বেশি এবং 2 ঘন্টার কম</option>
                                        <option value="2 hours per week">প্রতি সপ্তাহে 2 ঘন্টা</option>
                                        <option value="More than 2 hours and less than 3 hours per week">প্রতি সপ্তাহে 2 ঘন্টার বেশি এবং 3 ঘন্টার কম</option>
                                        <option value="3 hours per week">প্রতি সপ্তাহে 3 ঘন্টা</option>
                                        <option value="More than 3 hours and less than 4 hours per week">প্রতি সপ্তাহে 3 ঘন্টার বেশি এবং 4 ঘন্টার কম</option>
                                        <option value="4 hours per week">প্রতি সপ্তাহে 4 ঘন্টা</option>
                                        <option value="More than 4 hours per week">প্রতি সপ্তাহে 4 ঘন্টার বেশি</option>
                                        <option value="No preference">কোন পছন্দ নেই</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className='w-full flex justify-end'>
                            <input type="submit" value='সাবমিট' className='bg-blue-500 rounded-md text-white hover:bg-blue-600 transition-all px-12 py-2 inline-block my-5 mr-2 cursor-pointer banglaFont' />
                        </div>
                    </form>
                }
            </div>
        </div>
        <ScrollToTop></ScrollToTop>
        <Footer></Footer>
    </div>
  );
};

export default UpdateVolunteerInfo