import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import auth from '../../firebase.init';
import BaseUrl from '../BaseURL';
import { MdAddAPhoto } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { FiAlertTriangle } from 'react-icons/fi';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import activeDot from '../../assets/activeDot.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import ScrollToTop from '../Common/ScrollToTop';
import { toast } from 'react-toastify';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';
import { useUserContext } from '../GlobalState/UserContext';

function UserInfo() {
    const { language } = useContext(GlobalContext);
    // const { signedInUser, signedInUserInfos } = useUserContext();
    
    const [user] = useAuthState(auth);
    // console.log(user)
    const { register, handleSubmit, formState:{ errors }, reset } = useForm();
    const [activeLink, setActiveLink] = useState("personalInfo");
    const [mobile, setMobile]=useState(0);
    const [userEmail, setUserEmail]=useState("");
    const [userName, setUserName]=useState("");
    const [personalInfo, setPersonalInfo] = useState([]);
    const [userInfos, setUserInfos] = useState([]);
    // console.log(personalInfo);
    const navigate = useNavigate();
    const location = useLocation();
  
    const signedInUser = personalInfo.find((person) => person?.userEmail === user?.email);
    const signedInUserInfos = userInfos.find(userInfo => userInfo.user_id === signedInUser?.id);

    if(signedInUserInfos?.birthDate && signedInUserInfos?.nationality && signedInUserInfos?.district){
        navigate('/');
    };
    
    // popup
    const [isOpenPopup, setIsOpenPopup] = useState(false);
  
    useEffect(()=>{
      setUserEmail(user?.email);
      setUserName(user?.displayName);
    },[user?.email, user?.displayName]);
  
    // Get infos
    useEffect(()=>{
      Axios.get(`${BaseUrl}/infos`).then((response) => {
        setPersonalInfo(response.data);
      });
      Axios.get(`${BaseUrl}/userInfos`).then((response) => {
        setUserInfos(response.data);
      });
    },[]);
    
    // get Email, firstName & lastName values
    const inputRefs = {
      email: useRef(null),
      firstName: useRef(null),
      lastName: useRef(null),
    };

    // Get Other selected value
    const [selectedOption, setSelectedOption] = useState("");
    // Get Checkbox's value
    const [checkedValues, setCheckedValues] = useState([]);
    const [checkedValues2, setCheckedValues2] = useState([]);
    const [checkedValues3, setCheckedValues3] = useState([]);
    const [other, setOther] = useState(false);

    const [options, setOptions] = useState([
        { id: 1, value: "Pre-primary school level (early childhood education and development)", banglaValue: "প্রাক-প্রাথমিক বিদ্যালয় স্তর (প্রাথমিক শৈশব শিক্ষা এবং বিকাশ)" },
        { id: 2, value: "Primary school level", banglaValue: "প্রাথমিক বিদ্যালয় স্তর" },
        { id: 3, value: "Lower secondary school level", banglaValue: "নিম্ন মাধ্যমিক বিদ্যালয় স্তর" },
        { id: 4, value: "Upper secondary school level", banglaValue: "উচ্চ মাধ্যমিক বিদ্যালয় স্তর" },
        { id: 5, value: "College level and above", banglaValue: "কলেজ স্তর এবং তার উপরে" }
    ]);
    const [options2, setOptions2] = useState([
        { id: 1, value: "United Nations", banglaValue: "জাতিসংঘ" },
        { id: 2, value: "International NGOs", banglaValue: "আন্তর্জাতিক এনজিও" },
        { id: 3, value: "Local NGOs", banglaValue: "স্থানীয় এনজিও" },
        { id: 4, value: "University based organizations", banglaValue: "বিশ্ববিদ্যালয় ভিত্তিক সংগঠন" },
        { id: 5, value: "Other", banglaValue: "অন্যান্য" }
    ]);
    const [options3, setOptions3] = useState([
        { id: 1, value: "Early childhood education and development", banglaValue: "প্রাথমিক শৈশব শিক্ষা এবং বিকাশ" },
        { id: 2, value: "Educating primary graders", banglaValue: "প্রাথমিক গ্রেডারদের শিক্ষা দেওয়া" },
        { id: 3, value: "Educating secondary graders", banglaValue: "মাধ্যমিক গ্রেডার্স শিক্ষিত" },
        { id: 4, value: "Educating SSC students", banglaValue: "এসএসসি শিক্ষার্থীদের শিক্ষাদান" },
        { id: 5, value: "Educating HSC students", banglaValue: "এইচএসসি শিক্ষার্থীদের শিক্ষিত করা" },
        { id: 6, value: "Volunteering for environmental issues", banglaValue: "পরিবেশগত সমস্যাগুলির জন্য স্বেচ্ছাসেবক" },
        { id: 7, value: "Volunteering for counseling services", banglaValue: "কাউন্সেলিং পরিষেবার জন্য স্বেচ্ছাসেবক" },
        { id: 8, value: "Any other volunteering activities", banglaValue: "অন্য কোন স্বেচ্ছাসেবী কার্যক্রম" },
        { id: 9, value: "No preference", banglaValue: "কোন পছন্দ নেই" }
    ]);

    const handleChange = (e) => {
        const { value } = e.target;
        const index = checkedValues.indexOf(value);
        if (index === -1) {
        setCheckedValues([...checkedValues, value]);
        } else {
        checkedValues.splice(index, 1);
        setCheckedValues([...checkedValues]);
        }
    };
    const handleChange2 = (e) => {
        const { value } = e.target;
        if(value==='Other'){
            setOther(!other);
        }
        const index = checkedValues2.indexOf(value);
        if (index === -1) {
        setCheckedValues2([...checkedValues2, value]);
        } else {
        checkedValues2.splice(index, 1);
        setCheckedValues2([...checkedValues2]);
        }
    };
    const handleChange3 = (e) => {
        const { value } = e.target;
        const index = checkedValues2.indexOf(value);
        if (index === -1) {
        setCheckedValues3([...checkedValues3, value]);
        } else {
        checkedValues3.splice(index, 1);
        setCheckedValues3([...checkedValues3]);
        }
    };
    const [formData2, setFormData2]=useState({
        volExp: "",
        mentoringExp: "",
    });
    const inputChangeHandler=(e,key)=>{
        setFormData2({
            ...formData2, [key]:e.target.value
        })
    };

    // submit form
    const onSubmit = data => {
        const profileType = 'Private';
        const id = signedInUser?.id;
        // const inputData = {
        //     email: inputRefs.email.current.value,
        //     firstName: inputRefs.firstName.current.value,
        //     lastName: inputRefs.lastName.current.value,
        // }
        const tutoredStudentsLevel = checkedValues.length > 0 ? checkedValues.join(",") : "";
        const volunteeredOrganizations = checkedValues2.length > 0 ? checkedValues2.join(",") : "";
        const areaOfInterest = checkedValues3.length > 0 ? checkedValues3.join(",") : "";

        const isAdmin = signedInUser?.role === "Manager" || signedInUser?.role === "Partner";
        let updateFields = {
            // firstName: inputData.firstName,
            // lastName: inputData.lastName,
            // email: inputData.email,
            birthDate: data.birthDate,
            gender: data.gender,
            nationality: data.nationality,
            district: data.district,
            city: data.city,
            economicStatus: data.economicStatus,
            mobile: mobile,
            background: data.background,
            // profileType: profileType,
            id: id,
        };
        if (!isAdmin) {
            updateFields.studyLevel= data.studyLevel;
            updateFields.institute= data.institute;
            updateFields.studyArea= data.studyArea;
            updateFields.otherStudyArea= data.otherStudyArea;
            updateFields.englishProficiency= data.englishProficiency;
            updateFields.mentoringExperience= data.mentoringExperience;
            updateFields.tutoringExperience= data.tutoringExperience;
            updateFields.tutoredStudentsLevel= tutoredStudentsLevel;
            updateFields.volunteeringExperience= data.volunteeringExperience;
            updateFields.volunteeredOrganizations= volunteeredOrganizations;
            updateFields.otherVolunteeredOrganizations= data.otherVolunteeredOrganizations;
            updateFields.detailActivities= data.detailActivities;
            updateFields.experienceDescription= data.experienceDescription;
            updateFields.areaOfInterest= areaOfInterest;
            updateFields.volunteeringActivities= data.volunteeringActivities;
        };
        Axios.put(`${BaseUrl}/updatePersonalInfo`, updateFields)
            .then((response) => {
                setPersonalInfo(
                    personalInfo.map((val) => {
                    return val.id === id
                        ? {
                            ...val,
                            ...updateFields
                        }
                        : val;
                    })
                );
                Axios.get(`${BaseUrl}/infos`).then((response) => {
                    setPersonalInfo(response.data);
                });
                reset();
                toast.success("Successfully submitted!");
                setIsOpenPopup(true);
            }
        );
    };

    // Upload profile picture
    const [file, setFile] = useState(null);
    const id = signedInUser?.id;
    useEffect(() => {
        if (file) {
          let formData = new FormData();
          formData.append("profileImg", file);
          formData.append("id", id);
          Axios.post(`${BaseUrl}/upload-profile-image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(() => {
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
          });
        };
      }, [file, id]);

    // Make Profile Public
    const handleMakeProfilePublic = () => {
        const id = signedInUser?.id;
        Axios.put(`${BaseUrl}/updateProfileType`, { 
            profileType:"Public",
            id: id 
        }).then(
            (response) => {
                setPersonalInfo(
                    personalInfo.map((val) => {
                    return val.id === id
                        ? {
                            id: val.id,
                            profileType: val.profileType,
                        }
                        : val;
                    })
                );
                Axios.get(`${BaseUrl}/infos`).then((response) => {
                    setPersonalInfo(response.data);
                });
                toast.success("Your profile is Public now!");
                // setIsOpenPopup(false);
                // navigate("/my-profile");
                const anchor = document.createElement("a");
                anchor.href = "/my-profile";
                anchor.click();

                setIsOpenPopup(false);
            }
        );
    };
    // Make Profile Private
    const handleMakeProfilePrivate = () => {
        const id = signedInUser?.id;
        Axios.put(`${BaseUrl}/updateProfileType`, { 
            profileType:"Private",
            id: id 
        }).then(
            (response) => {
                setPersonalInfo(
                    personalInfo.map((val) => {
                    return val.id === id
                        ? {
                            id: val.id,
                            profileType: val.profileType,
                        }
                        : val;
                    })
                );
                Axios.get(`${BaseUrl}/infos`).then((response) => {
                    setPersonalInfo(response.data);
                });
                toast.success("Your profile is Private now!");
                // setIsOpenPopup(false);
                // navigate("/my-profile");
                const anchor = document.createElement("a");
                anchor.href = "/my-profile";
                anchor.click();

                setIsOpenPopup(false);
            }
        );
    };

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);


    // const onSubmitForm = data => {}
    // const [edit, setEdit] = useState('profileStyle');
    // const [userInfos, setUserInfos] = useState([]);
    // const signedInUserInfos = userInfos.find(userInfo => userInfo.user_id === signedInUser?.id);
    // // console.log(signedInUser);
    // // Get user_infos
    // useEffect(()=>{
    //     Axios.get(`${BaseUrl}/userInfos`).then((response) => {
    //       setUserInfos(response.data);
    //     });
    //   },[]);

    // const date = new Date(signedInUserInfos?.birthDate);
    // const day = date.getDate();
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    // const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

  return (
    <div>
        <Helmet>
            <title>User Info - Telementoring</title>
            <meta name='title' content='User Info - Telementoring' />
            <meta name="description" content="View and edit your user information for the Telementoring platform." />
            <meta name="keywords" content="telementoring, user info, personal information" />
        </Helmet>
        <div>
            <img src={ProfileBg} alt='profile page bg' className='w-screen h-44' />
        </div>

        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 lg:flex md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-5 mb-12 relative'>
            <div className='lg:w-[25%] md:w-[30%] w-[100%] -mt-12 md:px-0 px-5 '>
                <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-8 shadow-md sticky top-20'>
                    <div className='flex justify-center lg:-mt-20 -mt-[4.5rem] relative'>
                        <input type="file" id="fileInput" className="hidden" onChange={(e) => {
                            setFile(e.target.files[0]);
                        }} />
                        <label htmlFor="fileInput" className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center group cursor-pointer'>
                            <span className='hidden group-hover:block transition-all'>
                                { language === 'en' ?
                                    <span className='flex flex-col font-semibold opacity-40'>
                                        <span>Select</span>
                                        <span>Photo</span>
                                    </span>
                                    :
                                    <span className='flex flex-col font-semibold opacity-40 text-sm'>
                                        <span>ছবি</span>
                                        <span>নির্বাচন</span>
                                        <span>করুন</span>
                                    </span>
                                }
                            </span>
                            <span className='block group-hover:hidden transition-all'>
                                {/* { signedInUser?.profileImageUrl ?
                                    <img className='w-24 h-24 rounded-full' src={`${BaseUrl}${signedInUser?.profileImageUrl}`} alt="" referrerPolicy='no-referrer' />
                                    :
                                    <img className='w-full rounded-full' src={Avatar} alt="" referrerPolicy='no-referrer' />
                                } */}
                                { signedInUser?.profileImageUrl ?
                                    <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${signedInUser?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                    :
                                    <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                }
                            </span>
                            <span className='text-white text-lg absolute bottom-0 lg:right-14 md:right-12 right-24 bg-slate-400 rounded-full p-2'><MdAddAPhoto/></span>
                        </label>
                    </div>
                    <div className='text-center mt-2'>
                        {signedInUser?.firstName
                        ?
                            <p className='text-xl font-bold'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                        :
                            <p className='text-xl font-bold'>{userName}</p>
                        }
                        {signedInUser?.firstName
                        ?
                            <p className='text-base text-[#777B84]'>{signedInUser?.email}</p>
                        :
                            <p className='text-base text-[#777B84]'>{userEmail}</p>
                        }
                    </div>
                    { language === 'en' ?
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "personalInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "personalInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#personalInformation" onClick={() => {setActiveLink("personalInfo")}}>Personal Information</a>
                            </li>
                            <div className={`flex flex-col gap-3 ${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "academicInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "academicInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#academicInformation" onClick={() => {setActiveLink("academicInfo")}}>Academic Information</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "skillsExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "skillsExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#skillsExperience" onClick={() => {setActiveLink("skillsExperience")}}>Skills / Experience</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "tutoringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "tutoringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#tutoringExperience" onClick={() => {setActiveLink("tutoringExperience")}}>Tutoring Experience</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "volunteeringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "volunteeringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#volunteeringExperience" onClick={() => {setActiveLink("volunteeringExperience")}}>Volunteering Experience</a>
                                </li>
                            </div>
                        </ul>
                        :
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                activeLink === "personalInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                            >
                                <img src={activeDot} className={`${activeLink === "personalInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                <a href="#personalInformation" className='banglaFont' onClick={() => {setActiveLink("personalInfo")}}>ব্যক্তিগত তথ্য</a>
                            </li>
                            <div className={`flex flex-col gap-3 ${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "academicInfo" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "academicInfo" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#academicInformation" className='banglaFont' onClick={() => {setActiveLink("academicInfo")}}>একাডেমিক তথ্য</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "skillsExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "skillsExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#skillsExperience" className='banglaFont' onClick={() => {setActiveLink("skillsExperience")}}>দক্ষতা/অভিজ্ঞতা</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "tutoringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "tutoringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#tutoringExperience" className='banglaFont' onClick={() => {setActiveLink("tutoringExperience")}}>টিউটরিং অভিজ্ঞতা</a>
                                </li>
                                
                                <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md ${
                                    activeLink === "volunteeringExperience" ? "font-semibold bg-[#F6FAFF]" : ""
                                    }`}
                                >
                                    <img src={activeDot} className={`${activeLink === "volunteeringExperience" ? "block" : "hidden"}`} alt='activeDot' />
                                    <a href="#volunteeringExperience" className='banglaFont' onClick={() => {setActiveLink("volunteeringExperience")}}>স্বেচ্ছাসেবক অভিজ্ঞতা</a>
                                </li>
                            </div>
                        </ul>
                    }
                </div>
            </div>
            
            {/* Make Profile Popup */}
            {isOpenPopup && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                  <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                      <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
                    </div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white w-full md:px-8 md:py-20 p-8 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative">
                        { language === 'en' ?
                            <div>
                                <p className='text-center font-semibold mb-3'>Do you want to share your profile info with the viewers?</p>
                                <p className='text-sm text-justify'>We will not share your phone numbers in any case. If you choose a public profile, your name, affiliation and photo will be displayed in our volunteer list. If you choose a private profile, only your name will be displayed in the volunteer list.</p>
                                <p className='text-sm text-justify italic mt-2'>"You will be able to change your choice later from <b>profile settings</b>."</p>
                                <div className='flex justify-center items-center gap-5 mt-5'>
                                    <button onClick={() => handleMakeProfilePublic()} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Public</button>
                                    <button onClick={() => handleMakeProfilePrivate()} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Private</button>
                                </div>
                            </div>
                            :
                            <div>
                                <p className='text-center text-lg font-bold banglaFont mb-3'>আপনি কি আপনার প্রোফাইল পাবলিক নাকি প্রাইভেট রাখতে চান?</p>
                                
                                <p className='text-base text-justify banglaFont'>আমরা কখনোই আপনার ব্যক্তিগত ফোন নম্বর কারো সাথে শেয়ার করবো না। আপনি যদি আপনার প্রোফাইল পাবলিক রাখেন, তাহলে আপনার নাম, ই-মেইল, পেশা ও ছবি আমাদের স্বেচ্ছাসেবক তালিকায় প্রদর্শন করা হবে। আর আপনি যদি আপনার প্রোফাইল প্রাইভেট রাখেন, তাহলে শুধু আপনার নাম আমাদের স্বেচ্ছাসেবক তালিকায় প্রদর্শন করা হবে ।</p>
                                <p className='text-sm text-justify italic mt-2 banglaFont'>"আপনি পরবর্তীতে <b>প্রোফাইল সেটিংস</b> থেকে আপনার পছন্দটি পরিবর্তন কর‍তে পারবেন"</p>
                                <div className='flex justify-center items-center gap-5 mt-5'>
                                    <button onClick={() => handleMakeProfilePublic()} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>পাবলিক</button>
                                    {/* <button onClick={() => {
                                        setIsOpenPopup(false);
                                        navigate("/my-profile");
                                    }} 
                                        className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>না</button> */}
                                    <button onClick={() => handleMakeProfilePrivate()} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>প্রাইভেট</button>
                                </div>
                            </div>
                        }
                        <FaInfoCircle className='absolute -right-12 -bottom-5 text-9xl text-[#F74F22] opacity-10'/>
                    </div>
                  </div>
                </div>
            )}
            <div className='lg:w-[75%] md:w-[70%] w-[100%] lg:px-0 md:px-0 px-5 py-10'>
                <div className='flex justify-end w-full relative'>
                    {/* <Link className='bg-[#F74F22] rounded-md text-white px-5 py-1 absolute' to='/update-info'>Edit</Link> */}

                    {/* { signedInUser?.role !== "Manager" || signedInUser?.role !== "Partner" ? 
                        <Link className={`bg-[#F74F22] rounded-md text-white px-5 py-1 absolute ${signedInUser?.birthDate && signedInUser?.nationality && signedInUser?.institute && signedInUser?.district && signedInUser?.city && signedInUser?.economicStatus && signedInUser?.background ? 'block' : 'hidden'}`} to='/update-info'>Edit</Link>
                        :
                        <Link className={`bg-[#F74F22] rounded-md text-white px-5 py-1 absolute ${signedInUser?.birthDate && signedInUser?.nationality && signedInUser?.district && signedInUser?.city && signedInUser?.economicStatus && signedInUser?.background ? 'block' : 'hidden'}`} to='/update-info'>Edit</Link>
                    } */}
                    {/* { signedInUser?.role === "Manager" || signedInUser?.role === "Partner" ?
                        <Link className={`bg-[#F74F22] rounded-md text-white px-5 py-1 absolute ${signedInUser?.birthDate && signedInUser?.nationality && signedInUser?.district && signedInUser?.city && signedInUser?.economicStatus && signedInUser?.background ? 'block' : 'hidden'}`} to='/update-info'>Edit</Link>
                        :
                        <Link className={`bg-[#F74F22] rounded-md text-white px-5 py-1 absolute ${signedInUser?.birthDate && signedInUser?.nationality && signedInUser?.district && signedInUser?.city && signedInUser?.economicStatus && signedInUser?.background && signedInUser?.institute ? 'block' : 'hidden'}`} to='/update-info'>Edit</Link>
                    } */}
                </div>
                {/* <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div id='personalInformation' className='mb-12'>
                        <div className='flex justify-between items-center w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                            <p className='text-2xl font-bold'>Personal Information</p>
                            <span className={`${edit === 'profileStyle' ? 'block' : 'hidden'}`}><FiEdit onClick={()=> setEdit('formStyle')} title='Edit' className='cursor-pointer text-2xl' /></span>
                        </div>
                        <div className={`grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5  ${edit === 'formStyle' ? 'block' : 'hidden'}`}>
                            <div>
                                <label className='text-base font-semibold text-[#2E3033]'>Date of Birth<span className='text-red-500'> *</span></label><br/>
                                <input
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="date"
                                    {...register("birthDate", { required: 'Required field' })}
                                />
                                {errors.birthDate && <p className='text-red-500 text-sm mt-1'>{errors.birthDate.message}</p>}
                            </div>

                            <div>
                                <label htmlFor="gender" className='text-base font-semibold text-[#2E3033]'>Gender<span className='text-red-500'> *</span></label><br/>
                                <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    required
                                    {...register("gender", { required: 'Required field' })}
                                    >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Prefer not to say">Prefer not to say</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div>
                                <label className='text-base font-semibold text-[#2E3033]'>Nationality<span className='text-red-500'> *</span></label><br/>
                                <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                type="text"
                                placeholder='Your Nationality'
                                {...register("nationality", { required: 'Required field' })}
                                />
                                {errors.nationality && <p className='text-red-500 text-sm mt-1'>{errors.nationality.message}</p>}
                            </div>
                            <div>
                                <label className='text-base font-semibold text-[#2E3033]'>District, state, or province<span className='text-red-500'> *</span></label><br/>
                                <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                type="text"
                                placeholder='Your District, state, or province'
                                {...register("district", { required: 'Required field' })}
                                />
                                {errors.nationality && <p className='text-red-500 text-sm mt-1'>{errors.nationality.message}</p>}
                            </div>
                            <div>
                                <label className='text-base font-semibold text-[#2E3033]'>Subdistrict, city or town<span className='text-red-500'> *</span></label><br/>
                                <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                type="text"
                                placeholder='Your Subdistrict, city or town'
                                {...register("city", { required: 'Required field' })}
                                />
                                {errors.city && <p className='text-red-500 text-sm mt-1'>{errors.city.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2'>How would you classify your family&apos;s economic status?<span className='text-red-500'> *</span></label><br/>
                                <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    {...register("economicStatus")}
                                    defaultValue={"High income"}
                                >
                                    <option value="High income">High income</option>
                                    <option value="Upper middle income">Upper middle income</option>
                                    <option value="Lower middle income">Lower middle income</option>
                                    <option value="Low income">Low income</option>
                                    <option value="Prefer not to say">Prefer not to say</option>
                                </select>
                            </div>
                            <div>
                                <label className='text-base font-semibold text-[#2E3033] my-2'>Mobile Number<span className='text-red-500'> *</span></label><br/>
                                <PhoneInput className='mt-2 w-full'
                                    country={'bd'}
                                    enableSearch={true}
                                    value={mobile.phone}
                                    onChange={(phone) => setMobile({ phone })}
                                />
                            </div>
                            <div>
                                <label htmlFor="whereFrom" className='text-base font-semibold text-[#2E3033]'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)? <span className='text-red-500'> *</span></label>
                                <p className='text-[#777B84] text-xs pb-2 pt-1'>If you grew up in a village area, choose &apos;Rural&apos;. If you grew up in a Thana or District center, choose &apos;Semi urban&apos;. If you grew up in a metropolitan area (e.g., Dhaka, Chattogram, etc.), choose &apos;Urban&apos;.</p>
                                <select 
                                id='whereFrom' name='whereFrom'
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                {...register("background")}
                                >
                                    <option value="Rural area">Rural area</option>
                                    <option value="Semi urban area">Semi urban area</option>
                                    <option value="Urban area">Urban area</option>
                                </select>
                            </div>
                        </div>
                        <div className={`grid md:grid-cols-2 grid-cols-1 gap-5 ${edit === 'profileStyle' ? 'block' : 'hidden'}`}>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Date of Birth</p>
                                <p className='text-[#777B84] text-base'>{formattedDate}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Gender</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.gender ? signedInUserInfos?.gender : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Nationality</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.nationality ? signedInUserInfos?.nationality : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>District, state, or province</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.district ? signedInUserInfos?.district : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Subdistrict, city or town</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.city ? signedInUserInfos?.city : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>How would you classify your family&apos;s economic status?</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.economicStatus ? signedInUserInfos?.economicStatus : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Mobile Number</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.mobile ? signedInUserInfos?.mobile : 'Empty'}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)?</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.background ? signedInUserInfos?.background : 'Empty'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`w-full flex justify-end items-center gap-3 ${edit === 'formStyle' ? 'block' : 'hidden'}`}>
                        <span onClick={()=> setEdit('profileStyle')} className='cursor-pointer border  transition-all font-semibold md:text-base text-sm md:py-2 py-1 md:px-8 px-5 rounded-lg mt-5 uppercase' >Cancel</span>
                        <input type='submit' value='Update' className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-base text-sm md:py-2 py-1 md:px-8 px-5 rounded-lg mt-5 uppercase' />
                    </div>
                </form> */}
                { language === 'en' ?
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Personal Information */}
                        <div id='personalInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold'>Personal Information</p>
                            </div>
                            <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-8 mt-3'>
                                <span className='text-2xl text-red-500'><FiAlertTriangle/></span>
                                <span className='text-sm'>To change your Name, Email, Bio, and Profile Privacy, please navigate to <Link to='/user-settings' className='text-blue-500 font-semibold' >User Settings</Link> menu.</span>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                {/* <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>First Name<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="text"
                                        placeholder='First Name'
                                        // required
                                        // {...register("firstName")}
                                        ref={inputRefs.firstName}
                                        defaultValue={signedInUser?.firstName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Last Name</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="text"
                                        placeholder='Last Name'
                                        // {...register("lastName")}
                                        ref={inputRefs.lastName}
                                        defaultValue={signedInUser?.lastName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Email<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="email"
                                        {...register("email")}
                                        ref={inputRefs.email}
                                        defaultValue={user?.email}
                                        // required
                                    />
                                </div> */}
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Date of Birth<span className='text-red-500'> *</span></label><br/>
                                    <input
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="date"
                                        // required
                                        {...register("birthDate", { required: 'Required field' })}
                                    />
                                    {/* Display error message */}
                                    {errors.birthDate && <p className='text-red-500 text-sm mt-1'>{errors.birthDate.message}</p>}
                                </div>

                                <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033]'>Gender<span className='text-red-500'> *</span></label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        // value={gender}
                                        {...register("gender", { required: 'Required field' })}
                                        >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Nationality<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your Nationality'
                                    // required
                                    {...register("nationality", { required: 'Required field' })}
                                    />
                                    {errors.nationality && <p className='text-red-500 text-sm mt-1'>{errors.nationality.message}</p>}
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>District, state, or province<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your District, state, or province'
                                    // required
                                    {...register("district", { required: 'Required field' })}
                                    />
                                    {errors.nationality && <p className='text-red-500 text-sm mt-1'>{errors.nationality.message}</p>}
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033]'>Subdistrict, city or town<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="text"
                                    placeholder='Your Subdistrict, city or town'
                                    // required
                                    {...register("city", { required: 'Required field' })}
                                    />
                                    {errors.city && <p className='text-red-500 text-sm mt-1'>{errors.city.message}</p>}
                                </div>
                                {/* <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2'>How would you classify your family&apos;s economic status?</label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    required
                                    // value={economicStatus}
                                    {...register("economicStatus")}
                                    >
                                        <option value="High income">High income</option>
                                        <option value="Upper middle income">Upper middle income</option>
                                        <option value="Lower middle income">Lower middle income</option>
                                        <option value="Low income">Low income</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                </div> */}
                                <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2'>How would you classify your family&apos;s economic status?<span className='text-red-500'> *</span></label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        // required
                                        {...register("economicStatus")}
                                        defaultValue={"High income"}
                                    >
                                        <option value="High income">High income</option>
                                        <option value="Upper middle income">Upper middle income</option>
                                        <option value="Lower middle income">Lower middle income</option>
                                        <option value="Low income">Low income</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] my-2'>Mobile Number<span className='text-red-500'> *</span></label><br/>
                                    <PhoneInput className='mt-2 w-full'
                                        // required
                                        country={'bd'}
                                        enableSearch={true}
                                        value={mobile.phone}
                                        onChange={(phone) => setMobile({ phone })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="whereFrom" className='text-base font-semibold text-[#2E3033]'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)? <span className='text-red-500'> *</span></label>
                                    <p className='text-[#777B84] text-xs pb-2 pt-1'>If you grew up in a village area, choose &apos;Rural&apos;. If you grew up in a Thana or District center, choose &apos;Semi urban&apos;. If you grew up in a metropolitan area (e.g., Dhaka, Chattogram, etc.), choose &apos;Urban&apos;.</p>
                                    <select 
                                    id='whereFrom' name='whereFrom'
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    // required
                                    // value={background}
                                    {...register("background")}
                                    >
                                        <option value="Rural area">Rural area</option>
                                        <option value="Semi urban area">Semi urban area</option>
                                        <option value="Urban area">Urban area</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={`${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                            {/* Academic Information */}
                            <div className='my-12' id='academicInformation'>
                                <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Academic Information</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <label htmlFor="studyLevel" className='text-base font-semibold text-[#2E3033]'>What is your highest level of educational attainment?</label><br/>
                                        <select id="studyLevel" name="studyLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("studyLevel")}
                                        >
                                            <option value="Less than high school completion">Less than high school completion</option>
                                            <option value="High school completion (e.g., HSC/ A-Levels)">High school completion (e.g., HSC/ A-Levels)</option>
                                            <option value="Some college education but did not complete college">Some college education but did not complete college</option>
                                            <option value="Complete college education">Complete college education</option>
                                            <option value="More than college/university education">More than college/university education</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>Name of the institution that you obtained your highest level of education</label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="text"
                                        placeholder='Ex: University of Dhaka'
                                        // required
                                        {...register("institute")}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="studyArea" className='text-base font-semibold text-[#2E3033]'>Area of study (if you have attended college)</label><br/>
                                        <select id="studyArea" name="studyArea" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        {...register("studyArea")}
                                        value={selectedOption}
                                        onChange={(event)=>setSelectedOption(event.target.value)}
                                        >
                                            <option value="Medical Sciences">Medical Science</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="Humanities">Humanities</option>
                                            <option value="Social Sciences">Social Sciences</option>
                                            <option value="Business Studies">Business Studies</option>
                                            <option value="Science">Science</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {selectedOption === "Other" && (
                                            <input
                                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                                placeholder='Type your other value'
                                                type="text"
                                                {...register("otherStudyArea")}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Skills and Experience */}
                            <div id='skillsExperience' className='my-12'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Skills / Experience</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
                                    <div>
                                        <label htmlFor="englishProficiency" className='text-base font-semibold text-[#2E3033]'>Your English fluency/proficiency is:</label><br/>
                                        <select id="englishProficiency" name="englishProficiency" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("englishProficiency")}
                                        >
                                            <option value="No proficiency">No proficiency</option>
                                            <option value="Basic">Basic</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Advanced">Advanced</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="mentoringExperience" className='text-base font-semibold text-[#2E3033]'>Have you had any experience mentoring or tutoring others?</label><br/>
                                        <select id="mentoringExperience" name="mentoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            required
                                            {...register("mentoringExperience")}
                                            onChange={(e)=>{inputChangeHandler(e,"mentoringExp")}}
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* Tutoring Experience */}
                            <div className={`my-12 ${formData2.mentoringExp === 'No' ? 'hidden':'block'}`} id='tutoringExperience'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Tutoring Experience</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div className='mt-5'>
                                        <label htmlFor="tutoringExperience" className='text-base font-semibold text-[#2E3033]'>How many years of tutoring experience do you have?</label><br/>
                                        <select id="tutoringExperience" name="tutoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("tutoringExperience")}
                                        >
                                            <option value="Less than 1 year">Less than 1 year</option>
                                            <option value="at least 1 year and less than 2 years">at least 1 year and less than 2 years</option>
                                            <option value="at least 2 years and less than 3 years">at least 2 years and less than 3 years</option>
                                            <option value="at least 3 years and less than 4 years">at least 3 years and less than 4 years</option>
                                            <option value="at least 4 years">at least 4 years</option>
                                        </select>
                                    </div>
                                    <div className='mt-5'>
                                        <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033]'>What grade level(s) of students have you tutored?</label>
                                        <div className='mt-2'>
                                            {options.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange}
                                                        className='mr-2'
                                                    />
                                                    {option.value}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033]'>What grade level(s) of students have you tutored?</label><br/>
                                        <select id="tutoredStudentsLevel" name="tutoredStudentsLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("tutoredStudentsLevel")}
                                        >
                                            <option value="Pre-primary school level (early childhood education and development)">Pre-primary school level (early childhood education and development)</option>
                                            <option value="Primary school level">Primary school level</option>
                                            <option value="Lower secondary school level">Lower secondary school level</option>
                                            <option value="Upper secondary school level">Lower secondary school level</option>
                                            <option value="College level and above">College level and above</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                            {/* Volunteering experience */}
                            <div id='volunteeringExperience'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Volunteering experience</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div className='mt-5'>
                                        <label htmlFor="volunteeringExperience" className='text-base font-semibold text-[#2E3033]'>Do you have any volunteering experience?</label><br/>
                                        <select id="volunteeringExperience" name="volunteeringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("volunteeringExperience")}
                                        onChange={(e)=>{inputChangeHandler(e,"volExp")}}
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    {/* This empty div is using as a grid item */}
                                    <div></div>
                                    <div className={`${formData2.volExp === 'No' ? 'hidden':'block'}`}>
                                        <label htmlFor="volunteeredOrganizations" className='text-base font-semibold text-[#2E3033]'>What organization(s) have you volunteered for?</label>
                                        <div className='mt-2'>
                                            {options2.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange2}
                                                        className='mr-2'
                                                    />
                                                    {option.value}
                                                </div>
                                            ))}
                                            {other===true && (
                                                <input {...register('otherVolunteeredOrganizations')} type="text" placeholder="Please specify" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'  />
                                            )}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label htmlFor="volunteeringDetails" className='text-base font-semibold text-[#2E3033]'>What organization(s) have you volunteered for?</label><br/>
                                        <input type="checkbox" {...register("First")} required /> <span>First</span><br/>
                                        <input type="checkbox" {...register("Second")} required /> <span>Second</span><br/>
                                    </div> */}
                                    <div className={`${formData2.volExp === 'No' ? 'hidden':'block'}`}>
                                        <label htmlFor="detailActivities" className='text-base font-semibold text-[#2E3033]'>Please provide some details of the volunteering activities/services that you have given:</label><br/>
                                        <textarea id="detailActivities" name="detailActivities" rows="4" cols="50" placeholder='Your answer' className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' {...register("detailActivities")} />
                                    </div>
                                </div>
                                <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Other experiences</p>
                                </div>
                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                    <div className='mt-5'>
                                        <label htmlFor="experienceDescription" className='text-base font-semibold text-[#2E3033]'>If you have any other relevant experiences, please list them here:</label><br/>
                                        <p className='text-[#777B84] pb-2 pt-1'>Give a short description</p>
                                        <textarea id="experienceDescription" name="experienceDescription" rows="4" cols="50" placeholder='Your answer' className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                        {...register("experienceDescription")} 
                                        // required 
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold'>Volunteering (or Mentoring) preferences</p>
                                </div>
                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                    <div className='mt-5'>
                                        <label htmlFor="areaOfInterest" className='text-base font-semibold text-[#2E3033]'>What is your area of interest for volunteering or mentoring?</label>
                                        <p className='text-[#777B84] text-sm pb-2 pt-1'>When there is an opportunity for volunteering or mentoring with us, which area would you like to be involved with? You can choose more than one or all options.</p>
                                        <div className='mt-2'>
                                            {options3.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange3}
                                                        className='mr-2'
                                                    />
                                                    {option.value}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div className='mt-5'>
                                        <label htmlFor="volunteeringDetails" className='text-base font-semibold text-[#2E3033]'>What is your area of interest for volunteering or mentoring?</label><br/><p>When there is an opportunity for volunteering or mentoring with us, which area would you like to be involved with? You can choose more than one or all options.</p>
                                        <input type="checkbox" {...register("Early childhood education and development")} required /> <span>Early childhood education and development</span><br/>
                                        <input type="checkbox" {...register("Second")} required /> <span>Second</span><br/>
                                    </div> */}
                                    <div className='mt-5'>
                                        <label htmlFor="volunteeringActivities" className='text-base font-semibold text-[#2E3033]'>How many hours would you prefer to participate in volunteering/mentoring activities?</label><br/>
                                        <select id="volunteeringActivities" name="volunteeringActivities" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("volunteeringActivities")}
                                        >
                                            <option value="Less than 1 hour per week">Less than 1 hour per week</option>
                                            <option value="1 hour per week">1 hour per week</option>
                                            <option value="More than 1 hour and less than 2 hours per week">More than 1 hour and less than 2 hours per week</option>
                                            <option value="2 hours per week">2 hours per week</option>
                                            <option value="More than 2 hours and less than 3 hours per week">More than 2 hours and less than 3 hours per week</option>
                                            <option value="3 hours per week">3 hours per week</option>
                                            <option value="More than 3 hours and less than 4 hours per week">More than 3 hours and less than 4 hours per week</option>
                                            <option value="4 hours per week">4 hours per week</option>
                                            <option value="More than 4 hours per week">More than 4 hours per week</option>
                                            <option value="No preference">No preference</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-end'>
                            <input type='submit' className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-lg text-base md:py-3 py-2 md:px-12 px-8 rounded-lg mt-5 uppercase' />
                        </div>
                    </form>
                    :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Personal Information */}
                        <div id='personalInformation'>
                            <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                <p className='text-2xl font-bold banglaFont'>ব্যক্তিগত তথ্য</p>
                            </div>
                            <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-8 mt-3'>
                                <span className='text-2xl text-red-500'><FiAlertTriangle/></span>
                                <span className='text-base banglaFont'>আপনার নাম, ইমেল, বায়ো, এবং প্রোফাইল প্রাইভেসি পরিবর্তন করতে, অনুগ্রহ করে <Link to='/user-settings' className='text-blue-500 font-semibold' >ইউজার সেটিংস</Link> মেনুতে নেভিগেট করুন।</span>
                            </div>
                            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                {/* <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের প্রথম অংশ<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="text"
                                        placeholder='নামের প্রথম অংশ'
                                        required
                                        // {...register("firstName")}
                                        ref={inputRefs.firstName}
                                        defaultValue={signedInUser?.firstName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের শেষ অংশ</label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        type="text"
                                        placeholder='নামের শেষ অংশ'
                                        // {...register("lastName")}
                                        ref={inputRefs.lastName}
                                        defaultValue={signedInUser?.lastName}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>ইমেইল<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="email"
                                    {...register("email")}
                                    ref={inputRefs.email}
                                    defaultValue={user?.email}
                                    required
                                    />
                                </div> */}
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জন্ম তারিখ<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    type="date"
                                    required
                                    {...register("birthDate")}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="gender" className='text-base font-semibold text-[#2E3033] banglaFont'>লিঙ্গ<span className='text-red-500'> *</span></label><br/>
                                    <select id="gender" name="gender" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    required
                                    // value={gender}
                                    {...register("gender")}
                                    >
                                        <option value="Male">পুরুষ</option>
                                        <option value="Female">মহিলা</option>
                                        <option value="Prefer not to say">বলতে অনিচ্ছুক</option>
                                        <option value="Other">অন্যান্য</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জাতীয়তা<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    type="text"
                                    placeholder='আপনার জাতীয়তা'
                                    required
                                    {...register("nationality")}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>জেলা<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    type="text"
                                    placeholder='আপনার জেলা'
                                    required
                                    {...register("district")}
                                    />
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] banglaFont'>উপজেলা<span className='text-red-500'> *</span></label><br/>
                                    <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        type="text"
                                        placeholder='আপনার উপজেলা'
                                        required
                                        {...register("city")}
                                    />
                                </div>
                                {/* <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2'>How would you classify your family&apos;s economic status?</label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    required
                                    // value={economicStatus}
                                    {...register("economicStatus")}
                                    >
                                        <option value="High income">High income</option>
                                        <option value="Upper middle income">Upper middle income</option>
                                        <option value="Lower middle income">Lower middle income</option>
                                        <option value="Low income">Low income</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                </div> */}
                                <div>
                                    <label htmlFor="economicStatus" className='text-base font-semibold text-[#2E3033] my-2 banglaFont'>আপনার পরিবারের অর্থনৈতিক অবস্থা<span className='text-red-500'> *</span></label><br/>
                                    <select id="economicStatus" name="economicStatus" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("economicStatus")}
                                        defaultValue={"High income"}
                                    >
                                        <option value="High income">উচ্চবিত্ত</option>
                                        <option value="Upper middle income">উচ্চ মধ্যবিত্ত</option>
                                        <option value="Lower middle income">নিম্ন মধ্যবিত্ত</option>
                                        <option value="Low income">নিম্নবিত্ত</option>
                                        <option value="Prefer not to say">বলতে অনিচ্ছুক</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='text-base font-semibold text-[#2E3033] my-2 banglaFont'>মোবাইল<span className='text-red-500'> *</span></label><br/>
                                    <PhoneInput className='mt-2 w-full'
                                    required
                                    country={'bd'}
                                    enableSearch={true}
                                    value={mobile.phone}
                                    onChange={(phone) => setMobile({ phone })}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="whereFrom" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি প্রাথমিকভাবে আপনার শৈশব এবং কৈশোরকালে (অর্থাৎ, 18 বছর বয়সের আগে) কোথায় বড় হয়েছেন?<span className='text-red-500'> *</span></label>
                                    <p className='text-[#777B84] text-xs pb-2 pt-1 banglaFont'>আপনি যদি গ্রামে বড় হয়ে থাকেন তবে গ্রামীণ এলাকা নির্বাচন করুন। আপনি যদি একটি থানা বা জেলা কেন্দ্রে বেড়ে ওঠেন, আধা শহুরে এলাকা বেছে নিন। আপনি যদি একটি মেট্রোপলিটন এলাকায় বড় হয়ে থাকেন (যেমন, ঢাকা, চট্টগ্রাম, ইত্যাদি), শহুরে এলাকা বেছে নিন।</p>
                                    <select 
                                    id='whereFrom' name='whereFrom'
                                    className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    required
                                    // value={background}
                                    {...register("background")}
                                    >
                                        <option value="Rural area">গ্রামীণ এলাকা</option>
                                        <option value="Semi urban area">আধা শহুরে এলাকা</option>
                                        <option value="Urban area">শহুরে এলাকা</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={`${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                            {/* Academic Information */}
                            <div className='my-12' id='academicInformation'>
                                <div className='flex justify-between w-full pb-5 mb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>একাডেমিক তথ্য</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div>
                                        <label htmlFor="studyLevel" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার শিক্ষাগত অর্জনের সর্বোচ্চ স্তর কি?</label><br/>
                                        <select id="studyLevel" name="studyLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("studyLevel")}
                                        >
                                            <option value="Less than high school completion">উচ্চ বিদ্যালয়ের চেয়ে কম</option>
                                            <option value="High school completion (e.g., HSC/ A-Levels)">উচ্চ বিদ্যালয় শেষ (যেমন, এইচএসসি/ এ-লেভেল)</option>
                                            <option value="Some college education but did not complete college">কলেজে লেখাপড়া করলেও কলেজ শেষ করেননি</option>
                                            <option value="Complete college education">কলেজ শিক্ষা সম্পূর্ণ</option>
                                            <option value="More than college/university education">কলেজ/বিশ্ববিদ্যালয় শিক্ষার চেয়েও বেশি</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি আপনার সর্বোচ্চ স্তরের শিক্ষা অর্জন করেছেন এমন প্রতিষ্ঠানের নাম</label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        type="text"
                                        placeholder='উদাহরণঃ ঢাকা বিশ্ববিদ্যালয়'
                                        // required
                                        {...register("institute")}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="studyArea" className='text-base font-semibold text-[#2E3033] banglaFont'>অধ্যয়নের ক্ষেত্র (যদি আপনি কলেজে পড়ে থাকেন)</label><br/>
                                        <select id="studyArea" name="studyArea" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        {...register("studyArea")}
                                        value={selectedOption}
                                        onChange={(event)=>setSelectedOption(event.target.value)}
                                        >
                                            <option value="Medical Sciences">চিকিৎসা বিজ্ঞান</option>
                                            <option value="Engineering">প্রকৌশল</option>
                                            <option value="Humanities">মানবিক</option>
                                            <option value="Social Sciences">সামাজিক বিজ্ঞান</option>
                                            <option value="Business Studies">ব্যাবসা শিক্ষা</option>
                                            <option value="Science">বিজ্ঞান</option>
                                            <option value="Other">অন্যান্য</option>
                                        </select>
                                        {selectedOption === "Other" && (
                                            <input
                                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                                placeholder='অন্যান্য ক্ষেত্র লিখুন'
                                                type="text"
                                                {...register("otherStudyArea")}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Skills and Experience */}
                            <div id='skillsExperience' className='my-12'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>দক্ষতা/অভিজ্ঞতা</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
                                    <div>
                                        <label htmlFor="englishProficiency" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার ইংরেজি দক্ষতা:</label><br/>
                                        <select id="englishProficiency" name="englishProficiency" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("englishProficiency")}
                                        >
                                            <option value="No proficiency">দক্ষতা নেই</option>
                                            <option value="Basic">প্রাথমিক</option>
                                            <option value="Intermediate">মধ্যবর্তী</option>
                                            <option value="Advanced">উন্নত</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="mentoringExperience" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার কি অন্যদের পরামর্শ দেওয়ার বা টিউটর করার অভিজ্ঞতা আছে?</label><br/>
                                        <select id="mentoringExperience" name="mentoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                            required
                                            {...register("mentoringExperience")}
                                            onChange={(e)=>{inputChangeHandler(e,"mentoringExp")}}
                                        >
                                            <option value="Yes">হ্যাঁ</option>
                                            <option value="No">না</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* Tutoring Experience */}
                            <div className={`my-12 ${formData2.mentoringExp === 'No' ? 'hidden':'block'}`} id='tutoringExperience'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>টিউটরিং অভিজ্ঞতা</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div className='mt-5'>
                                        <label htmlFor="tutoringExperience" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার কত বছরের টিউটরিং অভিজ্ঞতা আছে?</label><br/>
                                        <select id="tutoringExperience" name="tutoringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("tutoringExperience")}
                                        >
                                            <option value="Less than 1 year">১ বছরের কম</option>
                                            <option value="at least 1 year and less than 2 years">কমপক্ষে ১ বছর এবং ২ বছরের কম</option>
                                            <option value="at least 2 years and less than 3 years">কমপক্ষে 2 বছর এবং 3 বছরের কম</option>
                                            <option value="at least 3 years and less than 4 years">কমপক্ষে 3 বছর এবং 4 বছরের কম</option>
                                            <option value="at least 4 years">কমপক্ষে 4 বছর</option>
                                        </select>
                                    </div>
                                    <div className='mt-5'>
                                        <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি ছাত্রদের কোন গ্রেড স্তরগুলি টিউটরিং করেছেন?</label>
                                        {/* <div className='mt-2'>
                                            {options.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange}
                                                        className='mr-2'
                                                    />
                                                    {option.value}
                                                </div>
                                            ))}
                                        </div> */}
                                        <div className='mt-2'>
                                            {options.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange}
                                                        className='mr-2'
                                                    />
                                                    {option.banglaValue}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label htmlFor="tutoredStudentsLevel" className='text-base font-semibold text-[#2E3033]'>What grade level(s) of students have you tutored?</label><br/>
                                        <select id="tutoredStudentsLevel" name="tutoredStudentsLevel" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        required
                                        {...register("tutoredStudentsLevel")}
                                        >
                                            <option value="Pre-primary school level (early childhood education and development)">Pre-primary school level (early childhood education and development)</option>
                                            <option value="Primary school level">Primary school level</option>
                                            <option value="Lower secondary school level">Lower secondary school level</option>
                                            <option value="Upper secondary school level">Lower secondary school level</option>
                                            <option value="College level and above">College level and above</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                            {/* Volunteering experience */}
                            <div id='volunteeringExperience'>
                                <div className='flex justify-between w-full pb-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>স্বেচ্ছাসেবক অভিজ্ঞতা</p>
                                </div>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5">
                                    <div className='mt-5'>
                                        <label htmlFor="volunteeringExperience" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার কোনো স্বেচ্ছাসেবক অভিজ্ঞতা আছে?</label><br/>
                                        <select id="volunteeringExperience" name="volunteeringExperience" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("volunteeringExperience")}
                                        onChange={(e)=>{inputChangeHandler(e,"volExp")}}
                                        >
                                            <option value="Yes">হ্যাঁ</option>
                                            <option value="No">না</option>
                                        </select>
                                    </div>
                                    {/* This empty div is using as a grid item */}
                                    <div></div>
                                    <div className={`${formData2.volExp === 'No' ? 'hidden':'block'}`}>
                                        <label htmlFor="volunteeredOrganizations" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি কোন সংস্থায় স্বেচ্ছাসেবক ছিলেন?</label>
                                        <div className='mt-2'>
                                            {options2.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange2}
                                                        className='mr-2'
                                                    />
                                                    {option.banglaValue}
                                                </div>
                                            ))}
                                            {other===true && (
                                                <input {...register('otherVolunteeredOrganizations')} type="text" placeholder="উল্লেখ করুন" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'  />
                                            )}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label htmlFor="volunteeringDetails" className='text-base font-semibold text-[#2E3033]'>What organization(s) have you volunteered for?</label><br/>
                                        <input type="checkbox" {...register("First")} required /> <span>First</span><br/>
                                        <input type="checkbox" {...register("Second")} required /> <span>Second</span><br/>
                                    </div> */}
                                    <div className={`${formData2.volExp === 'No' ? 'hidden':'block'}`}>
                                        <label htmlFor="detailActivities" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি যে স্বেচ্ছাসেবী কার্যক্রম/পরিষেবা দিয়েছেন তার কিছু বিশদ বিবরণ দিন:</label><br/>
                                        <textarea id="detailActivities" name="detailActivities" rows="4" cols="50" placeholder='আপনার বিবরণ' className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont' {...register("detailActivities")} />
                                    </div>
                                </div>
                                <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>অন্যান্য অভিজ্ঞতা</p>
                                </div>
                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                    <div className='mt-5'>
                                        <label htmlFor="experienceDescription" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনার যদি অন্য কোন প্রাসঙ্গিক অভিজ্ঞতা থাকে, অনুগ্রহ করে সেগুলি এখানে তালিকাভুক্ত করুন:</label><br/>
                                        <p className='text-[#777B84] pb-2 pt-1 banglaFont'>একটি সংক্ষিপ্ত বিবরণ দিন</p>
                                        <textarea id="experienceDescription" name="experienceDescription" rows="4" cols="50" placeholder='আপনার বিবরণ' className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont' 
                                        {...register("experienceDescription")} 
                                        // required 
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-between w-full py-5 border-b border-b-[#F0F5FB]' >
                                    <p className='text-2xl font-bold banglaFont'>স্বেচ্ছাসেবী (বা পরামর্শ) পছন্দ</p>
                                </div>
                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5'>
                                    <div className='mt-5'>
                                        <label htmlFor="areaOfInterest" className='text-base font-semibold text-[#2E3033] banglaFont'>স্বেচ্ছাসেবক বা পরামর্শদানের জন্য আপনার আগ্রহের ক্ষেত্র কী?</label>
                                        <p className='text-[#777B84] text-sm pb-2 pt-1 banglaFont'>যখন আমাদের সাথে স্বেচ্ছাসেবক বা পরামর্শ দেওয়ার সুযোগ থাকে, আপনি কোন এলাকার সাথে জড়িত হতে চান? আপনি একাধিক বা সব বিকল্প চয়ন করতে পারেন.</p>
                                        <div className='mt-2'>
                                            {options3.map((option) => (
                                                <div key={option.id} className='text-base text-[#777B84] mb-1 banglaFont'>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        onChange={handleChange3}
                                                        className='mr-2'
                                                    />
                                                    {option.banglaValue}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* <div className='mt-5'>
                                        <label htmlFor="volunteeringDetails" className='text-base font-semibold text-[#2E3033]'>What is your area of interest for volunteering or mentoring?</label><br/><p>When there is an opportunity for volunteering or mentoring with us, which area would you like to be involved with? You can choose more than one or all options.</p>
                                        <input type="checkbox" {...register("Early childhood education and development")} required /> <span>Early childhood education and development</span><br/>
                                        <input type="checkbox" {...register("Second")} required /> <span>Second</span><br/>
                                    </div> */}
                                    <div className='mt-5'>
                                        <label htmlFor="volunteeringActivities" className='text-base font-semibold text-[#2E3033] banglaFont'>আপনি কত ঘন্টা স্বেচ্ছাসেবী/পরামর্শদান কার্যক্রমে অংশগ্রহণ করতে পছন্দ করবেন?</label><br/>
                                        <select id="volunteeringActivities" name="volunteeringActivities" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                        required
                                        {...register("volunteeringActivities")}
                                        >
                                            <option value="Less than 1 hour per week">প্রতি সপ্তাহে 1 ঘন্টার কম</option>
                                            <option value="1 hour per week">প্রতি সপ্তাহে 1 ঘন্টা</option>
                                            <option value="More than 1 hour and less than 2 hours per week">প্রতি সপ্তাহে 1 ঘন্টার বেশি এবং 2 ঘন্টার কম</option>
                                            <option value="2 hours per week">প্রতি সপ্তাহে 2 ঘন্টা</option>
                                            <option value="More than 2 hours and less than 3 hours per week">প্রতি সপ্তাহে 2 ঘন্টার বেশি এবং 3 ঘন্টার কম</option>
                                            <option value="3 hours per week">প্রতি সপ্তাহে 3 ঘন্টা</option>
                                            <option value="More than 3 hours and less than 4 hours per week">প্রতি সপ্তাহে 3 ঘন্টার বেশি এবং 4 ঘন্টার কম</option>
                                            <option value="4 hours per week">প্রতি সপ্তাহে 4 ঘন্টা</option>
                                            <option value="More than 4 hours per week">প্রতি সপ্তাহে 4 ঘন্টার বেশি</option>
                                            <option value="No preference">কোন পছন্দ নেই</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-end'>
                            <input type='submit' value='সাবমিট' className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-lg text-base md:py-3 py-2 md:px-12 px-8 rounded-lg mt-5 uppercase banglaFont' />
                        </div>
                    </form>
                }
            </div>
        </div>

        <ScrollToTop></ScrollToTop>
        <Footer></Footer>
    </div>
  );
};

export default UserInfo