import React, { useContext, useEffect, useRef, useState } from 'react';
// import Navbar from '../Common/Navbar'
import { useForm } from 'react-hook-form';
import { useAuthState, useSendPasswordResetEmail, useSignInWithEmailAndPassword, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';
import { toast } from 'react-toastify';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import Axios from 'axios';
import BaseUrl from '../BaseURL';
import { AuthContext } from '../GlobalState/AuthProvider';
import { BsArrowLeft } from 'react-icons/bs';
import sha256 from 'crypto-js/sha256';
// import { useUserContext } from '../GlobalState/UserContext';

function Login() {
    // const { personalInfo, userInfos } = useUserContext();
    const [userInfos, setUserInfos] = useState([]);
    const [personalInfo, setPersonalInfo] = useState([]);
    const [userAuth] = useAuthState(auth);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const inputRefs = { email: useRef(null) };
    const navigate = useNavigate();
    const location = useLocation();
    const [ signInWithEmailAndPassword, user, loading, error, ] = useSignInWithEmailAndPassword(auth);
    const [ signInWithGoogle, gUser, gLoading, gError ] = useSignInWithGoogle(auth);
    const [sendPasswordResetEmail, sending] = useSendPasswordResetEmail(auth);
    const roleType = new URLSearchParams(location.search).get('type');

    const findSignedInUser = personalInfo?.find((person) => person?.email === emailRef?.current?.value);
    const findSignedInUser2 = personalInfo?.find((person) => person?.email === gUser?._tokenResponse?.email);
    const signedInWithEmailUser = userInfos?.find(userInfo => userInfo?.user_id === findSignedInUser?.id);
    const signedInWithGoogleUser = userInfos?.find(userInfo => userInfo?.user_id === findSignedInUser2?.id);
    // console.log(signedInWithGoogleUser)
    
    // Get user and infos
    useEffect(() => {
        // Fetch personal information
        Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
        });
    
        // Fetch user information
        Axios.get(`${BaseUrl}/userInfos`).then((response) => {
            setUserInfos(response.data);
            setIsLoading(false);
        });
    }, []);

    // sign In With Google
    useEffect(() => {
        const handleUserSignIn = async () => {
            if (userAuth) {
                const { displayName, email, uid } = userAuth;
                let lastName = displayName?.split(" ")[1];
                if(!displayName?.split(" ")[1]){
                    lastName = '';
                }
                const alreadyExist = personalInfo?.find((person) => person?.userEmail === email);

                // Save the user's role based on the type parameter
                let role = "Volunteer";
                if (roleType === "partner") {
                    role = "Partner";
                } else if (roleType === "volunteer") {
                    role = "Volunteer";
                }

                const min = 100000;
                const max = 999999;
                const newRandomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                const hashedNumber = sha256(newRandomNumber)?.toString();

                if (!alreadyExist) {
                    Axios.post(`${BaseUrl}/addUser`, {
                        firstName: displayName?.split(" ")[0],
                        lastName: lastName,
                        password: hashedNumber,
                        email: email,
                        userEmail: email,
                        role: role,
                        profileType: 'Private',
                        uid: uid,
                    });
                }
            }
        };
    
        if (userAuth && !gLoading) {
          handleUserSignIn();
        }
    }, [userAuth, gLoading]);

    let from = location.state?.from?.pathname || "/newsfeed";
    let from2 = location.state?.from?.pathname || "/update-info";
    
    var errorCode = error?.code;
    var errorMessage = error?.message;
    if (errorCode === 'auth/wrong-password') {
      errorMessage = 'Wrong email/password. Please try again.';
    }else if(errorCode === 'auth/user-not-found'){
        errorMessage = 'Wrong email/password. Please try again.';
    }else if(errorCode === 'auth/invalid-email.'){
        errorMessage = 'Provide valid email/password.';
    }

    var gErrorCode = gError?.code;
    var gErrorMessage = gError?.message;
    if (gErrorCode === 'auth/popup-closed-by-user') {
        gErrorMessage = 'Popup closed';
    }

    const isUserLoggedIn = userAuth || gUser;
    if (isUserLoggedIn) {
        let userInfo = '';
        if(signedInWithEmailUser){
            userInfo = signedInWithEmailUser;
        } else if(signedInWithGoogleUser){
            userInfo = signedInWithGoogleUser;
        }
        // const userInfo = isUserLoggedIn?.displayName ? signedInWithGoogleUser : signedInWithEmailUser;
        // console.log(userInfo);
        if (
          userInfo?.gender &&
          userInfo?.nationality &&
          userInfo?.district &&
          userInfo?.background
        ) {
          navigate('/newsfeed', { replace: true });
        } else {
          navigate('/user-info', { replace: true });
        }
    };
    
    let signInError;
    if (error || gError) {
        signInError = <small className='text-red-500'>{errorMessage || gErrorMessage}</small>
    };

    const handleGoogleSignIn = async () => {
        if(!userAuth){
            await signInWithGoogle();
        }else{
            toast.error("Already logged in!");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        // signInWithEmailAndPassword(email, password);
        if(!userAuth){
            signInWithEmailAndPassword(email, password);
        }else{
            toast.error("Already logged in!");
        }
    };
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSubmit(event);
        }
    };
    
    const handleReset = async (e) =>{
        e.preventDefault();
        const formData = {
            email: inputRefs.email.current.value,
        };
    
        if (formData?.email) {
            await sendPasswordResetEmail(formData?.email);
            toast('Reset email sending...');
            setIsOpenPopup(false);
        } else {
            toast.error('Please enter your email address!');
        }
    }

    return (
        <div>
            <Helmet>
                <title>Login - Telementoring</title>
                <meta name='title' content='Login - Telementoring' />
                <meta name="description" content="Log in to the Telementoring platform to get some of our special features." />
                <meta name="keywords" content="telementoring, login" />
            </Helmet>

            <div className='bg-blue-50'>
                <div className='max-w-7xl md:mx-auto mx-5 lg:px-8  md:pt-28 pt-12 md:pb-40 pb-12 flex justify-center'>
                    { !userAuth ?
                        <div className='bg-white md:p-8 p-5 rounded-2xl shadow-xl border hover:border-blue-300 transition-all md:w-1/2 w-full'>
                            <p className='text-center font-bold text-2xl mb-3'>Login</p>
                            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                                <label>
                                    <span className='font-semibold'>Email<span className='text-[#F74F22] font-bold'>*</span></span>
                                </label>
                                <input className='border border-gray-200 outline-blue-200 px-2 py-2 mb-3 rounded-lg'
                                    type="email" placeholder="Your Email"
                                    ref={emailRef}
                                    onKeyDown={handleKeyDown}
                                    required
                                />

                                <label>
                                    <span className='font-semibold'>Password<span className='text-[#F74F22] font-bold'>*</span></span>
                                </label>
                                <input className='border border-gray-200 outline-blue-200 px-2 py-2 mb-3 rounded-lg'
                                    type="password" placeholder="Password"
                                    ref={passwordRef}
                                    onKeyDown={handleKeyDown}
                                    required
                                />
                                
                                
                                {/* {error && <small className='text-red-500'>{error}</small>} */}
                                <p className='text-center'>{signInError}</p>

                                <button type="submit" className='cursor-pointer px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#0082C4] rounded-md shadow hover:bg-[#0083c4de] focus:outline-none focus:ring-blue-200 focus:ring-4'>Login</button>
                            </form>
                            {isOpenPopup && (
                                <div className="fixed z-50 inset-0 overflow-y-auto">
                                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                            <div className="absolute inset-0 bg-gray-500 opacity-60"></div>
                                        </div>

                                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                        <div className="inline-block align-bottom bg-white w-full md:p-8 p-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                            <div>
                                                <p className='text-center font-bold text-2xl'>Reset your password</p>
                                                <p className='text-center text-sm text-[#777B84] mt-1 mb-5'>Please enter your email and we will send you instructions on how to reset your password.</p>
                                                <form onSubmit={(e) => handleReset(e)} className='flex flex-col justify-center gap-4'>
                                                    <input 
                                                        type='email' 
                                                        placeholder='Write email...' 
                                                        className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3]'
                                                        required
                                                        ref={inputRefs.email}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    <div className='flex justify-between'>
                                                        <div></div>
                                                        <div className='flex gap-5'>
                                                        <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-sm px-4 md:py-2 py-1 rounded-lg'>Cancel</button>
                                                        <input type='submit' value='Submit' className='cursor-pointer px-4 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] text-sm transition-all text-white font-semibold rounded-lg' />
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* <input 
                                                    type='email' 
                                                    placeholder='Write email...' 
                                                    className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3]'
                                                    ref={inputRefs.email}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <div className='flex justify-between'>
                                                    <div></div>
                                                    <div className='flex gap-5'>
                                                    <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Cancel</button>
                                                    <button onClick={resetPassword} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Submit</button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='flex justify-center items-center gap-3 my-3'>
                                <p className='md:text-base text-sm'>Forget Password?</p>
                                {/* <button className='text-[#0082C4] hover:text-[#F74F22] transition-all font-semibold' onClick={()=> setIsOpenPopup(true)}>Reset Password</button> */}
                                <Link className='text-[#0082C4] hover:text-[#F74F22] transition-all font-semibold' to='/reset-password'>Reset Password</Link>
                            </div>
                            {/* Login with Google */}
                            <div className="flex flex-col space-y-5">
                                <span className="flex items-center justify-center space-x-2 mt-4">
                                    <span className="h-px bg-gray-400 w-full"></span>
                                    <span className="font-normal text-gray-500">or</span>
                                    <span className="h-px bg-gray-400 w-full"></span>
                                </span>
                                {/* <button onClick={() => signInWithGoogle()} className='cursor-pointer px-4 py-2 text-lg font- transition-colors duration-300 border border-[#0082C4] text-[#0082C4] rounded-md shadow hover:bg-[#0082C4] hover:text-white focus:outline-none focus:ring-blue-200 focus:ring-4 w-full'>Continue with Google</button> */}
                                <button onClick={handleGoogleSignIn} className="cursor-pointer px-4 py-2 text-lg font- transition-colors duration-300 border border-[#0082C4] text-[#0082C4] rounded-md shadow hover:bg-[#0082C4] hover:text-white focus:outline-none w-full"
                                >Continue with Google</button>
                            </div>

                            <p className='mt-5 md:text-base text-sm'>Don&apos;t have an account? <Link className='text-[#0082C4] hover:text-[#F74F22] transition-all font-semibold' to='/signup'>Create New Account</Link></p>
                        </div>
                        :
                        <div className='h-[60vh] flex flex-col gap-4 justify-center items-center'>
                            <p className='md:text-4xl text-2xl font-bold text-[#0082C4]'>Already logged in!</p>
                            <Link to='/' className='border border-[#0082C4] text-[#0082C4] px-5 py-2 rounded-md hover:bg-[#0082C4] hover:text-white transition-all flex items-center gap-1'><BsArrowLeft />Back to Homepage</Link>
                        </div>
                    }
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Login;