import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function EditBlog() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();

  const { blogId } = useParams();
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const [blogs, setBlogs] = useState('');
  const [blog, setBlog] = useState({});

  // Uploaded file
  const [file, setFile] = useState(null);

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])
  // get blogs
  useEffect(()=>{
    Axios.get(`${BaseUrl}/blogs`).then((response) => {
      setBlogs(response.data);
    });
  },[]);    
  // get blog details
  useEffect(()=>{
      Axios.get(`${BaseUrl}/edit-blog/${blogId}`).then((response) => { 
          setBlog(response.data);
      });
  },[blogId]);

  // Update Blog
  const inputRefs = {
    newTitle: useRef(null),
    newDescription: useRef(null),
  };
  const handleUpdateBlog = async e => {
    e.preventDefault();

    const formDataOfBlog = {
    newTitle: inputRefs.newTitle.current.value,
    newDescription: inputRefs.newDescription.current.value,
    };
    let formData = new FormData();
    formData.append("id", blogId);
    formData.append("title", formDataOfBlog?.newTitle);
    formData.append("description", formDataOfBlog?.newDescription);
    
    if (file !== null) {
    formData.append("blogImg", file);
    }
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      await Axios.put(`${BaseUrl}/update-blog`, formData, {
      headers: {
          "Content-Type": "multipart/form-data",
      },
      }).then(() => {
      Axios.get(`${BaseUrl}/blogs`).then((response) => {
          setBlogs(response.data);
      });
      })
      toast.success("Successfully Updated!");
    }else{
      toast.error("You don't have sufficient privilege!");
    }
    // console.log(formDataOfBlog.newTitle, formDataOfBlog.newDescription);
  };

  return (
    <div>
      <Helmet>
        <title>Edit Blog - Telementoring</title>
        <meta name='title' content='Edit Blog - Telementoring' />
        <meta name="description" content="Edit blog of Telementoring." />
        <meta name="keywords" content="telementoring, edit blog" />
      </Helmet>
      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          <div className='flex justify-between items-center mb-5 md:mt-0 mt-10'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Edit Blog</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>ইডিট ব্লগ</p>
            }
            <div className="relative inline-block group">
              <Link to='/admin-settings/manage-blogs?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link>
              { language === 'en' ?
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Close</div>
                :
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">বন্ধ</div>
              }
            </div>
          </div>
          <div>
            <div>
              { language === 'en' ?
                <form onSubmit={(e) => handleUpdateBlog(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Title
                      </label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={blog?.title}
                          placeholder='Write here' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Upload Cover Photo
                      </label>
                      <input
                        type="file"
                        name="blogImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <JoditEditor
                      ref={inputRefs.newDescription}
                      value={blog?.description}
                      // onChange={(newContent) => setDescription(newContent)}
                  />
                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='Submit' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    </div>
                  </div>
                </form>
                :
                <form onSubmit={(e) => handleUpdateBlog(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">টাইটেল</label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={blog?.title}
                          placeholder='এখানে লিখুন' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">ফটো আপলোড করুন</label>
                      <input
                        type="file"
                        name="blogImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <JoditEditor
                      ref={inputRefs.newDescription}
                      value={blog?.description}
                      // onChange={(newContent) => setDescription(newContent)}
                  />
                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='সাবমিট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>

      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default EditBlog