import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
// import Avatar from '../../assets/avatar.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
// import DeleteIcon from '../../assets/delete.png';
// import DeleteIcon from '../../assets/delete.svg';
import BaseUrl from '../BaseURL';
import { BsSearch } from 'react-icons/bs';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { IoSettingsOutline } from 'react-icons/io5';
// import { MdAdminPanelSettings } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { BiHide, BiShow } from 'react-icons/bi';
import ScrollToTop from '../Common/ScrollToTop';
import { toast } from 'react-toastify';
// import JoditEditor from "jodit-react";
import { SiAddthis } from 'react-icons/si';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function ManageSubscribers() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();
  // Search
  const [searchTerm, setSearchTerm] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const location = useLocation();
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState(subscribers);

  useEffect(() => {
    const results = subscribers.filter(val =>
      (val.email || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.subscription_status || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(results);
    setCurrentPage(1);
  }, [searchTerm, subscribers]);
  
  const reversedSubscribers = filteredItems.slice().reverse();

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[]);

  // Get subscribers
  useEffect(()=>{
    Axios.get(`${BaseUrl}/subscribers`).then((response) => {
        setSubscribers(response.data);
    });
  },[]);

  // make Subscribed
  const handleMakingSubscribed = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateSubscriptionStatus`, { 
        subscription_status: "Subscribed", 
        id: id 
      }).then(
          (response) => {
            setSubscribers(
                subscribers.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      visibility: val.visibility,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/subscribers`).then((response) => {
              setSubscribers(response.data);
            });
            toast.success("Subscribed!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // make Unsubscribed
  const handleMakingUnsubscribed = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateSubscriptionStatus`, { 
        subscription_status: "Unsubscribed", 
        id: id 
      }).then(
          (response) => {
            setSubscribers(
              subscribers.map((val) => {
              return val.id === id? {
                id: val.id,
                visibility: val.visibility,
              } : val;
            }));
            Axios.get(`${BaseUrl}/subscribers`).then((response) => {
              setSubscribers(response.data);
            });
            toast.success("Unsubscribed!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  } ;
  // Delete Subscriber
  const handleDeleteSubscriber = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      const proceed = window.confirm("Are you sure?");
      if(proceed){
        Axios.delete(`${BaseUrl}/subscriber/${id}`).then((response) => {
          setSubscribers(
            subscribers.filter((val) => {
              return val.id !== id;
            })
          );
          Axios.get(`${BaseUrl}/subscribers`).then((response) => {
            setSubscribers(response.data);
          });
          toast.success("Subscriber Deleted!");
        });
      }
    }else{
      toast.warn("You don't have sufficient privilege!")
    }
  };

  // Pagination
  const itemsPerPage = 10;
  const urlParams = new URLSearchParams(location.search);
  const pageFromURL = Number(urlParams.get('page')) || 1;
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const page = urlParams.get('page');
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [location, currentPage]);

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    const visiblePagesLimit = 4;
    if (totalPages <= visiblePagesLimit) {
      return Array.from(Array(totalPages).keys()).map((i) => i + 1);
    }
    const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
    const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);
    const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
    return visiblePages;
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(reversedSubscribers.length / itemsPerPage);
  const paginatedItems = reversedSubscribers.slice(startIndex, endIndex);
  // const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);
  const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

  return (
    <div>
      <Helmet>
        <title>Manage Subscribers - Telementoring</title>
        <meta name='title' content='Manage Subscribers - Telementoring' />
        <meta name="description" content="Manage Subscribers of Telementoring." />
        <meta name="keywords" content="telementoring, manage subscribers" />
      </Helmet>

      <div className="container mx-auto">
        <div>
          <div className='md:flex items-center grid grid-cols-1 justify-between gap-5 px-5 py-4 rounded-2xl shadow-md'>
            <div className='md:hidden block'>
              <div className='flex justify-between items-center gap-3 mt-8'>
                { language === 'en' ?
                  <h2 className="text-xl font-semibold">Manage Subscribers</h2>
                  :
                  <h2 className="text-xl font-semibold banglaFont">ম্যানেজ সাবস্ক্রাইবার</h2>
                }
              </div>
            </div>

            { language === 'en' ?
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden">Manage Subscribers</h2>
              :
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden banglaFont">ম্যানেজ সাবস্ক্রাইবার</h2>
            }

            <div className='flex md:flex-row flex-col items-center gap-5'>
              <div className='flex items-center w-full'>
                { language === 'en' ?
                  <input type="text" placeholder='Search Subscribers' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                  :
                  <input type="text" placeholder='সার্চ করুন' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none banglaFont" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                }
                <label className='font-semibold text-xl text-[#0082C4] border border-l-0 border-blue-500 border-opacity-20 bg-[#ECF4FF] rounded-r-xl py-3.5 px-4'><BsSearch/></label>
              </div>
            </div>
          </div>
          
          { paginatedItems?.length < 1 ?
            <div>
              { language === 'en' ?
                <p className='text-center text-2xl font-semibold py-12'>No Publication found.</p>
                :
                <p className='text-center text-2xl font-semibold py-12 banglaFont'>কোনো পাব্লিকেশন পাওয়া যায়নি ।</p>
              }
            </div>
            :
            <div className="pt-4 overflow-x-auto">
              <div className="inline-block min-w-full lg:mx-0 md:mx-5 mx-0 shadow-md rounded-lg overflow-hidden border-x">
                <table className="min-w-full leading-normal">
                  <thead className='bg-[#F6FAFF]'>
                    { language === 'en' ?
                      <tr>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">Index</th>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">Email</th>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">Subscription Status</th>
                        <th className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider">Actions</th>
                      </tr>
                      :
                      <tr>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">ইনডেক্স</th>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">ইমেইল</th>
                        <th className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider">সাবস্ক্রিপশন অবস্থা </th>
                        <th className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider" >এ্যাকশন</th>
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {paginatedItems.map((subscriber, index)=>{
                      return(
                        <tr key={subscriber?.id} >
                          <td className="px-5 py-5 text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{index+1}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <div>
                              <p className="text-gray-900 font-semibold whitespace-no-wrap">{subscriber?.email}</p>
                            </div>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                              <p className="text-[#777B84] text-base my-5" title={`${subscriber?.unsubscribed_reasons}`}>{subscriber?.subscription_status}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm flex items-center justify-center relative">
                            <div className='group flex flex-col gap-2'>
                              <div className='text-lg hover:text-white transition-all bg-white hover:bg-[#0082C4] border border-[#F1F7FF] rounded-full shadow-md lg:p-3 md:p-2 p-2 flex justify-center items-center cursor-pointer'><IoSettingsOutline /></div>
                              <div>
                                <div className="absolute pt-8 md:top-10 top-8 lg:-left-24 md:-left-24 -left-28 w-[190px]  hidden group-hover:block transition-all z-50">
                                  { language === 'en' ?
                                    <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                      { subscriber?.subscription_status === "Subscribed" ?
                                        <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleMakingUnsubscribed(subscriber?.id)}>
                                          <FaRegEdit />Make Unsubscribed
                                        </button>
                                      :
                                        <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleMakingSubscribed(subscriber?.id)}>
                                          <FaRegEdit />Make Subscribed
                                        </button>
                                      }
                                      <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeleteSubscriber(subscriber?.id)}>
                                        <RiDeleteBin6Line />Delete
                                      </button>
                                    </div>
                                    :
                                    <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                      { subscriber?.subscription_status === "Subscribed" ?
                                        <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleMakingUnsubscribed(subscriber?.id)}>
                                          <FaRegEdit />আনসাবস্ক্রাইবার করুন
                                        </button>
                                      :
                                        <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleMakingSubscribed(subscriber?.id)}>
                                          <FaRegEdit />সাবস্ক্রাইবার করুন
                                        </button>
                                      }
                                      <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeleteSubscriber(subscriber?.id)}>
                                      <RiDeleteBin6Line />মুছে ফেলুন
                                      </button>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )})
                    }
                  </tbody>
                </table>
                
              {/* Pagination */}
                <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                  <nav className="block">
                    <ul className="flex pl-0 rounded list-none gap-3">
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === 1}
                          onClick={() => handlePageChange(Number(currentPage) - 1)}
                        >
                          <CgChevronDoubleLeft />
                        </button>
                      </li>
                      {visiblePageNumbers.map((page) => (
                        <li key={page}>
                          <button
                            className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                              Number(currentPage) === page ? "font-bold bg-[#0082C4] text-white" : ""
                            }  focus:outline-none`}
                            onClick={() => handlePageChange(page)}
                          >
                          {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === totalPages}
                          onClick={() => handlePageChange(Number(currentPage) + 1)}
                        >
                          <CgChevronDoubleRight />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default ManageSubscribers