import React, { useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../Common/Footer';
import { toast } from 'react-toastify';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { GlobalContext } from '../GlobalState/GlobalState';

function ResetPassword() {
    const { language } = useContext(GlobalContext);

    const [sendPasswordResetEmail, sending] = useSendPasswordResetEmail(auth);
    const [email, setEmail] = useState('');

    const handleReset = async (e) =>{
        e.preventDefault();
    
        if (email) {
            await sendPasswordResetEmail(email);
            setEmail('');
            toast('Reset email sending...');
        } else {
            toast.error('Please enter your email address!');
        }
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleReset(e);
        }
    };

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
          window.scrollTo(0, 0);
        }
    }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Reset Password - Telementoring</title>
            <meta name='title' content='Reset Password - Telementoring' />
            <meta name="description" content="Reset your Telementoring account Password.
            " />
            <meta name="keywords" content="telementoring, Reset Password" />
        </Helmet>

        <div className='bg-blue-50'>
            <div className='max-w-7xl md:mx-auto mx-5 lg:px-8 md:pt-28 pt-12 md:pb-40 pb-12 flex justify-center'>
                { language === 'en' ?
                    <div className='bg-white md:p-8 p-5 rounded-2xl shadow-xl border hover:border-blue-300 transition-all md:w-1/2 w-full'>
                        <p className='text-center font-bold text-2xl mb-8'>Reset Password</p>
                        <form onSubmit={handleReset} className='flex flex-col gap-3'>
                            <label>
                                <span className='font-semibold'>Email<span className='text-[#F74F22] font-bold'>*</span></span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-5 py-2 mb-3 rounded-lg'
                                type="email" placeholder="Your Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onKeyDown={handleKeyDown}
                                required
                            />

                            <button type="submit" className='cursor-pointer px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#0082C4] rounded-md shadow hover:bg-[#0083c4de] focus:outline-none focus:ring-blue-200 focus:ring-4'>Reset</button>
                        </form>
                    </div>
                    :
                    <div className='bg-white md:p-8 p-5 rounded-2xl shadow-xl border hover:border-blue-300 transition-all md:w-1/2 w-full'>
                        <p className='text-center font-bold text-2xl mb-8 banglaFont'>পাসওয়ার্ড রিসেট করুন</p>
                        <form onSubmit={handleReset} className='flex flex-col gap-3'>
                            <label>
                                <span className='font-semibold banglaFont'>ইমেইল<span className='text-[#F74F22] font-bold'>*</span></span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-5 py-2 mb-3 rounded-lg banglaFont'
                                type="email" placeholder="আপনার ইমেইল"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onKeyDown={handleKeyDown}
                                required
                            />

                            <button type="submit" className='cursor-pointer px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#0082C4] rounded-md shadow hover:bg-[#0083c4de] focus:outline-none focus:ring-blue-200 focus:ring-4 banglaFont'>রিসেট</button>
                        </form>
                    </div>
                }
            </div>
        </div>
        
        <Footer />
    </div>
  )
}

export default ResetPassword