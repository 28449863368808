import React, { useContext, useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { BsUpload } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
// import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { Link, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function AddPublication() {
  const { language } = useContext(GlobalContext); 
  const { signedInUser, personalInfo } = useUserContext();

  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
  const [publications, setPublications] = useState([]);
  const navigate = useNavigate();
  // get all users mail
  const recipientsEmail = personalInfo?.map((e) => e?.email);
  const recipientsFirstName = personalInfo?.map((e) => e?.firstName);
  // console.log(recipientsFirstName);

  const [smtp, setSmtp] = useState([]);
  // Get smtp
  useEffect(()=>{
    Axios.get(`${BaseUrl}/smtp`).then((response) => {
        setSmtp(response.data);
    });
  },[]);
  
  // Title
  const [title, setTitle] = useState('');
  // Publication Location
  const [publishedLocation, setPublishedLocation] = useState('');
  // Content
  const [description, setDescription] = useState('');
  // Uploaded file
  const [file, setFile] = useState(null);
  // Checkbox
  const [isChecked, setIsChecked] = useState(false);

  // Reset Form
  const resetForm = () => {
    setTitle('');
    setPublishedLocation('');
    setDescription('');
    setFile(null);
  };
  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])
  // Get Publications
  useEffect(()=>{
    Axios.get(`${BaseUrl}/publications`).then((response) => {
      setPublications(response.data);
    });
  },[]);

  // get title
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  // get Published Location
  const handlePublishedLocationChange = (event) => {
    setPublishedLocation(event.target.value);
  };
  // get description
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Submit form
  const upload = async e => {
    e.preventDefault();
    const visibility = 'visible';
    const publication = 'publication';
    const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
    const authorImg = signedInUser?.profileImageUrl;
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    const post_time =currentTime +','+ ' '+ currentDate;
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      if(file !== null){
        let formData = new FormData();
        formData.append("pubFile", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("publishedLocation", publishedLocation);
        formData.append("post_time", post_time);
        formData.append("visibility", visibility);
        formData.append("publication", publication);
        formData.append("author", author);
        formData.append("authorImg", authorImg);
        await Axios.post(`${BaseUrl}/upload-publication-file`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        }).then(async (response) => {
          let latestPublication = response?.data;
          
          toast.success("Successfully Submitted!");

          if(isChecked){
            const convertedDescription = description?.replace(/<[^>]*>?/gm, '')?.replace(/&[^\s]*;/gm, '');
            
            Axios.post(`${BaseUrl}/sendPostNotification`, {
              title: title,
              content: convertedDescription,
              recipientsEmail: recipientsEmail,
              recipientsFirstName: recipientsFirstName,
              postDate: currentDate,
              authorFirstName: signedInUser?.firstName,
              authorFullName: author,
              postType: 'publication',
              postLink: `https://telementoring.net/publication-details/${latestPublication?.id}`,

              host: smtp?.smtpHost,
              port: smtp?.smtpPort,
              user: smtp?.smtpEmail,
              pass: smtp?.smtpPass,
            }).then(()=>{
              setIsChecked(!isChecked);
              setTimeout(()=>{
                toast.success("Mail sent!");
              }, 4000);
            })
          };
          // Fetch publications after email is sent
          await Axios.get(`${BaseUrl}/publications`).then((response) => {
            setPublications(response.data);
          });
          resetForm();
          navigate('/admin-settings/manage-publication?page=1');
        });
        
      }else{
        toast("Please choose an file!");
      }
    }else{
      toast.error("You don't have sufficient privilege!")
    }
  };

  // clear form
  const handleCancelClick = (e) => {
    e.preventDefault();
    resetForm();
  };

  return (
    <div>
      <Helmet>
        <title>Add Publication - Telementoring</title>
        <meta name='title' content='Add Publication - Telementoring' />
        <meta name="description" content="Add new publication at Telementoring." />
        <meta name="keywords" content="telementoring, add publication" />
      </Helmet>

      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          <div className='flex justify-between items-center mb-5 md:mt-0 mt-3'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Create a New Publication</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>নতুন পাব্লিকেশন তৈরি করুন</p>
            }
            <div className="relative inline-block group" >
              {/* <Link to='/newsfeed/manage-publication?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link> */}
              <Link to='/admin-settings/manage-publication?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link>
              { language === 'en' ?
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Close</div>
                :
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">বন্ধ</div>
              }
            </div>
          </div>
          <div>
            <div>
              { language === 'en' ?
                <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">Title</label>
                      <input type="text" value={title} onChange={handleTitleChange} required placeholder='Write here' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Upload PDF
                      </label>
                      <input
                        type="file"
                        name="pubFile"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block font-semibold text-gray-700 mb-2">Published location</label>
                    <input value={publishedLocation} type="text" required onChange={handlePublishedLocationChange} placeholder='Where published?' className='border border-blue-500 border-opacity-20 focus:outline-none rounded-xl px-5 py-3 text-base text-[#5d6069] bg-[#FAFCFF] w-full'/>
                  </div>
                  <textarea value={description} required onChange={handleDescriptionChange} placeholder='Short description...' className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069]' rows="6" />
                  {/* <JoditEditor
                    value={description}
                    onChange={(newContent) => setDescription(newContent)}
                    required
                  /> */}
                  <div className='flex md:flex-row flex-col gap-4 justify-between'>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                        className="mr-2"
                      />
                      <label htmlFor="checkbox" className='text-[#777B84]' >Check to send this publication notification to users email.</label>
                    </div>
                    <div className='flex gap-5'>
                      <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button>

                      <input type="submit" value='Submit Publication' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    </div>
                  </div>
                </form>
                :
                <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">টাইটেল</label>
                      <input type="text" value={title} onChange={handleTitleChange} required placeholder='এখানে লিখুন' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">পিডিএফ আপলোড</label>
                      <input
                        type="file"
                        name="pubFile"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block font-semibold text-gray-700 mb-2 banglaFont">প্রকাশিত স্থান</label>
                    <input value={publishedLocation} type="text" required onChange={handlePublishedLocationChange} placeholder='কোথায় প্রকাশিত?' className='border border-blue-500 border-opacity-20 focus:outline-none rounded-xl px-5 py-3 text-base text-[#5d6069] bg-[#FAFCFF] w-full banglaFont'/>
                  </div>
                  <textarea value={description} required onChange={handleDescriptionChange} placeholder='সংক্ষিপ্ত বর্ণনা...' className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069] banglaFont' rows="6" />
                  {/* <JoditEditor
                    value={description}
                    onChange={(newContent) => setDescription(newContent)}
                    required
                  /> */}
                  <div className='flex md:flex-row flex-col gap-4 justify-between'>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                        className="mr-2"
                      />
                      <label htmlFor="checkbox" className='text-[#777B84] banglaFont' >ব্যবহারকারীদের ইমেলে এই পাব্লিকেশন নোটিফিকেশন পাঠাতে চেক করুন ।</label>
                    </div>
                    <div className='flex gap-5'>
                      <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>বাতিল করুন</button>

                      <input type="submit" value='সাবমিট পাব্লিকেশন' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default AddPublication