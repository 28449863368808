import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function EditBlog() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();

  const { publicationId } = useParams();
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const [publications, setPublications] = useState([]);
  const [publication, setPublication] = useState({});

  // Uploaded file
  const [file, setFile] = useState(null);

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])

  // Get publications
  useEffect(()=>{
    Axios.get(`${BaseUrl}/publications`).then((response) => {
        setPublications(response.data);
    });
  },[]);  
  // get Publication details
  useEffect(()=>{
      Axios.get(`${BaseUrl}/edit-publication/${publicationId}`).then((response) => {
        setPublication(response.data);
      });
  },[publicationId]);

  // Update Publication
  const inputRefs = {
    newTitle: useRef(null),
    newDescription: useRef(null),
    newPublishedLocation: useRef(null),
  };
  const handleUpdatePublication = async (e) => {
    e.preventDefault();
  
    const formDataOfPublication = {
      newTitle: inputRefs.newTitle.current.value,
      newDescription: inputRefs.newDescription.current.value,
      newPublishedLocation: inputRefs.newPublishedLocation.current.value,
    };
    let formData = new FormData();
    formData.append("id", publicationId);
    formData.append("title", formDataOfPublication?.newTitle);
    formData.append("description", formDataOfPublication?.newDescription);
    formData.append("publishedLocation", formDataOfPublication?.newPublishedLocation);
  
    if (file !== null) {
      formData.append("pubFile", file);
    }
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      await Axios.put(`${BaseUrl}/update-publication`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(() => {
          Axios.get(`${BaseUrl}/publications`).then((response) => {
            setPublications(response.data);
          });
          toast.success("Successfully Updated!");
        })
        .catch((err) => {
          toast.error("Error in updating publication");
        });
    }else{
      toast.error("You don't have sufficient privilege!");
    }
  };
  
  return (
    <div>
      <Helmet>
        <title>Edit Publication - Telementoring</title>
        <meta name='title' content='Edit Publication - Telementoring' />
        <meta name="description" content="Edit publication of Telementoring." />
        <meta name="keywords" content="telementoring, edit publication" />
      </Helmet>
      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          <div className='flex justify-between items-center mb-5 md:mt-0 mt-10'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Edit Publication</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>ইডিট পাব্লিকেশন</p>
            }
            <div className="relative inline-block group">
              <Link to='/admin-settings/manage-publication?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link>
              { language === 'en' ?
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Close</div>
                :
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">বন্ধ</div>
              }
            </div>
          </div>
          <div>
            <div>
              { language === 'en' ?
                <form onSubmit={(e) => handleUpdatePublication(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">Title</label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={publication?.title}
                          placeholder='Write here' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Upload PDF
                      </label>
                      <input
                        type="file"
                        name="pubFile"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block font-semibold text-gray-700 mb-2">Published location</label>
                    <input 
                      type="text" 
                      ref={inputRefs.newPublishedLocation}
                      defaultValue={publication?.publishedLocation}
                      placeholder='Where published?' 
                      className='border border-blue-500 border-opacity-20 focus:outline-none rounded-xl px-5 py-3 text-base text-[#5d6069] bg-[#FAFCFF] w-full'
                    />
                  </div>
                  <textarea 
                      ref={inputRefs.newDescription}
                      defaultValue={publication?.description}
                      placeholder='Short description...' 
                      className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069]' rows="6" 
                  />
                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='Submit Publication' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    </div>
                  </div>
                </form>
                :
                <form onSubmit={(e) => handleUpdatePublication(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">টাইটেল</label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={publication?.title}
                          placeholder='এখানে লিখুন' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">পিডিএফ আপলোড</label>
                      <input
                        type="file"
                        name="pubFile"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block font-semibold text-gray-700 mb-2 banglaFont">পাব্লিকেশন স্থান</label>
                    <input 
                      type="text" 
                      ref={inputRefs.newPublishedLocation}
                      defaultValue={publication?.publishedLocation}
                      placeholder='কোথায় প্রকাশিত?' 
                      className='border border-blue-500 border-opacity-20 focus:outline-none rounded-xl px-5 py-3 text-base text-[#5d6069] bg-[#FAFCFF] w-full banglaFont'
                    />
                  </div>
                  <textarea 
                      ref={inputRefs.newDescription}
                      defaultValue={publication?.description}
                      placeholder='সংক্ষিপ্ত বর্ণনা...' 
                      className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069] banglaFont' rows="6" 
                  />
                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='সাবমিট পাব্লিকেশন' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default EditBlog