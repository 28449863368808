import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import Footer from '../Common/Footer';
// import Navbar from '../Common/Navbar';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import PublicationsHeroBg from '../../assets/Publication/PublicationsHeroBg.png';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdNavigateNext } from 'react-icons/md';
import { ImNewspaper } from 'react-icons/im';
import Axios  from "axios";
import BaseUrl from "../BaseURL";
import ScrollToTop from '../Common/ScrollToTop';
import Footer from "../Common/Footer";
import BecomeVolunteer from "../BecomeVolunteer/BecomeVolunteer";
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";

function Publications() {
  const { language } = useContext(GlobalContext);

  const [publications, setPublications] = useState([]);
  const visiblePublications = publications.filter((publication) => publication.visibility === 'visible');

  // Get Publications
  useEffect(()=>{
    Axios.get(`${BaseUrl}/publications`).then((response) => {
      setPublications(response.data);
    });
  },[]);

  //  Scroll to top, when user will be redirected in this page
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.preserveScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);
  
  return (
    <div className="bg-[#F6FAFF]">
      <Helmet>
        <title>Publications - Telementoring</title>
        <meta name='title' content='Publications - Telementoring' />
        <meta name="description" content="Explore the latest publications from Telementoring by the research collaborators." />
        <meta name="keywords" content="telementoring, publications, research" />
      </Helmet>

      {/* Hero */}
      <section>
          <div className='relative' style={{"backgroundImage": `url(${PublicationsHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
              <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                  <div className='TextInnerBg w-full'>
                      { language === 'en' ?
                        <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Our <span className='text-[#FDBE44]'>Publications</span></p>
                        :
                        <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold                 banglaFont'>গবেষণা ও <span className='text-[#FDBE44]'>প্রতিবেদন</span></p>
                      }
                      <div className='flex items-center gap-2 mt-4'>
                          { language === 'en' ?
                            <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                            :
                            <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                          }
                          <span><img src={NextIcon} alt='Next Icon' /></span>
                          { language === 'en' ?
                            <Link to='/publications' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Publications</Link>
                            :
                            <Link to='/publications' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>গবেষণা ও প্রতিবেদন</Link>
                          }
                      </div>
                  </div>
              </div>
          </div>
          {/* Divider */}
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
              <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
          </div>
      </section>

      
      {/* Publications */}
      <div className="max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 flex flex-col gap-8 my-12">
        {visiblePublications?.length < 1 ? 
          <div>
            { language === 'en' ?
              <p className='text-center text-3xl font-semibold py-16'>No publication found.</p>
              :
              <p className='text-center text-3xl font-semibold py-16'>কোনো গবেষণা ও প্রতিবেদন পাওয়া যায়নি ।</p>
            }
          </div>
          :
          <div>
            {
              visiblePublications.slice().reverse().map((publication)=>{
                return(
                  <div key={publication?.id} className="bg-white rounded-2xl md:px-10 px-5 md:py-12 py-5" style={{boxShadow:'0px 7px 12px rgba(0, 40, 89, 0.05)'}} >
                    <Link to={`/publication-details/${publication?.id}`} className="text-[#2E3033] hover:text-[#0082C4] transition-all font-bold text-xl">{publication?.title}</Link>
                    <div className="flex py-4 border-b border-b-[#0082C4] border-opacity-10">
                      <div className='rounded-md flex items-center gap-1 bg-[#F2F9FC] pr-3'>
                        <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><ImNewspaper /></p>
                        <p className='text-[#0082C4] font-semibold text-xs px-2 uppercase'>{publication?.publishedLocation}</p>
                      </div>
                    </div>
                    <p className="text-[#777B84] text-base my-5">{publication?.description}...</p>
                    <div className="flex">
                      { language === 'en' ?
                        <a href={`${BaseUrl}${publication?.fileUrl}`} target='_blank' className="flex items-center text-[#0082C4] underline">More: [pdf]</a>
                        :
                        <a href={`${BaseUrl}${publication?.fileUrl}`} target='_blank' className="flex items-center text-[#0082C4] underline banglaFont">আরও: [পিডিএফ]</a>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

      {/* Become Volunteer */}
      <section className='mb-20'>
        <BecomeVolunteer/>
      </section>

      <ScrollToTop></ScrollToTop>
      <Footer/>
    </div>
  );
};

export default Publications