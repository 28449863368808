import React, { useContext, useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { BsUpload } from 'react-icons/bs';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function AddBlog() {
  const { language } = useContext(GlobalContext);
  const { signedInUser, personalInfo } = useUserContext();

  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
  const recipientsEmail = subscribers?.filter(recipient => recipient?.subscription_status === 'Subscribed')?.map(recipient => recipient?.email);
  const recipientsFirstName = personalInfo?.map((e) => e?.firstName);
  const [smtp, setSmtp] = useState([]);
  // Get smtp
  useEffect(()=>{
    Axios.get(`${BaseUrl}/smtp`).then((response) => {
      setSmtp(response.data);
    });
  },[]);

  // subject
  const [subject, setSubject] = useState('');
  // Content
  const [description, setDescription] = useState('');
  // Uploaded file
  const [file, setFile] = useState(null);
  // Reset form
  const resetForm = () => {
    setSubject('');
    setDescription('');
    setFile(null);
  };
  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])
  // Get subscribers
  useEffect(()=>{
      Axios.get(`${BaseUrl}/subscribers`).then((response) => {
          setSubscribers(response.data);
      });
  },[]);
  // get subject
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  // Submit form
  // const upload = async e => {
  //   e.preventDefault();
  //   if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
  //     if(file !== null){
  //       let formData = new FormData();
  //       formData.append("attachmentFile", file);
  //       formData.append("subject", subject);
  //       formData.append("content", description);
  //       formData.append("recipientsEmail", recipientsEmail);
  //       formData.append("recipientsFirstName", recipientsFirstName);
  //       formData.append("author", 'Telementoring');
  //       formData.append("host", smtp?.smtpHost);
  //       formData.append("port", 587);
  //       formData.append("user", smtp?.smtpHost);
  //       formData.append("pass", smtp?.smtpHost);
  //       await Axios.post(`${BaseUrl}/sendNewsletter`, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }).then((response) => {
  //         setTimeout(()=>{
  //           toast.success("Mail sent!");
  //         }, 4000);
  //       });
        
  //     }else{
  //       await Axios.post(`${BaseUrl}/sendNewsletter`, {
  //         subject: subject,
  //         content: description,
  //         recipientsEmail: recipientsEmail,
  //         recipientsFirstName: recipientsFirstName,
  //         author: 'Telementoring',
  //         host: smtp?.smtpHost,
  //         port: smtp?.smtpPort,
  //         user: smtp?.smtpEmail,
  //         pass: smtp?.smtpPass,
  //       }).then(()=>{
  //         setTimeout(()=>{
  //           toast.success("Mail sent!");
  //         }, 4000);
  //       })
  //     }
  //   }else{
  //     toast.error("You don't have sufficient privilege!")
  //   }
  // };
  const upload = async e => {
    e.preventDefault();
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      try {
        await Axios.post(`${BaseUrl}/sendNewsletter`, {
          subject: subject,
          content: description,
          recipientsEmail: recipientsEmail,
          recipientsFirstName: recipientsFirstName,
          author: 'Telementoring',
          host: smtp?.smtpHost,
          port: smtp?.smtpPort,
          user: smtp?.smtpEmail,
          pass: smtp?.smtpPass,
        });
        toast.success("Mail sent!");
        resetForm();
      } catch (error) {
        toast.error("Something went wrong!");
      }
    }else{
      toast.error("You don't have sufficient privilege!");
    }
  };
  // clear form
  const handleCancelClick = (e) => {
    e.preventDefault();
    resetForm();
  };

  return (
    <div>
      <Helmet>
        <title>Send Announcement - Telementoring</title>
        <meta name='title' content='Add Blog - Telementoring' />
        <meta name="description" content="Add new blog at Telementoring." />
        <meta name="keywords" content="telementoring, add blog" />
      </Helmet>

      <div className='max-w-7xl lg:mx-auto mx-0 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          <div className='mb-5 md:mt-0 mt-3'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Send a Newsletter</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>নিউজলেটার পাঠান</p>
            }
          </div>
          <div>
            { language === 'en' ?
              <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                <div>
                  <label className="block font-semibold text-gray-700 mb-2">Subject</label>
                  <input type="text" value={subject} onChange={handleSubjectChange} required placeholder='Write here' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' />
                </div>
                <JoditEditor
                  value={description}
                  onChange={(newContent) => setDescription(newContent)}
                  required
                />
                {/* <div>
                  <label className="block font-semibold text-gray-700 mb-2">Add Attachment</label>
                  <input
                    type="file"
                    name="blogImg"
                    onChange={(e) => {
                        setFile(e.target.files[0]);
                    }}
                    className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                  />
                </div> */}
                <div className='flex md:flex-row flex-col gap-4 justify-end'>
                  <div className='flex gap-5'>
                    <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button>
                      
                    <input type="submit" value='Submit' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                  </div>
                </div>
              </form>
              :
              <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                <div>
                  <label className="block font-semibold text-gray-700 mb-2 banglaFont">বিষয়</label>
                  <input type="text" value={subject} onChange={handleSubjectChange} required placeholder='এখানে লিখুন' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' />
                </div>
                <JoditEditor
                  value={description}
                  onChange={(newContent) => setDescription(newContent)}
                  required
                />
                {/* <div>
                  <label className="block font-semibold text-gray-700 mb-2 banglaFont">এ্যটাচমেন্ট যুক্ত করুন</label>
                  <input
                    type="file" name="blogImg"
                    onChange={(e) => {
                        setFile(e.target.files[0]);
                    }}
                    className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                  />
                </div> */}
                <div className='flex md:flex-row flex-col gap-4 justify-end'>
                  <div className='flex gap-5'>
                  <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>বাতিল করুন</button>
                  <input type="submit" value='সাবমিট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default AddBlog