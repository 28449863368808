import React from 'react';
import { useAuthState, useSendEmailVerification } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../../firebase.init';
import Loading from '../Common/Loading';
import { toast } from 'react-toastify';
// import { GoMailRead } from 'react-icons/go';
import { FaRegEnvelope } from 'react-icons/fa';

function RequiredAuth({children}) {
    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    const [sendEmailVerification, sending, error] = useSendEmailVerification(auth);
    // console.log(user);

    if (loading) {
        return <Loading></Loading>
    };

    if (!user) {
        return <Navigate to='/login' state={{ from: location }} replace></Navigate>
    };
 
    // Verify email page
    if(!user.emailVerified){
        return(
            <div className='bg-blue-50'>
                <div className='h-screen flex justify-center items-center md:mx-12 mx-5'>
                    <div className='flex flex-col gap-5 justify-center items-center bg-white md:p-12 p-5 lg:mb-20 md:mb-28 mb-36 rounded-xl shadow-xl'>
                        <div className='text-6xl text-[#0082C4]'>
                            <FaRegEnvelope />
                        </div>
                        <h3 className='text-2xl font-semibold text-center'>Your email is not verified!</h3>
                        <h5 className='text-[#777B84] lg:w-3/5 md:w-4/5 w-full text-center'>Looks like your email is not verified. Please verify your email address. Didn&apos;t get the verification email?</h5>
                        <button
                            onClick={async () => {
                                const success = await sendEmailVerification();
                                if (success) {
                                    toast.success('Sent verification email');
                                };
                            }}
                            className='bg-[#0082C4] hover:bg-[#0083c4e0] transition-all text-white px-5 py-2 rounded-full text-lg'
                        >
                            Resend verification email
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return children;
};

export default RequiredAuth