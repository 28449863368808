import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import auth from '../../firebase.init';
import { Helmet } from 'react-helmet';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import activeDot from '../../assets/activeDot.png';
import { GlobalContext } from '../GlobalState/GlobalState';
import ScrollToTop from '../Common/ScrollToTop';
import Footer from '../Common/Footer';
import BaseUrl from '../BaseURL'
import { FiAlertTriangle } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useUserContext } from '../GlobalState/UserContext';

function Settings() {
    const { language } = useContext(GlobalContext);
    // const { signedInUser, personalInfo } = useUserContext();
    const [user] = useAuthState(auth);
    const [personalInfo, setPersonalInfo] = useState([]);
    const [activeLink, setActiveLink] = useState("profileSettings");
    const [userEmail, setUserEmail]=useState("");
    const [userName, setUserName]=useState("");
    const signedInUser = personalInfo.find((person) => person?.userEmail === user?.email);
    const navigate = useNavigate();

    useEffect(()=>{
        setUserEmail(user?.email);
        setUserName(user?.displayName);
    },[user?.email, user?.displayName]);

    // Get infos
    useEffect(()=>{
        Axios.get(`${BaseUrl}/infos`).then((response) => {
          setPersonalInfo(response.data);
        });
    },[]);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    // Change password
    const onSubmitPassword = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmNewPassword) {
            setError("New passwords do not match");
            return;
        }
        // Get auth provider
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
            user.email, 
            currentPassword
        );

        // Re-authenticate user
        reauthenticateWithCredential(user, credential)
        .then(() => {
            // User re-authenticated, now we can update the password
            updatePassword(user, newPassword)
            .then(() => {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                setError(null);
                alert('Password updated successfully');
            })
            .catch((error) => {
                setError(error);
            });
        })
        .catch((error) => {
            // An error happened.
            setError('Current password is incorrect');
        });
    };

    const [formData2, setFormData2]=useState({
        newFirstName: "",
        newLastName: "",
        newEmail: "",

        newProfileType: "",
        newBio: "",
    });
    useEffect(()=>{
        setFormData2({
            newFirstName: signedInUser?.firstName,
            newLastName: signedInUser?.lastName,
            newEmail: signedInUser?.email,

            newProfileType: signedInUser?.profileType,
            newBio: signedInUser?.bio,
        });
    },[signedInUser]);
    const inputChangeHandler = (e, key) => {
        setFormData2({
          ...formData2,
          [key]: e.target.value,
        });
    };
    const inputChangeHandler2 = (e, key) => {
        const value = e.target.value;
        const wordCount = value.trim().split(/\s+/).length;

        if (wordCount <= 100) {
            setFormData2({
              ...formData2,
              newBio: value
            });
          }
    };
    // Update profile settings
    const handleSubmit = (e, id) => {
        e.preventDefault();
        
        Axios.put(`${BaseUrl}/updateProfileSettings`, { 
            firstName: formData2?.newFirstName,
            lastName: formData2?.newLastName,
            email: formData2?.newEmail,

            bio: formData2?.newBio,
            profileType: formData2?.newProfileType,
            id: id 
            }).then(
                (response) => {
                setPersonalInfo(
                    personalInfo.map((val) => {
                    return val.id === id
                        ? {
                            id: val.id,

                            firstName: val.firstName,
                            lastName: val.lastName,
                            email: val.email,

                            bio: val.bio,
                            profileType: val.profileType,
                        }
                        : val;
                    })
                );
                Axios.get(`${BaseUrl}/infos`).then((response) => {
                    setPersonalInfo(response.data);
                });
                toast.success("Settings updated!");
            }
        );
    };

    // delete account
    const handleDeleteAccount = () =>{
        const proceed = window.confirm("Are you sure?");
        if(proceed){
            navigate("/contact-us");
        }
    }

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Settings - Telementoring</title>
            <meta name='title' content='Settings - Telementoring' />
            <meta name="description" content="View and edit your user information for the Telementoring platform at Profile settings." />
            <meta name="keywords" content="telementoring, Settings" />
        </Helmet>

        {/* Hero */}
        <div className='h-44 flex justify-center md:items-center' style={{"backgroundImage": `url(${ProfileBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize': 'cover'}}>
            { language === 'en' ?
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl'>User Settings</h2>
                :
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl banglaFont'>ইউজার সেটিংস</h2>
            }
        </div>


        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 lg:flex md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-5 mb-12 relative'>
            <div className='lg:w-[25%] md:w-[30%] w-[100%] -mt-12 md:px-0 px-5 '>
                <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-8 shadow-md sticky top-20'>
                    <div className='flex justify-center lg:-mt-20 -mt-[4.5rem] relative'>
                        { signedInUser?.profileImageUrl ?
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${signedInUser?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                        }
                    </div>
                    <div className='text-center mt-2'>
                        {signedInUser?.firstName
                        ?
                            <p className='text-xl font-bold'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                        :
                            <p className='text-xl font-bold'>{userName}</p>
                        }
                        {signedInUser?.firstName
                        ?
                            <p className='text-base text-[#777B84]'>{signedInUser?.email}</p>
                        :
                            <p className='text-base text-[#777B84]'>{userEmail}</p>
                        }
                    </div>
                    { language === 'en' ?
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "profileSettings" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("profileSettings");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "profileSettings" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Profile Settings</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "changePassword" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                setActiveLink("changePassword");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "changePassword" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Change Password</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "deleteAccount" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("deleteAccount");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "deleteAccount" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Delete Account</span>
                            </li>
                        </ul>
                        :
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "profileSettings" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                setActiveLink("profileSettings");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "profileSettings" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>প্রোফাইল সেটিংস</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "changePassword" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                setActiveLink("changePassword");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "changePassword" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>পাসওয়ার্ড পরিবর্তন</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                activeLink === "deleteAccount" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                setActiveLink("deleteAccount");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "deleteAccount" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>একাউন্ট ডিলিট</span>
                            </li>
                        </ul>
                    }
                </div>
            </div>
            
            <div className='lg:w-[75%] md:w-[70%] w-[100%] lg:px-0 md:px-0 px-5 py-10'>
                { language === 'en' ?
                    <div className={`${activeLink === "profileSettings" ? "block" : "hidden"}`}>
                        <div>
                            <form onSubmit={(e) => handleSubmit(e, signedInUser?.id)}>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mb-5">
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>First Name </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='First Name'
                                            onChange={(e)=>{inputChangeHandler(e,"newFirstName")}}
                                            defaultValue={signedInUser?.firstName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>Last Name </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='Last Name'
                                            onChange={(e)=>{inputChangeHandler(e,"newLastName")}}
                                            defaultValue={signedInUser?.lastName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>Email </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="email"
                                            onChange={(e)=>{inputChangeHandler(e,"newEmail")}}
                                            defaultValue={signedInUser?.email}
                                            placeholder='hello@gmail.com'
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="bio" className='text-base font-semibold text-[#2E3033]'>Add Bio <span className='text-sm'>[Maximum 100 words]</span></label>
                                    <br/>
                                    <textarea
                                        id="bio" name="bio" rows="4" cols="50"
                                        placeholder='Write something about yourself'
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        value={formData2?.newBio}
                                        onChange={inputChangeHandler2}
                                    />
                                    <p className={`text-xs text-right ${formData2?.newBio?.trim().split(/\s+/).length >= 100 ? 'text-red-500' : 'text-gray-800'}`}>
                                        {formData2?.newBio?.trim().split(/\s+/).length}/100
                                    </p>
                                </div>

                                <label htmlFor="gender" className='text-base font-semibold text-[#2E3033]'>Profile privacy</label><br/>
                                <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-8 mt-3'>
                                    <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                                    <span className='text-sm'>We will not share your phone numbers in any case. If you choose a public profile, your profile and photo will be displayed in our volunteer list. If you choose a private profile, only your name will be displayed in the volunteer list.</span>
                                </div>
                                <div>
                                    <select id="profileType" name="profileType" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                    value={formData2?.newProfileType}
                                    onChange={(e)=>{inputChangeHandler(e,"newProfileType")}}
                                    >
                                        <option value="Public">Public</option>
                                        <option value="Private">Private</option>
                                    </select>
                                </div>
                                <div className='w-full flex justify-end'>
                                    <input type="submit" value='Submit' className='bg-blue-500 rounded-md text-white hover:bg-blue-600 transition-all px-12 py-2 inline-block my-5 mr-2 cursor-pointer' />
                                </div>
                            </form>
                        </div>
                    </div>
                    :
                    <div className={`${activeLink === "profileSettings" ? "block" : "hidden"}`}>
                        <div>
                            <form onSubmit={(e) => handleSubmit(e, signedInUser?.id)}>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 mb-5">
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের প্রথম অংশ</label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='নামের প্রথম অংশ'
                                            onChange={(e)=>{inputChangeHandler(e,"newFirstName")}}
                                            defaultValue={signedInUser?.firstName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033] banglaFont'>নামের শেষ অংশ</label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='নামের শেষ অংশ'
                                            onChange={(e)=>{inputChangeHandler(e,"newLastName")}}
                                            defaultValue={signedInUser?.lastName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033] banglaFont'>ইমেইল</label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="email"
                                            onChange={(e)=>{inputChangeHandler(e,"newEmail")}}
                                            defaultValue={signedInUser?.email}
                                            placeholder='hello@gmail.com'
                                        />
                                    </div>
                                    {/* <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>First Name </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='First Name'
                                            onChange={(e)=>{inputChangeHandler(e,"newFirstName")}}
                                            defaultValue={signedInUser?.firstName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>Last Name </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="text"
                                            placeholder='Last Name'
                                            onChange={(e)=>{inputChangeHandler(e,"newLastName")}}
                                            defaultValue={signedInUser?.lastName}
                                        />
                                    </div>
                                    <div>
                                        <label className='text-base font-semibold text-[#2E3033]'>Email </label><br/>
                                        <input className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                            type="email"
                                            onChange={(e)=>{inputChangeHandler(e,"newEmail")}}
                                            defaultValue={signedInUser?.email}
                                            placeholder='hello@gmail.com'
                                        />
                                    </div> */}
                                </div>
                                <div>
                                    <label htmlFor="bio" className='text-base font-semibold text-[#2E3033] banglaFont'>বায়ো যুক্ত করুন <span className='text-sm'>[সর্বোচ্চ ১০০ শব্দ]</span>
                                    </label>
                                    <br/>
                                    <textarea
                                        id="bio" name="bio" rows="4" cols="50"
                                        placeholder='আপনার সম্পর্কে কিছু লিখুন'
                                        className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                        value={formData2?.newBio}
                                        onChange={inputChangeHandler2}
                                    />
                                    <p className={`text-xs text-right ${formData2?.newBio?.trim().split(/\s+/).length >= 100 ? 'text-red-500' : 'text-gray-800'}`}>
                                        {formData2?.newBio?.trim().split(/\s+/).length}/100
                                    </p>
                                </div>

                                <label htmlFor="profileType" className='text-base font-semibold text-[#2E3033] banglaFont'>প্রোফাইলের প্রাইভেসি</label><br/>
                                <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-8 mt-3'>
                                    <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                                    <span className='text-sm banglaFont'>আমরা কোনো অবস্থাতেই আপনার ফোন নম্বর শেয়ার করব না । আপনি যদি একটি পাবলিক প্রোফাইল পছন্দ করেন, আপনার প্রোফাইল এবং ফটো আমাদের স্বেচ্ছাসেবক তালিকায় প্রদর্শিত হবে । আপনি যদি একটি প্রাইভেট প্রোফাইল পছন্দ করেন, শুধুমাত্র আপনার নাম স্বেচ্ছাসেবক তালিকায় প্রদর্শিত হবে ।</span>
                                </div>
                                <div>
                                    <select id="profileType" name="profileType" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                    value={formData2?.newProfileType}
                                    onChange={(e)=>{inputChangeHandler(e,"newProfileType")}}
                                    >
                                        <option value="Public">পাবলিক</option>
                                        <option value="Private">প্রাইভেট</option>
                                    </select>
                                </div>
                                <div className='w-full flex justify-end'>
                                    <input type="submit" value='সাবমিট' className='bg-blue-500 rounded-md text-white hover:bg-blue-600 transition-all px-12 py-2 inline-block my-5 mr-2 cursor-pointer banglaFont' />
                                </div>
                            </form>
                        </div>
                    </div>
                }

                { language === 'en' ?
                    <div className={`${activeLink === "changePassword" ? "block" : "hidden"}`}>
                        <h3 className='text-center font-semibold text-xl mb-5'>Change your password</h3>
                        <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-10'>
                            <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                            <span className='text-sm'>If you have sigend up using Google and don't know the current password, than please use <Link to='/reset-password' className='hover:underline text-blue-500 transition-all font-semibold'>Reset Password</Link> option to reset your password.</span>
                        </div>
                        <form onSubmit={onSubmitPassword} className='flex flex-col gap-2'>
                            <input
                                name="currentPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                value={currentPassword}
                                onChange={e => setCurrentPassword(e.target.value)}
                                type="password"
                                placeholder="Current Password"
                            />
                            <input
                                name="newPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                type="password"
                                placeholder="New Password"
                            />
                            <input
                                name="confirmNewPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'
                                value={confirmNewPassword}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                type="password"
                                placeholder="Confirm New Password"
                            />
                            <button type="submit" className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-lg text-base md:py-3 py-2 md:px-12 px-8 rounded-lg mt-5 uppercase'>Change Password</button>

                            {error && <p>{error.message}</p>}
                        </form>
                    </div>
                    :
                    <div className={`${activeLink === "changePassword" ? "block" : "hidden"}`}>
                        <h3 className='text-center font-semibold text-xl mb-5 banglaFont'>আপনার পাসওয়ার্ড পরিবর্তন করুন</h3>
                        <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-10 banglaFont'>
                            <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                            <span className='text-sm'>আপনি যদি Google ব্যবহার করে সাইন আপ করে থাকেন এবং বর্তমান পাসওয়ার্ড জানেন না, তাহলে আপনার পাসওয়ার্ড রিসেট করতে অনুগ্রহ করে  <Link to='/reset-password' className='hover:underline text-blue-500 transition-all font-semibold'>রিসেট পাসওয়ার্ড</Link> বিকল্পটি ব্যবহার করুন।</span>
                        </div>
                        <form onSubmit={onSubmitPassword} className='flex flex-col gap-2'>
                            <input
                                name="currentPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                value={currentPassword}
                                onChange={e => setCurrentPassword(e.target.value)}
                                type="password"
                                placeholder="বর্তমান পাসওয়ার্ড"
                            />
                            <input
                                name="newPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                type="password"
                                placeholder="নতুন পাসওয়ার্ড"
                            />
                            <input
                                name="confirmNewPassword"
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full banglaFont'
                                value={confirmNewPassword}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                type="password"
                                placeholder="কনফার্ম নতুন পাসওয়ার্ড"
                            />
                            <button type="submit" className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-lg text-base md:py-3 py-2 md:px-12 px-8 rounded-lg mt-5 uppercase banglaFont'>পাসওয়ার্ড পরিবর্তন</button>

                            {error && <p>{error.message}</p>}
                        </form>
                    </div>
                }

                { language === 'en' ?
                    <div className={`${activeLink === "deleteAccount" ? "block" : "hidden"}`}>
                        <h3 className='text-center font-semibold text-xl mb-5'>Delete Account</h3>
                        <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-10'>
                            <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                            <span className='text-sm'>If you would like to delete your account from Telementoring, you have to submit a request to us. Click on the "<span className='font-semibold'>Delete Account</span>" below to submit the deregistration request.</span>
                        </div>
                        <button onClick={handleDeleteAccount} className='bg-red-500 text-white px-8 py-2 rounded-lg'>Delete Account</button>
                    </div>
                    :
                    <div className={`${activeLink === "deleteAccount" ? "block" : "hidden"}`}>
                        <h3 className='text-center font-semibold text-xl mb-5 banglaFont'>একাউন্ট ডিলিট</h3>
                        <div className='flex items-center gap-4 border border-red-200 rounded-xl p-3 shadow-md mb-10'>
                            <span className='text-3xl text-red-500'><FiAlertTriangle/></span>
                            <span className='text-base banglaFont'>আপনি যদি টেলিমেন্টরিং থেকে আপনার অ্যাকাউন্ট মুছে ফেলতে চান, তাহলে আপনাকে আমাদের কাছে একটি অনুরোধ জমা দিতে হবে। ডিরেজিস্ট্রেশন রিকুয়েস্ট জমা দিতে নীচে "<span className='font-semibold'>ডিলিট একাউন্ট</span>" এ ক্লিক করুন৷</span>
                        </div>
                        <button onClick={handleDeleteAccount} className='bg-red-500 text-white px-8 py-2 rounded-lg banglaFont'>ডিলিট একাউন্ট</button>
                    </div>
                }
            </div>
        </div>

        <ScrollToTop></ScrollToTop>
        <Footer></Footer>
    </div>
  )
}

export default Settings