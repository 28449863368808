import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import NewsAndArticleHeroBg from '../../assets/NewsAndArticle/NewsAndArticleHeroBg.png';
import { AiOutlineCalendar } from 'react-icons/ai';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { MdNavigateNext } from 'react-icons/md';
import GreaterThan from '../../assets/Home/greater-than.png';
import Axios from 'axios';
import BaseUrl from '../BaseURL';
import ScrollToTop from '../Common/ScrollToTop';
import DefaultImg from '../../assets/default-img.png';
import Footer from '../Common/Footer';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function Media() {
  const { language } = useContext(GlobalContext);

  function isBangla(text) {
    const banglaCharRegex = /[\u0980-\u09FF]/;
    return banglaCharRegex.test(text);
  }
  const [media, setMedia]=useState([]);
  const visibleMedia = media.filter((singleMedia) => singleMedia.visibility === 'visible');
  const latestMedia = visibleMedia[visibleMedia?.length - 1];
  
  // Get media
  useEffect(()=>{
    Axios.get(`${BaseUrl}/media`).then((response) => {
      setMedia(response.data);
    });
  },[]);

  const [blogs, setBlogs] = useState([]);
  const visibleBlogs = blogs.filter((blog) => blog.visibility === 'visible');
  // Get blogs
  useEffect(()=>{
    Axios.get(`${BaseUrl}/blogs`).then((response) => {
        setBlogs(response.data);
    });
  },[]);

  //  Scroll to top, when user will be redirected in this page
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.preserveScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);
  
  return (
    <div>
      <Helmet>
        <title>Media - Telementoring</title>
        <meta name='title' content='Media - Telementoring' />
        <meta name="description" content="Stay up-to-date with the latest media coverage of Telementoring, a volunteer-based education program." />
        <meta name="keywords" content="telementoring, media, news, volunteer-based, article" />
      </Helmet>
      {/* Hero */}
      <section>
          <div className='relative' style={{"backgroundImage": `url(${NewsAndArticleHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
              <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                  <div className='TextInnerBg w-full'>
                      { language === 'en' ?
                        <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>News and <span className='text-[#FDBE44]'>Article</span></p>
                        :
                        <p className='text-[#FDBE44] md:text-4xl text-2xl font-bold banglaFont'>মিডিয়া</p>
                      }
                      <div className='flex items-center gap-2 mt-4'>
                          { language === 'en' ?
                            <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                            :
                            <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                          }
                          <span><img src={NextIcon} alt='Next Icon' /></span>
                          { language === 'en' ?
                            <Link to='/media' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Media</Link>
                            :
                            <Link to='/media' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>মিডিয়া</Link>
                          }
                      </div>
                  </div>
              </div>
          </div>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
              <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
          </div>
      </section>

      {/*latest media */}
      <section className={`max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 pt-12 lg:pb-12 pb-0 ${visibleMedia?.length < 1 ? 'hidden' : 'block'}`}>
        <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-5 gap-0 items-center bg-white rounded-xl shadow-lg lg:p-8 p-4'>
          {
            latestMedia?.imageUrl ?
            <img src={`${BaseUrl}${latestMedia?.imageUrl}`} alt="News cover Img" className='rounded-xl' />
            :
            <img src={DefaultImg} alt="News cover Img"  className='rounded-xl' />
          }
          <div className='flex flex-col gap-4'>
            <a href={latestMedia?.externalLink} target='_blank' className='text-[#2E3033] font-semibold text-2xl hover:text-blue-500 transition-all'>{latestMedia?.title}</a>
            <div className='flex'>
              <p className='flex items-center gap-3 text-[#777B84] text-sm bg-[#F6FAFF] rounded-full px-3 py-1'><span className='text-[#0082C4]'><AiOutlineCalendar/></span>{latestMedia?.post_time}</p>
            </div>
            <p className='text-[#777B84] text-base'>{latestMedia && latestMedia?.description && latestMedia?.description.length >= 130 ? latestMedia?.description?.slice(0,130) + "..." : latestMedia?.description}</p>
            
            <div className='flex'>
              { language === 'en' ?
                <a href={latestMedia?.externalLink} target='_blank' className='bg-[#0082C4] hover:bg-[#0083c4e1] transition-all rounded-lg text-white flex items-center gap-2 px-5 lg:py-3 md:py-2 py-2 lg:mt-10'>Learn More<HiArrowNarrowRight/></a>
                :
                <a href={latestMedia?.externalLink} target='_blank' className='bg-[#0082C4] hover:bg-[#0083c4e1] transition-all rounded-lg text-white flex items-center gap-2 px-5 lg:py-3 md:py-2 py-2 lg:mt-10'>আরও জানুন<HiArrowNarrowRight/></a>
              }
            </div>
          </div>
        </div>
      </section>

      {/* All Media */}
      <section className={`max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-16 md:px-8 py-12`}>
        {visibleMedia?.length < 1 ? 
          <div>
            { language === 'en' ?
              <p className='text-center text-3xl font-semibold py-16'>No news or articles found.</p>
              :
              <p className='text-center text-3xl font-semibold py-16'>কোনো মিডিয়া পাওয়া যায়নি ।</p>
            }
          </div>
          :
          <div>
            { language === 'en' ?
              <p className='text- font-bold md:text-4xl text-2xl flex items-center border-b pb-3'>All Media <MdNavigateNext/></p>
              :
              <p className='text- font-bold md:text-4xl text-2xl flex items-center border-b pb-3 banglaFont'>মিডিয়াগুলো <MdNavigateNext/></p>
            }
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 pt-12'>
              {
                visibleMedia.slice().reverse().map((singleMedia)=>{
                  return(
                    <div key={singleMedia?.id} className='bg-white rounded-2xl shadow-md pb-12 border border-white hover:border-orange-300 transition-all relative'>
                      {
                        singleMedia?.imageUrl ?
                        <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${BaseUrl}${singleMedia?.imageUrl})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                            <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                <p className='text-lg font-bold text-white rounded-md flex justify-center items-center px-1 py-1 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                <p className='text-white text-xs px-2'>{singleMedia?.post_time}</p>
                            </div>
                        </div>
                        :
                        <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${DefaultImg})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                            <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                <p className='text-xl font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                <p className='text-white text-xs px-2'>{singleMedia?.post_time}</p>
                            </div>
                        </div>
                      }
                      <div className='lg:px-7 md:px-7 px-5'>
                        <div className='border-b py-3'>
                          <a href={singleMedia?.externalLink} target='_blank' className={`font-bold hover:text-blue-500 transition-all ${isBangla(singleMedia?.title) ? 'banglaFont' : ''}`}>{singleMedia && singleMedia?.title && singleMedia?.title.length >= 60 ? singleMedia?.title?.slice(0,60) + "..." : singleMedia?.title}</a>
                          
                        </div>
                        <p className={`py-3 text-[#777B84] text-base ${ isBangla(singleMedia?.title) ? 'banglaFont' : ''}`}>{singleMedia && singleMedia?.description && singleMedia?.description.length >= 160 ? singleMedia?.description?.slice(0,160) + "..." : singleMedia?.description}</p>

                        <div className='flex justify-between items-center pb-5 absolute bottom-0'>
                            { language === 'en' ?
                              <a href={singleMedia?.externalLink} target='_blank' className='text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all'>Learn More<img src={GreaterThan} alt='Greater Than' /></a>
                              :
                              <a href={singleMedia?.externalLink} target='_blank' className='text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all banglaFont'>আরও জানুন<img src={GreaterThan} alt='Greater Than' /></a>
                            }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </section>

      {/* Become Volunteer */}
      <section className='mb-20'>
        <BecomeVolunteer/>
      </section>

      <ScrollToTop></ScrollToTop>
      
      <Footer/>
    </div>
  );
};

export default Media