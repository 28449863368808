import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Avatar from '../../assets/avatar.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
// import DeleteIcon from '../../assets/delete.png';
// import DeleteIcon from '../../assets/delete.svg';
import BaseUrl from '../BaseURL';
import { BsSearch } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdAdminPanelSettings } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import ScrollToTop from '../Common/ScrollToTop';
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";
// import { deleteUser } from 'firebase/auth';
import { GlobalContext } from "../GlobalState/GlobalState";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from '../GlobalState/UserContext';

function ManageUsers() {
  const { language } = useContext(GlobalContext);
  // const { signedInUser, personalInfo } = useUserContext();
  // Search
  const [searchTerm, setSearchTerm] = useState("");
  const [user] = useAuthState(auth);
  // const [activeLink, setActiveLink] = useState(1);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [filteredItems, setFilteredItems] = useState(personalInfo);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const results = personalInfo.filter(val =>
      (val.email || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.firstName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.lastName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.role || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.profileType || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    setFilteredItems(results);
    setCurrentPage(1);

  }, [searchTerm, personalInfo]);

  // const reversedUsers = personalInfo.slice().reverse();
  const reversedUsers = filteredItems.slice().reverse();
  const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  // Get infos
  useEffect(()=>{
    Axios.get(`${BaseUrl}/infos`).then((response) => {
      setPersonalInfo(response.data);
    });
  },[]);

  // Make Admin
  const handleMakeAdmin = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateRole`, { 
        role:"Admin", 
        id: id 
      }).then(
          (response) => {
            setPersonalInfo(
              personalInfo.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      role: val.role,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
            toast.success("User is admin now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // Remove Admin
  const handleRemoveAdmin = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateRole`, { 
        role:"Volunteer", 
        id: id 
      }).then(
          (response) => {
            setPersonalInfo(
              personalInfo.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      role: val.role,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
            toast.success("Removed from admin!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };

  // Make Manager
  const handleMakeManager = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateRole`, { 
        role:"Manager", 
        id: id 
      }).then(
          (response) => {
            setPersonalInfo(
              personalInfo.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      role: val.role,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
            toast.success("User is manager now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // Remove Manager
  const handleRemoveManager = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateRole`, { 
        role:"Volunteer", 
        id: id 
      }).then(
          (response) => {
            setPersonalInfo(
              personalInfo.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      role: val.role,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
            toast.success("Removed from manager!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!")
    }
  };

  // Make profile public
  const handlePublicProfile = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role === "Manager"){
      Axios.put(`${BaseUrl}/updateProfileType`, { 
        profileType: "Public",
        id: id 
      }).then(
        (response) => {
          setPersonalInfo(
            personalInfo.map((val) => {
              return val.id === id
                ? {
                    id: val.id,
                    profileType: val.profileType,
                  }
                : val;
            })
          );
          Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
          });
          toast.success("Profile Updated");
        }
      );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // Make profile private
  const handlePrivateProfile = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role === "Manager"){
      Axios.put(`${BaseUrl}/updateProfileType`, { 
        profileType: "Private",
        id: id 
      }).then(
        (response) => {
          setPersonalInfo(
            personalInfo.map((val) => {
              return val.id === id
                ? {
                    id: val.id,
                    profileType: val.profileType,
                  }
                : val;
            })
          );
          Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
          });
          toast.success("Profile Updated");
        }
      );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };

  // Make Volunteer
  const handleMakeVolunteer = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updateRole`, { 
        role:"Volunteer", 
        id: id 
      }).then(
          (response) => {
            setPersonalInfo(
              personalInfo.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      role: val.role,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/infos`).then((response) => {
              setPersonalInfo(response.data);
            });
            toast.success("Updated to volunteer!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };

  // Delete method
  const handleDeleteUser = (id) => {
    // const clickedUser = personalInfo?.find((user)=>user?.id === id);
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      const proceed = window.confirm("Are you sure?");
      if(proceed){
        Axios.delete(`${BaseUrl}/infos/${id}`).then((response) => {
          setPersonalInfo(
            personalInfo.filter((val) => {
              return val.id !== id;
            })
          );
          Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
          });
          toast.success("User deleted!");
        });
      };
    }else{
      toast.warn("You don't have sufficient privilege!");
    };
  };

  // Pagination
  const itemsPerPage = 10;
  const urlParams = new URLSearchParams(location.search);
  const pageFromURL = Number(urlParams.get('page')) || 1;
  // initialize currentPage with the value from URL
  const [currentPage, setCurrentPage] = useState(pageFromURL);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const page = urlParams.get('page');
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [location, currentPage]);

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    const visiblePagesLimit = 4; // Adjust this number based on your preference

    if (totalPages <= visiblePagesLimit) {
      return Array.from(Array(totalPages).keys()).map((i) => i + 1);
    }

    const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
    const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);

    const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
    return visiblePages;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(reversedUsers.length / itemsPerPage);
  const paginatedItems = reversedUsers.slice(startIndex, endIndex);
  // const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);
  const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

  // if (user) {
  //   // Access the user metadata to get the signed-in date
  //   // const signedInDate = user.metadata.creationTime;
  //   const creationTime = user.metadata.creationTime;
  //   const lastSignInTime = user.metadata.lastSignInTime;
  //   console.log('creationTime:', creationTime);
  //   console.log('lastSignInTime:', lastSignInTime);
  // } else {
  //   console.log('No user is currently signed in.');
  // }

  return (
    <div>
      <Helmet>
          <title>Manage Users - Telementoring</title>
          <meta name='title' content='Manage Users - Telementoring' />
          <meta name="description" content="Manage all users of Telementoring." />
          <meta name="keywords" content="telementoring, manage users" />
      </Helmet>

      <div className="container mx-auto">
        <div>
          <div className='md:flex items-center grid grid-cols-1 justify-between gap-5 px-5 py-4 rounded-2xl shadow-md'>
            { language === 'en' ?
              <h2 className="text-2xl font-semibold md:text-left text-right">Manage Users</h2>
              :
              <h2 className="text-2xl font-semibold md:text-left text-right banglaFont">ম্যানেজ ইউজার</h2>
            }
            <div className='flex items-center'>
              { language === 'en' ?
                <input type="text" placeholder='Search User' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none" onChange={(event)=>{
                  setSearchTerm(event.target.value);
                }}/>
                :
                <input type="text" placeholder='সার্চ করুন' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none banglaFont" onChange={(event)=>{
                  setSearchTerm(event.target.value);
                }}/>
              }
              <label className='font-semibold text-xl text-[#0082C4] border border-l-0 border-blue-500 border-opacity-20 bg-[#ECF4FF] rounded-r-xl py-3.5 px-4'><BsSearch/></label>
            </div>
          </div>
          { paginatedItems?.length < 1 ?
            <div>
              { language === 'en' ?
                <p className='text-center text-2xl font-semibold py-12'>No User found.</p>
                :
                <p className='text-center text-2xl font-semibold py-12 banglaFont'>কোনো ইউজার পাওয়া যায়নি ।</p>
              }
            </div>
            :
            <div className="pt-4 overflow-x-auto">
              <div className="inline-block min-w-full lg:mx-0 md:mx-5 mx-5 shadow-md rounded-lg overflow-hidden border-x">
                <table className="min-w-full leading-normal">
                  <thead className='bg-[#F6FAFF]'>
                    { language === 'en' ?
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Index
                        </th>
                        <th
                          className="lg:px-5 md:px-0 px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          User Name
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Role
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Profile Type
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >Actions</th>
                      </tr>
                      :
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          ইনডেক্স
                        </th>
                        <th
                          className="lg:px-5 md:px-0 px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          ইউজার নাম
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          ইমেইল
                        </th>
                        <th
                          className="lg:px-5 md:px-5 px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          ভূমিকা
                        </th>
                        <th
                          className="lg:px-5 md:px-5 px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >
                          প্রোফাইলের ধরণ
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider banglaFont"
                        >এ্যাকশন</th>
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {
                      paginatedItems.map((user, index)=>{
                        return(
                          <tr key={user?.id}>
                            <td className="px-5 py-5 text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{index+1}</p>
                            </td>
                            <td className="lg:px-5 md:px-0 px-5 py-5 text-sm">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 p-1 lg:w-10 lg:h-10 md:w-8 md:h-8 w-8 h-8 overflow-hidden border-2 border-blue-100 rounded-full flex justify-center items-center">
                                  {/* { user?.profileImageUrl ?
                                    <img className='w-full rounded-full' src={`${BaseUrl}${user?.profileImageUrl}`} alt="" referrerPolicy='no-referrer' />
                                    :
                                    <img className='w-full rounded-full' src={Avatar} alt="" referrerPolicy='no-referrer' />
                                  } */}
                                  { user?.profileImageUrl ?
                                    <div className='w-8 h-8 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${user?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                    :
                                    <div className='w-8 h-8 rounded-full' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                  }
                                </div>
                                <div className="ml-3">
                                    <Link to={`/volunteer-profile/${user?.id}`} className="text-gray-900 whitespace-no-wrap hover:text-[#0082C4] transition-all">
                                    {user?.firstName} {user?.lastName}
                                    </Link>
                                </div>
                              </div>
                            </td>
                            <td className="px-5 py-5 text-sm">
                              <p className="text-gray-900 whitespace-no-wrap">{user?.email}</p>
                            </td>
                            <td className="lg:px-5 md:px-0 px-5 text-sm">
                              <p className={`${user?.role === 'Admin' || user?.role === 'Manager' || user?.role === "Partner" ? 'text-[#0082C4]' : 'text-[#777B84]'} whitespace-no-wrap`}>{user?.role}</p>
                            </td>
                            <td className="lg:px-5 md:px-5 px-5 text-sm">
                              <p className="whitespace-no-wrap text-[#777B84]">{user?.profileType}</p>
                            </td>
                            {/* <td className="lg:px-5 md:px-0 px-5 text-sm">
                              { language === 'en' ?
                                <Link to={`/volunteer-profile/${user?.id}`} className='bg-[#E0F0F8] px-2 py-1 rounded-full text-[#0082C4] text-xs'>View Profile</Link>
                                :
                                <Link to={`/volunteer-profile/${user?.id}`} className='bg-[#E0F0F8] px-2 py-1 rounded-full text-[#0082C4] text-xs banglaFont'>প্রোফাইল দেখুন</Link>
                              }
                            </td> */}
                            <td className="px-5 py-5 text-sm flex items-center justify-center relative">
                              <div className='group flex flex-col gap-2'>
                                <div 
                                  className='text-lg hover:text-white transition-all bg-white hover:bg-[#0082C4] border border-[#F1F7FF] rounded-full shadow-md lg:p-3 md:p-2 p-2 flex justify-center items-center cursor-pointer'
                                >
                                  <IoSettingsOutline />
                                </div>
                                <div>
                                  {/* <div className="absolute pt-8 md:top-10 top-8 lg:-left-14 md:-left-24 -left-28 w-[170px] hidden group-hover:block transition-all z-50"> */}
                                  <div className="absolute pt-8 md:top-10 top-8 md:-left-24 -left-28 w-44  hidden group-hover:block transition-all z-50">
                                    { language === 'en' ?
                                      <div className='flex flex-col gap-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                        {user?.role === 'Admin' ?
                                          <button className={`w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 ${user?.id === signedInUser?.id ? 'hidden' : 'block'}`} onClick={() => handleRemoveAdmin(user?.id)}>
                                            <MdAdminPanelSettings />Remove Admin
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleMakeAdmin(user?.id)}>
                                            <MdAdminPanelSettings />Make Admin
                                          </button>
                                        }
                                        {user?.role==="Manager" ?
                                          <button className={`w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 ${user?.id === signedInUser?.id ? 'hidden' : 'block'}`} onClick={() => handleRemoveManager(user?.id)}>
                                            <MdAdminPanelSettings />Remove Manager
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleMakeManager(user?.id)}>
                                            <MdAdminPanelSettings />Make Manager
                                          </button>
                                        }
                                        {user?.role==="User" ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleMakeVolunteer(user?.id)}>
                                            <MdAdminPanelSettings />Make Volunteer
                                          </button>
                                          :
                                          ''
                                        }
                                        {user?.profileType === 'Private' ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handlePublicProfile(user?.id)}>
                                            <MdAdminPanelSettings />Make Public
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handlePrivateProfile(user?.id)}>
                                            <MdAdminPanelSettings />Make Private
                                          </button>
                                        }
                                        <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeleteUser(user?.id)}>
                                          <RiDeleteBin6Line />Delete
                                        </button>
                                      </div>
                                      :
                                      <div className='flex flex-col gap-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                        {user?.role === 'Admin' ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleRemoveAdmin(user?.id)}>
                                            <MdAdminPanelSettings />অ্যাডমিন বাতিল করুন
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleMakeAdmin(user?.id)}>
                                          <MdAdminPanelSettings />অ্যাডমিন করুন
                                          </button>
                                        }
                                        {/* {user?.role==="Manager" ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleRemoveManager(user?.id)}>
                                            <MdAdminPanelSettings />ম্যানেজার বাতিল করুন
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleMakeManager(user?.id)}>
                                          <MdAdminPanelSettings />ম্যানেজার করুন
                                          </button>
                                        } */}
                                        {user?.profileType === 'Private' ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handlePublicProfile(user?.id)}>
                                            <MdAdminPanelSettings />পাবলিক করুন
                                          </button>
                                          :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handlePrivateProfile(user?.id)}>
                                            <MdAdminPanelSettings />প্রাইভেট করুন
                                          </button>
                                        }
                                        <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeleteUser(user?.id)}>
                                          <RiDeleteBin6Line />বাতিল করুন
                                        </button>
                                          {/* {user?.role==="Admin" ?
                                            <button onClick={() => handleRemoveAdmin(user?.id)} className='text-xs px-4 py-2 hover:bg-red-50 hover:text-red-500 flex items-center gap-1'><MdAdminPanelSettings />Remove Admin</button>
                                            :
                                            <button onClick={() => handleMakeAdmin(user?.id)} className='text-xs px-4 py-2 hover:bg-blue-50 text-blue-500 flex items-center gap-1'><MdAdminPanelSettings />Make Admin</button>
                                          }
                                          <button 
                                            onClick={() => handleDeleteUser(user?.id)}
                                            className='px-4 py-2 text-left hover:bg-red-50 text-red-400 flex items-center gap-1'
                                          ><RiDeleteBin6Line />Delete user</button> */}
                                      </div>
                                    }
                                  </div>
                                </div>
                                {/* <div className='absolute lg:-left-14 md:-left-24 -left-28 top-[5%] pr-4'>
                                  <div className='bg-white px-3 py-3 rounded-xl shadow-md hidden group-hover:block transition-all z-10'>
                                    <div className='flex flex-col gap-1'>
                                      {user?.role==="Admin" ?
                                        <button onClick={() => handleRemoveAdmin(user?.id)} className='text-xs px-1 py-1 hover:bg-red-50 hover:text-red-500 flex items-center gap-1'><MdAdminPanelSettings />Remove Admin</button>
                                        :
                                        <button onClick={() => handleMakeAdmin(user?.id)} className='text-xs px-1 py-1 hover:bg-blue-50 text-blue-500 flex items-center gap-1'><MdAdminPanelSettings />Make Admin</button>
                                      }
                                      <button 
                                        onClick={() => handleDeleteUser(user?.id)}
                                        className='px-1 text-left hover:bg-red-50 text-red-400 flex items-center gap-1'
                                      ><RiDeleteBin6Line />Delete user</button>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                {/* Pagination buttons */}
                {/* <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none gap-3">
                            <li>
                              <button
                                className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                              >
                                <CgChevronDoubleLeft />
                              </button>
                            </li>
                            {pageNumbers.map((page) => (
                              <li key={page}>
                                <button
                                className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                                    currentPage === page ? "font-bold bg-[#0082C4] text-white" : ""
                                }  focus:outline-none`}
                                onClick={() => handlePageChange(page)}
                                >
                                {page}
                                </button>
                              </li>
                            ))}
                            <li>
                                <button
                                    className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                  <CgChevronDoubleRight />
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div> */}
                <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                  <nav className="block">
                    <ul className="flex pl-0 rounded list-none gap-3">
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === 1}
                          onClick={() => handlePageChange(Number(currentPage) - 1)}
                        >
                          <CgChevronDoubleLeft />
                        </button>
                      </li>
                      {visiblePageNumbers.map((page) => (
                        <li key={page}>
                          <button
                            className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                              Number(currentPage) === page ? "font-bold bg-[#0082C4] text-white" : ""
                            }  focus:outline-none`}
                            onClick={() => handlePageChange(page)}
                          >
                          {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === totalPages}
                          onClick={() => handlePageChange(Number(currentPage) + 1)}
                        >
                          <CgChevronDoubleRight />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default ManageUsers