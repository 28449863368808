import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { useForm } from 'react-hook-form';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function PostAnnouncement() {
  const { language } = useContext(GlobalContext);
  const { signedInUser, personalInfo, loadingAuth } = useUserContext();

  const { register, reset, handleSubmit } = useForm();
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo?.find((person) => person?.userEmail === user?.email);
  const recipientsEmail = personalInfo?.map((e) => e?.email);
  const recipientsFirstName = personalInfo?.map((e) => e?.firstName);

  const [smtp, setSmtp] = useState([]);
  // Get smtp
  useEffect(()=>{
    Axios.get(`${BaseUrl}/smtp`).then((response) => {
      setSmtp(response.data);
    });
  },[]);

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[]);

  const currentDate = new Date().toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  // Submit form
  const onSubmit = data => {
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      Axios.post(`${BaseUrl}/send-announcement`, {
          subject: data.subject,
          message: data.message,
          recipientsEmail: recipientsEmail,
          recipientsFirstName: recipientsFirstName,
          announceDate: currentDate,
          authorFirstName: signedInUser?.firstName,
          authorFullName: signedInUser?.firstName + ' ' + signedInUser?.lastName,
          
          host: smtp?.smtpHost,
          port: smtp?.smtpPort,
          user: smtp?.smtpEmail,
          pass: smtp?.smtpPass,
      });
      reset();
      toast.success("Successfully sent the announcement!");
    }else{
      toast.error("You don't have sufficient privilege!");
    }
  };

  // clear form
  const handleCancelClick = (e) => {
    e.preventDefault();
    reset();
  };

  return (
    <div>
      <Helmet>
        <title>Post Announcement - Telementoring</title>
        <meta name='title' content='Post Announcement - Telementoring' />
        <meta name="description" content="Post Announcement at Telementoring." />
        <meta name="keywords" content="telementoring, post announcement" />
      </Helmet>

      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          { language === 'en' ?
            <p className='mb-5 font-semibold md:text-2xl text-lg'>Create a New Announcement</p>
            :
            <p className='mb-5 font-semibold md:text-2xl text-xl banglaFont'>নতুন অ্যানাউন্সমেন্ট তৈরি করুন </p>
          }
          { language === 'en' ?
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center gap-4'>
              <div>
                { language === 'en' ?
                  <label className="block font-semibold text-gray-700 mb-2">Subject</label>
                  :
                  <label className="block font-semibold text-gray-700 mb-2 banglaFont">বিষয়</label>
                }
                <input type="text" {...register("subject")} required placeholder='Write here' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' />
              </div>
              <textarea {...register("message")} placeholder='Message...' required className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069]' rows="6" />
              <div className='flex justify-between'>
                <div></div>
                <div className='flex md:gap-5 gap-2'>
                  { language === 'en' ?
                    <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold md:text-base text-sm md:px-5 px-2 lg:py-3 md:py-2 py-1 rounded-lg'>Cancel</button>
                    :
                    <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold md:text-base text-sm md:px-5 px-2 lg:py-3 md:py-2 py-1 rounded-lg banglaFont'>বাতিল করুন</button>
                  }

                  { language === 'en' ?
                    <input type="submit" value='Post Announcement' className='cursor-pointer md:text-base text-sm md:px-5 px-3 lg:py-3 md:py-2 py-1 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    :
                    <input type="submit" value='ঘোষণা করুন' className='cursor-pointer md:text-base text-sm md:px-5 px-3 lg:py-3 md:py-2 py-1 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                  }
                </div>
              </div>
            </form>
            :
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center gap-4'>
              <div>
                <label className="block font-semibold text-gray-700 mb-2 banglaFont">বিষয়</label>
                <input type="text" {...register("subject")} required placeholder='এখানে লিখুন' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' />
              </div>
              <textarea {...register("message")} placeholder='ম্যাসেজ...' required className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069] banglaFont' rows="6" />
              <div className='flex justify-between'>
                <div></div>
                <div className='flex gap-5'>
                  <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>বাতিল করুন</button>

                  <input type="submit" value='ঘোষণা করুন' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                </div>
              </div>
            </form>
          }
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
  </div>
  )
}

export default PostAnnouncement