import React, { useContext, useEffect, useState } from 'react';
import FooterBg from '../../assets/footer-bg.png';
// import WhiteLogo from '../../assets/white-logo.png';
import Logo from '../../assets/logo.png';
import WhiteLogo from '../../assets/logo-white.png';
import MonashLogo from '../../assets/monash-university.png';
// import LocationIconYellow from '../../assets/location-icon-yellow.png';
import CallIconYellow from '../../assets/call-icon-yellow.png';
import EmailIconYellow from '../../assets/emial-icon-yellow.png';
// import Logos from '../../assets/TELEMENTORING.png';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import { BsWhatsapp } from 'react-icons/bs';
import { FiInstagram } from 'react-icons/fi';
import { RiFacebookFill } from 'react-icons/ri';
import { GrLinkedin } from 'react-icons/gr';
import { GlobalContext } from '../GlobalState/GlobalState';
import crypto from 'crypto-js';

function Footer2() {
  const { language } = useContext(GlobalContext);

  const { register, handleSubmit, reset } = useForm();
  const [subscribers, setSubscribers] = useState([]);

  // Get subscribers
  useEffect(()=>{
    Axios.get(`${BaseUrl}/subscribers`).then((response) => {
      setSubscribers(response.data);
    });
  },[]);
  // Submit form
  const onSubmit = data => {
    const foundEmail = subscribers?.find((email) => email?.email === data?.email);
    const subscription_status = 'Subscribed';
    const hashed_email = crypto.SHA256(data?.email).toString(crypto.enc.Hex);

    if(foundEmail){
      toast.warn("Already subscribed with this email!");
    }else{
      Axios.post(`${BaseUrl}/addSubscriber`, {
        email: data.email,
        hashed_email: hashed_email,
        subscription_status: subscription_status,
      });
      reset();
      toast.success("Thanks for subscribe!");
    };
  };

  return (
    <div>
      <div className='relative' style={{"backgroundImage":`url(${FooterBg})`}}>
        <div className='max-w-7xl mx-auto'>
          <div className='bg-[#09446F] rounded-2xl lg:px-12 md:px-12 px-5 lg:py-10 md:py-10 py-5 mx-3 absolute lg:-top-24 md:-top-24 -top-20 z-30'>
            <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 items-center justify-between bg-[#09446F]'>
              <div>
                { language === 'en' ?
                  <p className='lg:text-lg md:text-lg text-sm font-semibold py-1 lg:px-5 md:px-5 px-2 rounded-full lg:w-36 md:w-36 w-28 text-center text-[#E3E6EA] bg-[#07507F]'>Newsletter</p>
                  :
                  <p className='lg:text-lg md:text-lg text-sm font-semibold py-1 lg:px-5 md:px-5 px-2 rounded-full lg:w-36 md:w-36 w-28 text-center text-[#E3E6EA] bg-[#07507F] banglaFont'>নিউজলেটার</p>
                }
                { language === 'en' ?
                  <p className='text-[#F6FAFF] lg:text-3xl md:text-3xl text-2xl font-bold lg:w-5/6 md:w-full w-5/6'>To get news, <span className='text-[#FDBE44]'>Subscribe</span> to our newsletter.</p>
                  :
                  <p className='text-[#F6FAFF] lg:text-3xl md:text-3xl text-2xl font-bold lg:w-5/6 md:w-full w-5/6 banglaFont'>খবর পেতে, আমাদের নিউজলেটার <span className='text-[#FDBE44]'>সাবস্ক্রাইব</span> করুন ।</p>
                }
                
                
              </div>
              <div>
                { language === 'en' ?
                  <form onSubmit={handleSubmit(onSubmit)} className='flex gap-2 items-center'>
                    <input type='email' {...register("email")} required placeholder='Email address' className='bg-white lg:text-lg md:text-lg text-sm px-5 lg:py-4 md:py-4 py-2 rounded-xl lg:w-96 md:w-56 w-48 border-none focus:outline-none' />
                    <input type='submit' value='Subscribe' className='lg:px-10 md:px-6 px-3 lg:py-4 md:py-4 py-2 bg-[#FDBE44] hover:bg-[#fbcf76] transition-all text-[#09446E] font-semibold lg:text-xl md:text-xl text-base cursor-pointer rounded-xl' />
                  </form>
                  :
                  <form onSubmit={handleSubmit(onSubmit)} className='flex gap-2 items-center'>
                    <input type='email' {...register("email")} required placeholder='ইমেইল এ্যাড্রেস' className='bg-white lg:text-lg md:text-lg text-sm px-5 lg:py-4 md:py-4 py-2 rounded-xl lg:w-96 md:w-56 w-48 border-none focus:outline-none banglaFont' />
                    <input type='submit' value='সাবস্ক্রাইব' className='lg:px-10 md:px-6 px-3 lg:py-4 md:py-4 py-2 bg-[#FDBE44] hover:bg-[#fbcf76] transition-all text-[#09446E] font-semibold lg:text-xl md:text-xl text-base cursor-pointer rounded-xl banglaFont' />
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 pt-40'>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-20 gap-5'>
            {/* <div className='grid grid-cols-1 gap-8'>
              <Link to='/'><img src={Logo} alt='White Logo' className='lg:w-44 md:w-44 w-36' /></Link>
              <p className='text-base text-[#E3E6EA]'>Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many variations of passages</p>
              <div className='grid grid-cols-1 gap-2'>
                <p className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm font-semibold rounded-md w-64'><img src={CallIconYellow} alt='' />+880125 455785 55</p>
                <p className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm font-semibold rounded-md w-64'><img src={EmailIconYellow} alt='' />info@telementoring.net</p>
              </div>
            </div> */}
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Contact Us</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>যোগাযোগ</p>
              }
              
              <div className='grid grid-cols-1 gap-2 py-5'>
                <a href="tel:+8801971104077" className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm rounded-md w-64'><img src={CallIconYellow} alt='Phone icon' />+880 1971 104077</a>
                <a href='mailto:info@telementoring.net' className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm rounded-md w-64'><img src={EmailIconYellow} alt='Email icon' />info@telementoring.net</a>
              </div>
            </div>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Quick Links</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>কুইক লিঙ্ক</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
                { language === 'en' ?
                  <Link to='/about-us' className='md:text-base text-sm text-[#E3E6EA]'>About Us</Link>
                  :
                  <Link to='/about-us' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>আমাদের কথা</Link>
                }
                { language === 'en' ?
                  <Link to='/meet-the-team' className='md:text-base text-sm text-[#E3E6EA]'>Meet the Team</Link>
                  :
                  <Link to='/meet-the-team' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>টেলিমেন্টরিং টিম</Link>
                }
                { language === 'en' ?
                  <Link to='/collaborators' className='md:text-base text-sm text-[#E3E6EA]'>Collaborators</Link>
                  :
                  <Link to='/collaborators' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>সহযোগীবৃন্দ</Link>
                }
                { language === 'en' ?
                  <Link to='/volunteers?page=1' className='md:text-base text-sm text-[#E3E6EA]'>Volunteers</Link>
                  :
                  <Link to='/volunteers?page=1' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>স্বেচ্ছাসেবকবৃন্দ</Link>
                }
                { language === 'en' ?
                  <Link to='/publications' className='md:text-base text-sm text-[#E3E6EA]'>Publications</Link>
                  :
                  <Link to='/publications' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>গবেষণা ও প্রতিবেদন</Link>
                }
                
              </div>
            </div>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Get Involved</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>যুক্ত হোন</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
              { language === 'en' ?
                  <Link to='/get-involved?join=volunteer#volunteer' className='md:text-base text-sm text-[#E3E6EA]'>Become a Mentor</Link>
                  :
                  <Link to='/get-involved?join=volunteer#volunteer' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>মেন্টর হোন</Link>
                }
                { language === 'en' ?
                  <Link to='/get-involved?join=partner#partner' className='md:text-base text-sm text-[#E3E6EA]'>Become a Partner</Link>
                  :
                  <Link to='/get-involved?join=partner#partner' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>অংশীদার হোন</Link>
                }
              </div>
            </div>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Support</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>সহায়তা</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
                { language === 'en' ?
                  <Link to='/faq' className='md:text-base text-sm text-[#E3E6EA]'>Help & FAQ</Link>
                  :
                  <Link to='/faq' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>সাহায্য এবং জিজ্ঞাসা</Link>
                }
                { language === 'en' ?
                  <Link to='/contact-us' className='md:text-base text-sm text-[#E3E6EA]'>Contact us</Link>
                  :
                  <Link to='/contact-us' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>যোগাযোগ</Link>
                }
              </div>
            </div>
            {/* <div>
              <p className='text-xl text-white font-bold pb-2'>A collaboration between:</p>
                <img src={Logos} className='mt-5 w-full'/>
            </div> */}
          </div>
          <div className='lg:flex md:flex grid grid-cols-1 justify-between items-center py-6 FooterListBorder2'>
            <div className='w-full flex items-center gap-4 md:mt-0 mt-5 '>
              <a href='https://www.monash.edu/' target='_blank'><img src={MonashLogo} className='w-44' /></a>
              <div className='w-[2px] h-10 bg-[#0082C4]'></div>
              { language === 'en' ?
                <Link to='/'><img src={Logo} className='w-44' /></Link>
                :
                <Link to='/'><img src={WhiteLogo} className='w-40' /></Link>
              }
            </div>
            <div className='flex items-center justify-center gap-4 md:mt-0 mt-4'>
              <a href='https://facebook.com/telementoring.net' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Facebook' ><RiFacebookFill /></a>
              <a href='https://www.linkedin.com/company/telementoring' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='LinkedIn' ><GrLinkedin /></a>
              <a href='https://wa.me/+880 1971 104077' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Whatsapp' ><BsWhatsapp /></a>
              <a href='https://instagram.com/telementoring' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Instagram' ><FiInstagram /></a>
            </div>
          </div>
          <div className='py-5'>
            { language === 'en' ?
              <p className='text-[#E3E6EA] md:text-sm text-xs'>Copyright © 2022 <Link to='/' className='text-[#F74F22] font-semibold'>TELEMENTORING</Link>. Developed by <a href='https://optimus.com.bd/' target='_blank' className='text-[#F74F22] font-semibold'>Optimus Technologies</a>.</p>
              :
              <p className='text-[#E3E6EA] md:text-sm text-xs banglaFont'>কপিরাইট © ২০২২ <Link to='/' className='text-[#F74F22] font-semibold'>টেলিমেন্টরিং</Link> । ডেভেলপ করেছেন <a href='https://optimus.com.bd/' target='_blank' className='text-[#F74F22] font-semibold'>অপটিমাস টেকনোলোজিস</a>.</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer2