import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Footer from '../Common/Footer';
// import Navbar from '../Common/Navbar';
// import { GrNext } from 'react-icons/gr';
import AboutHeroBg from '../../assets/AboutUs/AboutUsHeroBg.png';
import NextIcon from '../../assets/AboutUs/next-icon.png';
// import FounderImg from '../../assets/AboutUs/founder.png';
// import AboutContentImg from '../../assets/AboutUs/about-content-img.png';
import AboutTeleImg1 from '../../assets/AboutUs/about-tele-img1.png';
// import AboutTeleImg2 from '../../assets/AboutUs/about-tele-img2.png';
import AboutTeleImg3 from '../../assets/AboutUs/about-tele-img3.png';
// import AboutTeleImg4 from '../../assets/AboutUs/about-tele-img4.png';
import AboutTeleImg4 from '../../assets/AboutUs/about-tele-img5.jpg';
import Review from '../Review/Review';
import Countdown from '../Countdown/Countdown';
// import AboutSlider from './AboutSlider';
// import OurTeam from '../OurTeam/OurTeam';
import './AboutUs.css';
import ScrollToTop from '../Common/ScrollToTop';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function AboutUs() {
    const { language } = useContext(GlobalContext);

    //  Scroll to top, when user will be redirected in this page
    const location = useLocation();
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

  return (
    <div>
      <Helmet>
        <title>About Us - Telementoring</title>
        <meta name='title' content='About Us - Telementoring' />
        <meta name="description" content="Telementoring is an initiative by a group of researchers at Monash University, Melbourne, Australia, and Jagannath University, Dhaka, Bangladesh." />
      </Helmet>
      {/* Hero */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${AboutHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              { language === 'en' ?
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>About <span className='text-[#FDBE44]'>Us</span></p>
                :
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'>আমাদের <span className='text-[#FDBE44]'>সম্পর্কে</span></p>
              }
              <div className='flex items-center gap-2 mt-4'>
                { language === 'en' ?
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                  :
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                }
                <span><img src={NextIcon} alt='Next Icon' /></span>
                { language === 'en' ?
                  <Link to='/about-us' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>About Us</Link>
                  :
                  <Link to='/about-us' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>আমাদের সম্পর্কে</Link>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>
      
      {/* Content */}
      <section className='max-w-7xl lg:mx-auto md:mx-4 mx-5 lg:px-8 md:px-4 px-0 py-12'>
        <div className='flex flex-col lg:gap-16 md:gap-16 gap-8'>
          <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-8'>
            <div>
              <img src={AboutTeleImg1} alt='About Telementoring' />
            </div>
            <div className='flex flex-col gap-5 mb-10'>
              <div className='flex'>
                { language === 'en' ?
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE]'>About Telementoring</p>
                  :
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>টেলিমেন্টরিং সম্পর্কে</p>
                }
              </div>
              { language === 'en' ?
                <p className='text-[#2E3033] md:text-4xl text-2xl font-bold'>What is Telementoring?</p>
                :
                <p className='text-[#2E3033] md:text-4xl text-2xl font-bold banglaFont'>টেলিমেন্টরিং কি?</p>
              }
              <div className='text-[#777B84] text-base flex flex-col gap-5 text-justify'>
                { language === 'en' ?
                  <p className='text-[#2E3033] mb-5 font-semibold'>Telementoring is an initiative by a group of researchers at Monash University, Melbourne, Australia, and Jagannath University, Dhaka, Bangladesh.</p>
                  :
                  <p className='text-[#2E3033] mb-5 font-semibold banglaFont'>টেলিমেন্টরিং প্রকল্পটি মোনাশ ইউনিভার্সিটি, মেলবোর্ন, অস্ট্রেলিয়া এবং জগন্নাথ ইউনিভার্সিটি, ঢাকা, বাংলাদেশের একদল গবেষকের একটি অলাভজক শিক্ষা সহায়ক কার্যক্রম।</p>
                }
                { language === 'en' ?
                  <p>This program was first introduced in Bangladesh during the early days of the Covid-19 pandemic when millions of children were missing out on formal education after all educational institutions in Bangladesh were forced to close on March 18, 2020. The school closures disproportionately disadvantaged rural children who had limited access to the internet, television and radio for distance learning, and rural parents who did not have the resources and skills to provide educational support for their children at home. <span className='text-[#2E3033]'>As the vast majority of households in Bangladesh have access to mobile phones,</span> telementoring was developed as a solution to this learning crisis by facilitating volunteers in urban areas to deliver free educational mentoring services to rural households via mobile phones.</p>
                  :
                  <p className='banglaFont'>২০২০ সালের ১৮ মার্চ মহামারী করোনাভাইরাস ছড়িয়ে পড়লে বাংলাদেশের সকল স্কুল-কলেজ বন্ধ ঘোষণা করা হয়।  এর ফলে লক্ষ লক্ষ শিশু বিদ্যায়তনিক পড়াশোনা থেকে বঞ্চিত হচ্ছিলো। যাদের বাড়িতে টেলিভিশন বা ইন্টারনেটেরমতো সুবিধা গুলো ছিলো তারা কিছু মাত্রায় ডিস্টেন্স লার্নিং চালিয়ে যেতে পারলেও, প্রান্তিক পর্যায়ে যেসব শিশুর অভিভাবকেরা টেলিভিশন-ইন্টারনেটের ব্যবস্থা করতে পারেনি, তারা সম্পূর্ণরূপে পড়াশোনা থেকে বিচ্ছিন্ন হয়ে পড়েছিলো। অন্যদিকে ইন্টারনেটের সংযোগ না থাকলেও, বাংলাদেশের অধিকাংশ পরিবারেই নিদেনপক্ষে একটি বেসিক মুঠোফোন রয়েছে। টেলিমেন্টরিং বেসিক মুঠোফোনের এই স্বল্পমূল্যের প্রযুক্তিকে কাজে লাগিয়ে প্রান্তিক শিশুদের মেন্টরিংসেবা প্রদান করে।</p>
                }
              </div>
            </div>
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-8 SecondCardShadow lg:px-12 md:px-12 px-5 lg:py-12 md:py-12 py-6'>
            <div className='flex flex-col gap-5 lg:order-1 md:order-1 order-2'>
              <div className='flex'>
                { language === 'en' ?
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE]'>Our Progress</p>
                  :
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>টেলিমেন্টরিং’র অগ্রগতি</p>
                }
              </div>
              {/* <p className='text-[#2E3033] md:text-4xl text-2xl font-bold'>What is Telementoring?</p> */}
              <div className='text-[#777B84] text-base flex flex-col gap-5 text-justify'>
                { language === 'en' ?
                  <p className='text-[#2E3033] mb-5 font-semibold'>When telementoring was first introduced, it was designed as a randomized controlled trial to ensure the program could be rigorously evaluated for its impacts.</p>
                  :
                  <p className='text-[#2E3033] mb-5 font-semibold banglaFont'>টেলিমেন্টরিং’এর পাইলট প্রজেক্টটি এমনভাবে ডিজাইন করা হয়েছিলো যাতে করে এর প্রভাবগুলো কঠোরভাবে মূল্যায়ন করা যায়।</p>
                }
                { language === 'en' ?
                  <p>Subsequent impact evaluations indicate that telementoring significantly benefited participating children. For example, when the program ended, children receiving telementoring scored 35% higher on a standardized test than children in the control group. This positive impact on children&apos;s academic performance persisted even months after the program ended and schools reopened. Importantly, children who were academically weaker before the pandemic benefitted the most from this program.</p>
                  :
                  <p className='banglaFont'>আমাদের পাইলট প্রজেক্টে অংশগ্রহণকারী শিক্ষার্থীসহ সম্পূর্ণ প্রজেক্টটি মূল্যায়ন করার পর দেখা গেছে, টেলিমেন্টরিং শিক্ষার্থীদের উল্লেখযোগ্যভাবে উপকৃত করে। দেখা গেছে, টেলিমেন্টরিং সেবাপ্রাপ্ত শিক্ষার্থীরা একটি সাধারণ (স্ট্যান্ডার্ড) পরীক্ষায় সাধারণ শিক্ষার্থীদের তুলনায় ৩৫ শতাংশ ভালো ফলাফল অর্জন করেছে। মহামারী পরবর্তীকালেও এই ফলাফল বজায় ছিলো। আরো দেখা গেছে, মহামারী পূর্বে অপেক্ষাকৃত দূর্বল শিক্ষার্থীরা সবচেয়ে বেশী উপকৃত হয়েছে।</p>
                }
                {/* <p>Globally, mobile phone penetration has increased significantly over the past two decades. Despite this rapid growth in mobile phone usage, access to the internet is still lagging, especially in low income and lower middle-income countries where sustainable development work is also needed. Given the significant positive impacts and low cost of telementoring, the researchers are working with NGOs and international organizations to bring telementoring in the forms of educational services, health services and financial services to more people in Bangladesh and beyond. In addition, <span className='text-[#2E3033]'>the research team is also trialing other low-cost solutions to sustainable development issues with the help of mobile phone technologies,</span> such as using Interactive Voice Response (IVR) system to deliver educational services to rural households and using audio messages to deliver health information.</p> */}
              </div>
            </div>
            <div className='lg:order-2 md:order-2 order-1 lg:justify-end flex'>
              <img src={AboutTeleImg4} alt='About Telementoring' className='rounded-xl' />
            </div>
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-8'>
            <div>
              <img src={AboutTeleImg3} alt='About Telementoring' />
            </div>
            <div className='flex flex-col gap-5 mb-10'>
              { language === 'en' ?
                <p className='text-[#2E3033] md:text-4xl text-2xl font-bold'>How does it work?</p>
                :
                <p className='text-[#2E3033] md:text-4xl text-2xl font-bold banglaFont'>টেলিমেন্টরিং যেভাবে কাজ করে</p>
              }
              <div className='text-[#777B84] text-base flex flex-col gap-5 text-justify'>
                { language === 'en' ?
                  <p className='text-[#2E3033] mb-5 font-semibold'>Globally, mobile phone penetration has increased significantly over the past two decades. Despite this rapid growth in mobile phone usage, access to the internet is still lagging, especially in low-income and lower-middle-income countries where sustainable development work is also needed.</p>
                  :
                  <p className='text-[#2E3033] mb-5 font-semibold banglaFont'>গত দুই দশকে মোবাইল ফোনের ব্যবহার উল্লেখযোগ্যভাবে বৃদ্ধি পেয়েছে। মোবাইল ফোন ব্যবহারের এই দ্রুত বৃদ্ধি সত্ত্বেও, ইন্টারনেট অ্যাক্সেসের দিক দিয়ে বাংলাদেসহ নিম্ন-আয়ের এবং নিম্ন-মধ্যম আয়ের দেশগুলো বেশ পিছিয়ে আছে।</p>
                }

                { language === 'en' ?
                  <p>Given the significant positive impacts and low cost of telementoring, the researchers are working with NGOs and international organizations to bring telementoring in the forms of educational services, health services and financial services to more people in Bangladesh and beyond. In addition, the research team is also trialling other low-cost solutions to sustainable development issues with the help of mobile phone technologies, such as using an Interactive Voice Response (IVR) system to deliver educational services to rural households and audio messages to deliver health information.</p>
                  :
                  <p className='banglaFont'>এই প্রেক্ষাপটে টেলিমেন্টরিংয়ের ইতিবাচক ভূমিকা ও স্বল্প খরচের প্রযুক্তি ব্যবহার করে মানসম্মত শিক্ষাসেবা দেয়ার কারণে  বিভিন্ন আন্তর্জাতিক সংস্থা ও গবেষকেরা এই কার্যক্রমের সাথে যুক্ত হয়েছে। শিক্ষার পাশাপাশি স্বাস্থ্য, কৃষি ও আর্থিকসেবার ক্ষেত্রে টেলিমেন্টরিং প্রজেক্টের সাফল্যকে কাজে লাগাতে চান তারা। ইন্টারেক্টিভ ভয়েস রেসপন্স (আইভিআর) প্রযুক্তি ব্যবহার করে এসডিজির অন্যান্য লক্ষ্যমাত্রাগুলো অর্জন করতে এই গবেষকেরা কাজ করে যাচ্ছেন।</p>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <Countdown />
      
      <section>
        <Review />
      </section>

      <section className='mb-28'>
        <BecomeVolunteer />
      </section>
      
      <ScrollToTop></ScrollToTop>

      <Footer/>
    </div>
  );
};

export default AboutUs