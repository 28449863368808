import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// import Navbar from '../Common/Navbar';
import Slider from './Slider';
import { FaChevronDown } from 'react-icons/fa';
// import { AiOutlineCalendar } from 'react-icons/ai';
import Ellipse from '../../assets/Home/Ellipse.png';
import PhoneWithBoy from '../../assets/Home/PhoneWithBoy.png';
import Review from '../Review/Review';
import './Home.css';
import Countdown from '../Countdown/Countdown';
import Faq from '../Faq/Faq';
import ScrollToTop from '../Common/ScrollToTop';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import Footer2 from '../Common/Footer2';
import LatestStories from '../LatestStories/LatestStories';
// import SliderTest from '../Test/SliderTest';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../GlobalState/GlobalState';

function Home() {
    const { language } = useContext(GlobalContext);
    return (
        <div>
            <Helmet>
                <title>Telementoring</title>
                <meta name='title' content='Telementoring' />
                <meta name="description" content="Telementoring is an innovative program initiated by researchers at Monash University and Jagannath University to provide free educational mentoring services to rural households in Bangladesh through mobile phones. The program was developed in response to the Covid-19 pandemic, which disproportionately affected rural children's access to formal education. Impact evaluations have shown that children receiving telementoring scored significantly higher on standardized tests than children in the control group. The program's success has led to its expansion to provide educational, health, and financial services to more people in Bangladesh and other low-income and lower-middle-income countries." />
            </Helmet>

            <section>
                <Slider></Slider>
            </section>
            
            <section style={{"backgroundImage": `url(${Ellipse})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
                <div className='max-w-7xl lg:mx-auto md:mx-5 lg:px-8 mx-5'>
                    <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 items-center lg:gap-12 md:gap-8 gap-8 py-12'>
                        <div>
                            <img src={PhoneWithBoy} alt='safetyImage' />
                        </div>
                        <div className='lg:py-24 md:py-12 py-0 flex flex-col lg:gap-6 md:gap-6 gap-4'>
                            <div className='flex'>
                                { language === 'en' ?
                                    <p className='lg:text-lg md:text-lg text-base font-semibold py-2 px-5 rounded-full text-center text-[#F74F22]' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>Telementoring</p>
                                    :
                                    <p className='lg:text-lg md:text-lg text-base font-semibold py-2 px-5 rounded-full text-center text-[#F74F22] banglaFont' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>টেলিমেন্টরিং</p>
                                }
                            </div>
                            { language === 'en' ?
                                <p className='md:text-4xl text-3xl text-[#2E3033] font-bold lg:w-3/4 w-full'>Mentoring via basic feature phone</p>
                                :
                                <p className='md:text-4xl text-3xl text-[#2E3033] font-bold lg:w-3/4 w-full banglaFont'>বেসিক ফিচারফোনের মাধ্যমে মেন্টরিং</p>
                            }

                            { language === 'en' ?
                                <p className='md:text-lg text-base text-[#777B84] text-justify fontStyle'>Telementoring provides free educational, health and financial services to individuals and households in hard-to-reach areas via mobile phone technologies. Join us to make an impact on people&apos;s lives with the help of mobile phones. If you&apos;re interested in volunteering as a mentor, you can sign up as a telementor and nominate your preferred areas of volunteer mentoring. We will match you with an organization in need of volunteer mentors to provide services to individuals and households via mobile phones. If you&apos;re from an NGO that needs volunteers to help provide mentoring support to people in need or you&apos;re looking for opportunities to fund projects that bring positive impacts to people&apos;s lives, please get in touch with us to explore the options.</p>
                                :
                                <p className='md:text-lg text-base text-[#777B84] text-justify fontStyle banglaFont'>বেসিক ফিচারফোন ও স্বল্পমূল্যের প্রযুক্তির  ব্যবহার করে টেলিমেন্টরিং প্রোগ্রাম দূর্গম ও দূর্বল নেটওয়ার্ক অবকাঠামোয়  থাকা এলাকার জনগণের কাছে বিনামূল্যে শিক্ষা, স্বাস্থ্য এবং আর্থিক পরিষেবা প্রদান করে।  
                                টেলিমেন্টরিং প্রোগ্রাম গ্রামীণ ও প্রান্তিক মানুষের জীবনযাত্রায় একটি ইতিবাচক প্রভাব ফেলতে পারে। আমাদের এই উদ্যোগে যুক্ত হতে পারেন আপনিও।  খুব সহজেই আপনি টেলিমেন্টরিং ওয়েবসাইটে সাইন আপ করে আপনার পছন্দের বিষয়ে একজন স্বেচ্ছাসেবী মেন্টর হিসেবে আমাদের সাথে যুক্ত হতে পারেন।  টেলিমেন্টরিং প্রোগ্রাম আপনাকে সেবাগ্রহণকারী ব্যক্তি এবং পরিবারের সাথে যুক্ত করে দিবে। স্বেচ্ছাসেবা ও উন্নয়ন কার্যক্রমের সঙ্গে যুক্ত স্থানীয় প্রতিষ্ঠানগুলোর সাথেও আপনাকে যুক্ত করে দিতে পারে টেলিমেন্টরিং প্রোগ্রাম। এছাড়াও, মানুষের পরামর্শমূলক কাজের জীবনে ইতিবাচক পরিবর্তন আনতে যুক্ত বেসরকারি সংস্থাসমূহকে  (এনজিও) উৎসাহী স্বেচ্ছাসেবী পেয়ে সাহায্য করে টেলিমেন্টরিং।</p>
                            }

                            <div className='flex'>
                                { language === 'en' ?
                                    <Link to={{ pathname: '/about-us', state: { preserveScrollPosition: false } }} className='flex items-center gap-2 text-base font-medium rounded-lg lg:px-6 md:px-4 px-3 lg:py-3 md:py-2 py-2 bg-[#F74F22] text-[#FFEAE4]'>Learn More<span className='text-sm -rotate-90'><FaChevronDown /></span></Link>
                                    :
                                    <Link to={{ pathname: '/about-us', state: { preserveScrollPosition: false } }} className='flex items-center gap-2 text-base font-medium rounded-lg lg:px-6 md:px-4 px-3 lg:py-3 md:py-2 py-2 bg-[#F74F22] text-[#FFEAE4]'>আরো জানুন<span className='text-sm -rotate-90'><FaChevronDown /></span></Link>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="flex flex-col">
                <section className="md:order-1 order-2">
                    <Countdown />
                </section>

                <section className="md:order-2 order-1">
                    <LatestStories />
                </section>
            </div>
            
            <section>
                <Faq />
            </section>
            
            <section>
                <Review />
            </section>

            <section className='mb-28'>
                <BecomeVolunteer />
            </section>

            <ScrollToTop></ScrollToTop>
            <Footer2 />
        </div>
    );
  };
  
  export default Home