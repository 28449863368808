import React, { useEffect, useState } from 'react';
import { FaAngleDoubleUp } from 'react-icons/fa';

function ScrollToTop() {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 100) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        });
    }, []);

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
  return (
    <div>
        <div className={`fixed md:bottom-10 bottom-3 right-3 z-50 ${showScroll ? "" : "hidden"}`}>
            <div onClick={scrollTop} className="text-primary cursor-pointer bg-blue-100 hover:bg-blue-200 transition-all text-[#0082C4] text-2xl font-bold p-3 rounded-full">
                <FaAngleDoubleUp />
            </div> 
        </div>
    </div>
  );
};

export default ScrollToTop