import React, { useState, useEffect, useContext } from "react";
import Axios  from "axios";
import { Link, useParams } from "react-router-dom";
import NewsDetailsImg from '../../assets/NewsAndArticle/news-details-img.png';
import { BsFacebook, BsTwitter, BsLinkedin } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
// import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import BaseUrl from "../BaseURL";
import Avatar from '../../assets/avatar.png';
import ScrollToTop from '../Common/ScrollToTop';
import DefaultImg from '../../assets/default-img.png';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { GlobalContext } from "../GlobalState/GlobalState";

const NewsDetails = () => {
  const { language } = useContext(GlobalContext);

  const { newsId } = useParams();
  const [singleMedia, setSingleMedia] = useState({});
  // console.log(news)
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const [media, setMedia]=useState([]);
  const visibleMedia = media.filter((singleMedia) => singleMedia.visibility === 'visible');
  // console.log(latestNews);

  // Get media
  useEffect(()=>{
    Axios.get(`${BaseUrl}/media`).then((response) => {
      setMedia(response.data);
    });
  },[]);

  
  // const testUrl = 'https://beta.telementoring.net/news-details/16';
  // const encodedUrl = encodeURIComponent(testUrl);
  // console.log(encodedUrl);

  useEffect(()=>{
    Axios.get(`${BaseUrl}/media-details/${newsId}`).then((response) => {
      setSingleMedia(response.data);
    });
  },[newsId]);

  return (
    <div>
      <section className="mb-24">
        <div className="max-w-7xl lg:mx-auto md:mx-5 mx-5 lg:px-8 py-12 md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-12">

          <div className="md:w-3/4 w-full flex flex-col lg:gap-12 md:gap-8 gap-8">
            {
              singleMedia?.imageUrl ?
              <img src={`${BaseUrl}${singleMedia?.imageUrl}`} alt="News Details Img" className="w-full rounded-2xl"/>
              :
              <img src={DefaultImg} alt="News Details Img" className="w-full rounded-2xl"/>
            }
            {/* <img src={NewsDetailsImg} alt="News Details Img" className="w-full"/> */}
            <div className='flex'>
              <p className='flex items-center gap-3 text-[#777B84] text-lg bg-[#F6FAFF] rounded-full px-4 py-1 border border-gray-300 border-solid backdrop-filter backdrop-blur'><span className='text-[#0082C4]'><AiOutlineCalendar/></span>{singleMedia?.post_time}</p>
            </div>
            <div>
              <p className="text-[#2E3033] font-bold md:text-3xl text-2xl mb-8">{singleMedia?.title}</p>
              {/* <p className="text-[#777B84] text-base">{news?.description}</p> */}
              <div dangerouslySetInnerHTML={{__html: singleMedia?.description}}></div>
            </div>
            
            {/* This is next or previous media. Will work letter */}

            {/* <div className="grid grid-cols-2 lg:gap-8 md:gap-8 gap-3 lg:mt-16 md:mt-8 mt-8">
              <Link to='' className="border border-[#E3E6EA] shadow-md rounded-md lg:px-8 md:px-4 px-4 lg:py-5 md:py-3 py-5 flex flex-col gap-2">
                <p className="uppercase flex items-center gap-2 text-[#0082C4] text-sm" ><HiOutlineArrowNarrowLeft /> Previous Post</p>
                <p className="font-semibold lg:text-base md:text-sm text-base text-[#2E3033]">Children have lost chunks of their education during COVID-19 </p>
              </Link>
              <Link to='' className="border border-[#E3E6EA] shadow-md rounded-md lg:px-8 md:px-4 px-4 lg:py-5 md:py-3 py-5 flex flex-col gap-2">
                <p className="uppercase flex justify-end items-center gap-2 text-[#0082C4] text-sm text-right" >Next Post <HiOutlineArrowNarrowRight /></p>
                <p className="font-semibold lg:text-base md:text-sm text-base text-[#2E3033] text-right">Children have lost chunks of their education during COVID-19 </p>
              </Link>
            </div> */}
          </div>

          <div className="md:w-1/4 w-full">
            <div className="sticky top-5 flex flex-col gap-10">
              <div>
                <p className="text-lg font-bold uppercase mb-4">Share</p>
                <div className="flex flex-col gap-3">
                  <FacebookShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-500 to-blue-700 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                    <BsFacebook />Facebook
                  </FacebookShareButton>
                  <TwitterShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                    <BsTwitter />Twitter
                  </TwitterShareButton>
                  <LinkedinShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-600 to-blue-400 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                    <BsLinkedin />LinkedIn
                  </LinkedinShareButton>
                  {/* <Link className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-500 to-blue-700 px-4 lg:py-3 md:py-2 py-3 text-white flex items-center gap-3 rounded-md"><BsFacebook />Facebook</Link>
                  <Link className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 px-4 lg:py-3 md:py-2 py-3 text-white flex items-center gap-3 rounded-md"><BsTwitter />Twitter</Link>
                  <Link className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-600 to-blue-400 px-4 lg:py-3 md:py-2 py-3 text-white flex items-center gap-3 rounded-md"><BsLinkedin />LinkedIn</Link> */}
                </div>
              </div>
              <div>
                <p className="text-lg font-bold uppercase mb-4">Featured</p>
                <div className="lg:flex md:grid grid-cols-1 flex gap-2 items-center">
                  <div className="w-12 border-2 border-blue-100 rounded-full">
                    <img className="w-full h-full rounded-full" src={Avatar} alt="avatar"/>
                  </div>
                  <div>
                    <p className="text-[#0082C4] text-xl font-bold whitespace-no-wrap">Asadul Islam</p>
                    <p className="text-[#A2AAB7] text-sm whitespace-no-wrap">Professor and Director CDES</p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-lg font-bold uppercase mb-4">Department</p>
                <div className="lg:flex md:grid grid-cols-1 flex gap-2 items-center">
                  <div className="lg:w-20 md:w-12 w-16 border-2 border-blue-100 rounded-full">
                    <img className="w-full h-full rounded-full" src={Avatar} alt="avatar"/>
                  </div>
                  <div>
                    <p className="text-[#0082C4] text-base font-bold whitespace-no-wrap">Centre for Development Economics and Sustainability</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default NewsDetails;
