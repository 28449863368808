import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import FaqHeroBg from '../../assets/Faq/FaqHeroBg.png';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import Footer from '../Common/Footer';
import Faq from '../Faq/Faq';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function FAQ() {
  const { language } = useContext(GlobalContext);
  return (
    <div>
      <Helmet>
        <title>FAQ - Telementoring</title>
        <meta name='title' content='FAQ - Telementoring' />
        <meta name="description" content="Get answers to frequently asked questions about Telementoring." />
        <meta name="keywords" content="telementoring, FAQ, frequently asked questions, volunteer-based" />
      </Helmet>

      {/* Hero */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${FaqHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              { language === 'en' ?
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Frequently Asked <span className='text-[#FDBE44]'>Questions</span></p>
                :
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'>সাধারণ <span className='text-[#FDBE44]'>জিজ্ঞাসা</span></p>
              }
              <div className='flex items-center gap-2 mt-4'>
                { language === 'en' ?
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                  :
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                }
                <span><img src={NextIcon} alt='Next Icon' /></span>
                { language === 'en' ?
                  <Link to='/faq' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>FAQs</Link>
                  :
                  <Link to='/faq' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>সাধারণ জিজ্ঞাসা</Link>
                }
              </div>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>

      <section>
        <Faq/>
      </section>

      {/* Become Volunteer */}
      <section className='mb-12'>
        <BecomeVolunteer/>
      </section>

      <Footer />
    </div>
  );
};

export default FAQ