import React, { useContext, useEffect, useState } from 'react';
import Footer2 from '../Common/Footer2';
import ContactUsHeroBg from '../../assets/ContactUs/ContactUsHeroBg.png';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import { RxEnvelopeClosed } from 'react-icons/rx';
import { BsWhatsapp } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import BaseUrl from '../BaseURL';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function ContactUs() {
  const { language } = useContext(GlobalContext);

  const { register, handleSubmit, reset } = useForm();
  const [smtp, setSmtp] = useState([]);
  // Get smtp
  useEffect(()=>{
    axios.get(`${BaseUrl}/smtp`).then((response) => {
        setSmtp(response.data);
    });
  },[]);

  const [verified, setVerified] = useState(false);
  function onChange(value) {
    setVerified(true);
  };

  const onSubmit =async data => {
    if(verified){
      await axios.post(`${BaseUrl}/send-email`, { 
        ...data,
        host: smtp?.smtpHost,
        port: smtp?.smtpPort,
        user: smtp?.smtpEmail,
        pass: smtp?.smtpPass,
      });
      reset();
      toast.success('Massage sent!');
    }else{
      toast.error('Please fill out the captcha to proceed!');
    };
  };

  //  Scroll to top, when user will be redirected in this page
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.preserveScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);

  return (
    <div>
      <Helmet>
          <title>Contact Us - Telementoring</title>
          <meta name='title' content='Contact Us - Telementoring' />
          <meta name="description" content="Get in touch with the Telementoring team. Contact us to ask questions, share feedback etc." />
          <meta name="keywords" content="telementoring, contact us, telehealth education, volunteer-based, healthcare outcomes, rural communities" />
      </Helmet>

      {/* Hero */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${ContactUsHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              { language === 'en' ?
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Contact <span className='text-[#FDBE44]'>Us</span></p>
                :
                <p className='text-[#FDBE44] md:text-4xl text-2xl font-bold banglaFont'>যোগাযোগ</p>
              }
              <div className='flex items-center gap-2 mt-4'>
                { language === 'en' ?
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                  :
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                }
                <span><img src={NextIcon} alt='Next Icon' /></span>
                { language === 'en' ?
                  <Link to='/contact-us' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Contact Us</Link>
                  :
                  <Link to='/contact-us' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>যোগাযোগ</Link>
                }
              </div>
            </div>
          </div>
        </div>
        {/* Divider */}
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>

      {/* Form */}
      <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8'>
        <div className='md:flex grid grid-cols-1 gap-8 py-12'>
          <div className='md:w-2/5 w-full'>
            <div className='grid grid-cols-1 gap-8'>
              <div className='bg-white border border-[#D2DCFF] rounded-2xl p-8 flex flex-col justify-center items-center' style={{boxShadow: '0px 16px 32px rgba(0, 60, 126, 0.03)'}}>
                <span className='lg:text-5xl text-4xl text-[#F74F22] mb-3'><MdOutlinePhoneInTalk/></span>
                { language === 'en' ?
                  <p className='text-[#4D4E5A] text-xl font-semibold mb-3'>Phone Number</p>
                  :
                  <p className='text-[#4D4E5A] text-xl font-semibold mb-3 banglaFont'>ফোন নাম্বার</p>
                }
                <p className='text-base text-[#777B84] flex items-center gap-2'><BsWhatsapp className='text-[#0082C4] text-xl' title='Whatsapp' /> +880 1971 104077</p>
              </div>
              <div className='bg-white border border-[#D2DCFF] rounded-2xl p-8 flex flex-col justify-center items-center' style={{boxShadow: '0px 16px 32px rgba(0, 60, 126, 0.03)'}}>
                <span className='lg:text-5xl text-4xl text-[#F74F22] mb-3'><RxEnvelopeClosed/></span>
                { language === 'en' ?
                  <p className='text-[#4D4E5A] text-xl font-semibold mb-3'>Email Address</p>
                  :
                  <p className='text-[#4D4E5A] text-xl font-semibold mb-3 banglaFont'>ইমেইল এড্রেস</p>
                }
                <p className='text-base text-[#777B84]'>info@telementoring.net</p>
              </div>
            </div>
          </div>
          <div className='border border-[#D2DCFF] rounded-2xl overflow-hidden md:w-3/5 w-full'>
            <div className='bg-[#F7FBFD] py-4'>
              { language === 'en' ?
                <p className='text-2xl text-[#0082C4] font-bold text-center'>Get in Touch</p>
                :
                <p className='text-2xl text-[#0082C4] font-bold text-center banglaFont'>যোগাযোগের মাধ্যম</p>
              }
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='py-8 px-5'>
              <input className='focus:outline-none rounded-xl px-5 lg:py-4 md:py-2 py-3 text-base text-[#777B84] mt-3 w-full border border-[#E4EAFF]'
                type="text"
                placeholder='Your Name'
                required
                {...register("name")}
              />
              <input className='focus:outline-none rounded-xl px-5 lg:py-4 md:py-2 py-3 text-base text-[#777B84] mt-3 w-full border border-[#E4EAFF]'
                type="email"
                placeholder='Email Address'
                required
                {...register("email")}
              />
              <input className='focus:outline-none rounded-xl px-5 lg:py-4 md:py-2 py-3 text-base text-[#777B84] mt-3 w-full border border-[#E4EAFF]'
                type="tel"
                placeholder='Phone Number'
                required
                {...register("phone")}
              />
              <textarea placeholder='Write your message...' className='focus:outline-none rounded-xl px-5 lg:py-4 md:py-2 py-3 text-base text-[#777B84] my-3 w-full border border-[#E4EAFF]' {...register("message")} rows="6" required />

              <ReCAPTCHA
                sitekey="6LcF-5clAAAAAJZNxyYFIlRXThtVa_pNzxPNctQd"
                onChange={onChange}
              />
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                onChange={onChange}
              /> */}
              { language === 'en' ?
                <input type='submit' value='Send Message' className='uppercase bg-[#0082C4] hover:bg-[#0083c4e1] transition-all text-white lg:text-xl md:text-base text-base lg:py-4 md:py-3 py-3 mt-5 w-full rounded-xl cursor-pointer' />
                :
                <input type='submit' value='বার্তাটি পাঠান' className='uppercase bg-[#0082C4] hover:bg-[#0083c4e1] transition-all text-white lg:text-xl md:text-base text-base lg:py-4 md:py-3 py-3 mt-5 w-full rounded-xl cursor-pointer banglaFont' />
              }
            </form>
          </div>
        </div>
      </section>
      
      {/* Become Volunteer */}
      <section className='mb-32'>
          <BecomeVolunteer />
      </section>

      <Footer2 />
    </div>
  )
}

export default ContactUs