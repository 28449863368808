import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import BaseUrl from '../BaseURL';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import { Link, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from '../Common/ScrollToTop';
import { BiEdit } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { ImProfile } from 'react-icons/im';
import { MdLibraryAdd } from 'react-icons/md';
import { signOut } from 'firebase/auth';
import Footer from '../Common/Footer';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../GlobalState/GlobalState';

function Volunteer() {
    const { language } = useContext(GlobalContext);
    function isBangla(text) {
        const banglaCharRegex = /[\u0980-\u09FF]/;
        return banglaCharRegex.test(text);
    }
    const [user] = useAuthState(auth);
    const logout = () => {
        signOut(auth);
    };
    const location = useLocation();

    const { volunteerId } = useParams();
    const [volunteerInfo, setVolunteerInfo] = useState([]);
    const [userInfos, setUserInfos] = useState([]);
    const [volunteer, setVolunteer] = useState({});
    const [showFullProfile, setShowFullProfile] = useState(false);

    const signedInUser = volunteerInfo?.find((person) => person.userEmail === user?.email);
    const signedInUserInfos = userInfos?.find(userInfo => userInfo.user_id.toString() === volunteerId);
    
    // Format date (1994-12-26) type to (26 december)
    const formattedDate = new Date(signedInUserInfos?.birthDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
    });
    // Format date (1994-02-06) type to (06-02-1994)
    const date = new Date(signedInUserInfos?.birthDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate2 = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    // console.log(volunteer?.birthDate);

    // get volunteer details
    useEffect(()=>{
        Axios.get(`${BaseUrl}/volunteer-profile/${volunteerId}`).then((response) => {
            setVolunteer(response.data);
        });
    },[volunteerId]);
    // Get volunteer profile info
    useEffect(()=>{
        Axios.get(`${BaseUrl}/volunteer_profile_info`).then((response) => {
            setVolunteerInfo(response.data);
        });
    },[]);
    // Get user_infos
    useEffect(()=>{
      Axios.get(`${BaseUrl}/userInfos`).then((response) => {
        setUserInfos(response.data);
      });
    },[]);
    
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Volunteer - Telementoring</title>
            <meta name='title' content='Volunteer - Telementoring' />
            <meta name="description" content="If you are studying at university level, want to engage in volunteering and get recognized in the wider community, please became a volunteer." />
            <meta name="keywords" content="telementoring, volunteer" />
        </Helmet>
        <div>
            <img src={ProfileBg} alt='profile page bg' className='w-screen h-44' />
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 mb-12 relative'>
            <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-5 shadow-md -mt-12'>
                {/* Profile Image */}
                <div className='flex justify-center lg:-mt-28 -mt-[5.5rem]'>
                    <div className='lg:w-40 lg:h-40 w-32 h-32 rounded-md flex justify-center items-center bg-[#eaeaef] border border-[#F74F22] overflow-hidden'>
                        { volunteer?.profileImageUrl ?
                            <div className='lg:w-40 lg:h-40 w-32 h-32 rounded-md overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${volunteer?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='lg:w-40 lg:h-40 w-32 h-32 rounded-md bg-[#eaeaef] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                        }
                    </div>
                </div>
                {/* Name and Bio */}
                <div className='text-center mt-2'>
                    <p className='text-xl font-bold'>{volunteer?.firstName} {volunteer?.lastName}</p>

                    { volunteer?.id === signedInUser?.id ?
                        <div>
                            { volunteer?.bio ?
                                <div className='flex justify-center w-full mt-3'>
                                    <p className={`text-base text-[#777B84] md:w-4/5 w-full ${isBangla(volunteer?.bio) ? 'banglaFont' : ''}`}>{volunteer?.bio}</p>
                                </div>
                                :
                                <div className="flex justify-center mt-3">
                                    <Link to='/user-settings' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2'><span className="text-[#0082C4]"><MdLibraryAdd /></span> Add Bio</Link>
                                </div>
                            }
                        </div>
                        :
                        <p className={`text-base text-[#777B84] mt-3 ${isBangla(volunteer?.bio) ? 'banglaFont' : ''}`}>{volunteer?.bio}</p>
                    }
                </div>
                
                { volunteer?.id === signedInUser?.id || signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin' ?
                    <div>
                        { language === 'en' ?
                            <div className='flex items-center justify-center gap-3 mt-5 pt-5 border-t border-t-[#F0F5FB]'>
                                { signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin' ?
                                    <Link to={`/volunteer-profile-update/${volunteer?.id}`} className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2'><span className='text-[#0082C4]'><BiEdit /></span>Edit Profile</Link>
                                    :
                                    <Link to='/update-info' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2'><span className='text-[#0082C4]'><BiEdit /></span>Edit Profile</Link>
                                }
                                <button onClick={()=> setShowFullProfile(!showFullProfile)} className={`bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2`}><span className='text-[#0082C4]'><ImProfile /></span>{`${showFullProfile === true ? 'Hide' : 'Show'}`} Full Profile</button>
                            </div>
                            :
                            <div className='flex items-center justify-center gap-3 mt-5 pt-5 border-t border-t-[#F0F5FB]'>
                                { signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin' ?
                                    <Link to={`/volunteer-profile-update/${volunteer?.id}`} className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2 banglaFont'><span className='text-[#0082C4]'><BiEdit /></span>ইডিট প্রোফাইল</Link>
                                    :
                                    <Link to='/update-info' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2 banglaFont'><span className='text-[#0082C4]'><BiEdit /></span>ইডিট প্রোফাইল</Link>
                                }
                                <Link to='' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2  banglaFont' 
                                onClick={logout}>
                                    <span className='text-[#0082C4]'><FiLogOut /></span>
                                    লগ আউট
                                </Link>
                            </div>
                        }
                    </div>
                    :
                    ''
                }
                <div>
                    <div className={`${showFullProfile === true ? 'hidden' : 'block'}`}>
                        <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-2xl mt-10'>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Name</p>
                                <p className='text-[#777B84] text-base'>{volunteer?.firstName} {volunteer?.lastName}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Date of Birth</p>
                                <p className='text-[#777B84] text-base'>{formattedDate}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Place of Birth</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.district ? signedInUserInfos?.district : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Gender</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.gender ? signedInUserInfos?.gender : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-b md:border-b-0 md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>District</p>
                                <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.district ? `${signedInUserInfos?.district}`: 'Empty'}`}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Subdistrict</p>
                                <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.city ? `${signedInUserInfos?.city}`: 'Empty'}`}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 md:border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Institution</p>
                                <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.institute ? `${signedInUserInfos?.institute}`: 'Empty'}`}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Discipline</p>
                                <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.studyArea ? `${signedInUserInfos?.studyArea}`: 'Empty'}`}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-r-[#F0F5FB]'>
                            <p className='text-[#2E3033] text-base font-semibold'>Experienced in</p>
                                <span>
                                    <span className='text-[#777B84] text-base'>{`${signedInUserInfos?.mentoringExperience === 'Yes' ? 'Mentoring, Tutoring' : ''}`}</span>
                                    <span className='text-[#777B84] text-base'>{`${signedInUserInfos?.mentoringExperience === 'Yes' && signedInUserInfos?.volunteeringExperience === 'Yes' ? ', ': ' '}`}</span>
                                    <span className='text-[#777B84] text-base'>{`${signedInUserInfos?.volunteeringExperience === 'Yes' ? 'Volunteering' : ''}`}</span>
                                    <span className='text-[#777B84] text-base'>{`${signedInUserInfos?.mentoringExperience !== 'Yes' && signedInUserInfos?.volunteeringExperience !== 'Yes' ? 'Empty' : ''}`}</span>
                                </span>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col'>
                            <p className='text-[#2E3033] text-base font-semibold'>Email</p>
                                <p className='text-[#777B84] text-base'>{volunteer?.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${showFullProfile === true ? 'block' : 'hidden'}`}>
                        <div className='grid grid-cols-1 gap-8 mt-10'>
                            {/* Personal Information */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Personal Information</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Name</p>
                                        <p className='text-[#777B84] text-base'>{volunteer?.firstName} {volunteer?.lastName}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Email</p>
                                        <p className='text-[#777B84] text-base'>{volunteer?.email}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Date of Birth</p>
                                        <p className='text-[#777B84] text-base'>{`${formattedDate2 ? `${formattedDate2}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Gender</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.gender}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-b md:border-b-0 md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Nationality</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.nationality ? `${signedInUserInfos?.nationality}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>District, state, or province</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.district ? `${signedInUserInfos?.district}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Subdistrict, city or town</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.city ? `${signedInUserInfos?.city}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>How would you classify your family&apos;s economic status?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.economicStatus}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-r-[#F0F5FB] border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Mobile Number</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.mobile ? `${signedInUserInfos?.mobile}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.background}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Profile Type</p>
                                        <p className='text-[#777B84] text-base'>{volunteer?.profileType}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Academic Information */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Academic Information</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>What is your highest level of educational attainment?</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.studyLevel ? `${signedInUserInfos?.studyLevel}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Name of the institution that you obtained your highest level of education</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.institute ? `${signedInUserInfos?.institute}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Area of study (if you have attended college)</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.studyArea ? `${signedInUserInfos?.studyArea}`: 'Empty'}`}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Skills / Experience */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Skills / Experience</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Your English fluency/proficiency is:</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.englishProficiency ? `${signedInUserInfos?.englishProficiency}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Have you had any experience mentoring or tutoring others?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.mentoringExperience}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Tutoring Experience */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Tutoring Experience</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>How many years of tutoring experience do you have?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.tutoringExperience}</p>
                                    </div>
                                    {/* <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'></div> */}
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>What grade level(s) of students have you tutored?</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.tutoredStudentsLevel ? `${signedInUserInfos?.tutoredStudentsLevel}`: 'Empty'}`}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Volunteering experience */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Volunteering experience</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Do you have any volunteering experience?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringExperience}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>What organization(s) have you volunteered for?</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.volunteeredOrganizations ? `${signedInUserInfos?.volunteeredOrganizations}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>Please provide some details of the volunteering activities/services that you have given:</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.detailActivities ? `${signedInUserInfos?.detailActivities}`: 'Empty'}`}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Other experiences */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Other experiences</h2>
                                </div>
                                <div className=' border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-6 px-8'>
                                        <p className='text-[#2E3033] text-base font-semibold'>If you have any other relevant experiences, please list them here:</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.experienceDescription ? `${signedInUserInfos?.experienceDescription}`: 'Empty'}`}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Volunteering (or Mentoring) preferences */}
                            <div>
                                <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                    <h2 className='text-[#0082C4] text-xl font-semibold'>Volunteering (or Mentoring) preferences</h2>
                                </div>
                                <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                    <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>What is your area of interest for volunteering or mentoring?</p>
                                        <p className='text-[#777B84] text-base'>{`${signedInUserInfos?.areaOfInterest ? `${signedInUserInfos?.areaOfInterest}`: 'Empty'}`}</p>
                                    </div>
                                    <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                        <p className='text-[#2E3033] text-base font-semibold'>How many hours would you prefer to participate in volunteering/mentoring activities?</p>
                                        <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringActivities}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <ScrollToTop></ScrollToTop>
        <Footer/>
    </div>
  );
};

export default Volunteer