import React, { useContext } from 'react';
import VolunteersBg from '../../assets/Home/volunteer-bg.png';
import UsersIconBlue from '../../assets/Home/users-icon-blue.png';
import HelpIconBlue from '../../assets/Home/help-icon-blue.png';
import { Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import { GlobalContext } from '../GlobalState/GlobalState';

function BecomeVolunteer() {
    const { language } = useContext(GlobalContext);
  return (
    <div>
        <section className='pt-12'>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 grid md:grid-cols-2 grid-cols-1 items-center lg:gap-16 md:gap-8 gap-8'>
                <div className=''>
                    <img src={VolunteersBg} alt='Volunteers Bg' className='w-full rounded-[50px]' />
                </div>
                <div className='flex flex-col gap-4'>
                    { language === 'en' ?
                        <p className='lg:text-lg md:text-base text-sm font-semibold py-2 px-5 rounded-full w-36 text-center text-[#F74F22] bg-[#F9EEEE]'>Volunteers</p>
                        :
                        <p className='lg:text-lg md:text-base text-sm font-semibold py-2 px-5 rounded-full w-36 text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>স্বেচ্ছাসেবকবৃন্দ</p>
                    }
                    { language === 'en' ?
                        <p className='md:text-4xl text-2xl font-bold text-[#2E3033] lg:w-3/4 md:w-full w-full'>Join our volunteer family to help people</p>
                        :
                        <p className='md:text-4xl text-2xl font-bold text-[#2E3033] banglaFont'>টেলিমেন্টরিং-এর মাধ্যমে স্বেচ্ছাসেবী পরিবারের অংশ হোন।</p>
                    }
                    { language === 'en' ?
                        <p className='text-lg text-[#777B84]'>If you are studying at the university level, want to volunteer and get recognized in the wider community, please become a volunteer.</p>
                        :
                        <p className='text-lg text-[#777B84] banglaFont'>আপনি যদি একজন বিশ্ববিদ্যালয়পড়ুয়া শিক্ষার্থী হয়ে থাকেন, স্বেচ্ছাসেবী মেন্টর হিসেবে পিছিয়ে থাকা মানুষের অগ্রযাত্রার সঙ্গী হয়ে উঠতে পারেন। স্বেচ্ছাসেবী হিসেবে এখনই নিবন্ধন করুন।</p>
                    }
                    <div className='flex'>
                        { language === 'en' ?
                            <Link to='/get-involved?join=volunteer' className='text-base font-medium rounded-lg lg:px-6 md:px-4 px-3 lg:py-3 md:py-2 py-2 bg-[#F74F22] hover:bg-[#dd380a] transition-all text-[#FFEAE4]'><p className='flex items-center gap-2'>Become a volunteer<span className='text-sm -rotate-90'><FaChevronDown /></span></p></Link>
                            :
                            <Link to='/get-involved?join=volunteer' className='text-base font-medium rounded-lg lg:px-6 md:px-4 px-3 lg:py-3 md:py-2 py-2 bg-[#F74F22] hover:bg-[#dd380a] transition-all text-[#FFEAE4]'><p className='flex items-center gap-2 banglaFont'>স্বেচ্ছাসেবক হোন<span className='text-sm -rotate-90'><FaChevronDown /></span></p></Link>
                        }
                    </div>
                    <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-5 md:gap-3 gap-5 lg:mr-0 md:mr-5 mr-0'>
                        <Link to='/get-involved?join=volunteer#volunteer' className='lg:flex grid items-center lg:gap-4 md:gap-4 gap-2 bg-white shadow-md rounded-md px-5 py-5'>
                            <div>
                                <img src={UsersIconBlue} alt='Users Icon Blue' className='lg:w-20 md:w-10 w-10' />
                            </div>
                            <div>
                                { language === 'en' ?
                                    <p className='text-base font-semibold text-[#2E3033] mb-1'>Join our team</p>
                                    :
                                    <p className='text-base font-semibold text-[#2E3033] mb-1 banglaFont'>আমাদের টিমে যোগ দিন</p>
                                }
                                { language === 'en' ?
                                    <p className='text-xs text-[#777B84]'>Be a part of our ever increasing network of volunteers</p>
                                    :
                                    <p className='text-xs text-[#777B84] banglaFont'>আমাদের স্বেচ্ছাসেবকদের ক্রমবর্ধমান নেটওয়ার্কের অংশ হোন</p>
                                }
                            </div>
                        </Link>
                        <Link to='/get-involved?join=partner#partner' className='lg:flex grid items-center lg:gap-4 md:gap-4 gap-2 bg-white shadow-md rounded-md px-5 py-5'>
                            <div>
                                <img src={HelpIconBlue} alt='Users Icon Blue' className='lg:w-16 md:w-10 w-10' />
                            </div>
                            <div>
                                { language === 'en' ?
                                    <p className='text-base font-semibold text-[#2E3033] mb-1'>Help people</p>
                                    :
                                    <p className='text-base font-semibold text-[#2E3033] mb-1 banglaFont'>মানুষকে সাহায্য করুন</p>
                                }
                                { language === 'en' ?
                                    <p className='text-xs text-[#777B84]'>Support lives with your action.</p>
                                    :
                                    <p className='text-xs text-[#777B84] banglaFont'>আপনার কর্মের সাথে জীবনকে সমর্থন করুন।</p>
                                }
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default BecomeVolunteer