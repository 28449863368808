import React, { useContext, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Quote from '../../assets/Home/quote.png';
import Avatar from '../../assets/avatar.png';
// import LocationIconRed from '../../assets/Home/location-icon-red.png';
import ReviewBg from '../../assets/review-bg.png';
import KashpiaAfrozMridula from '../../assets/Faq/Kashpia Afroz Mridula.png';
import KhairulHassanJahin from '../../assets/Faq/Khairul Hassan Jahin.png';
import FarhanIftakherAlam from '../../assets/Faq/Md. Farhan Iftakher Alam.png';
import SojibHossain from '../../assets/Faq/Sojib Hossain.png';
import TishitaSenApe from '../../assets/Faq/Tishita Sen Ape.png';
import './Review.css';
import { GlobalContext } from '../GlobalState/GlobalState';

function Review() {
    const { language } = useContext(GlobalContext);

    const [sliderRef, setSliderRef] = useState(null);
  
    const handlePrevious = () => {
      if (sliderRef) {
        sliderRef.slickPrev();
      }
    };
  
    const handleNext = () => {
      if (sliderRef) {
        sliderRef.slickNext();
      }
    };
  return (
    <section className='max-w-7xl lg:mx-auto md:mx-auto px-3 py-12'>
        <div className='bg-[#F6FAFF] rounded-2xl lg:p-12 md:p-8 p-3 relative overflow-hidden'>
            <img src={ReviewBg} alt='Review-Bg' className='absolute lg:block md:hidden hidden -bottom-[28rem] -left-56' />
            <div className='md:flex grid grid-cols-1 lg:gap-24 md:gap-5 gap-8'>
                <div className='md:w-1/2 w-full'>
                    { language === 'en' ?
                        <p className='lg:text-lg md:text-base text-sm font-semibold py-2 px-5 inline-block rounded-full text-[#F74F22]' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>Feedback</p>
                        :
                        <p className='lg:text-lg md:text-base text-sm font-semibold py-2 px-5 inline-block rounded-full text-[#F74F22] banglaFont' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>প্রতিক্রিয়া</p>
                    }
                    
                    { language === 'en' ?
                        <p className='md:text-4xl text-2xl font-bold mb-3 text-[#2E3033] my-5 lg:w-3/4 md:w-full w-full'>Quotes from the mentors</p>
                        :
                        <p className='md:text-4xl text-2xl font-bold mb-3 text-[#2E3033] my-5 lg:w-3/4 md:w-full w-full banglaFont'>মেন্টরদের অভিজ্ঞতা</p>
                    }
                    { language === 'en' ? 
                        <p className='md:text-base text-sm text-[#777B84]'>Learn from the experiences of other mentors and gain valuable insights about how to be a better mentor. Find out what makes a successful mentoring relationship, and get motivated to start yours.</p>
                        :
                        <p className='md:text-base text-sm text-[#777B84] banglaFont'>অন্যান্য পরামর্শদাতাদের অভিজ্ঞতা থেকে শিখুন এবং কীভাবে একজন ভাল পরামর্শদাতা হতে হয় সে সম্পর্কে মূল্যবান অন্তর্দৃষ্টি অর্জন করুন। একটি সফল পরামর্শদাতা সম্পর্ক কী করে তা খুঁজে বের করুন এবং আপনার শুরু করতে অনুপ্রাণিত হন।</p>
                    }
                </div>
                <div className='md:w-1/2 w-full'>
                    <div className='flex justify-end gap-5 w-full md:mb-8 mb-4 mr-8'>
                        <button className='bg-white rounded-xl px-4 py-4 shadow-md active:shadow-none' onClick={handlePrevious}>
                            <FaChevronLeft className='text-[#F74F22] lg:text-2xl md:text-xl text-xl' />
                        </button>
                        <button className='bg-white rounded-xl px-4 py-4 shadow-md active:shadow-none' onClick={handleNext}>
                            <FaChevronRight className='text-[#F74F22] lg:text-2xl md:text-xl text-xl' />
                        </button>
                    </div>
                    <Slider ref={(slider) => setSliderRef(slider)} autoplay={true} autoplaySpeed={5000}>
                        {/* {reviews.map((review) => (
                            <div key={review.id} className='w-full lg:p-10 md:p-8 p-5 grid grid-cols-1 bg-white rounded-xl relative'>
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center gap-4'>
                                        <div>
                                            <img src={Avatar} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                        </div>
                                        <div>
                                            <p className='text-lg font-semibold text-[#2E3033]'>{review?.name}</p>
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>{review?.from}</p>
                                        </div>
                                    </div>
                                    <div className='absolute top-5 md:right-5 right-0'>
                                        <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                    </div>
                                </div>
                                <div className='inline-block pt-3 text-[#777B84]'>
                                    <p>{review.text}</p>
                                </div>
                            </div>
                        ))} */}
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={SojibHossain} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Sojib Hossain<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>সজীব হোসাইন<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Begum Rokeya University, Rangpur, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'> মেন্টর, বেগম রোকেয়া বিশ্ববিদ্যালয়, রংপুর</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>Telementoring was a symbol of hope for the parents in the program during the Pandemic.</p>
                                    :
                                    <p className='text-justify banglaFont'>টেলিমেন্টোরিং এই মহামারী সময়কালে বাবা-মায়ের  জন্য একটি আস্থা এবং আশার প্রতীক ছিল।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={FarhanIftakherAlam} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Md. Farhan Iftakher Alam<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>ফারহান ইফতেখার আলম<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Shaheed M. Monsur Ali Medical College, Sirajganj, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>মেন্টর, শহীদ এম মনসুর আলী মেডিকেল কলেজ, সিরাজগঞ্জ</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>When I first started, the situation of the children was very bad as their parents had already decided to put an end to their educational life. I had to deal with the parents more than the children so that they did not give up. I felt that telementoring helped them realize that it wouldn&apos;t be a good decision to stop their studies.</p>
                                    :
                                    <p className='text-justify banglaFont'>আমি যখন বাচ্চাদের প্রথম পড়ানো শুরু করি তখন জানতে পারি যে বাচ্চাদের বাবা-মা ইতিমধ্যে তাদের পড়ালেখা বন্ধ করার সিদ্ধান্ত নিয়েছিলেন। আমাকে বাচ্চাদের পাশাপাশি তাদের বাবা-মায়ের সাথে কথা বলে তাদের অনুপ্রাণিত করতে হয়েছে যেন তারা ওদের পড়ালেখা বন্ধ না করেন। টেলিমেন্টরিং তাদের বোঝাতে সাহায্য করেছিল যে বাচ্চাদের পড়াশোনা বন্ধ করা একটি ভাল সিদ্ধান্ত নয়।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={Avatar} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Suraiya Islam Marjan<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>সুরাইয়া ইসলাম মারজান<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Jagannath University, Dhaka, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>জগন্নাথ বিশ্ববিদ্যালয়, ঢাকা</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>During this uncertain time of the world, Telementoring gave an assurance to the parents about their children&apos;s education by providing a new model of education.</p>
                                    :
                                    <p className='text-justify banglaFont'>বিশ্ব যখন একটি অনিশ্চিত অবস্থায় ছিল মহামারীর সময় তখন টেলিমেন্টরিং শিক্ষা প্রদানের একটি  নতুন মডেল হিসেবে অভিভাবকদের জন্য একটি আস্থার জায়গা হয়ে দাঁড়িয়েছিল।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={Avatar} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Jiniya Jarin Shuchi<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>জিনিয়া জারিন সূচী<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Begum Rokeya University, Rangpur, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>বেগম রোকেয়া বিশ্ববিদ্যালয়, রংপুর</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>As the students learned from me while keeping the phone on speaker, their neighbors who visited them were amazed by the process of tutoring over the phone. Some of these neighbours even asked for help for their children too. It was an interesting and satisfying experience for me.</p>
                                    :
                                    <p className='text-justify banglaFont'>শিক্ষার্থীরা তাদের ফোনটি স্পিকারে রেখে আমার কাছে যখন ক্লাস করতো তাদের প্রতিবেশীরা খুবই বিস্মিত হতো ফোনের মাধ্যমে ক্লাস নেয়ার প্রক্রিয়াটি দেখে। তাদের মধ্যে অনেকেই আমাকে বলেছেন তাদের বাচ্চাদের পড়াশোনায় সাহায্য করতে। পুরো অভিজ্ঞতাটি আমার জন্য খুবই আকর্ষণীয় এবং সন্তোষজনক ছিল।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={KhairulHassanJahin} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Khairul Hassan Jahin<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>খায়রুল হাসান জাহিন<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Jagannath University, Dhaka, Bangladesh.</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>জগন্নাথ বিশ্ববিদ্যালয়, ঢাকা</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>I had no prior experience in telementoring back then. I am happy to be a member of a project that&apos;s making a difference for underprivileged children amid the COVID-19 epidemic. Our mentoring initiative helped individuals who were most in need of direction and encouragement. We hope that by providing them with resources, they will be able to excel in their studies and realize their potential.</p>
                                    :
                                    <p className='text-justify banglaFont'>টেলিমেন্টরিং করোনাকালীন সময়ে সুবিধাবঞ্চিত শিশুদের জন্য একটি সুযোগ তৈরি করছে। এই প্রকল্পের মেন্টরিং উদ্যোগ সেইসকল  বাচ্চাদের সাহায্য করেছে যাদের দিকনির্দেশ এবং উৎসাহের সবচেয়ে বেশি প্রয়োজন ছিল। আমরা আশা করি যে সকল বাচ্চাদের আমরা মেন্টরিং করেছি তারা তাদের পড়াশোনায় দক্ষতা অর্জন করতে এবং তাদের মাঝে যে সম্ভাবনা আছে তা তারা উপলব্ধি করতে সক্ষম হবে।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={KashpiaAfrozMridula} alt='avatar' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Kashpia Afroz Mridula<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>কাশফিয়া আফরোজ মৃদুলা<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>University of Dhaka, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>ঢাকা বিশ্ববিদ্যালয়</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>This was my first tutoring and mentoring experience. I learned how to tutor kids, and the parents were amazed by the technique of providing tutoring and mentoring over the phone. The whole experience of telementoring taught me many things, especially dealing with children.</p>
                                    :
                                    <p className='text-justify banglaFont'>টেলিমেন্টরিং ছিল আমার প্রথম টিউটরিং অভিজ্ঞতা। ফোনে টিউটরিং দেওয়ার কৌশল দেখে অভিভাবকরা অনেক অবাক হয়েছিলেন। এই অভিজ্ঞতা আমাকে অনেক কিছু শিখিয়েছে, বিশেষ করে বাচ্চাদের সাথে কিভাবে মিশতে হবে।</p>
                                }
                            </div>
                        </div>
                        <div className='w-full lg:p-10 md:p-8 p-3 grid grid-cols-1 bg-white rounded-xl relative border-x-8 border-[#F6FAFF] cursor-grab'>
                            <div className='flex items-center justify-between'>
                                <div className='grid grid-flow-col-dense gap-4'>
                                    <div>
                                        <img src={TishitaSenApe} alt='TishitaSenApe' className='h-12 w-12 rounded-full border-4 border-[#E4E4E4]' />
                                    </div>
                                    <div>
                                        { language === 'en' ?
                                            <p className='text-lg font-semibold text-[#2E3033]'>Tishita Sen Ape<span className='text-sm font-semibold text-[#0082C4] ml-3'>Mentor</span></p>
                                            :
                                            <p className='text-lg font-semibold text-[#2E3033] banglaFont'>তিশিতা সেন অপি<span className='text-sm font-semibold text-[#0082C4] ml-3'>মেন্টর</span></p>
                                        }
                                        { language === 'en' ?
                                            <p className='text-sm flex items-center gap-1 text-[#777B84]'>Chattogram Veterinary and Animal Sciences University, Chattogram, Bangladesh</p>
                                            :
                                            <p className='text-sm flex items-center gap-1 text-[#777B84] banglaFont'>চট্টগ্রাম ভেটেরিনারি ও এনিম্যাল সায়েন্সেস বিশ্ববিদ্যালয়, চট্টগ্রাম, বাংলাদেশ</p>
                                        }
                                    </div>
                                </div>
                                <div className='absolute top-5 md:right-5 right-0'>
                                    <img src={Quote} alt='Quote' className='md:w-full w-4/5' />
                                </div>
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                
                            </div>
                            <div className='inline-block pt-3 text-[#777B84]'>
                                { language === 'en' ?
                                    <p className='text-justify'>Telementoring helps me to connect with people from underprivileged backgrounds and understand their lives and livelihood. I was an introvert, so through mentoring, at least, I got the chance to get out of my comfort zone and talk with people I never saw or lived with.</p>
                                    :
                                    <p className='text-justify banglaFont'>টেলিমেন্টরিং আমাকে সুবিধাবঞ্চিত ব্যাকগ্রাউন্ডের লোকদের সাথে সংযোগ করতে এবং তাদের জীবন ও জীবিকা বুঝতে সাহায্য করে। আমি একজন অন্তর্মুখী ছিলাম, তাই পরামর্শ দেওয়ার মাধ্যমে, অন্তত, আমি আমার কমফোর্ট জোন থেকে বেরিয়ে আসার এবং এমন লোকদের সাথে কথা বলার সুযোগ পেয়েছি যাদের সাথে আমি কখনও দেখিনি বা বাস করিনি।</p>
                                }
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Review