import React, { useContext, useEffect, useState } from 'react';
import GreaterThan from '../../assets/Home/greater-than.png';
import DefaultImg from '../../assets/default-img.png';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdNavigateNext } from 'react-icons/md';
import { HiArrowNarrowRight } from 'react-icons/hi';
import BlogHeroBg from '../../assets/Blogs/BlogHeroBg.png';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import { Link, useLocation } from 'react-router-dom';
import BaseUrl from '../BaseURL';
import Axios from 'axios';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function Blogs() {
    const { language } = useContext(GlobalContext);
    function isBangla(text) {
        const banglaCharRegex = /[\u0980-\u09FF]/;
        return banglaCharRegex.test(text);
    }
    const [blogs, setBlogs] = useState([]);
    const visibleBlogs = blogs?.filter((blog) => blog?.visibility === 'visible');
    const latestBlog = visibleBlogs[visibleBlogs?.length - 1];

    // Get blogs
    useEffect(()=>{
        Axios.get(`${BaseUrl}/blogs`).then((response) => {
            setBlogs(response.data);
        });
    },[]);

    //  Scroll to top, when user will be redirected in this page
    const location = useLocation();
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Blogs - Telementoring</title>
            <meta name='title' content='Blogs - Telementoring' />
            <meta name="description" content="Read the latest blog posts from Telementoring." />
            <meta name="keywords" content="telementoring, blogs, articles, volunteer-based" />
        </Helmet>
        
        {/* Hero */}
        <section>
            <div className='relative' style={{"backgroundImage": `url(${BlogHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                    <div className='TextInnerBg w-full'>
                        { language === 'en' ?
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>All <span className='text-[#FDBE44]'>Blogs</span></p>
                            :
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'>সকল <span className='text-[#FDBE44]'>ব্লগ</span></p>
                        }
                        <div className='flex items-center gap-2 mt-4'>
                            { language === 'en' ?
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                                :
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                            }
                            <span><img src={NextIcon} alt='Next Icon' /></span>
                            {/* <Link to='/blogs' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Blogs</Link> */}
                            { language === 'en' ?
                                <Link to='/blogs' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Blogs</Link>
                                :
                                <Link to='/media' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>ব্লগ</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
                <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
            </div>
        </section>

        {/* latest Blog */}
        <section className={`max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 pt-12 lg:pb-12 pb-0 ${visibleBlogs?.length < 1 ? 'hidden' : 'block'}`}>
            <div className='grid md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-5 gap-0 items-center bg-white rounded-xl shadow-lg lg:p-8 p-4'>
            {
                latestBlog?.imageUrl ?
                <img src={`${BaseUrl}${latestBlog?.imageUrl}`} alt="News cover Img" className='rounded-xl' />
                :
                <img src={DefaultImg} alt="News cover Img"  className='rounded-xl' />
            }
            <div className='flex flex-col gap-4'>
                {/* <a href={latestBlog?.link} target='_blank' className='text-[#2E3033] font-semibold text-2xl hover:text-blue-500 transition-all'>{latestBlog?.title}</a> */}
                <Link to={`/blog-details/${latestBlog?.id}`} className={`text-[#2E3033] font-semibold text-2xl hover:text-blue-500 transition-all ${isBangla(latestBlog?.title) ? 'banglaFont' : ''}`}>{latestBlog?.title}</Link>
                <div className='flex'>
                    <p className='flex items-center gap-3 text-[#777B84] text-sm bg-[#F6FAFF] rounded-full px-3 py-1'><span className='text-[#0082C4]'><AiOutlineCalendar/></span>{latestBlog?.post_time}</p>
                </div>
                {/* <p className='text-[#777B84] text-base'>{latestBlog?.description?.slice(0,130)}...</p> */}
                <div dangerouslySetInnerHTML={{__html: latestBlog && latestBlog?.description && latestBlog?.description.length >= 130 ? latestBlog?.description?.slice(0, 130) + "..." : latestBlog?.description}} className={`text-[#777B84] text-base ${isBangla(latestBlog?.description) ? 'banglaFont' : ''}`}></div>

                <div className='flex'>
                    {/* <a href={latestBlog?.link} target='_blank' className='bg-[#0082C4] hover:bg-[#0083c4e1] transition-all rounded-lg text-white flex items-center gap-2 px-5 lg:py-3 md:py-2 py-2 lg:mt-10'>Learn More<HiArrowNarrowRight/></a> */}
                    <Link to={`/blog-details/${latestBlog?.id}`} className='bg-[#0082C4] hover:bg-[#0083c4e1] transition-all rounded-lg text-white flex items-center gap-2 px-5 lg:py-3 md:py-2 py-2 lg:mt-10'>Learn More<HiArrowNarrowRight/></Link>
                </div>
            </div>
            </div>
        </section>

        {/* All Blogs */}
        <section className='max-w-7xl mx-auto lg:px-16 md:px-16 px-5 pt-12 pb-20'>
            {visibleBlogs?.length < 1 ? 
                <div>
                    { language === 'en' ?
                        <p className='text-center text-3xl font-semibold py-16'>No blogs found.</p>
                        :
                        <p className='text-center text-3xl font-semibold py-16 banglaFont'>কোনো ব্লগ পাওয়া যায়নি ।</p>
                    }
                </div>
                :
                <div>
                    { language === 'en' ?
                        <p className='text- font-bold md:text-4xl text-2xl flex items-center border-b pb-3'>All Blogs <MdNavigateNext/></p>
                        :
                        <p className='text- font-bold md:text-4xl text-2xl flex items-center border-b pb-3 banglaFont'>ব্লগগুলো <MdNavigateNext/></p>
                    }
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 pt-10'>
                        {
                            visibleBlogs.slice().reverse().map((blog)=>{
                                return(
                                    <div key={blog?.id} className='bg-white rounded-2xl shadow-md pb-14 relative border border-white hover:border-[#F74F22] hover:border-opacity-30 transition-all'>
                                        {
                                            blog?.imageUrl ?
                                            <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${BaseUrl}${blog?.imageUrl})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                                                <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                                    <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                                    <p className='text-white text-xs px-2'>{blog?.post_time}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${DefaultImg})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                                                <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                                    <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                                    <p className='text-white text-xs px-2'>{blog?.post_time}</p>
                                                </div>
                                            </div>
                                        }
                                        <div className='lg:px-7 md:px-7 px-5'>
                                            {/* <div className='innerCardBorder'>
                                                <div className='grid grid-cols-2 my-2'>
                                                    <div className='innerCardRightBorder'>
                                                        <p className='text-xs flex justify-center items-center gap-1 text-gray-500'><span><img src={ClockIcon} alt='Clock Icon' /></span>Starts - 09:50 AM</p>
                                                    </div>
                                                    <div>
                                                        <p className='text-xs flex items-center justify-center gap-1 text-gray-500'><span><img src={LocationIcon} alt='Clock Icon' /></span>Dhaka, Bangladesh</p>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='py-3'>
                                                <Link to={`/blog-details/${blog?.id}`} className={`text-xl font-bold text-[#2E3033] hover:text-blue-500 transition-all ${isBangla(blog?.title) ? 'banglaFont' : ''}`}>{blog && blog?.title && blog?.title.length >= 50 ? blog?.title?.slice(0,50) + "..." : blog?.title }</Link>
                                            </div>

                                            {/* <p className='text-base text-[#777B84] py-3'>{blog?.description?.slice(0,120)}...</p> */}
                                            
                                            <div dangerouslySetInnerHTML={{__html: blog && blog?.description && blog?.description.length >= 120 ? blog?.description?.slice(0, 120) + "..." : blog?.description}} className={`text-[#777B84] font-normal ${isBangla(blog?.description) ? 'banglaFont' : ''}`}></div>

                                            <div className='flex justify-between items-center mt-4 pb-5 absolute bottom-0'>
                                                <Link to={`/blog-details/${blog?.id}`} className='text-base font-semibold text-gray-500 hover:text-gray-400 flex items-center gap-2 transition duration-300'>More details<img src={GreaterThan} alt='Greater Than' /></Link>
                                                {/* <Link to='' className='bg-blue-50 hover:bg-blue-100 transition duration-300 rounded-md text-base font-semibold text-blue-500 flex items-center gap-2 py-2 px-5'>Join now +</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })   
                        }
                    </div>
                </div>
            }
        </section>
        
        <Footer />
    </div>
  )
}

export default Blogs