import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// import HashibulHassanProfile from '../../assets/MeetTheTeam/HashibulHassanProfile.png';
// import LiangChoonWangProfile from '../../assets/MeetTheTeam/LiangChoonWangProfile.png';
// import AsadIslamProfile from '../../assets/MeetTheTeam/AsadIslamProfile.png';
import HashibulHassanProfile from '../../assets/MeetTheTeam/HashibulHassanProfile.jpeg';
import LiangChoonWangProfile from '../../assets/MeetTheTeam/LiangChoonWangProfile.jpg';
import AsadIslamProfile from '../../assets/MeetTheTeam/AsadIslamProfile.jpg';
import './OurTeam.css';
import { GlobalContext } from '../GlobalState/GlobalState';

function OurTeam() {
  const { language } = useContext(GlobalContext);
  return (
    <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12 mt-8'>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-4 gap-8'>            
            <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${AsadIslamProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                    <div className='absolute bottom-5 left-[50%] right-[50%] md:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                        <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                            <li>
                                <a href="https://www.facebook.com/asadul.islam" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-facebook" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/asad-islam-74a6bb80/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-linkedin-in" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/asadislambd" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-twitter" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                    { language === 'en' ?
                        <a href='https://users.monash.edu.au/~asaduli/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Asad Islam</a>
                        :
                        <a href='https://users.monash.edu.au/~asaduli/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>আসাদ ইসলাম</a>
                    }
                    { language === 'en' ?
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>FOUNDER</p>
                        :
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>প্রতিষ্ঠাতা</p>
                    }
                </div>
                { language === 'en' ?
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center'>Director, Centre for Development Economics and</p>
                        <p className='text-center'>Sustainability (CDES)</p>
                        <p className='text-center'>Professor, Department of Economics</p>
                        <p className='text-center'>Monash University</p>
                    </div>
                    :
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center banglaFont'>পরিচালক, সেন্টার ফর ডেভেলপমেন্ট ইকোনমিক্স অ্যান্ড</p>
                        <p className='text-center banglaFont'>সাসটেইনেবিলিটি (CDES)</p>
                        <p className='text-center banglaFont'>অধ্যাপক, অর্থনীতি বিভাগ, মোনাশ বিশ্ববিদ্যালয়</p>
                    </div>
                }
            </div>
            <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${LiangChoonWangProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                    <div className='absolute bottom-5 left-[50%] right-[50%] md:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                        <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                            <li>
                                <a href="https://www.facebook.com/choonyboy" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-facebook" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/choon-wang-90161443/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-linkedin-in" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/choon_wang" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-twitter" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                    
                    { language === 'en' ?
                        <a href='https://sites.google.com/site/liangchoonwang/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Liang Choon Wang</a>
                        :
                        <a href='https://sites.google.com/site/liangchoonwang/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>লিয়াং চুন ওয়াং</a>
                    }

                    { language === 'en' ?
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>FOUNDER</p>
                        :
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>প্রতিষ্ঠাতা</p>
                    }
                </div>
                { language === 'en' ?
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center'>Associate Professor, Department of Economics</p>
                        <p className='text-center'>Monash University</p>
                    </div>
                    :
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center banglaFont'>সহযোগী অধ্যাপক, অর্থনীতি বিভাগ, মোনাশ বিশ্ববিদ্যালয়</p>
                    </div>
                }
            </div>
            <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${HashibulHassanProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                    <div className='absolute bottom-5 left-[50%] right-[50%] md:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                        <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=594254448" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-facebook" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/hashibul-hassan/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-linkedin-in" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/hasib_hassan_BD" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                    <svg className="fa-brands fa-twitter" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                    { language === 'en' ?
                        <a href='https://sites.google.com/view/hashibulhassan' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Hashibul Hassan</a>
                        :
                        <a href='https://sites.google.com/view/hashibulhassan' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>হাসিবুল হাসান</a>
                    }
                    { language === 'en' ?
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>FOUNDER</p>
                        :
                        <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>প্রতিষ্ঠাতা</p>
                    }
                </div>
                { language === 'en' ?
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center'>Associate Professor, Department of Finance</p>
                        <p className='text-center'>Jagannath University</p>
                    </div>
                    :
                    <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                        <p className='text-center banglaFont'>সহযোগী অধ্যাপক, অর্থ বিভাগ, জগন্নাথ বিশ্ববিদ্যালয়</p>
                    </div>
                }
            </div>
        </div>
    </section>
  );
};

export default OurTeam