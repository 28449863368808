import React from 'react';
import { Link } from 'react-router-dom';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import VisionValuesBg from '../../assets/VisionAndValues/vision-values-hero-bg.png';
import CenteredTargetImg from '../../assets/VisionAndValues/target-center.png';
import PinkCircle from '../../assets/VisionAndValues/pink-circle.png';
import DurbinImg from '../../assets/VisionAndValues/durbin-img.png';
import BlueCircle from '../../assets/VisionAndValues/blue-circle.png';
import ValuesImg1 from '../../assets/VisionAndValues/values-1.png';
import ValuesImg2 from '../../assets/VisionAndValues/values-2.png';
import ValuesImg3 from '../../assets/VisionAndValues/values-3.png';
import BgOverlay from '../../assets/bg-overlay.png';
// import Footer from '../Common/Footer';
// import Navbar from '../Common/Navbar';
import Review from '../Review/Review';

function VisionAndValues() {
  return (
    <div>
      {/* <Navbar></Navbar> */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${VisionValuesBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Vision And <span className='text-[#FDBE44]'>Values</span></p>
              <div className='flex items-center gap-2 mt-4'>
                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                <span><img src={NextIcon} alt='Next Icon' /></span>
                <Link to='/about-us' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Vision And Values</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>

      <section>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12'>
          <div className='relative'>
            <img src={BgOverlay} alt='Bg Overlay' className='absolute lg:block md:hidden hidden -right-72 -top-56 w-1/2' />
            <div className='bg-white px-8 pt-12 pb-20 shadow-md rounded-xl relative overflow-hidden'>
              <img src={PinkCircle} alt='Pink Circle Img' className='absolute -right-32 -bottom-60' />
              <div className='md:flex grid grid-cols-1 items-center'>
                <div className='md:w-3/4 w-full'>
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-3 rounded-full w-32 text-center text-[#F74F22] bg-[#F9EEEE]'>Mission</p>
                  <p className='text-[#2E3033] md:text-4xl text-2xl font-bold my-4'>Our Mission</p>
                  <p className='text-[#777B84] mb-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a erat ante. Nam rhoncus sagittis lorem. Curabitur feugiat felis vitae dui venenatis, vitae porta sapien pretium. Nulla facilisi. Integer ut turpis vel mi mattis sollicitudin ac at ligula. Fusce consectetur auctor nulla quis condimentum.</p>
                  <p className='text-[#777B84]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a erat ante. Nam rhoncus sagittis lorem. Curabitur feugiat felis vitae dui venenatis, vitae porta sapien pretium. Nulla facilisi. Integer ut turpis vel mi mattis sollicitudin ac at ligula. Fusce consectetur auctor nulla quis condimentum.</p>
                </div>
                <div className='md:w-1/4 w-full flex justify-center'>
                  <img src={CenteredTargetImg} alt='Centered Target Img' />
                </div>
              </div>
            </div>
          </div>
          
          <div className='relative'>
            <img src={BgOverlay} alt='Bg Overlay' className='absolute lg:block md:hidden hidden -left-72 -bottom-56 w-1/2' />
            <div className='bg-white px-8 pt-12 pb-20 shadow-md rounded-xl relative overflow-hidden mt-12'>
              <img src={BlueCircle} alt='Pink Circle Img' className='absolute -right-32 -bottom-60' />
              <div className='md:flex grid grid-cols-1 items-center'>
                <div className='md:w-3/4 w-full'>
                  <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-3 rounded-full w-32 text-center text-[#F74F22] bg-[#F9EEEE]'>Vision</p>
                  <p className='text-[#2E3033] md:text-4xl text-2xl font-bold my-4'>Our Vision</p>
                  <p className='text-[#777B84] mb-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a erat ante. Nam rhoncus sagittis lorem. Curabitur feugiat felis vitae dui venenatis, vitae porta sapien pretium. Nulla facilisi. Integer ut turpis vel mi mattis sollicitudin ac at ligula. Fusce consectetur auctor nulla quis condimentum.</p>
                  <p className='text-[#777B84]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a erat ante. Nam rhoncus sagittis lorem. Curabitur feugiat felis vitae dui venenatis, vitae porta sapien pretium. Nulla facilisi. Integer ut turpis vel mi mattis sollicitudin ac at ligula. Fusce consectetur auctor nulla quis condimentum.</p>
                </div>
                <div className='md:w-1/4 w-full flex justify-center'>
                  <img src={DurbinImg} alt='Centered Target Img' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 md:px-4 px-4 py-12'>
        <div className='flex flex-col gap-2 mb-10'>
          <div className='flex justify-center items-center'>
            <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-3 rounded-full w-32 text-center text-[#F74F22] bg-[#F9EEEE]'>Values</p>
          </div>
          <p className='text-[#2E3033] md:text-4xl text-2xl text-center font-bold'>Our Values</p>
          <div className='flex justify-center'>
            <p className='text-[#777B84] text-lg text-center lg:w-2/3 md:w-4/5 w-full'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a erat ante. Nam rhoncus sagittis lorem. Curabitur feugiat felis vitae</p>
          </div>
        </div>
        <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-12 md:gap-5 gap-8'>
          <div className='lg:h-[553px] md:h-[453px] h-[453px] relative rounded-2xl' style={{"backgroundImage": `url(${ValuesImg1})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
            <div className='absolute bottom-0 lg:p-8 md:p-4 p-8' >
              <p className='text-[#FDBE44] text-lg font-bold mb-2'>Values Names</p>
              <p className='text-[#F6FAFF]'>Quisque eget nisi vitae tellus auctor lacinia. Proin nec odio et ipsum ultricies vulputate eu in leo. Integer fringilla fringilla tellus a dapibus. </p>
            </div>
          </div>
          <div className='lg:h-[553px] md:h-[453px] h-[453px] relative rounded-2xl' style={{"backgroundImage": `url(${ValuesImg2})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
            <div className='absolute bottom-0 lg:p-8 md:p-4 p-8' >
              <p className='text-[#FDBE44] text-lg font-bold mb-2'>Values Names</p>
              <p className='text-[#F6FAFF]'>Quisque eget nisi vitae tellus auctor lacinia. Proin nec odio et ipsum ultricies vulputate eu in leo. Integer fringilla fringilla tellus a dapibus. </p>
            </div>
          </div>
          <div className='lg:h-[553px] md:h-[453px] h-[453px] relative rounded-2xl' style={{"backgroundImage": `url(${ValuesImg3})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
            <div className='absolute bottom-0 lg:p-8 md:p-4 p-8' >
              <p className='text-[#FDBE44] text-lg font-bold mb-2'>Values Names</p>
              <p className='text-[#F6FAFF]'>Quisque eget nisi vitae tellus auctor lacinia. Proin nec odio et ipsum ultricies vulputate eu in leo. Integer fringilla fringilla tellus a dapibus. </p>
            </div>
          </div>
          {/* <div className='h-[553px] relative rounded-2xl' style={{"backgroundImage": `url(${ValuesImg2})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
            <div className='absolute bottom-0 p-8' >
              <p className='text-[#FDBE44] text-lg font-bold mb-2'>Values Names</p>
              <p className='text-[#F6FAFF]'>Quisque eget nisi vitae tellus auctor lacinia. Proin nec odio et ipsum ultricies vulputate eu in leo. Integer fringilla fringilla tellus a dapibus. </p>
            </div>
          </div>
          <div className='h-[553px] relative rounded-2xl' style={{"backgroundImage": `url(${ValuesImg3})`, 'backgroundPosition':'right', 'backgroundRepeat':'no-repeat'}}>
            <div className='absolute bottom-0 p-8' >
              <p className='text-[#FDBE44] text-lg font-bold mb-2'>Values Names</p>
              <p className='text-[#F6FAFF]'>Quisque eget nisi vitae tellus auctor lacinia. Proin nec odio et ipsum ultricies vulputate eu in leo. Integer fringilla fringilla tellus a dapibus. </p>
            </div>
          </div> */}
        </div>
      </section>

      <section className='mb-24'>
        <Review/>
      </section>

      {/* <Footer/> */}
    </div>
  );
};

export default VisionAndValues