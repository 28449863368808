import React, { useContext, useEffect, useState } from 'react';
import GreaterThan from '../../assets/Home/greater-than.png';
import DefaultImg from '../../assets/default-img.png';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdNavigateNext } from 'react-icons/md';
import { Link } from 'react-router-dom';
import BaseUrl from '../BaseURL';
import Axios, { all } from 'axios';
import { GlobalContext } from '../GlobalState/GlobalState';

function LatestStories() {
    const { language } = useContext(GlobalContext);

    function isBangla(text) {
        const banglaCharRegex = /[\u0980-\u09FF]/;
        return banglaCharRegex.test(text);
    }
      
    const [blogs, setBlogs] = useState([]);
    const visibleBlogs = blogs?.filter((blog) => blog?.visibility === 'visible');
    const latestBlog = visibleBlogs[visibleBlogs?.length - 1];
    // console.log(latestBlog?.post_time);

    const [media, setMedia]=useState([]);
    const visibleMedia = media?.filter((singleMedia) => singleMedia?.visibility === 'visible');
    const latestMedia = visibleMedia[visibleMedia?.length - 1];
    // console.log(latestMedia.post_time);
    // console.log(visibleMedia);

    const [publications, setPublications] = useState([]);
    const visiblePublications = publications?.filter((publication) => publication?.visibility === 'visible');
    const latestPublication = visiblePublications[visiblePublications?.length - 1];
    // console.log(latestPublication?.post_time);
    // console.log(visiblePublications);

    // Get Blogs
    useEffect(()=>{
      Axios.get(`${BaseUrl}/blogs`).then((response) => {
          setBlogs(response.data);
      });
    },[]);
    // Get media
    useEffect(()=>{
        Axios.get(`${BaseUrl}/media`).then((response) => {
        setMedia(response.data);
        });
    },[]);
    // Get Publications
    useEffect(()=>{
        Axios.get(`${BaseUrl}/publications`).then((response) => {
        setPublications(response.data);
        });
    },[]);

    // Method 1
    const allPosts = [...visibleBlogs, ...visibleMedia, ...visiblePublications];
    // Sort posts based on post time (latest to oldest)
    allPosts.sort((a, b) => new Date(b?.post_time) - new Date(a?.post_time))
    // Get the last six posts
    const latestPosts = allPosts?.slice(0, 6);
    // console.log(latestPosts?.length);


    return (
        <section className={`max-w-7xl mx-auto lg:px-16 md:px-16 px-5 py-12 ${latestPosts?.length < 1 ? 'hidden' : 'block'}`}>
            <div className='grid grid-cols-1 justify-items-center'>
                { language === 'en' ?
                    <h1 className='md:text-4xl text-3xl font-bold mb-3 text-[#2E3033]'>Latest Stories</h1>
                    :
                    <h1 className='md:text-4xl text-3xl font-bold mb-3 text-[#2E3033] banglaFont'>সর্বশেষ প্রকাশ</h1>
                }
                { language === 'en' ?
                    <p className='text-center md:text-base text-sm text-[#777B84] lg:w-2/3 md:w-2/3 w-full'>Here are some reports, opinions and editorials related to telementoring, and learning via basic feature phone etc.</p>
                    :
                    <p className='text-center md:text-base text-sm text-[#777B84] lg:w-1/2 md:w-2/3 w-full banglaFont'>এখানে টেলিমেন্টোরিং এবং মৌলিক ফিচার ফোনের মাধ্যমে শেখার সাথে সম্পর্কিত কিছু প্রতিবেদন, মতামত এবং সম্পাদকীয় রয়েছে।</p>
                }
            </div>
            {/* All posts */}
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 pt-10">
                {latestPosts.map((post) => (
                    <div key={post?.id} className='bg-white rounded-2xl shadow-md pb-14 relative border border-white hover:border-[#F74F22] hover:border-opacity-30 transition-all'>
                        {
                            post?.imageUrl ?
                            <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${BaseUrl}${post?.imageUrl})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                                <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                    <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                    <p className='text-white text-xs px-2'>{post?.post_time}</p>
                                </div>
                            </div>
                            :
                            <div className='h-44 relative rounded-t-2xl' style={{"backgroundImage": `url(${DefaultImg})`, 'backgroundPosition':'center', 'backgroundSize': 'cover'}}>
                                <div className='rounded-md flex items-center gap-1 bottom-5 left-6 absolute' style={{'background':'rgba(0, 0, 0, 0.5)'}}>
                                    <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><AiOutlineCalendar /></p>
                                    <p className='text-white text-xs px-2'>{post?.post_time}</p>
                                </div>
                            </div>
                        }
                        <div className='md:px-7 px-5'>
                            <div className='border-b py-3 mb-3'>
                                <a
                                    href={post?.externalLink}
                                    target='_blank'
                                    className={`${post?.externalLink ? 'block' : 'hidden'} text-xl font-bold text-[#2E3033] hover:text-blue-500 transition-all ${
                                        isBangla(post?.title) ? 'banglaFont' : ''
                                    }`}
                                    >
                                    {post?.title?.slice(0, 60)}...
                                </a>
                                <Link
                                    to={{ pathname: `/publication-details/${post?.id}`, state: { preserveScrollPosition: false } }}
                                    className={`${post?.publishedLocation ? 'block' : 'hidden'} text-xl font-bold text-[#2E3033] hover:text-blue-500 transition-all ${
                                        isBangla(post?.title) ? 'banglaFont' : ''
                                    }`}
                                >
                                    {post?.title?.slice(0, 60)}...
                                </Link>
                                <Link
                                    to={{ pathname: `/blog-details/${post?.id}`, state: { preserveScrollPosition: false } }}
                                    className={`${post?.externalLink || post?.publishedLocation ? 'hidden' : 'block'} text-xl font-bold text-[#2E3033] hover:text-blue-500 transition-all ${
                                        isBangla(post?.title) ? 'banglaFont' : ''
                                    }`}
                                >
                                    {post?.title?.slice(0, 60)}...
                                </Link>
                            </div>
                            
                            <div className='text-[#777B84] text-base' dangerouslySetInnerHTML={{__html: post?.description?.slice(0,160) + "..."}}></div>

                            { language === 'en' ?
                                <div className='flex justify-between items-center pb-5 absolute bottom-0'>
                                    <a href={post?.externalLink} target='_blank' className={`${post?.externalLink ? 'block' : 'hidden'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all`} >Learn More<img src={GreaterThan} alt='Greater Than' /></a>

                                    <Link to={`/blog-details/${post?.id}`} className={`${post?.externalLink || post?.publishedLocation ? 'hidden' : 'block'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all`}>Learn More<img src={GreaterThan} alt='Greater Than' /></Link>
            
                                    <Link to={`/publication-details/${post?.id}`} className={`${post?.publishedLocation ? 'block' : 'hidden'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all`}>Learn More<img src={GreaterThan} alt='Greater Than' /></Link>
                                </div>
                                :
                                <div className='flex justify-between items-center pb-5 absolute bottom-0'>
                                    <a href={post?.externalLink} target='_blank' className={`${post?.externalLink ? 'block' : 'hidden'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all banglaFont`} >আরো জানুন<img src={GreaterThan} alt='Greater Than' /></a>

                                    <Link to={`/blog-details/${post?.id}`} className={`${post?.externalLink || post?.publishedLocation ? 'hidden' : 'block'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all banglaFont`}>আরো জানুন<img src={GreaterThan} alt='Greater Than' /></Link>
            
                                    <Link to={`/publication-details/${post?.id}`} className={`${post?.publishedLocation ? 'block' : 'hidden'} text-base font-semibold text-[#F74F22] flex items-center gap-2 hover:gap-2.5 transition-all banglaFont`}>আরো জানুন<img src={GreaterThan} alt='Greater Than' /></Link>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className='flex w-full justify-end mt-12'>
                { language === 'en' ?
                    <Link to={{ pathname: '/media', state: { preserveScrollPosition: false } }} className='flex items-center bg-[#F74F22] hover:bg-[#f75022e0] transition-all px-5 py-3 rounded-lg text-white font-semibold'>View All <MdNavigateNext /> </Link>
                    :
                    <Link to={{ pathname: '/media', state: { preserveScrollPosition: false } }} className='flex items-center bg-[#F74F22] hover:bg-[#f75022e0] transition-all px-5 py-3 rounded-lg text-white font-semibold banglaFont'>সবগুলো দেখুন<MdNavigateNext /> </Link>
                }
            </div>
        </section>
    )
}

export default LatestStories