import React from 'react';
// import Navbar from '../Common/Navbar';

function Bangladesh() {
  return (
    <div>
      {/* <Navbar></Navbar> */}
      <div className='max-w-7xl mx-auto h-screen'>
        <p>Bangladesh Page</p>
      </div>
    </div>
  );
};

export default Bangladesh