import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Footer from '../Common/Footer';
// import Navbar from '../Common/Navbar';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import MeetTheTeamBg from '../../assets/MeetTheTeam/MeetTheTeamHeroBg.png';
// import FeatureImg from '../../assets/MeetTheTeam/feature-img.png';
// import FeatureImg2 from '../../assets/MeetTheTeam/feature-img2.png';
import JoinTeamBg from '../../assets/MeetTheTeam/join-team-bg.png';
import Icon1 from '../../assets/MeetTheTeam/meet-the-team-icon1.png';
import Icon2 from '../../assets/MeetTheTeam/meet-the-team-icon2.png';
import Icon3 from '../../assets/MeetTheTeam/meet-the-team-icon3.png';
import Icon4 from '../../assets/MeetTheTeam/meet-the-team-icon4.png';
import Review from '../Review/Review';
import './MeetTheTeam.css';
import '../OurTeam/OurTeam.css';
import OurTeam from '../OurTeam/OurTeam';
import ScrollToTop from '../Common/ScrollToTop';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function MeetTheTeam() {
  const { language } = useContext(GlobalContext);
  
  //  Scroll to top, when user will be redirected in this page
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.preserveScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);

  return (
    <div>
      <Helmet>
        <title>Meet the team - Telementoring</title>
        <meta name='title' content='Meet the team - Telementoring' />
        <meta name="description" content="Get to know the team behind Telementoring." />
        <meta name="keywords" content="telementoring, meet the team" />
      </Helmet>

      {/* Hero */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${MeetTheTeamBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              { language === 'en' ?
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Meet The <span className='text-[#FDBE44]'>Team</span></p>
                :
                <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>টেলিমেন্টরিং <span className='text-[#FDBE44]'>টিম</span></p>
              }
              <div className='flex items-center gap-2 mt-4'>
                { language === 'en' ?
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                  :
                  <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                }
                <span><img src={NextIcon} alt='Next Icon' /></span>
                { language === 'en' ?
                  
                  <Link to='/meet-the-team' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Meet The Team</Link>
                  :
                  <Link to='/meet-the-team' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>টেলিমেন্টরিং টিম</Link>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>

      {/* Team members */}
      <section>
        <OurTeam />
      </section>

      {/* Become a volunteer */}
      <section style={{"backgroundImage": `url(${JoinTeamBg})`, 'backgroundPosition':'bottom', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 grid md:grid-cols-2 grid-cols-1 items-center lg:gap-16 md:gap-3 gap-5 py-16 lg:my-12'>
          <div className='flex flex-col lg:gap-5 md:gap-2 gap-5'>
            <div className='flex'>
              { language === 'en' ?
                <p className='lg:text-base md:text-base text-sm font-semibold py-1 px-5 rounded-full text-center text-white bg-[#0082C4]'>Become a volunteer</p>
                :
                <p className='lg:text-base md:text-base text-sm font-semibold py-1 px-5 rounded-full text-center text-white bg-[#0082C4] banglaFont'>স্বেচ্ছাসেবক হোন</p>
              }
            </div>
            
            { language === 'en' ?
              <p className='text-white md:text-4xl text-2xl font-bold lg:w-2/3 md:w-full w-2/3'>Become a volunteer and help people</p>
              :
              <p className='text-white md:text-4xl text-2xl font-bold lg:w-2/3 md:w-full w-2/3 banglaFont'>একজন স্বেচ্ছাসেবক হন এবং মানুষকে সাহায্য করুন</p>
            }
            { language === 'en' ?
              <p className='text-[#E3E6EA] lg:w-4/5 md:w-full w-full'>Mobile phone penetration has increased significantly over the past two decades. Despite rapid growth, internet users are still lagging, especially in developing countries.</p>
              :
              <p className='text-[#E3E6EA] lg:w-4/5 md:w-full w-full banglaFont'>গত দুই দশকে মোবাইল ফোনের প্রবেশ উল্লেখযোগ্যভাবে বৃদ্ধি পেয়েছে। দ্রুত বৃদ্ধি সত্ত্বেও, ইন্টারনেট ব্যবহারকারীরা এখনও পিছিয়ে রয়েছে, বিশেষ করে উন্নয়নশীল দেশগুলিতে।</p>
            }
            <div className='mt-6'>
              { language === 'en' ?
                <Link to='/get-involved?join=volunteer#volunteer' className='px-5 py-2 text-white bg-[#F74F22] hover:bg-[#ff3700] transition-all rounded-lg' >Become a volunteer</Link>
                :
                <Link to='/get-involved?join=volunteer#volunteer' className='px-5 py-2 text-white bg-[#F74F22] hover:bg-[#ff3700] transition-all rounded-lg banglaFont' >স্বেচ্ছাসেবক হোন</Link>
              }
            </div>
          </div>
          <div className='flex flex-col lg:gap-5 md:gap-2 gap-2'>
            <div className='lg:flex md:flex grid grid-cols-1 items-center lg:gap-4 md:gap-2 gap-2'>
              <div className='joinTeamInnerBg lg:h-[100px] md:h-[100px] w-full lg:px-8 md:px-4 px-8 lg:py-5 md:py-0 py-5 flex items-center lg:gap-4 md:gap-2 gap-4'>
                <div className='bg-[#0082C4] rounded-full lg:p-4 md:p-3 p-4 lg:w-14 md:w-10 w-12 lg:h-14 md:h-10 h-12 flex justify-center items-center'>
                  <img src={Icon1} alt='Icon1' />
                </div>
                <div>
                  <p className='text-[#0082C4] lg:text-4xl md:text-3xl text-2xl font-bold flex items-center'>
                    <VisibilitySensor
                        partialVisibility
                        offset={{ bottom: 200 }}
                      >
                        {({ isVisible }) => (
                          <div>
                            {isVisible ? <CountUp end={5} duration={1} /> : 30}
                          </div>
                        )}
                      </VisibilitySensor>+
                  </p>
                  { language === 'en' ?
                    <p className='text-white text-[12px]'>Total Interventions</p>
                    :
                    <p className='text-white text-[12px] banglaFont'>মোট হস্তক্ষেপ</p>
                  }
                </div>
              </div>
              <div className='joinTeamInnerBg lg:h-[100px] md:h-[100px] w-full lg:px-8 md:px-4 px-8 lg:py-5 md:py-0 py-5 flex items-center lg:gap-4 md:gap-2 gap-4'>
                <div className='bg-[#F74F22] rounded-full lg:p-4 md:p-3 p-4 lg:w-14 md:w-10 w-12 lg:h-14 md:h-10 h-12 flex justify-center items-center'>
                  <img src={Icon2} alt='Icon2' />
                </div>
                <div>
                  <p className='text-[#F74F22] lg:text-4xl md:text-3xl text-2xl font-bold flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                      >
                      {({ isVisible }) => (
                          <div>
                          {isVisible ? <CountUp end={10} duration={1} /> : 30}
                          </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  { language === 'en' ?
                    <p className='text-white text-[12px]'>Contributors</p>
                    :
                    <p className='text-white text-[12px] banglaFont'>অবদানকারী</p>
                  }
                </div>
              </div>
            </div>
            <div className='lg:flex md:flex grid grid-cols-1 items-center lg:gap-4 md:gap-2 gap-2'>
              <div className='joinTeamInnerBg lg:h-[100px] md:h-[100px] w-full lg:px-8 md:px-4 px-8 lg:py-5 md:py-0 py-5 flex items-center lg:gap-4 md:gap-2 gap-4'>
                <div className='bg-[#F5B200] rounded-full lg:p-4 md:p-3 p-4 lg:w-14 md:w-10 w-12 lg:h-14 md:h-10 h-12 flex justify-center items-center'>
                  <img src={Icon3} alt='Icon3' />
                </div>
                <div>
                  <p className='text-[#F5B200] lg:text-4xl md:text-3xl text-2xl font-bold flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? <CountUp end={500} duration={2} /> : 30}
                        </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  { language === 'en' ?
                    <p className='text-white text-[12px]'>Our volunteers</p>
                    :
                    <p className='text-white text-[12px] banglaFont'>আমাদের স্বেচ্ছাসেবক গণ</p>
                  }
                </div>
              </div>
              <div className='joinTeamInnerBg lg:h-[100px] md:h-[100px] w-full lg:px-8 md:px-4 px-8 lg:py-5 md:py-0 py-5 flex items-center lg:gap-4 md:gap-2 gap-4'>
                <div className='bg-[#AB56FF] rounded-full lg:p-4 md:p-3 p-4 lg:w-14 md:w-10 w-12 lg:h-14 md:h-10 h-12 flex justify-center items-center'>
                  <img src={Icon4} alt='Icon4' />
                </div>
                <div>
                  <p className='text-[#AB56FF] lg:text-4xl md:text-3xl text-2xl font-bold flex items-center'>
                    <VisibilitySensor
                      partialVisibility
                      offset={{ bottom: 200 }}
                    >
                      {({ isVisible }) => (
                        <div>
                          {isVisible ? <CountUp end={5000} duration={3} /> : 30}
                        </div>
                      )}
                    </VisibilitySensor>+
                  </p>
                  { language === 'en' ?
                    <p className='text-white text-[12px]'>Families served</p>
                    :
                    <p className='text-white text-[12px] banglaFont'>পরিবেশিত পরিবার</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Review */}
      <section className='mb-16'>
        <Review />
      </section>

      <ScrollToTop></ScrollToTop>

      <Footer/>
    </div>
  );
};

export default MeetTheTeam