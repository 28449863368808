import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
// import Avatar from '../../assets/avatar.png';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
// import DeleteIcon from '../../assets/delete.png';
// import DeleteIcon from '../../assets/delete.svg';
import BaseUrl from '../BaseURL';
import { BsSearch } from 'react-icons/bs';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { IoSettingsOutline } from 'react-icons/io5';
// import { MdAdminPanelSettings } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import { BiHide, BiShow } from 'react-icons/bi';
import ScrollToTop from '../Common/ScrollToTop';
import { toast } from 'react-toastify';
// import JoditEditor from "jodit-react";
import { SiAddthis } from 'react-icons/si';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function ManagePublications() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();
  // Search
  const [searchTerm, setSearchTerm] = useState("");
  const [publications, setPublications] = useState([]);
  // const reversedPublications = publications.slice().reverse();
  // console.log(reversedPublications)
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const location = useLocation();
  const navigate = useNavigate();
  const [filteredItems, setFilteredItems] = useState(publications);

  useEffect(() => {
    const results = publications.filter(val =>
      (val.title || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (val.description || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    setFilteredItems(results);
    setCurrentPage(1);

  }, [searchTerm, publications]);
  
  const reversedPublications = filteredItems.slice().reverse();

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[]);

  // Get publications
  useEffect(()=>{
    Axios.get(`${BaseUrl}/publications`).then((response) => {
        setPublications(response.data);
    });
  },[]);

  // make Visible Publication
  const handleVisiblePublication = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updatePublicationVisibility`, { 
        visibility:"visible", 
        id: id 
      }).then(
          (response) => {
            setPublications(
                publications.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      visibility: val.visibility,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/publications`).then((response) => {
              setPublications(response.data);
            });
            toast.success("This publication is public now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  };
  // make Invisible Publication
  const handleInvisiblePublication = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      Axios.put(`${BaseUrl}/updatePublicationVisibility`, { 
        visibility:"invisible", 
        id: id 
      }).then(
          (response) => {
            setPublications(
                publications.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      visibility: val.visibility,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/publications`).then((response) => {
                setPublications(response.data);
            });
            toast.success("This publication is private now!");
          }
        );
    }else{
      toast.warn("You don't have sufficient privilege!");
    }
  } ;
  // Delete Publication
  const handleDeletePublication = (id) => {
    if(signedInUser?.role==="Admin" || signedInUser?.role==="Manager"){
      const proceed = window.confirm("Are you sure?");
      if(proceed){
        Axios.delete(`${BaseUrl}/publication/${id}`).then((response) => {
          setPublications(
            publications.filter((val) => {
              return val.id !== id;
            })
          );
          Axios.get(`${BaseUrl}/publications`).then((response) => {
            setPublications(response.data);
          });
          toast.success("Publication Deleted!");
        });
      }
    }else{
      toast.warn("You don't have sufficient privilege!")
    }
  };

  // Pagination
  // const itemsPerPage = 10;
  // const [currentPage, setCurrentPage] = useState(1);
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedItems = reversedPublications.slice(startIndex, endIndex);
  // const totalPages = Math.ceil(reversedPublications.length / itemsPerPage);
  // const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);
  const itemsPerPage = 10;
  const urlParams = new URLSearchParams(location.search);
  const pageFromURL = Number(urlParams.get('page')) || 1;
  // initialize currentPage with the value from URL
  const [currentPage, setCurrentPage] = useState(pageFromURL);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`?page=${page}`);
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const page = urlParams.get('page');
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [location, currentPage]);

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    const visiblePagesLimit = 4; // Adjust this number based on your preference

    if (totalPages <= visiblePagesLimit) {
      return Array.from(Array(totalPages).keys()).map((i) => i + 1);
    }

    const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
    const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);

    const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
    return visiblePages;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(reversedPublications.length / itemsPerPage);
  const paginatedItems = reversedPublications.slice(startIndex, endIndex);
  // const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);
  const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

  return (
    <div>
      <Helmet>
        <title>Manage Publications - Telementoring</title>
        <meta name='title' content='Manage Publications - Telementoring' />
        <meta name="description" content="Manage publication of Telementoring." />
        <meta name="keywords" content="telementoring, manage publication" />
      </Helmet>

      <div className="container mx-auto">
        <div >
          <div className='lg:hidden md:block hidden'>
            <div className='flex justify-end mx-5'>
              {/* { language === 'en' ?
                <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2'><SiAddthis/> Add Publication</Link>
                :
                <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/> নতুন পাব্লিকেশন</Link>
              } */}
              { language === 'en' ?
                <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add Publication</div>
                :
                <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/> নতুন পাব্লিকেশন</div>
              }
            </div>
          </div>
          <div className='md:flex items-center grid grid-cols-1 justify-between gap-5 px-5 py-4 rounded-2xl shadow-md'>
            <div className='md:hidden block'>
              <div className='flex justify-between items-center gap-3 mt-8'>
                { language === 'en' ?
                  <h2 className="text-xl font-semibold">Manage Publications</h2>
                  :
                  <h2 className="text-xl font-semibold banglaFont">ম্যানেজ পাব্লিকেশন</h2>
                }
                {/* { language === 'en' ?
                  <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2'><SiAddthis/> Add Publication</Link>
                  :
                  <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/> নতুন পাব্লিকেশন</Link>
                } */}
                { language === 'en' ?
                  <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add Publication</div>
                  :
                  <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all md:px-4 px-3 md:py-3 py-2 rounded-lg md:text-base text-xs text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/> নতুন পাব্লিকেশন</div>
                }
              </div>
            </div>

            { language === 'en' ?
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden">Manage Publications</h2>
              :
              <h2 className="text-2xl font-semibold md:text-left text-right lg:block md:block hidden banglaFont">ম্যানেজ পাব্লিকেশন</h2>
            }

            <div className='flex md:flex-row flex-col items-center gap-5'>
              <div className='lg:block hidden'>
                {/* { language === 'en' ?
                  <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2'><SiAddthis/> Add <span>Publication</span></Link>
                  :
                  <Link to='/newsfeed/add-publication' className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 banglaFont'><SiAddthis/> নতুন <span>পাব্লিকেশন</span></Link>
                } */}
                { language === 'en' ?
                  <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer'><SiAddthis/> Add <span>Publication</span></div>
                  :
                  <div onClick={() => navigate("/admin-settings/add-publication", { replace: true })} className='bg-[#0082C4] hover:bg-[#0083c4c8] transition-all px-4 py-3 rounded-lg md:text-base text-sm text-white font-semibold flex items-center gap-2 cursor-pointer banglaFont'><SiAddthis/> নতুন <span>পাব্লিকেশন</span></div>
                }
              </div>
              <div className='flex items-center w-full'>
                { language === 'en' ?
                  <input type="text" placeholder='Search Publication' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                  :
                  <input type="text" placeholder='সার্চ করুন' className="py-3 px-4 border border-blue-500 border-opacity-20 bg-[#FAFCFF] rounded-l-xl lg:w-96 md:w-80 w-full focus:outline-none banglaFont" onChange={(event)=>{
                    setSearchTerm(event.target.value);
                  }}/>
                }
                <label className='font-semibold text-xl text-[#0082C4] border border-l-0 border-blue-500 border-opacity-20 bg-[#ECF4FF] rounded-r-xl py-3.5 px-4'><BsSearch/></label>
              </div>
            </div>
          </div>
          { paginatedItems?.length < 1 ?
            <div>
              { language === 'en' ?
                <p className='text-center text-2xl font-semibold py-12'>No Publication found.</p>
                :
                <p className='text-center text-2xl font-semibold py-12 banglaFont'>কোনো পাব্লিকেশন পাওয়া যায়নি ।</p>
              }
            </div>
            :
            <div className="pt-4 overflow-x-auto">
              <div className="inline-block min-w-full lg:mx-0 md:mx-5 mx-0 shadow-md rounded-lg overflow-hidden border-x">
                <table className="min-w-full leading-normal">
                  <thead className='bg-[#F6FAFF]'>
                    { language === 'en' ?
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Index
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Title
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Short description
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          Post Time
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >Actions</th>
                      </tr>
                      :
                      <tr>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          ইনডেক্স
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          টাইটেল
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          সংক্ষিপ্ত বর্ণনা
                        </th>
                        <th
                          className="px-5 py-4 text-left text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >
                          পোস্টের সময়
                        </th>
                        <th
                          className="px-5 py-4 text-xs font-bold text-[#0082C4] uppercase tracking-wider"
                        >এ্যাকশন</th>
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {paginatedItems?.map((publication, index)=>{
                      return(
                        <tr key={publication?.id}>
                          <td className="px-5 py-5 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{index+1}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <div>
                              <p className="text-gray-900 font-semibold whitespace-no-wrap">
                              {publication && publication?.title && publication?.title.length >= 50 ? publication?.title?.slice(0,50) + "..." : publication?.title}
                              </p>
                            </div>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <p className="text-[#777B84] text-base my-5">{publication && publication?.description && publication?.description.length >= 50 ? publication?.description?.slice(0,50) + "..." : publication?.description}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{publication?.post_time}</p>
                          </td>
                          <td className="lg:px-5 md:px-1 px-2 py-5 text-sm flex items-center justify-center relative">
                            <div className='group flex flex-col gap-2'>
                              <div 
                                className='text-lg hover:text-white transition-all bg-white hover:bg-[#0082C4] border border-[#F1F7FF] rounded-full shadow-md lg:p-3 md:p-2 p-2 flex justify-center items-center cursor-pointer'
                              >
                                <IoSettingsOutline />
                              </div>
                              <div>
                                <div className="absolute pt-8 md:top-10 top-8 lg:-left-24 md:-left-24 -left-28 w-[170px]  hidden group-hover:block transition-all z-50">
                                  { language === 'en' ?
                                    <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                      <Link to={`/admin-settings/edit-publication/${publication?.id}`}  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" >
                                        <FaRegEdit />Edit Publication
                                      </Link>
                                      {
                                        publication?.visibility === "visible" ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleInvisiblePublication(publication?.id)}>
                                            <BiShow />Make Private
                                          </button>
                                        :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleVisiblePublication(publication?.id)}>
                                            <BiHide />Make Public
                                          </button>
                                      }
                                      <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeletePublication(publication?.id)}>
                                        <RiDeleteBin6Line />Delete
                                      </button>
                                    </div>
                                    :
                                    <div className='flex flex-col rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                                      <Link to={`/newsfeed/edit-publication/${publication?.id}`}  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" >
                                        <FaRegEdit />ইডিট পাব্লিকেশন
                                      </Link>
                                      {
                                        publication?.visibility === "visible" ?
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleInvisiblePublication(publication?.id)}>
                                            <BiShow />প্রাইভেট করুন
                                          </button>
                                        :
                                          <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleVisiblePublication(publication?.id)}>
                                            <BiHide />পাবলিক করুন
                                          </button>
                                      }
                                      <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeletePublication(publication?.id)}>
                                        <RiDeleteBin6Line />মুছে ফেলুন
                                      </button>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                      })
                    }
                  </tbody>
                </table>

                {/* Pagination button */}
                {/* <div className={`flex lg:justify-end md:justify-end justify-start my-8 mx-8`}>
                  <nav className="block">
                      <ul className="flex pl-0 rounded list-none gap-3">
                          <li>
                            <button
                              className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                              disabled={currentPage === 1}
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <CgChevronDoubleLeft />
                            </button>
                          </li>
                          {pageNumbers.map((page) => (
                            <li key={page}>
                              <button
                              className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                                  currentPage === page ? "font-bold bg-[#0082C4] text-white" : ""
                              }  focus:outline-none`}
                              onClick={() => handlePageChange(page)}
                              >
                              {page}
                              </button>
                            </li>
                          ))}
                          <li>
                                <button
                                  className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                                  disabled={currentPage === totalPages}
                                  onClick={() => handlePageChange(currentPage + 1)}
                                >
                                  <CgChevronDoubleRight />
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div> */}
                <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                  <nav className="block">
                    <ul className="flex pl-0 rounded list-none gap-3">
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === 1}
                          onClick={() => handlePageChange(Number(currentPage) - 1)}
                        >
                          <CgChevronDoubleLeft />
                        </button>
                      </li>
                      {visiblePageNumbers.map((page) => (
                        <li key={page}>
                          <button
                            className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                              Number(currentPage) === page ? "font-bold bg-[#0082C4] text-white" : ""
                            }  focus:outline-none`}
                            onClick={() => handlePageChange(page)}
                          >
                          {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === totalPages}
                          onClick={() => handlePageChange(Number(currentPage) + 1)}
                        >
                          <CgChevronDoubleRight />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default ManagePublications