import React, { useState, useEffect, useRef, useContext } from "react";
import Axios  from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsFacebook, BsTwitter, BsLinkedin } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FaEnvelope, FaComment, FaRegEdit } from 'react-icons/fa';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { SlOptionsVertical } from 'react-icons/sl';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ImNewspaper } from 'react-icons/im';
import BaseUrl from "../BaseURL";
import Avatar from '../../assets/avatar.png';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import Footer from "../Common/Footer";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { toast } from "react-toastify";
import PublicationCommentsReply from "./PublicationCommentsReply";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from "../GlobalState/UserContext";

const PublicationDetails = () => {
    const { language } = useContext(GlobalContext);
    const { signedInUser } = useUserContext();

    const { publicationId } = useParams();
    // const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { register, handleSubmit, reset } = useForm();
    const [active, setActive]=useState(0);
    // const [personalInfo, setPersonalInfo] = useState([]);
    // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
    const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
    const [publicationComments, setPublicationComments] = useState([]);
    const filteredComments = publicationComments?.filter((comment)=>comment?.publication_id === `${publicationId}`);
    // Edit
    const [editCommentId, setEditCommentId]=useState();
    const clickedPublicationComment = filteredComments?.find((comment)=>comment?.id === editCommentId);
    // popup
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [publication, setPublication] = useState({});
    const [url, setUrl] = useState('');
    //   get page url
    useEffect(() => {
        setUrl(window.location.href);
    }, []);
    // get Publication details
    useEffect(()=>{
        Axios.get(`${BaseUrl}/publication-details/${publicationId}`).then((response) => {
        setPublication(response.data);
        });
    },[publicationId]);
    // Get infos
    // useEffect(()=>{
    //     Axios.get(`${BaseUrl}/infos`).then((response) => {
    //     setPersonalInfo(response.data);
    //     });
    // },[]);
    // Get Publication Comments
    useEffect(()=>{
        Axios.get(`${BaseUrl}/publication-comments`).then((response) => {
            setPublicationComments(response.data);
        });
    },[]);
    // Add Publication Comment
    const onSubmit = data => {
        const user_id = signedInUser?.id;
        const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
        const authorImg = signedInUser?.profileImageUrl;
        const currentDate = new Date().toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
        const currentTime = new Date().toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
        const comment_time =currentTime +','+ ' '+ currentDate;
        if(signedInUser){
            Axios.post(`${BaseUrl}/addPublicationComment`, {
                comment: data.comment,
                author: author,
                authorImg: authorImg,
                comment_time: comment_time,
                user_id: user_id,
                publication_id: publicationId,
            }).then(() => {
                Axios.get(`${BaseUrl}/publication-comments`).then((response) => {
                    setPublicationComments(response.data);
                });
                reset();
                toast.success("Successfully posted!");
            });
        }else{
            toast.error("Please login!");
            navigate('/login');
        };
    };
    // Update Publication Comment
    const inputRefs = {
        newComment: useRef(null),
    };
    const handleUpdate = (e) => {
        e.preventDefault();
        const id = clickedPublicationComment?.id;
        const formData = {
            newComment: inputRefs.newComment.current.value,
        };

        Axios.put(`${BaseUrl}/updatePublicationComment`, { 
            comment:formData.newComment,
            id: id 
        }).then(
            (response) => {
                setPublicationComments(
                publicationComments.map((val) => {
                return val.id === id
                    ? {
                        id: val.id,
                        comment: val.newComment,
                    }
                    : val;
                })
            );
            Axios.get(`${BaseUrl}/publication-comments`).then((response) => {
                setPublicationComments(response.data);
            });
            setIsOpenPopup(false);
            toast.success("Updated!");
            }
        );
    };
    // Delete Blog Comment
    const handleDeletePublicationComment = (id) => {
        const proceed = window.confirm("Are you sure?");
        if(proceed){
            Axios.delete(`${BaseUrl}/publicationComment/${id}`).then((response) => {
                setPublicationComments(
                    publicationComments.filter((val) => {
                        return val.id !== id;
                    })
                );
                Axios.get(`${BaseUrl}/publication-comments`).then((response) => {
                    setPublicationComments(response.data);
                });
                toast.success("Deleted!");
            });
        }
    };
    // handle closing for outside clicking
    useEffect(() => {
        const handleDocumentClick = (event) => {
        const isInsideComponent = event.target.closest('.dropdown-closing') !== null;
        if (!isInsideComponent) {
            setActive(0);
        }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setActive]);
    //  Scroll to top, when user will be redirected in this page
    const location = useLocation();
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

    // Pagination
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = filteredComments.slice(startIndex, endIndex);
  
    const totalPages = Math.ceil(filteredComments.length / itemsPerPage);
    const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);

  return (
    <section>
        <Helmet>
            <title>Publication - Telementoring</title>
            <meta name='title' content='Publication - Telementoring' />
            <meta name="description" content="Explore the latest publications from Telementoring by the research collaborators." />
            <meta name="keywords" content="telementoring, publications, research" />
        </Helmet>

        <div className="max-w-7xl lg:mx-auto md:mx-5 mx-5 lg:px-8 py-12 md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-12">
            <div className="md:w-3/4 w-full">
                <section className="flex flex-col lg:gap-12 md:gap-8 gap-8 mb-20">
                    <div>
                        <p className="text-[#2E3033] font-bold md:text-3xl text-2xl">{publication?.title}</p>
                        <div className="md:flex justify-between grid grid-cols-1 gap-4 py-4 my-3 border-b border-b-[#0082C4] border-opacity-10">
                            <div className='rounded-md flex items-center gap-1 bg-[#F2F9FC] pr-3'>
                                <p className='text-base font-bold text-white rounded-md flex justify-center items-center px-2 py-2 bg-[#F74F22]'><ImNewspaper /></p>
                                <p className='text-[#0082C4] font-semibold text-xs px-2 uppercase'>{publication?.publishedLocation}</p>
                            </div>
                            <div className='flex'>
                                <p className='flex items-center gap-3 text-[#777B84] text-sm bg-[#F6FAFF] rounded-full px-4 py-1 border border-gray-300 border-solid backdrop-filter backdrop-blur'><span className='text-[#0082C4]'><AiOutlineCalendar/></span>{publication?.post_time}</p>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: publication?.description}}></div>
                        <div className="flex mt-5">
                          { language === 'en' ?
                            <a href={`${BaseUrl}${publication?.fileUrl}`} target='_blank' className="flex items-center text-[#0082C4] underline">More: [pdf]</a>
                            :
                            <a href={`${BaseUrl}${publication?.fileUrl}`} target='_blank' className="flex items-center text-[#0082C4] underline banglaFont">আরও: [পিডিএফ]</a>
                          }
                        </div>
                    </div>
                </section>

                {/* Comments */}
                <section id="comments">
                    { language === 'en' ?
                        <p className={`mb-5 flex justify-end ${filteredComments?.length > 1 ? 'block': 'hidden'}`}><span className="font-semibold text-xl">Comments ({filteredComments?.length})</span></p>
                        :
                        <p className={`mb-5 flex justify-end ${filteredComments?.length > 1 ? 'block': 'hidden'}`}><span className="font-semibold text-xl banglaFont">কমেন্টগুলো ({filteredComments?.length})</span></p>
                    }
                    {
                        paginatedItems?.map((singleComment) =>
                        <div key={singleComment?.id} className='justify-center flex flex-col gap-3 mb-5 border border-[#E3E6EA]  rounded-2xl'>
                            {/* Popup */}
                            {isOpenPopup && (
                                <div className="fixed z-10 inset-0 overflow-y-auto">
                                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                            <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
                                        </div>

                                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                        <div className="inline-block align-bottom bg-white w-full md:p-8 p-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                        <div>
                                            <form onSubmit={(e) => handleUpdate(e)} className='flex flex-col justify-center gap-4'>
                                            <textarea 
                                                placeholder='Write content' 
                                                className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF]' 
                                                rows="6" 
                                                ref={inputRefs.newComment}
                                                defaultValue={clickedPublicationComment?.comment} 
                                            />
                                            <div className='flex justify-between'>
                                                <div></div>
                                                <div className='flex gap-5'>
                                                    { language === 'en' ?
                                                        <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Cancel</button>
                                                        :
                                                        <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>বাতিল করুন</button>
                                                    }
                                                    { language === 'en' ?
                                                        <input type='submit' value='Update' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                                                        :
                                                        <input type='submit' value='আপডেট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                                                    }
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                            <div className="md:px-8 px-5 pb-5">
                                <div className="flex justify-between items-center md:py-5 py-3">
                                    <div className='flex items-center'>
                                        <div className="md:w-12 md:h-12 w-10 h-10 border-2 border-blue-100 rounded-full overflow-hidden flex justify-center items-center">
                                            { singleComment?.authorImg ?
                                                <div className='w-8 h-8 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${singleComment?.authorImg}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                                :
                                                <div className='w-8 h-8 rounded-full' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                            }
                                        </div>
                                        <div className="ml-3">
                                            {singleComment?.author ?
                                                <p className="text-[#0082C4] md:text-xl text-lg font-semibold whitespace-no-wrap">{singleComment?.author}</p>
                                                :
                                                <p className="text-[#0082C4] md:text-xl text-lg font-semibold whitespace-no-wrap">{singleComment?.name}</p>
                                            }
                                            <p className="text-[#A2AAB7] md:text-sm text-xs whitespace-no-wrap">{singleComment?.comment_time}</p>
                                        </div>
                                    </div>
                                    {
                                    signedInUser?.role==="Admin" || signedInUser?.role==="Manager" || author === singleComment?.author 
                                    ?
                                    <div className='relative dropdown-closing'>
                                        <button className='text-gray-500' onClick={() => {
                                          setActive(singleComment?.id)
                                          setEditCommentId(singleComment?.id);
                                        }}>
                                            <SlOptionsVertical />
                                        </button>
                                        <div className={`absolute right-0 mt-2 md:w-40 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${active === singleComment?.id ? 'block' : 'hidden'}`}>
                                        <div className="">
                                            { language === 'en' ?
                                                <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => setIsOpenPopup(true)}>
                                                    <FaRegEdit />Edit
                                                </button>
                                                :
                                                <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => setIsOpenPopup(true)}>
                                                    <FaRegEdit />সংস্করণ করুন
                                                </button>
                                            }

                                            { language === 'en' ?
                                                <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeletePublicationComment(singleComment?.id)}>
                                                    <RiDeleteBin6Line />Delete
                                                </button>
                                                :
                                                <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeletePublicationComment(singleComment?.id)}>
                                                    <RiDeleteBin6Line />মুছে ফেলুন
                                                </button>
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                    }
                                </div>
                                <div className='pl-12'>
                                    <p className='md:text-xl text-base mb-1'>{singleComment?.comment}</p>
                                </div>
                                <div>
                                    <PublicationCommentsReply signedInUser={signedInUser} singlePublicationId={publicationId} singleCommentId={singleComment?.id} userId={signedInUser?.id} author={author} authorImg={signedInUser?.profileImageUrl} name={singleComment?.name} />
                                </div>
                            </div>
                        </div>
                        )
                    }
                    {/* Pagination button */}
                    <div className={`flex justify-end my-8 mx-8 ${paginatedItems?.length >= 5 ? 'block' : 'hidden'}`}>
                    <nav className="block">
                        <ul className="flex pl-0 rounded list-none gap-3">
                        <li>
                            <button
                            className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <CgChevronDoubleLeft />
                            </button>
                        </li>
                        {pageNumbers.map((page) => (
                            <li key={page}>
                                <button
                                    className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                                        currentPage === page ? "font-bold bg-[#0082C4] text-white" : ""
                                    }  focus:outline-none`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </button>
                            </li>
                        ))}
                        <li>
                            <button
                                className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <CgChevronDoubleRight />
                            </button>
                        </li>
                        </ul>
                    </nav>
                    </div>
                    {/* Add Comment */}
                    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center gap-4 mt-8'>
                        <textarea placeholder='Write here...' className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF]' {...register("comment")} rows="6" required />
                        <div className='flex justify-between'>
                            <div></div>
                            <div className='flex gap-5'>
                                { language === 'en' ?
                                    <input type='submit' value='Comment' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                                    :
                                    <input type='submit' value='কমেন্ট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                                }
                            </div>
                        </div>
                    </form>
                </section>
            </div>

            <div className="md:w-1/4 w-full">
                <div className="sticky top-5 flex flex-col gap-10">
                    <div>
                        { language === 'en' ?
                            <p className="text-lg font-bold uppercase mb-4">Share</p>
                            :
                            <p className="text-lg font-bold uppercase mb-4 banglaFont">শেয়ার</p>
                        }
                        { language === 'en' ?
                            <div className="flex flex-col gap-3">
                                <EmailShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <FaEnvelope />Email
                                </EmailShareButton>
                                <FacebookShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-600 to-blue-400 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsFacebook />Facebook
                                </FacebookShareButton>
                                <TwitterShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsTwitter />Twitter
                                </TwitterShareButton>
                                <LinkedinShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-600 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsLinkedin />LinkedIn
                                </LinkedinShareButton>
                                <a href="#comments" className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md" ><FaComment />Comments</a>
                            </div>
                            :
                            <div className="flex flex-col gap-3">
                                <EmailShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <FaEnvelope />ইমেইল
                                </EmailShareButton>
                                <FacebookShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-600 to-blue-400 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsFacebook />ফেসবুক
                                </FacebookShareButton>
                                <TwitterShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsTwitter />টুইটার
                                </TwitterShareButton>
                                <LinkedinShareButton url={url} className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-600 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md">
                                    <BsLinkedin />লিংকডিন
                                </LinkedinShareButton>
                                <a href="#comments" className="bg-gradient-to-b hover:bg-gradient-to-t transition-all from-blue-400 to-blue-500 !px-4 lg:!py-3 md:!py-2 !py-3 !text-white flex items-center gap-3 rounded-md" ><FaComment />কমেন্টগুলো</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        
        <Footer/>
    </section>
  );
};

export default PublicationDetails;
