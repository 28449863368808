import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import AboutUs from './components/OurWork/AboutUs';
import VisionAndValues from './components/OurWork/VisionAndValues';
import MeetTheTeam from './components/OurWork/MeetTheTeam';
import Bangladesh from './components/GlobalPresence/Bangladesh';
import Nepal from './components/GlobalPresence/Nepal';
import FAQ from './components/Learn/FAQ';
import Publications from './components/Learn/Publications';
import Media from './components/Learn/Media';
import Login from './components/Social/Login';
import SignUp from './components/Social/SignUp';
import RequiredAuth from './components/Social/RequiredAuth';
// import UpdateProfile from './components/Social/UpdateProfile';
import Newsfeed from './components/Dashboard/Newsfeed';
import ManageUsers from './components/Dashboard/ManageUsers';
import ManageBlogs from './components/Dashboard/ManageBlogs';
import NotFound from './components/Common/NotFound';
import UpdateInfo from './components/UserInfo/UpdateInfo';
import Navbar from './components/Common/Navbar';
import GlobalPresence from './components/GlobalPresence/GlobalPresence';
import AddBlog from './components/Dashboard/AddBlog';
import NewsDetails from './components/Learn/NewsDetails';
import GetInvolved from './components/GetInvolved/GetInvolved';
import MyProfile from './components/UserInfo/MyProfile';
import BlogDetails from './components/Blogs/BlogDetails';
import PublicationDetails from './components/Learn/PublicationDetails';
import AddMedia from './components/Dashboard/AddMedia';
import ManageMedia from './components/Dashboard/ManageMedia';
import AddPublication from './components/Dashboard/AddPublication';
import ManagePublications from './components/Dashboard/ManagePublications';
import ContactUs from './components/Learn/ContactUs';
import PostAnnouncement from './components/Dashboard/PostAnnouncement';
import SendNewsletter from './components/Dashboard/SendNewsletter';
import Blogs from './components/Blogs/Blogs';
import Volunteers from './components/OurWork/Volunteers';
import Volunteer from './components/OurWork/Volunteer';
import UserInfo from './components/UserInfo/UserInfo';
import EditBlog from './components/Dashboard/EditBlog';
import EditPublication from './components/Dashboard/EditPublication';
import EditMedia from './components/Dashboard/EditMedia';
import Collaborators from './components/OurWork/Collaborators';
import Settings from './components/UserInfo/Settings';
import ResetPassword from './components/Social/ResetPassword';
// import Test from './components/Test/Test';
import AdminSettings from './components/UserInfo/AdminSettings';
import UpdateVolunteerInfo from './components/UserInfo/UpdateVolunteerInfo';
import SMTP from './components/Dashboard/SMTP';
import ExportUserData from './components/Dashboard/ExportUserData';
import { GlobalStateProvider } from './components/GlobalState/GlobalState';
import EmailChange from './components/Social/EmailChange';
import Unsubscribe from './components/Dashboard/Unsubscribe';
import ManageSubscribers from './components/Dashboard/ManageSubscribers';
import RequiredAdmin from './components/Social/RequiredAdmin';

function App() {
  return (
    <div>
      <GlobalStateProvider>
        <Navbar />
        
        <Routes>
          <Route path='/' element={<Home></Home>} /> 

          {/* Out Work routes */}
          <Route path='/about-us' element={<AboutUs></AboutUs>} />
          <Route path='/vision-and-values' element={<VisionAndValues></VisionAndValues>} />
          <Route path='/meet-the-team' element={<MeetTheTeam></MeetTheTeam>} />
          <Route path='/collaborators' element={<Collaborators></Collaborators>} />
          <Route path='/volunteers' element={<Volunteers />} />
          <Route path='/volunteer-profile/:volunteerId' element={<Volunteer />} />
          <Route path='/volunteer-profile-update/:volunteerId' element={<UpdateVolunteerInfo />} />

          {/* Global Presence routes */}
          <Route path='/bangladesh' element={<Bangladesh></Bangladesh>} />
          <Route path='/nepal' element={<Nepal></Nepal>} />
          <Route path='/global-presence' element={<GlobalPresence/>} />

          {/* Learn routes */}
          <Route path='/faq' element={<FAQ></FAQ>} />
          <Route path='/publications' element={<Publications></Publications>} />
          <Route path='/publication-details/:publicationId' element={<PublicationDetails/>} />
          <Route path='/media' element={<Media></Media>} />
          <Route path='/media-details/:newsId' element={<NewsDetails/>} />
          <Route path='/blogs' element={<Blogs/>} />
          <Route path='/blog-details/:blogsId' element={<BlogDetails/>} />
          <Route path='/contact-us' element={<ContactUs/>} />

          <Route path='/get-involved' element={<GetInvolved/>} />

          {/* Log in routes */}
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/change-email' element={<EmailChange />} />
          {/* <Route path='/update-personal-info' element={<UpdateProfile />} /> */}

          {/* Admin panel & Newsfeed */}
          <Route path='admin-settings' element={<RequiredAuth><RequiredAdmin><AdminSettings /></RequiredAdmin></RequiredAuth>} >
          {/* <Route path='admin-settings' element={<RequiredAuth><AdminSettings /></RequiredAuth>} > */}
            <Route index element={<ManageUsers />} />
            <Route path='/admin-settings/manage-users' element={<ManageUsers />} />
            <Route path='/admin-settings/manage-blogs' element={<ManageBlogs />} />
            <Route path='/admin-settings/manage-media' element={<ManageMedia />} />
            <Route path='/admin-settings/edit-media/:mediaId' element={<EditMedia />} />
            <Route path='/admin-settings/manage-publication' element={<ManagePublications />} />
            <Route path='/admin-settings/manage-subscribers' element={<ManageSubscribers />} />
            <Route path='/admin-settings/add-blog' element={<AddBlog/>} />
            <Route path='/admin-settings/edit-blog/:blogId' element={<EditBlog />} />
            <Route path='/admin-settings/add-media' element={<AddMedia/>} />
            <Route path='/admin-settings/add-publication' element={<AddPublication/>} />
            <Route path='/admin-settings/edit-publication/:publicationId' element={<EditPublication />} />
            <Route path='/admin-settings/post-announcement' element={<PostAnnouncement/>} />
            <Route path='/admin-settings/send-newsletter' element={<SendNewsletter />} />
            <Route path='/admin-settings/smtp' element={<SMTP />} />
            <Route path='/admin-settings/export-user-data' element={<ExportUserData />} />
          </Route>

          <Route path='/newsfeed' element={<RequiredAuth><Newsfeed /></RequiredAuth>}></Route>

          {/* User profile */}
          <Route path='/user-info' element={<RequiredAuth><UserInfo /></RequiredAuth>}></Route>
          <Route path='/update-info' element={<RequiredAuth><UpdateInfo/></RequiredAuth>}></Route>
          <Route path='/my-profile' element={<RequiredAuth><MyProfile/></RequiredAuth>}></Route>
          <Route path='/user-settings' element={<RequiredAuth><Settings/></RequiredAuth>}></Route>
          <Route path='/admin-settings' element={<RequiredAuth><AdminSettings /></RequiredAuth>}></Route>

          <Route path='/unsubscribe/:hashed_email_params' element={<Unsubscribe />}></Route>
          
          {/* Not Found */}
          <Route path='*' element={<NotFound></NotFound>} />
        </Routes>
      </GlobalStateProvider>

      {/* <Footer/> */}
    </div>
  );
}

export default App;
