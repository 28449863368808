import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import BaseUrl from '../BaseURL';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from '../Common/ScrollToTop';
import { BiEdit } from 'react-icons/bi';
import { FiLogOut } from 'react-icons/fi';
import { TbNews } from 'react-icons/tb';
import { signOut } from 'firebase/auth';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';
// import { useUserContext } from '../GlobalState/UserContext';

function MyProfile() {
    const { language } = useContext(GlobalContext);
    // const { signedInUser, signedInUserInfos } = useUserContext();

    function isBangla(text) {
        const banglaCharRegex = /[\u0980-\u09FF]/;
        return banglaCharRegex.test(text);
    }

    const [user] = useAuthState(auth);
    const logout = () => {
        signOut(auth);
    };
    const [userName, setUserName]=useState("");
    const [personalInfo, setPersonalInfo] = useState([]);
    const [userInfos, setUserInfos] = useState([]);
    const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);
    const signedInUserInfos = userInfos.find(userInfo => userInfo.user_id === signedInUser?.id);
    // console.log(signedInUserInfos);
    const location = useLocation();

    useEffect(()=>{
        setUserName(user?.displayName)
    },[user?.displayName]);

    // Get users
    useEffect(()=>{
        Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
        });
    },[]);

    // Get user_infos
    useEffect(()=>{
      Axios.get(`${BaseUrl}/userInfos`).then((response) => {
        setUserInfos(response.data);
      });
    },[]);
    
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

    const date = new Date(signedInUserInfos?.birthDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    // console.log(formattedDate);
    
  return (
    <div>
        <Helmet>
            <title>My Profile - Telementoring</title>
            <meta name='title' content='My Profile - Telementoring' />
            <meta name="description" content="View and edit your profile information for the Telementoring platform. " />
            <meta name="keywords" content="telementoring, my profile, personal information" />
        </Helmet>
        
        
        {/* Hero */}
        <div className='h-44 flex justify-center md:items-center' style={{"backgroundImage": `url(${ProfileBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize': 'cover'}}>
            { language === 'en' ?
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl'>My Profile</h2>
                :
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl banglaFont'>আমার প্রোফাইল</h2>
            }
        </div>

        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 lg:flex md:flex grid grid-cols-1 lg:gap-12 md:gap-5 gap-5 mb-12 relative'>
            <div className='lg:w-[25%] md:w-[30%] w-[100%] -mt-12 md:px-0 px-5'>
                <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-8 shadow-md sticky top-20'>
                    <div className='flex justify-center lg:-mt-20 -mt-[4.5rem]'>
                        {/* <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center'>
                            { signedInUser?.profileImageUrl ?
                                <img className='w-24 h-24 rounded-full' src={`${BaseUrl}${signedInUser?.profileImageUrl}`} alt="" referrerPolicy='no-referrer' />
                                :
                                <img className='w-full rounded-full' src={Avatar} alt="" referrerPolicy='no-referrer' />
                            }
                        </div> */}
                        { signedInUser?.profileImageUrl ?
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${signedInUser?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                        }
                    </div>
                    <div className='mt-2' >
                        {signedInUser?.firstName
                        ?
                            <p className='text-xl font-bold text-center'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                        :
                            <p className='text-xl font-bold text-center'>{userName}</p>
                        }
                        <p className={`text-base text-center text-[#777B84] ${isBangla(signedInUser?.bio) ? 'banglaFont' : ''}`}>{signedInUser?.bio}</p>
                    </div>
                    { language === 'en' ?
                        <div className='flex flex-col gap-3 mt-5 pt-5 border-t border-t-[#F0F5FB]'>
                            <Link to='/update-info' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2'><span className='text-[#0082C4]'><BiEdit /></span>Edit Profile</Link>
                            <Link to='/newsfeed' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2'><span className='text-[#0082C4]'><TbNews /></span>Newsfeed</Link>
                            <Link to='' 
                            className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2' 
                            onClick={logout}>
                                <span className='text-[#0082C4]'><FiLogOut /></span>
                                Log out
                            </Link>
                        </div>
                        :
                        <div className='flex flex-col gap-3 mt-5 pt-5 border-t border-t-[#F0F5FB]'>
                            <Link to='/update-info' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2 banglaFont'><span className='text-[#0082C4]'><BiEdit /></span>ইডিট প্রোফাইল</Link>
                            <Link to='/newsfeed' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2 banglaFont'><span className='text-[#0082C4]'><TbNews /></span>নিউজফিড</Link>
                            <Link to='' className='bg-[#F6FAFF] hover:bg-[#edf5ff] transition-all rounded-lg px-4 py-2 text-[#2E3033] text-base font-semibold flex items-center gap-2 banglaFont' 
                            onClick={logout}>
                                <span className='text-[#0082C4]'><FiLogOut /></span>
                                লগ আউট
                            </Link>
                        </div>
                    }
                </div>
            </div>
            { language === 'en' ?
                <div className='lg:w-[75%] md:w-[70%] w-[100%] lg:px-0 md:px-0 px-5 pt-10 flex flex-col gap-8'>
                    {/* Personal Information */}
                    <div>
                        <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                            <h2 className='text-[#0082C4] text-xl font-semibold'>Personal Information</h2>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Name</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Email</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.email ? signedInUser?.email : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Date of Birth</p>
                                <p className='text-[#777B84] text-base'>{formattedDate}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Gender</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.gender ? signedInUserInfos?.gender : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-b md:border-b-0 md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Nationality</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.nationality ? signedInUserInfos?.nationality : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>District, state, or province</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.district ? signedInUserInfos?.district : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Subdistrict, city or town</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.city ? signedInUserInfos?.city : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>How would you classify your family&apos;s economic status?</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.economicStatus ? signedInUserInfos?.economicStatus : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-r-[#F0F5FB] border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Mobile Number</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.mobile ? signedInUserInfos?.mobile : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold'>Where did you primarily grow up during your childhood and adolescence (i.e., before 18 years old)?</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.background ? signedInUserInfos?.background : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold'>Profile Type</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.profileType ? signedInUser?.profileType : 'Empty'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex flex-col gap-8 ${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                        {/* Academic Information */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Academic Information</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>What is your highest level of educational attainment?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.studyLevel ? signedInUserInfos?.studyLevel : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Name of the institution that you obtained your highest level of education</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.institute ? signedInUserInfos?.institute : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Area of study (if you have attended college)</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.studyArea ? signedInUserInfos?.studyArea : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Skills / Experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Skills / Experience</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Your English fluency/proficiency is:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.englishProficiency ? signedInUserInfos?.englishProficiency : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Have you had any experience mentoring or tutoring others?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.mentoringExperience ? signedInUserInfos?.mentoringExperience : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Tutoring Experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Tutoring Experience</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>How many years of tutoring experience do you have?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.tutoringExperience ? signedInUserInfos?.tutoringExperience : 'Empty'}</p>
                                </div>
                                {/* <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'></div> */}
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>What grade level(s) of students have you tutored?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.tutoredStudentsLevel ? signedInUserInfos?.tutoredStudentsLevel : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Volunteering experience</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Do you have any volunteering experience?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringExperience ? signedInUserInfos?.volunteeringExperience : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>What organization(s) have you volunteered for?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeredOrganizations ? signedInUserInfos?.volunteeredOrganizations : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>Please provide some details of the volunteering activities/services that you have given:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.detailActivities ? signedInUserInfos?.detailActivities : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Other experiences */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Other experiences</h2>
                            </div>
                            <div className=' border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-6 px-8'>
                                    <p className='text-[#2E3033] text-base font-semibold'>If you have any other relevant experiences, please list them here:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.experienceDescription ? signedInUserInfos?.experienceDescription : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering (or Mentoring) preferences */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold'>Volunteering (or Mentoring) preferences</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>What is your area of interest for volunteering or mentoring?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.areaOfInterest ? signedInUserInfos?.areaOfInterest : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold'>How many hours would you prefer to participate in volunteering/mentoring activities?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringActivities ? signedInUserInfos?.volunteeringActivities : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='lg:w-[75%] md:w-[70%] w-[100%] lg:px-0 md:px-0 px-5 pt-10 flex flex-col gap-8'>
                    {/* Personal Information */}
                    <div>
                        <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                            <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>ব্যক্তিগত তথ্য</h2>
                        </div>
                        <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>নাম</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>ইমেইল</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.email ? signedInUser?.email : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>জন্ম তারিখ</p>
                                <p className='text-[#777B84] text-base'>{formattedDate}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>লিঙ্গ</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.gender ? signedInUserInfos?.gender : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-b md:border-b-0 md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>জাতীয়তা</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.nationality ? signedInUserInfos?.nationality : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>জেলা</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.district ? signedInUserInfos?.district : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>উপজেলা</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.city ? signedInUserInfos?.city : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার পরিবারের অর্থনৈতিক অবস্থা</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.economicStatus ? signedInUserInfos?.economicStatus : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col md:border-r border-r-[#F0F5FB] border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>মোবাইল</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.mobile ? signedInUserInfos?.mobile : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি প্রাথমিকভাবে আপনার শৈশব এবং কৈশোরকালে (অর্থাৎ, 18 বছর বয়সের আগে) কোথায় বড় হয়েছেন?</p>
                                <p className='text-[#777B84] text-base'>{signedInUserInfos?.background ? signedInUserInfos?.background : 'Empty'}</p>
                            </div>
                            <div className='py-3 md:px-8 px-5 flex flex-col'>
                                <p className='text-[#2E3033] text-base font-semibold banglaFont'>প্রোফাইলের ধরণ</p>
                                <p className='text-[#777B84] text-base'>{signedInUser?.profileType ? signedInUser?.profileType : 'Empty'}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex flex-col gap-8 ${signedInUser?.role === 'Manager' || signedInUser?.role === 'Partner' || signedInUser?.role === 'Admin' ? 'hidden' : 'block'}`}>
                        {/* Academic Information */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>একাডেমিক তথ্য</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার শিক্ষাগত অর্জনের সর্বোচ্চ স্তর কি?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.studyLevel ? signedInUserInfos?.studyLevel : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি আপনার সর্বোচ্চ স্তরের শিক্ষা অর্জন করেছেন এমন প্রতিষ্ঠানের নাম</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.institute ? signedInUserInfos?.institute : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>অধ্যয়নের ক্ষেত্র (যদি আপনি কলেজে পড়ে থাকেন)</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.studyArea ? signedInUserInfos?.studyArea : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Skills / Experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>দক্ষতা/অভিজ্ঞতা</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার ইংরেজি দক্ষতা:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.englishProficiency ? signedInUserInfos?.englishProficiency : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার কি অন্যদের পরামর্শ দেওয়ার বা টিউটর করার অভিজ্ঞতা আছে?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.mentoringExperience ? signedInUserInfos?.mentoringExperience : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Tutoring Experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>টিউটরিং অভিজ্ঞতা</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার কত বছরের টিউটরিং অভিজ্ঞতা আছে?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.tutoringExperience ? signedInUserInfos?.tutoringExperience : 'Empty'}</p>
                                </div>
                                {/* <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'></div> */}
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি ছাত্রদের কোন গ্রেড স্তরগুলি টিউটরিং করেছেন?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.tutoredStudentsLevel ? signedInUserInfos?.tutoredStudentsLevel : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering experience */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>স্বেচ্ছাসেবক অভিজ্ঞতা</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border-r md:border-b-0 border-b md:border-r-[#F0F5FB] border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার কোনো স্বেচ্ছাসেবক অভিজ্ঞতা আছে?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringExperience ? signedInUserInfos?.volunteeringExperience : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি কোন সংস্থায় স্বেচ্ছাসেবক ছিলেন?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeredOrganizations ? signedInUserInfos?.volunteeredOrganizations : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি যে স্বেচ্ছাসেবী কার্যক্রম/পরিষেবা দিয়েছেন তার কিছু বিশদ বিবরণ দিন:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.detailActivities ? signedInUserInfos?.detailActivities : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Other experiences */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>অন্যান্য অভিজ্ঞতা</h2>
                            </div>
                            <div className=' border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-6 px-8'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনার যদি অন্য কোন প্রাসঙ্গিক অভিজ্ঞতা থাকে, অনুগ্রহ করে সেগুলি এখানে তালিকাভুক্ত করুন:</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.experienceDescription ? signedInUserInfos?.experienceDescription : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                        {/* Volunteering (or Mentoring) preferences */}
                        <div>
                            <div className='bg-[#F6FAFF] py-3 md:px-8 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                                <h2 className='text-[#0082C4] text-xl font-semibold banglaFont'>স্বেচ্ছাসেবী (বা পরামর্শ) পছন্দ</h2>
                            </div>
                            <div className='grid md:grid-cols-2 grid-cols-1 border border-blue-400 border-opacity-20 rounded-b-2xl'>
                                <div className='py-3 md:px-8 px-5 flex flex-col md:border md:border-l-0 border-b border-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>স্বেচ্ছাসেবক বা পরামর্শদানের জন্য আপনার আগ্রহের ক্ষেত্র কী?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.areaOfInterest ? signedInUserInfos?.areaOfInterest : 'Empty'}</p>
                                </div>
                                <div className='py-3 md:px-8 px-5 flex flex-col border-b md:border-b-0 border-b-[#F0F5FB]'>
                                    <p className='text-[#2E3033] text-base font-semibold banglaFont'>আপনি কত ঘন্টা স্বেচ্ছাসেবী/পরামর্শদান কার্যক্রমে অংশগ্রহণ করতে পছন্দ করবেন?</p>
                                    <p className='text-[#777B84] text-base'>{signedInUserInfos?.volunteeringActivities ? signedInUserInfos?.volunteeringActivities : 'Empty'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <ScrollToTop></ScrollToTop>
        <Footer/>
    </div>
  );
};

export default MyProfile