import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function EditMedia() {
  const { language } = useContext(GlobalContext);
  const { signedInUser } = useUserContext();

  const { mediaId } = useParams();
  // const [user] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const [media, setMedia] = useState([]);
  const [singleMedia, setSingleMedia] = useState({});

  // Uploaded file
  const [file, setFile] = useState(null);

  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])
  
  // Get Media
  useEffect(()=>{
    Axios.get(`${BaseUrl}/media`).then((response) => {
        setMedia(response.data);
    });
  },[]);
  // get Media details
  useEffect(()=>{
      Axios.get(`${BaseUrl}/edit-media/${mediaId}`).then((response) => {
        setSingleMedia(response.data);
      });
  },[mediaId]);

  // Update Media
  const inputRefs = {
    newTitle: useRef(null),
    newExternalLink: useRef(null),
    newDescription: useRef(null),
  };
  const handleMediaUpdate = async e => {
      e.preventDefault();
      const formDataOfMedia = {
          newTitle: inputRefs.newTitle.current.value,
          newExternalLink: inputRefs.newExternalLink.current.value,
          newDescription: inputRefs.newDescription.current.value,
      };
      let formData = new FormData();
      formData.append("id", mediaId);
      formData.append("title", formDataOfMedia.newTitle);
      formData.append("externalLink", formDataOfMedia.newExternalLink);
      formData.append("description", formDataOfMedia.newDescription);

      if (file !== null) {
          formData.append("mediaImg", file);
      }
      if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
        await Axios.put(`${BaseUrl}/update-media`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        }).then(() => {
          Axios.get(`${BaseUrl}/media`).then((response) => {
            setMedia(response.data);
          });
        });
        toast.success("Successfully Submitted!");
      }else{
        toast.error("You don't have sufficient privilege!");
      }
  };

  return (
    <div>
      <Helmet>
        <title>Edit Media - Telementoring</title>
        <meta name='title' content='Edit Media - Telementoring' />
        <meta name="description" content="Edit media of Telementoring." />
        <meta name="keywords" content="telementoring, edit media" />
      </Helmet>
      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
        <div className='flex justify-between items-center mb-5 md:mt-0 mt-10'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Edit Media</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>ইডিট মিডিয়া</p>
            }
            <div className="relative inline-block group">
              <Link to='/admin-settings/manage-media?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link>
              { language === 'en' ?
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Close</div>
                :
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">বন্ধ</div>
              }
            </div>
          </div>
          <div>
            <div>
              { language === 'en' ?
                <form onSubmit={(e) => handleMediaUpdate(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Title
                      </label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={singleMedia?.title}
                          placeholder='Write here' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">
                        Upload Cover Photo
                      </label>
                      <input
                        type="file"
                        name="mediaImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <input 
                      type="text" 
                      ref={inputRefs.newExternalLink}
                      defaultValue={singleMedia?.externalLink}
                      placeholder='Media link (https://example.com)' 
                      className='border border-blue-500 border-opacity-20 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] w-full' 
                  />
                  <textarea 
                      ref={inputRefs.newDescription}
                      defaultValue={singleMedia?.description}
                      placeholder='Short description...' 
                      className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069]' rows="6" 
                  />

                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='Submit Media' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    </div>
                  </div>
                </form>
                :
                <form onSubmit={(e) => handleMediaUpdate(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">টাইটেল</label>
                      <input 
                          type="text" 
                          ref={inputRefs.newTitle}
                          defaultValue={singleMedia?.title}
                          placeholder='এখানে লিখুন' 
                          className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' 
                      />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">আপলোড কভার ফটো</label>
                      <input
                        type="file"
                        name="mediaImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <input 
                      type="text" 
                      ref={inputRefs.newExternalLink}
                      defaultValue={singleMedia?.externalLink}
                      placeholder='মিডিয়া লিংক (https://example.com)' 
                      className='border border-blue-500 border-opacity-20 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] w-full banglaFont' 
                  />
                  <textarea 
                      ref={inputRefs.newDescription}
                      defaultValue={singleMedia?.description}
                      placeholder='সংক্ষিপ্ত বর্ণনা...' 
                      className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#5d6069] banglaFont' rows="6" 
                  />

                  <div className='flex justify-between'>
                    <div></div>
                    <div className='flex gap-5'>
                      {/* <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button> */}

                      <input type="submit" value='সাবমিট মিডিয়া' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default EditMedia