import { createContext, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import Axios from "axios";
import BaseUrl from "../BaseURL";

const UserContext = createContext();

export function UserContextProvider({ children }) {
    const [user, loadingAuth] = useAuthState(auth);
    const [userInfos, setUserInfos] = useState([]);
    const [personalInfo, setPersonalInfo] = useState([]);
    // Get infos
    useEffect(() => {
        Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
        });
    }, []);
    // Get user_infos
    useEffect(()=>{
        Axios.get(`${BaseUrl}/userInfos`).then((response) => {
            setUserInfos(response.data);
        });
    },[]);

    const signedInUser = personalInfo?.find((person) => person?.userEmail === user?.email);
    const signedInUserInfos = userInfos?.find(userInfo => userInfo?.user_id === signedInUser?.id);

    const value = { signedInUser, signedInUserInfos, loadingAuth, personalInfo, userInfos };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    return useContext(UserContext);
}
