import React, { useEffect, useState } from 'react'
// import Navbar from '../Common/Navbar'
import { useForm } from 'react-hook-form';
import { useAuthState, useCreateUserWithEmailAndPassword, useSignInWithGoogle, useUpdateProfile } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../Common/Loading';
import Axios from 'axios';
import BaseUrl from '../BaseURL';
import sha256 from 'crypto-js/sha256';
import Footer from '../Common/Footer';
import {Helmet} from "react-helmet";
import { BsArrowLeft } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useUserContext } from '../GlobalState/UserContext';

function SignUp() {
    // const { personalInfo } = useUserContext();
    const [personalInfo, setPersonalInfo] = useState([]);
    const [userAuth, setUserAuth] = useState(null);
    const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const roleType = new URLSearchParams(location.search).get('type');
    const [ createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(auth, {sendEmailVerification: true});
    const [updateProfile, updating, updateError] = useUpdateProfile(auth);

    // Get infos
    useEffect(() => {
        Axios.get(`${BaseUrl}/infos`).then((response) => {
            setPersonalInfo(response.data);
        });
    }, []);

    var errorCode = error?.code;
    var errorMessage = error?.message;
    if (errorCode === 'auth/email-already-in-use') {
      errorMessage = 'Already a user. Please login.';
    }
    var gErrorCode = gError?.code;
    var gErrorMessage = gError?.message;
    if (gErrorCode === 'auth/popup-closed-by-user') {
        gErrorMessage = 'Popup closed';
    }
    let signInError;

    let from = location.state?.from?.pathname || "/user-info";

    if(user || gUser){
        navigate(from, { replace: true });
    };
    if (error || gError || updateError) {
        signInError = <small className='text-red-500'>{errorMessage || gErrorMessage}</small>
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          setUserAuth(user);
        });
    
        return () => {
          unsubscribe();
        };
    }, []);

    // sign In With Google
    useEffect(() => {
        const handleUserSignIn = async () => {
          if (userAuth) {
            const { displayName, email, uid } = userAuth;
            let lastName = displayName?.split(" ")[1];
            if(!displayName?.split(" ")[1]){
                lastName = '';
            }
            const alreadyExist = personalInfo?.find((person) => person?.userEmail === email);

            // Save the user's role based on the type parameter
            let role = "Volunteer";
            if (roleType === "partner") {
                role = "Partner";
            } else if (roleType === "volunteer") {
                role = "Volunteer";
            }

            const min = 100000;
            const max = 999999;
            const newRandomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
            const hashedNumber = sha256(newRandomNumber)?.toString();
        
            if (alreadyExist) {
                // toast.warn("Already signed up with that email!");
                console.log('already exist.');
            } else {
                Axios.post(`${BaseUrl}/addUser`, {
                firstName: displayName?.split(" ")[0],
                lastName: lastName,
                password: hashedNumber,
                email: email,
                userEmail: email,
                role: role,
                profileType: 'Private',
                uid: uid,
                }).then((response) =>{
                    Axios.get(`${BaseUrl}/infos`).then((response) => {
                        setPersonalInfo(response.data);
                    });
                });
            }
          }
        };
    
        if (userAuth && !loading) {
          handleUserSignIn();
        }
    }, [userAuth, loading]);
    
    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = async data => {
        const { email, password, firstName, lastName } = data;
        const { user } = await createUserWithEmailAndPassword(email, password);
    
        await updateProfile({ displayName: firstName });
    
        const hashedPassword = sha256(password).toString();
    
        const alreadyExist = personalInfo?.find((person) => person?.userEmail === email);
    
        let role = 'Volunteer';
        if (roleType === 'partner') {
            role = 'Partner';
        } else if (roleType === 'volunteer') {
            role = 'Volunteer';
        }
    
        if(alreadyExist){
            toast.warn("Already signed up with that email!");
        }else{
            await Axios.post(`${BaseUrl}/addUser`, {
                firstName: firstName,
                lastName: lastName,
                password: hashedPassword,
                email: email,
                userEmail: email,
                role: role,
                profileType: 'Private',
                uid: user?.uid,
            });
            toast.success("Successfully signed up!");
        };
    };

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

    if (loading || gLoading || updating) {
        return <Loading></Loading>
    };

  return (
    <div>
        <Helmet>
            <title>Sign Up - Telementoring</title>
            <meta name='title' content='Sign Up - Telementoring' />
            <meta name="description" content="Sign Up to the Telementoring platform to get some of our special features." />
            <meta name="keywords" content="telementoring, sign up" />
        </Helmet>
        
        <div className='bg-blue-50'>
            <div className='max-w-7xl md:mx-auto mx-5 lg:px-8 md:pt-28 pt-12 md:pb-40 pb-12 flex justify-center'>
                { !userAuth ?
                    <div className='bg-white md:p-8 p-5 rounded-2xl shadow-xl border hover:border-blue-300 transition-all md:w-1/2 w-full'>
                        <p className='text-center font-bold text-2xl mb-3'>Sign Up</p>
                        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-3' action='/users' method='post'>
                            <label>
                                <span className='font-semibold'>First Name<span className='text-[#F74F22] font-bold'>*</span></span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-2 py-2 rounded-lg'
                                type="text" placeholder="First Name" name='firstName'
                                {...register("firstName", {
                                    required: {
                                        value: true,
                                        message: 'First Name is Required'
                                    }
                                })}
                            />
                            <label>
                                {errors.name?.type === 'required' && <span className="text-red-500">{errors.name.message}</span>}
                            </label>

                            <label>
                                <span className='font-semibold'>Last Name</span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-2 py-2 mb-3 rounded-lg'
                                type="text" placeholder="Last Name" name='lastName'
                                {...register("lastName")}
                            />

                            <label>
                                <span className='font-semibold'>Email<span className='text-[#F74F22] font-bold'>*</span></span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-2 py-2 rounded-lg'
                                type="email" placeholder="Your Email" name='email'
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: 'Email is Required'
                                    },
                                    pattern: {
                                        value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                        message: 'Provide a valid Email'
                                    }
                                })}
                            />
                            <label>
                                {errors.email?.type === 'required' && <span className="text-red-500">{errors.email.message}</span>}
                                {errors.email?.type === 'pattern' && <span className="text-red-500">{errors.email.message}</span>}
                            </label>

                            <label>
                                <span className='font-semibold'>Password<span className='text-[#F74F22] font-bold'>*</span></span>
                            </label>
                            <input className='border border-gray-200 outline-blue-200 px-2 py-2 rounded-lg'
                                type="password" placeholder="Password" name='password'
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: 'Password is Required'
                                    },
                                    minLength: {
                                        value: 6,
                                        message: 'Must be 6 characters or longer'
                                    }
                                })}
                            />
                            <label>
                                {errors.password?.type === 'required' && <span className="text-red-500">{errors.password.message}</span>}
                                {errors.password?.type === 'minLength' && <span className="text-red-500">{errors.password.message}</span>}
                            </label>
                            
                            <p className='text-center'>{signInError}</p>

                            <input type="submit" value='Sign Up' className='cursor-pointer w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-[#0082C4] rounded-md shadow hover:bg-[#0083c4e2] focus:outline-none focus:ring-blue-200 focus:ring-4'/>
                        </form>
                        <p className='mt-3 md:text-base text-sm'>Already have an account? <Link className='text-[#0082C4] hover:text-[#F74F22] transition-all font-semibold' to='/login'>Please login</Link></p>

                        {/* Sign Up with Google */}
                        <div className="flex flex-col space-y-5">
                            <span className="flex items-center justify-center space-x-2 mt-4">
                                <span className="h-px bg-gray-400 w-full"></span>
                                <span className="font-normal text-gray-500">or</span>
                                <span className="h-px bg-gray-400 w-full"></span>
                            </span>
                            <button
                                onClick={handleGoogleSignIn}
                                className="cursor-pointer px-4 py-2 text-lg font- transition-colors duration-300 border border-[#0082C4] text-[#0082C4] rounded-md shadow hover:bg-[#0082C4] hover:text-white focus:outline-none w-full"
                            >
                                Continue with Google
                            </button>
                        </div>
                    </div>
                    :
                    <div className='h-[60vh] flex flex-col gap-4 justify-center items-center'>
                        <p className='md:text-4xl text-2xl font-bold text-[#0082C4]'>Already logged in!</p>
                        <Link to='/' className='border border-[#0082C4] text-[#0082C4] px-5 py-2 rounded-md hover:bg-[#0082C4] hover:text-white transition-all flex items-center gap-1'><BsArrowLeft />Back to Homepage</Link>
                    </div>
                }
            </div>
        </div>
        <Footer/>
    </div>
  );
};

export default SignUp