import React, { useState } from "react";
import auth from "../../firebase.init";


function EmailChange() {
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleEmailChange = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;

      // Verify current password
      const credential = auth.EmailAuthProvider.credential(
        user.email,
        password
      );
      await user.reauthenticateWithCredential(credential);

      // Update email
      await user.updateEmail(newEmail);

      // Send email verification
      await user.sendEmailVerification();

      // Email successfully updated and verification sent
      setError(null);
      setNewEmail('');
      setPassword('');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Change Email</h2>
      <form onSubmit={handleEmailChange}>
        <label>New Email</label>
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
        <label>Current Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Change Email</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
}

export default EmailChange;
