import React, { useContext, useEffect, useState } from 'react';
import { HiPlus, HiMinus } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import './Faq.css';
import ScrollToTop from '../Common/ScrollToTop';
import { GlobalContext } from '../GlobalState/GlobalState';

function Faq() {
    
    const { language } = useContext(GlobalContext);

    const [activeIndex, setActiveIndex] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);

    const handleClick = (index) => {
      if (index === activeIndex) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
        setActiveIndex2(null);
      };
    };
    const handleClick2 = (index) => {
      if (index === activeIndex2) {
        setActiveIndex2(null);
      } else {
        setActiveIndex2(index);
        setActiveIndex(null);
      };
    };
  
    const questions = [
      {
        question: 'What is telementoring?',
        answer: 'Telementoring is a form of mentoring that uses communication technologies, such as voice calls, videoconferencing, email, or instant messaging, to connect mentors and mentees in different physical locations. It enables mentors to provide guidance, support, and expertise to mentees regardless of location, making mentoring accessible to people who would otherwise not have access to it due to different barriers.',
        banglaQuestion: 'টেলিমেন্টরিং কি?',
        banglaAnswer: 'টেলিমেন্টরিং হল মেন্টরিংয়ের একটি রূপ যা নানামাত্রিক প্রযুক্তি ব্যবহার করে (যেমন ভয়েস কল, ভিডিও কনফারেন্সিং, ইমেল বা এসএমএস) মেন্টর (পরামর্শদাতা) এবং মেন্টিদের (পরামর্শসেবা গ্রহণকারী)  মধ্যে যোগাযোগ তৈরি করে। এর মাধ্যমে মেন্টরবৃন্দ পরামর্শসেবা গ্রহণকারী মেন্টিদের নানা বিষয়ে পরামর্শ, সহায়তা এবং দক্ষতা অর্জন বিষয়ক তথ্য প্রদান করতে সক্ষম করে। টেলিমেন্টরিং প্রোগ্রাম স্বল্প খরচের প্রযুক্তি ব্যবহার করে পিছিয়ে থাকা জনগোষ্ঠীকে এগিয়ে যেতে সাহায্য করে।',
      },
      {
        question: 'Who are the mentees?',
        answer: 'The mentees are children or parents who do not have access to the internet but have access to a phone.',
        banglaQuestion: 'মেন্টি বা পরামর্শসেবা গ্রহণকারী কারা?',
        banglaAnswer: 'মেন্টি বা পরামর্শসেবা গ্রহণকারী হল এমন শিশু বা তাদের পিতামাতা যাদের ইন্টারনেট অ্যাক্সেস নেই কিন্তু একটি বেসিক ফিচারফোন রয়েছে।',
      },
      {
        question: 'How does telementoring benefit the participants?',
        answer:
          'Telementoring can benefit mentees and mentors in various ways. For example, it helps mentors to gain valuable mentoring skills and experiences. It also helps mentees to get free access to quality services that are otherwise not available to them.',
          banglaQuestion: 'টেলিমেন্টরিং কিভাবে সেবাদাতা (মেন্টর) ও সেবাগ্রহণকারীদের (মেন্টি) সাহায্য করে?',
          banglaAnswer: 'টেলিমেন্টরিং বিভিন্ন উপায়ে পরামর্শসেবা দাতা (মেন্টর) ও পরামর্শসেবা গ্রহণকারীদের (মেন্টি) উপকার করতে পারে। উদাহরণস্বরূপ, এটি পরামর্শদাতাদের (মেন্টর) পরামর্শদানের দক্ষতা এবং অভিজ্ঞতা অর্জন করতে সহায়তা করে। এটি মেন্টিদের মানসম্পন্ন সেবাগুলো বিনামূল্যে দিয়ে থাকে যা কি না উচ্চমূল্যের প্রযুক্তিতে (যেমন, ভিডিও কন্টেন্ট, লাইভ ক্লাস) পাওয়া অত্যন্ত ব্যয় সাপেক্ষ।',
      },
      {
        question: 'Who are the partners in telementoring?',
        answer:
          'Partners can be any organization that works with the education and development of children. To increase the outreach of the telementoring service, we will work closely with the partners to provide telementoring service to the children. For example, if any NGO wants to use telementoring for their existing participants, we can work with them to design a suitable format of mentoring and implement it.',
        banglaQuestion: 'এই প্রকল্পটির সাথে কারা যুক্ত হবেন?',
        banglaAnswer: 'যে কোনও সংস্থা যা শিশুদের শিক্ষা এবং বিকাশ নিয়ে কাজ করে তারা এই প্রকল্পটির অংশীদার হতে পারেন। উদাহরণস্বরূপ, যদি কোনো এনজিও তাদের নির্দিষ্ঠ একটি গোষ্ঠীর জন্য টেলিমেন্টরিং সেবা প্রদান করতে চায়, তাহলে আমরা তাদের সাথে কাজ করতে পারি।',
      },
      {
        question: 'What will be the impact of telementoring in the post-pandemic world?',
        answer:
          "Though the telementoring program was introduced during the Covid-19 pandemic, the idea of mentoring from a distance is not new. The geographic distance can make it difficult for mentors to meet with mentees, while the lack of internet access prevents mentors and mentees to meet online. Telementoring makes mentoring feasible and cost-effective via phone.",
        banglaQuestion: 'করোনাভাইরাস মহামারী পরবর্তী পৃথিবীতে টেলিমেন্টরিং কিভাবে ভূমিকা রাখবে?',
        banglaAnswer: 'যদিও টেলিমেন্টরিং প্রোগ্রামটি কোভিড -19 মহামারী চলাকালীন চালু হয়েছিল, তবে দূরশিখন বা ডিস্ট্যান্স লার্নিং ধারণাটি নতুন নয়। মহামারী ও ভৌগলিক দূরত্ব মেন্টরদের সাথে মেন্টিদের সশরীরে যোগাযোগে বাঁধা করে। অন্যদিকে ইন্টারনেট অ্যাক্সেসের অভাব স্বল্প আয়ের মানুষদের সেবাগ্রহণ থেকে বঞ্চিত করে। টেলিমেন্টরিং একটি বেসিক ফিচারফোনের মাধ্যমে পরামর্শ প্রদানকে সম্ভবপর এবং সাশ্রয়ী করে তোলে।',
      },
      {
        question: 'How to get involved?',
        answer:
          'Any interested individuals, especially those enrolled at the undergraduate and postgraduate levels, can become volunteer mentors in the telementoring program. Just join our mentor team by signing up and completing your profile. Joining does not mean that you have to mentor someone immediately. Based on your preferences, we will match you with a mentee in the future. You can then decide whether to take up the assignment. So, just join the team now.',
        banglaQuestion: 'কিভাবে টেলিমেন্টরিংয়ের সাথে যুক্ত হবো?',
        banglaAnswer: 'যেকোনো আগ্রহী ব্যক্তি, বিশেষ করে যারা স্নাতক এবং স্নাতকোত্তর স্তরে পড়াশোনা করছেন, তারা টেলিমেন্টরিং প্রোগ্রামে স্বেচ্ছাসেবক পরামর্শদাতা (মেন্টর) হতে পারে। শুধু সাইন আপ করে এবং আপনার প্রোফাইল সম্পূর্ণ করে আমাদের মেন্টর হিসেবে আপনি যোগ দিতে পারবেন। যোগদানের অর্থ এই নয় যে আপনাকে অবিলম্বে কাউকে পরামর্শ দিতে হবে। আপনার পছন্দের উপর ভিত্তি করে, আমরা আপনাকে ভবিষ্যতে একজন শিক্ষার্থী ও তার পরিবারের সাথে যোগাযোগ তৈরি করে দিব। তারপর আপনি অ্যাসাইনমেন্ট গ্রহণ করবেন কিনা তা সিদ্ধান্ত নিতে পারেন।',
      },
      {
        question: 'Is it possible to provide mentorship through mobile phones in the internet era?',
        answer:
          'Yes, it is possible to provide mentorship through feature phones using voice calls, interactive voice response (IVR) technology, or the Internet. Telementoring can be particularly valuable in areas where internet connectivity is limited or expensive, providing a way for mentors to support and guide mentees through voice calls and interactive technology. Previous research shows that telementoring improves cognitive and noncognitive outcomes of children.',
        banglaQuestion: 'ইন্টারনেটের যুগে বেসিক ফিচারফোনে মেন্টরিংসেবার প্রাসঙ্গিকতা কতোটুকু?',
        banglaAnswer: 'ইন্টারেক্টিভ ভয়েস রেসপন্স (IVR) প্রযুক্তি করে ইন্টারনেট ব্যবহার করে ভয়েস কলের মাধ্যমে ফিচার ফোনে পরামর্শসেবা (মেন্টরিং) প্রদান করা সম্ভব। যেসব এলাকায় যেখানে ইন্টারনেট সংযোগ সীমিত বা ব্যয়বহুল সেখানে টেলিমেন্টরিং সেবা বিশেষভাবে কার্যকর হতে পারে। বিভিন্ন গবেষণায় দেখা গেছে, টেলিমেন্টরিং শিশুদের পড়াশোনায় উন্নতিসাধনে সাহায্য করে।',
      },
    ];
    const questions2 = [ 
      {
        question: 'How can I contribute to the program?',
        answer:
          'If you are an individual, then sign up to become a volunteer mentor. We will provide you with details about your engagement later. If you are an organization, then contact us to explore the options and become a partner.',
        banglaQuestion: 'কিভাবে এই কর্মসূচীতে অবদান রাখা যেতে পারে?',
        banglaAnswer: 'ব্যক্তিগতভাবে যেকোনো ব্যক্তি স্বেচ্ছাসেবক মেন্টর হিসেবে সাইন আপ করতে পারেন। সাইন আপ করার পর টেলিমেণ্টরিং প্রকল্প থেকে আপনাকে কার্যক্রমে (অ্যাসাইনমেন্ট) যুক্ত করা হবে। এছাড়াও বিভিন্ন স্বেচ্ছাসেবী গ্রুপ ও বেসরকারি সংস্থা আমাদের সাথে যুক্ত হতে পারেন। সেক্ষেত্রে আমাদের সাথে সরাসরি যোগাযোগ করুন।',
      },
      {
        question: 'What are the duties of a mentor?',
        answer:
          'Broadly, telementoring is a mentoring service from a distance. However, each project is different from the other project. Before starting each project, we give the details to the mentors. If you like the project and are willing to offer service, you can join that project. If you feel any project is not suitable for you, you are free to opt-out.',
        banglaQuestion: 'মেন্টর হতে গেলে কি কি দায়িত্ব পালন করতে হবে?',
        banglaAnswer: 'মোটামুটিভাবে, টেলিমেন্টরিং হল একটি দূরশিখন বা ডিস্ট্যান্স লার্নিং পরিষেবা। তবে প্রতিটি অ্যাসাইনমেন্ট অপর একটি অ্যাসাইনমেন্ট থেকে আলাদা। প্রতিটি অস্যাইনমেন্ট শুরু করার আগে, আমরা পরামর্শদাতাদের বিশদ বিবরণ দিই। আপনি যদি অ্যাসাইনমেন্টটিকে পছন্দ করেন এবং পরিষেবা দিতে ইচ্ছুক হন তবে আপনি সেটিতে যোগ দিতে পারেন। আপনি যদি মনে করেন যে কোনও প্রকল্প আপনার পক্ষে সম্ভব হচ্ছে না, আপনি সরাসরি অব্যাহতি নিতে পারবেন।',
      },
      {
        question: 'Who to report to?',
        answer:
          'Every specific project is managed by a specific team and research associates. Normally, you have to report to the team leader of the specific project that you are participating in. You are informed about the team leader when you take up an assignment and have participated in the induction program.',
        banglaQuestion: 'কার কাছে রিপোর্ট করতে হবে?',
        banglaAnswer: 'প্রতিটি টেলিমেন্টরিং প্রকল্প একটি নির্দিষ্ট দল এবং গবেষণা সহযোগীদের দ্বারা পরিচালিত হয়। সাধারণত, আপনি যে নির্দিষ্ট প্রকল্পে অংশগ্রহণ করছেন তার দলের নেতার কাছে আপনাকে রিপোর্ট করতে হবে। আপনি যখন একটি অ্যাসাইনমেন্ট গ্রহণ করবেন এবং প্রোগ্রামে অংশগ্রহণ করবেন তখন আপনাকে একজন দলনেতার সাথে যুক্ত করে দেয়া হবে।',
      },
      {
        question: 'What are the membership benefits?',
        answer:
          'Telementoring is volunteer-based service. So, there are no financial benefits. Mentors will not bear any cost associated with telementoring. All costs borne by the mentoring service will be provided by us. After the completion of each mentoring service to the mentee, we will provide a certificate of appreciation.',
        banglaQuestion: 'একজন স্বেচ্ছাসেবক মেন্টর হিসেবে কিভাবে উপকৃত হবে?',
        banglaAnswer: 'টেলিমেন্টরিং হল স্বেচ্ছাসেবাভিত্তিক কার্যক্রম। তাই এতে আর্থিক সুবিধা নেই।  তবে মেন্টরদের টেলিমেন্টোরিংয়ের সাথে সম্পর্কিত কোনও খরচ বহন করতে হবেনা। মেন্টরিং’এর জন্য  সমস্ত খরচ টেলিমেন্টরিং কর্তৃপক্ষ বহন করবে। প্রত্যেক মেন্টর প্রতিটি মেন্টরিং কার্যক্রম শেষ করার পর একটি করে প্রশংসাপত্র পাবেন।',
      },
      {
        question: 'Can I revoke my mentorship anytime?',
        answer:
          'Yes. Joining our volunteer mentoring team does not mean you are bound to provide some sort of mentoring service. After joining, you can choose to revoke or avoid mentoring. We understand that your situation may change anytime. You are completely free to change your mind.',
        banglaQuestion: 'মেন্টরিং কার্যক্রম থেকে অব্যাহতি নেয়া যাবে কি?',
        banglaAnswer: 'অবশ্যই। স্বেচ্ছাসেবক হিসেবে যুক্ত হলেই আপনি মেন্টরিংসেবা প্রদান করতে বাধ্য নন। যোগদানের পর আমি আপনি যেকোন সময় অব্যাহতিগ্রহণ করতে পারেন।',
      },
      {
        question: 'What is the privacy policy regarding the information shared?',
        answer:
          'We will not share any personal information with anyone without your consent',
        banglaQuestion: 'স্বেচ্ছাসেবক হিসেবে যুক্ত হওয়ার পর ব্যক্তিগত তথ্য কি কোথাও প্রকাশ করা হবে?',
        banglaAnswer: 'আমরা আপনার সম্মতি ছাড়া কারো সাথে কোনো ব্যক্তিগত তথ্য শেয়ার করব না।',
      },
      {
        question: 'Who is funding the program?',
        answer:
          'The funding comes from various sources, including partners and donors. For example, the World Bank and EdTech Hub have provided research funds for evaluation purposes while Monash University and GDRI have provided operating funds to help service delivery.',
        banglaQuestion: 'এই কর্মসূচীতে কারা অর্থায়ন করছে?',
        banglaAnswer: 'বিশ্বব্যাংক এবং এডটেক (EdTech)  হাবের মতো বৈশ্বিক সংস্থাগুলো  ও বিভিন্ন দাতাসংস্থা টেলিমেন্টরিং কর্মসূচীটিতে অর্থয়ায়ন করছে।  এছাড়াও মোনাশ ইউনিভার্সিটি, অস্ট্রেলিয়া ও বেসরকারি সংস্থা জিডিআরআই এই কর্মসূচীর পরিচলন ব্যয়বহন করছে।',
      },
    ];

    //  Scroll to top, when user will be redirected in this page
    const location = useLocation();
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);
    
  return (
    <section>
      <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 lg:py-12 md:py-12 py-5'>
        <div className='grid grid-cols-1 justify-items-center'>
          { language === 'en' ?
            <p className='lg:text-lg md:text-base text-sm font-semibold mb-2 py-1 px-5 rounded-full text-center text-[#F74F22]' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>Questions answer</p>
            :
            <p className='lg:text-lg md:text-base text-sm font-semibold mb-2 py-1 px-5 rounded-full text-center text-[#F74F22] banglaFont' style={{'backgroundColor':'rgba(247, 79, 34, 0.08)'}}>প্রশ্নত্তোর</p>
          }

          { language === 'en' ?
            <h1 className='md:text-4xl text-3xl font-bold mb-3 text-[#2E3033]'>Frequently asked questions</h1>
            :
            <h1 className='md:text-4xl text-3xl font-bold mb-3 text-[#2E3033] banglaFont'>সাধারণ জিজ্ঞাসা</h1>
          }
          { language === 'en' ?
            <p className='text-center lg:text-lg md:text-lg text-base text-gray-600 lg:w-2/3 md:w-2/3 w-full'>Do you have any questions? <Link to={{ pathname: '/contact-us', state: { preserveScrollPosition: false } }} className='text-[#F74F22] font-semibold'>Click here</Link></p>
            :
            <p className='text-center lg:text-lg md:text-lg text-base text-gray-600 lg:w-2/3 md:w-2/3 w-full banglaFont'>আপনি কি কিছু জানতে চান? <Link to={{ pathname: '/contact-us', state: { preserveScrollPosition: false } }} className='text-[#F74F22] font-semibold'>ক্লিক করুন</Link></p>
          }
        </div>
        <div className='md:flex grid grid-cols-1 items-center gap-5 w-full'>
          <div className='grid grid-cols-1 gap-5 mt-8 md:w-1/2 w-full'>
            {questions.map((q, index) => (
              <div key={index} className="bg-[#F6FAFF] rounded-lg">
                  <div className='px-3 py-2' >
                      <button className={`w-full text-left font-medium focus:outline-none flex items-center justify-between text-[#777B84] pb-1 ${activeIndex === index && 'text-[#0082C4]  border-b border-[#E4F0FF]'}`}
                      onClick={() => handleClick(index)}
                      >
                        {language === 'en' ?
                          <span>{q.question}</span>
                          :
                          <span className='banglaFont'>{q.banglaQuestion}</span>
                        }
                        {activeIndex === index ? (
                            <HiMinus size={20} className='font-semibold text-[#0082C4]' />
                        ) : (
                            <HiPlus size={20} className='font-semibold text-[#0082C4]'  />
                        )}
                      </button>
                  </div>
                  { language === 'en' ?
                    <div className={`accordion-answer px-3 pb-1 text-sm text-[#777B84] ${
                      activeIndex === index && 'accordion-answer-open'
                      }`}
                    >
                      {q.answer}
                    </div>
                    :
                    <div className={`accordion-answer px-3 text-sm text-[#777B84] banglaFont ${
                      activeIndex === index && 'accordion-answer-open'
                      }`}
                    >
                      {q.banglaAnswer}
                    </div>
                  }
              </div>
              ))}
          </div>
          <div className='grid grid-cols-1 gap-5 md:mt-8 md:w-1/2 w-full'>
            {questions2.map((q, index) => (
              <div key={index} className="bg-[#F6FAFF] rounded-lg">
                  <div className='px-3 py-2' >
                      <button className={`w-full text-left font-medium focus:outline-none flex items-center justify-between text-[#777B84] pb-1 ${activeIndex2 === index && 'text-[#0082C4]  border-b border-[#E4F0FF]'}`}
                      onClick={() => handleClick2(index)}
                      >
                        {language === 'en' ?
                          <span>{q.question}</span>
                          :
                          <span className='banglaFont'>{q.banglaQuestion}</span>
                        }
                      {activeIndex2 === index ? (
                          <HiMinus size={20} className='font-semibold text-[#0082C4]' />
                      ) : (
                          <HiPlus size={20} className='font-semibold text-[#0082C4]'  />
                      )}
                      </button>
                  </div>
                  { language === 'en' ?
                    <div className={`accordion-answer px-3 pb-1 text-sm text-[#777B84] ${
                      activeIndex2 === index && 'accordion-answer-open'
                      }`}
                    >
                      {q.answer}
                    </div>
                    :
                    <div className={`accordion-answer px-3 text-sm text-[#777B84] banglaFont ${
                      activeIndex2 === index && 'accordion-answer-open'
                      }`}
                    >
                      {q.banglaAnswer}
                    </div>
                  }
              </div>
              ))}
          </div>
        </div>
      </div>
      <ScrollToTop></ScrollToTop>
    </section>
  );
};

export default Faq