import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../Common/Loading';
import { useUserContext } from '../GlobalState/UserContext';

function RequiredAdmin({ children }) {
    const { signedInUser, loadingAuth } = useUserContext();
    const location = useLocation();

    if (loadingAuth) {
        return <Loading />;
    }

    if (signedInUser?.role === "Admin" || signedInUser?.role === "Manager") {
        return children;
    }else {
        return <Navigate to='/' state={{ from: location }} replace />;
    }
}

export default RequiredAdmin;
