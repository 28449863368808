import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';
import './Slider.css';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from 'react-router-dom';
import { GlobalContext } from '../GlobalState/GlobalState';

function SlideTest() {
  const { language } = useContext(GlobalContext);
  return (
    <div>
      <Swiper
        // direction={"vertical"}
        spaceBetween={30}
        // centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
            clickable: true,
            dynamicBullets: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper h-[70vh] -z-50"
      >
        <SwiperSlide className='FirstSlideBg'>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 md:py-28 py-5 h-full flex flex-col justify-center'>
            { language === 'en' ?
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'><span className='text-[#FDBE44]'>Telementoring</span> program </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'>helps students to learn and parents </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'> to educate their children at home</p>
              </div>
              :
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'><span className='text-[#FDBE44]'>টেলিমেন্টরিং</span> প্রোগ্রাম ঘরে বসে </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'> শিক্ষার্থীদের পড়াশোনা করতে সাহায্য করে</p>
                {/* <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'> সন্তানদের বাড়িতে শিক্ষিত করতে সাহায্য করে</p> */}
              </div>
            }
            <div className='mt-20'>
              {language === 'en' ?
                <Link to='/about-us' className='text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all' >Learn More</Link>
                :
                <Link to='/about-us' className="text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all banglaFont" >আরো জানুন</Link>
              }
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='SecondSlideBg'>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 md:py-28 py-5 h-full flex flex-col justify-center'>
            { language === 'en' ?
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                  <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'><span className='text-[#FDBE44]'>Evidence</span> suggests that </p>
                  <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'>children learn better with parental</p>
                  <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'> guidance and supervision</p>
                {/* <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'></p> */}
              </div>
              :
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'><span className='text-[#FDBE44]'>গবেষণা বলছে,</span> সচেতন অভিভাবকের </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'>তত্ত্বাবধায়নে থাকা শিক্ষার্থীরা তুলনামূলক </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'> ভালো ফলাফল অর্জন করতে পারে</p>
                {/* <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'></p> */}
              </div>
            }
            <div className='mt-20'>
              {language === 'en' ?
                <Link to='/about-us' className='text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all' >Learn More</Link>
                :
                <Link to='/about-us' className="text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all banglaFont" >আরো জানুন</Link>
              }
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='ThirdSlideBg'>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 md:py-28 py-5 h-full flex flex-col justify-center'>
            { language === 'en' ?
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'><span className='text-[#FDBE44]'>Globally,</span> a significant portion of </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'> children and household does not have </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF]'>access to the internet</p>
              </div>
              :
              <div className='grid grid-cols-1 lg:gap-3 md:gap-3 gap-1 SlideInnerBg'>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'><span className='text-[#FDBE44]'>বিশ্বব্যাপী,</span> শিক্ষার্থীদের একটি বড় অংশ </p>
                <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'>  বাড়িতে ইন্টারনেট সুবিধা পায় না</p>
                {/* <p className='lg:text-5xl md:text-4xl text-2xl font-bold text-[#F6FAFF] banglaFont'> ইন্টারনেট সুবিধা পায় না</p> */}
              </div>
            }
            <div className='mt-20'>
              {language === 'en' ?
                <Link to='/about-us' className='text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all' >Learn More</Link>
                :
                <Link to='/about-us' className="text-lg font-semibold px-4 py-3 rounded-xl bg-[#FDBE44] hover:bg-[#F3B234] transition-all banglaFont" >আরো জানুন</Link>
              }
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SlideTest