import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Footer from '../Common/Footer';
// import Navbar from '../Common/Navbar';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import CollaborationHeroBg from '../../assets/Collaborations/CollaborationHeroBg.png';
// import AbuSiddiqueProfile from '../../assets/Collaborations/AbuSiddiqueProfile.png';
import AbuSiddiqueProfile from '../../assets/Collaborations/AbuSiddiqueProfile.jpg';
import HaiAnhProfile from '../../assets/Collaborations/HaiAnhProfile.png';
// import MichaelVlassopoulosProfile from '../../assets/Collaborations/MichaelVlassopoulosProfile.png';
import MichaelVlassopoulosProfile from '../../assets/Collaborations/MichaelVlassopoulosProfile.JPG';
import ListPoint from '../../assets/Collaborations/list-point.png';
import GdriLogo from '../../assets/Collaborations/gdri-logo.png';
import RisingAcademiesLogo from '../../assets/Collaborations/rising-academies-logo.png';
import LeadLogo from '../../assets/Collaborations/lead-logo.png';
import EdTechLogo from '../../assets/Collaborations/Ed-tech-logo.png';
import LaerdalFoundationLogo from '../../assets/Collaborations/laerdal-foundation-logo.png';
import InfoBipLogo from '../../assets/Collaborations/info-bip-logo.png';
import WorldBankLogo from '../../assets/Collaborations/world-bank-logo.png';
// import Review from '../Review/Review';
import './MeetTheTeam.css';
import '../OurTeam/OurTeam.css';
import ScrollToTop from '../Common/ScrollToTop';
import Footer from '../Common/Footer';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function Collaborators() {
    const { language } = useContext(GlobalContext);

  //  Scroll to top, when user will be redirected in this page
  const location = useLocation();
  useEffect(() => {
    if (!location.state?.preserveScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [location.state]);

  return (
    <div>
        <Helmet>
            <title>Collaborators - Telementoring</title>
            <meta name='title' content='Collaborators - Telementoring' />
            <meta name="description" content="Explore the list of our collaborators at Telementoring" />
            <meta name="keywords" content="telementoring, collaborators, partnership" />
        </Helmet>

      {/* Hero */}
      <section>
        <div className='relative' style={{"backgroundImage": `url(${CollaborationHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
          <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
            <div className='TextInnerBg w-full'>
              { language === 'en' ?
                <p className='text-[#FDBE44] md:text-4xl text-2xl font-bold'>Collaborators</p>
                :
                <p className='text-[#FDBE44] md:text-4xl text-2xl font-bold banglaFont'>সহযোগীবৃন্দ</p>
              }
              <div className='flex items-center gap-2 mt-4'>
                { language === 'en' ?
                    <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                    :
                    <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                }
                <span><img src={NextIcon} alt='Next Icon' /></span>
                { language === 'en' ?
                    <Link to='/collaborators' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Collaborators</Link>
                    :
                    <Link to='/collaborators' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>সহযোগীবৃন্দ</Link>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
          <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
        </div>
      </section>

      {/* Researchers */}
        <section>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12'>
                { language === 'en' ?
                    <p className='text-[#2E3033] md:text-4xl text-2xl text-center font-bold'>Research collaborators</p>
                    :
                    <p className='text-[#2E3033] md:text-4xl text-2xl text-center font-bold banglaFont'>গবেষণা সহযোগীবৃন্দ</p>
                }
                { language === 'en' ?
                    <p className='text-[#777B84] font-semibold text-base text-center mt-2'>Providing evidence in collaborations with researcher</p>
                    :
                    <p className='text-[#777B84] font-semibold text-base text-center mt-2 banglaFont'>গবেষকের সাথে সহযোগিতার প্রমাণ প্রদান</p>
                }
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-12 md:gap-4 gap-8 pt-12'>                
                    <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                        <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${HaiAnhProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                            <div className='absolute bottom-5 left-[50%] right-[50%] lg:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                                <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                                    <li>
                                        <a href="https://www.facebook.com/haianhdh/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-facebook" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/hai-anh-dang-b14a632a/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-linkedin-in" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                            { language === 'en' ?
                                <a href='https://sites.google.com/site/haianhhdang/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Hai-Anh H. Dang</a>
                                :
                                <a href='https://sites.google.com/site/haianhhdang/' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>হাই-আন এইচ. ড্যাং</a>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>Researcher</p>
                                :
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>গবেষক</p>
                            }
                        </div>
                        { language === 'en' ?
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center'>Senior Economist</p>
                                <p className='text-center'>Development Data Group, World Bank</p>
                            </div>
                            :
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center banglaFont'>সিনিয়র অর্থনীতিবিদ</p>
                                <p className='text-center banglaFont'>ডেভেলপমেন্ট ডাটা গ্রুপ, বিশ্বব্যাংক</p>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                        <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${AbuSiddiqueProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                            <div className='absolute bottom-5 left-[50%] right-[50%] lg:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                                <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                                    <li>
                                        <a href="https://twitter.com/absidd" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-twitter" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                            { language === 'en' ?
                                <a href='https://sites.google.com/view/abusiddique' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Abu Siddique</a>
                                :
                                <a href='https://sites.google.com/view/abusiddique' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>আবু সিদ্দিক</a>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>Researcher</p>
                                :
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>গবেষক</p>
                            }
                        </div>
                        { language === 'en' ?
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center'>Lecturer, Department of Political Economy</p>
                                <p className='text-center'>King's College London</p>
                            </div>
                            :
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center banglaFont'>প্রভাষক, রাজনৈতিক অর্থনীতি বিভাগ</p>
                                <p className='text-center banglaFont'>কিংস কলেজ লন্ডন</p>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col border border-blue-400 border-opacity-25 rounded-2xl bg-[#F6FAFF] group">
                        <div className="rounded-t-xl relative h-[400px]" style={{"backgroundImage": `url(${MichaelVlassopoulosProfile})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                            <div className='absolute bottom-5 left-[50%] right-[50%] lg:scale-0 scale-100 md:group-hover:scale-100 hoverEffect'>
                                <ul className="socialLinks relative flex justify-center items-center gap-2 mt-1">
                                    <li>
                                        <a href="https://www.facebook.com/michael.vlassopoulos" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-facebook" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/michael-vlassopoulos-60232a35/" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-linkedin-in" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/mvlass" target='_blank' className='relative text-white bg-black bg-opacity-60 fill-current w-7 h-7 flex justify-center items-center rounded hover:bg-[#F74F22] transition-all'>
                                            <svg className="fa-brands fa-twitter" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='py-5 w-full bg-white flex flex-col justify-center border-b border-blue-400 border-opacity-25'>
                            { language === 'en' ?
                                <a href='https://sites.google.com/view/michael-vlassopoulos/home' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center'>Michael Vlassopoulos</a>
                                :
                                <a href='https://sites.google.com/view/michael-vlassopoulos/home' target='_blank' className='text-[#0082C4] md:text-xl text-base font-bold text-center banglaFont'>মাইকেল ভ্লাসোপোলোস</a>
                            }
                            { language === 'en' ?
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase'>Researcher</p>
                                :
                                <p className='text-[#777B84] md:text-base text-sm text-center uppercase banglaFont'>গবেষক</p>
                            }
                        </div>
                        { language === 'en' ?
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center'>Professor, Economics, University of Southampton </p>
                                <p className='text-center'>Research Fellow, IZA</p>
                            </div>
                            :
                            <div className='text-[#777B84] text-sm p-3 flex flex-col'>
                                <p className='text-center banglaFont'>অধ্যাপক, অর্থনীতি, সাউদাম্পটন বিশ্ববিদ্যালয় </p>
                                <p className='text-center banglaFont'>রিসার্চ ফেলো, IZA</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
      
      {/* Institution List */}
      <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12'>
        <div className='flex flex-col gap-4'>
          <div>
            { language === 'en' ?
                <h1 className='md:text-4xl text-2xl text-[#2E3033] font-bold text-center' >Institutional collaborators</h1>
                :
                <h1 className='md:text-4xl text-2xl text-[#2E3033] font-bold text-center banglaFont' >প্রাতিষ্ঠানিক সহযোগীবৃন্দ</h1>
            }
            { language === 'en' ?
                <p className='text-[#777B84] font-semibold text-base text-center mt-2'>Transforming education in collaborations with top institutions</p>
                :
                <p className='text-[#777B84] font-semibold text-base text-center mt-2 banglaFont'>শীর্ষ প্রতিষ্ঠানের সাথে সহযোগিতায় শিক্ষার রূপান্তর</p>
            }
          </div>
          <div className='border-l-[2px] border-dashed border-[#B7BBC1] px-8 mt-12 relative'>
            <div className='absolute flex items-center gap-5 -ml-12 -mt-1'>
              <img src={ListPoint} alt='List Point Icon' className='w-8' />
              { language === 'en' ?
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold'>National Collaborators</p>
                :
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold banglaFont'>জাতীয় সহযোগীবৃন্দ</p>
              }
            </div>
            <div className='lg:mt-10 md:mt-6 mt-4 lg:mb-12 md:mb-8 mb-4'>
              <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-5 md:gap-5 gap-3'>
                <a href='https://gdri.org/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl mt-5'>
                  <img src={GdriLogo} alt='Gdri Logo' className='md:w-10/12 w-4/6 mx-auto' />
                </a>
              </div>
            </div>
            <div className='absolute flex items-center gap-5 -ml-12'>
              <img src={ListPoint} alt='List Point Icon' className='w-8' />
              { language === 'en' ?
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold'>International Collaborators</p>
                :
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold banglaFont'>আন্তর্জাতিক সহযোগীবৃন্দ</p>
              }
            </div>
            <div className='lg:mt-28 md:mt-20 mt-14 lg:mb-12 md:mb-8 mb-4'>
              <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-5 md:gap-5 gap-3'>
                <a href='https://www.risingacademies.com/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-5 rounded-2xl'>
                  <img src={RisingAcademiesLogo} alt='Gdri Logo' className='md:w-10/12 w-4/6 mx-auto' />
                </a>
                <a href='https://leadedu.org/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl'>
                  <img src={LeadLogo} alt='Gdri Logo' className='md:w-10/12 w-4/6 mx-auto' />
                </a>
              </div>
            </div>
            <div className='absolute flex items-center gap-5 -ml-12'>
              <img src={ListPoint} alt='List Point Icon' className='w-8' />
              { language === 'en' ?
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold'>Digital Experience Partner</p>
                :
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold banglaFont'>ডিজিটাল এক্সপেরিয়েন্স অংশীদার</p>
              }
            </div>
            <div className='lg:mt-28 md:mt-20 mt-14 lg:mb-12 md:mb-8 mb-4'>
                <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-5 md:gap-5 gap-3'>
                    <a href='https://www.infobip.com/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl'>
                    <img src={InfoBipLogo} alt='Gdri Logo' className='md:w-10/12 w-4/6 mx-auto' />
                    </a>
                </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Funding Agencies */}
      <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 pb-12'>
        <div className='flex flex-col gap-4'>
          <div>
            { language === 'en' ?
                <h1 className='md:text-4xl text-2xl text-[#2E3033] font-bold text-center' >Funding Agencies</h1>
                :
                <h1 className='md:text-4xl text-2xl text-[#2E3033] font-bold text-center banglaFont'>তহবিল সংস্থা</h1>
            }
            { language === 'en' ?
                <p className='text-[#777B84] font-semibold text-base text-center mt-2'>Our global research partners for catering clear policy</p>
                :
                <p className='text-[#777B84] font-semibold text-base text-center mt-2 banglaFont'>সুস্পষ্ট নীতির জন্য আমাদের বিশ্বব্যাপী গবেষণা অংশীদার</p>
            }
          </div>
          <div className='border-l-[2px] border-dashed border-[#B7BBC1] px-8 mt-12 relative'>
            <div className='absolute flex items-center gap-5 -ml-12 -mt-1'>
              <img src={ListPoint} alt='List Point Icon' className='w-8' />
              { language === 'en' ?
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold'>Funding Agencies</p>
                :
                <p className='text-[#777B84] lg:text-3xl md:text-2xl text-xl font-bold banglaFont'>তহবিল সংস্থা</p>
              }
            </div>
            <div className='lg:mt-16 md:mt-10 mt-8 lg:mb-12 md:mb-8 mb-4'>
                <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-5 md:gap-5 gap-3'>
                    <a href='https://www.worldbank.org/en/home' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl'>
                        <img src={WorldBankLogo} alt='Gdri Logo' className='md:w-10/12 w-[80%] mx-auto' />
                    </a>
                    <a href='https://edtechhub.org/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl'>
                        <img src={EdTechLogo} alt='Gdri Logo' className='md:w-10/12 w-[80%] mx-auto' />
                    </a>
                    <a href='https://laerdalfoundation.org/' target='_blank' className='flex items-center border border-[#0082C426] md:py-5 py-3 rounded-2xl'>
                        <img src={LaerdalFoundationLogo} alt='Gdri Logo' className='md:w-1/2 w-[80%] mx-auto' />
                    </a>
                </div>
            </div>
          </div>
        </div>
      </section>

      {/* Become Volunteer */}
      <section className='mb-20'>
        <BecomeVolunteer/>
      </section>

      <ScrollToTop></ScrollToTop>
      <Footer/>
    </div>
  );
};

export default Collaborators