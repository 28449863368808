import React, { useEffect, useState } from 'react';
import BaseUrl from '../BaseURL';
import Axios from 'axios';
import {useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function ExportUserData() {
    // console.log(auth.currentUser);
    const location = useLocation();

    // CSV format
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [userInfos, setUserInfos] = useState([]);
    const [columnSections, setColumnSections] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    // console.log(userInfos);
    // Merge users & userInfos in one array. //
    const mergedInfos = users?.map(user => ({
      ...user,
      ...(userInfos?.find(userInfo => userInfo?.user_id === user?.id) || {})
    }));
    // console.log(mergedInfos);

    useEffect(() => {
      fetchUsers();
    }, []);
  
    const fetchUsers = async () => {
      try {
        const response = await Axios.get(`${BaseUrl}/infos`);
        const response2 = await Axios.get(`${BaseUrl}/userInfos`);
        setUsers(response.data);
        setUserInfos(response2.data);
        setColumnSections([
          { section: 'Personal Information', columns: ['firstName', 'lastName', 'email', 'birthDate', 'gender', 'nationality', 'district', 'city', 'economicStatus', 'mobile', 'background', 'profileType'] },
          { section: 'Academic Information', columns: ['studyLevel', 'institute', 'studyArea', 'otherStudyArea' ] },
          { section: 'Skills / Experience', columns: ['englishProficiency', 'mentoringExperience'] },
          { section: 'Tutoring Experience', columns: ['tutoringExperience', 'tutoredStudentsLevel'] },
          { section: 'Volunteering Experience', columns: ['volunteeringExperience', 'volunteeredOrganizations', 'otherVolunteeredOrganizations', 'detailActivities'] },
          { section: 'Other Experiences', columns: ['experienceDescription'] },
          { section: 'Volunteering (or Mentoring) preferences', columns: ['areaOfInterest', 'volunteeringActivities'] },
          { section: 'Others', columns: ['id', 'role', 'bio'] },
          // Add more sections and columns as needed
        ]);
        
      } catch (error) {
        console.error(error);
      }
    };
    const columnDisplayNameMap = {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      birthDate: 'Birth Date',
      gender: 'Gender',
      nationality: 'Nationality',
      district: 'District',
      city: 'City',
      economicStatus: 'Economic Status',
      mobile: 'Mobile',
      background: 'Background',
      profileType: 'Profile Type',
      studyLevel: 'Study Level',
      institute: 'Institute',
      studyArea: 'Study Area',
      otherStudyArea: 'Other Study Area',
      englishProficiency: 'English Proficiency',
      mentoringExperience: 'Mentoring Experience',
      tutoringExperience: 'Tutoring Experience',
      tutoredStudentsLevel: 'Tutored Students Level',
      volunteeringExperience: 'Volunteering Experience',
      volunteeredOrganizations: 'Volunteered Organizations',
      otherVolunteeredOrganizations: 'Other Volunteered Organizations',
      detailActivities: 'Detail Activities',
      experienceDescription: 'Experience Description',
      areaOfInterest: 'Area of Interest',
      volunteeringActivities: 'Volunteering Activities',
      id: 'ID',
      role: 'Role',
      bio: 'Bio',
      // Add more mappings as needed
    };    

    const handleSelectAllToggle = () => {
      if (selectAll) {
        setSelectedColumns([]);
      } else {
        const allColumns = columnSections.flatMap((section) => section.columns);
        setSelectedColumns(allColumns);
      }
      setSelectAll(!selectAll);
    };
  
    const handleColumnToggle = (columnName) => {
      setSelectedColumns((prevColumns) => {
        if (prevColumns.includes(columnName)) {
          return prevColumns.filter((column) => column !== columnName);
        } else {
          return [...prevColumns, columnName];
        }
      });
    };
  
    const handleSectionToggle = (sectionName) => {
      const section = columnSections.find((section) => section.section === sectionName);
      if (section) {
        const sectionColumns = section.columns;
        const isSectionSelected = sectionColumns.every((column) =>
          selectedColumns.includes(column)
        );
        if (isSectionSelected) {
          setSelectedColumns((prevColumns) =>
            prevColumns.filter((column) => !sectionColumns.includes(column))
          );
        } else {
          setSelectedColumns((prevColumns) => [...prevColumns, ...sectionColumns]);
        }
      }
    };

    const handleExportCSV = () => {
      setIsLoading(true);
    
      if (selectedColumns?.length === 0) {
        toast.error('Nothing selected');
        setIsLoading(false);
        return;
      }
    
      const filteredUsers = mergedInfos?.map((user) =>
        Object.keys(user)
          .filter((key) => selectedColumns?.includes(key) && key !== 'password' && key !== 'uid')
          .reduce((obj, key) => {
            if (key === 'tutoredStudentsLevel' || key === 'volunteeredOrganizations' || key === 'areaOfInterest' || key === 'otherStudyArea' || key === 'otherVolunteeredOrganizations' || key === 'detailActivities' || key === 'experienceDescription' || key === 'bio' || key === 'firstName'  || key === 'lastName'  || key === 'email'  || key === 'nationality' || key === 'district'  || key === 'city'  || key === 'institute') {
              obj[columnDisplayNameMap[key]] = `"${user[key]}"`; 
            } else {
              obj[columnDisplayNameMap[key]] = user[key];
            }
            return obj;
          }, {})
      );
    
      if (filteredUsers?.length === 0) {
        toast.error('No infos found with selected columns');
        setIsLoading(false);
        return;
      }
    
      const csvRows = [];
    
      // Generate the header row
      const headerRow = selectedColumns?.map((column) => columnDisplayNameMap[column]); // Use display name instead of column name
      csvRows.push(headerRow);
    
      // Generate the data rows
      filteredUsers.forEach((user) => {
        const dataRow = selectedColumns?.map((column) => user[columnDisplayNameMap[column]]);
        csvRows.push(dataRow);
      });
    
      // Convert the CSV rows to a string
      const csvContent = csvRows?.map((row) => row.join(',')).join('\n');
    
      // Create a download link and trigger the download
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
      setIsLoading(false);
    };
    
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

  return (
    <div>
        <div>
            <div className='flex justify-between items-center mb-3'>
                <h4 className='text-lg font-bold mb-4'>Select columns to include in CSV:</h4>
                <label className="flex items-center mb-2 bg-[#F6FAFF] px-5 py-2 rounded-md border border-blue-400 border-opacity-40">
                    <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectAll}
                    onChange={handleSelectAllToggle}
                    />
                    <span className='flex items-center gap-1'>Select<span> All</span></span>
                </label>
            </div>
            <div className='grid grid-cols-1 gap-5'>
                {columnSections.map((section) => (
                    <div key={section.section} >
                        <div className='flex justify-between bg-[#F6FAFF] py-3 lg:px-8 md:px-5 px-5 border border-b-0 border-blue-400 border-opacity-40 rounded-t-2xl'>
                            <h5 className="text-base font-medium">{section.section}</h5>
                            <label className="flex items-center text-sm">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={section.columns.every((column) => selectedColumns.includes(column))}
                                    onChange={() => handleSectionToggle(section.section)}
                                />
                                <span className='flex items-center gap-1'>Select<span> All</span></span>
                            </label>
                        </div>
                        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 border lg:text-base md:text-sm text-base border-blue-400 border-opacity-20 rounded-b-2xl px-5 py-5'>
                            {section.columns.map((column) => (
                                <label key={column} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        checked={selectedColumns.includes(column)}
                                        onChange={() => handleColumnToggle(column)}
                                    />
                                    {/* {column} */}
                                    {columnDisplayNameMap[column]}
                                </label>
                            ))}
                            {/* {section.columns.map((column) => (
                                <label key={column.name} className="flex items-center">
                                    <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={selectedColumns.includes(column.name)}
                                    onChange={() => handleColumnToggle(column.name)}
                                    />
                                    {column.displayName}
                                </label>
                            ))} */}
                        </div>
                    </div>
                ))}
            </div>
            <button
                onClick={handleExportCSV}
                disabled={isLoading || selectedColumns.length === 0}
                className="px-4 py-2 bg-[#0082C4] text-white rounded disabled:bg-gray-400 disabled:cursor-not-allowed mt-5"
            >
                {isLoading ? 'Exporting...' : 'Export Data'}
            </button>
        </div>
    </div>
  )
}

export default ExportUserData