import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import VolunteersHeroBg from '../../assets/Volunteers/VolunteersHeroBg.png';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import Avatar from '../../assets/avatar.png';
import Footer from '../Common/Footer';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import BaseUrl from '../BaseURL';
import Axios from 'axios';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';

function Volunteers() {
    const { language } = useContext(GlobalContext);

    const [volunteerInfo, setVolunteerInfo] = useState([]);
    const volunteers = volunteerInfo?.filter((volunteer)=> volunteer?.role === 'Volunteer');
    volunteers?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName));

    const location = useLocation();
    const navigate = useNavigate();

    // Get volunteerInfo
    useEffect(()=>{
      Axios.get(`${BaseUrl}/volunteer_info`).then((response) => {
        setVolunteerInfo(response.data);
      });
    },[]);
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);

    // Pagination
    const itemsPerPage = 20;
    const urlParams = new URLSearchParams(location.search);
    const pageFromURL = Number(urlParams.get('page')) || 1;
    const [currentPage, setCurrentPage] = useState(pageFromURL);
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
      navigate(`?page=${page}`);
    };
    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const page = urlParams.get('page');
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    }, [location, currentPage]);

    const getVisiblePageNumbers = (currentPage, totalPages) => {
      const visiblePagesLimit = 4;
  
      if (totalPages <= visiblePagesLimit) {
        return Array.from(Array(totalPages).keys()).map((i) => i + 1);
      }
  
      const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
      const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);
  
      const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
      return visiblePages;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const totalPages = Math.ceil(volunteers.length / itemsPerPage);
    const paginatedItems = volunteers.slice(startIndex, endIndex);
    const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);
  return (
    <div className="bg-[#F6FAFF]">
        <Helmet>
            <title>Volunteers - Telementoring</title>
            <meta name='title' content='Volunteers - Telementoring' />
            <meta name="description" content="If you are studying at university level, want to engage in volunteering and get recognized in the wider community, please became a volunteer." />
            <meta name="keywords" content="telementoring, volunteers" />
        </Helmet>
        {/* Hero */}
        <section>
            <div className='relative' style={{"backgroundImage": `url(${VolunteersHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                    <div className='TextInnerBg w-full'>
                        { language === 'en' ?
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'><span className='text-[#FDBE44]'>Volunteers</span></p>
                            :
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'><span className='text-[#FDBE44]'>স্বেচ্ছাসেবকবৃন্দ</span></p>
                        }
                        <div className='flex items-center gap-2 mt-4'>
                            { language === 'en' ?
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                                :
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                            }
                            <span><img src={NextIcon} alt='Next Icon' /></span>
                            { language === 'en' ?
                                <Link to='/volunteers?page=1' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Volunteers</Link>
                                :
                                <Link to='/volunteers?page=1' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>স্বেচ্ছাসেবকবৃন্দ</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
                <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
            </div>
        </section>

        {/* Volunteers */}
        <section className={`max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-12`}>
          {volunteers?.length < 1 ?
            <p className='text-center text-3xl font-semibold py-16'>No volunteers found.</p>
            :
            <div>
              <div className='mb-14 flex items-center gap-3'>
                  <div className='w-full h-[1px] bg-[#E3E6EA]'></div>
                  { language === 'en' ?
                      <h1 className='text-[#2E3033] md:text-4xl text-2xl font-bold'>Volunteers</h1>
                      :
                      <h1 className='text-[#2E3033] md:text-4xl text-2xl font-bold banglaFont'>স্বেচ্ছাসেবকবৃন্দ</h1>
                  }
                  <div className='w-full h-[1px] bg-[#E3E6EA]'></div>
              </div>
              <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 md:gap-5 gap-2'>
                  {
                      paginatedItems?.map((singleVolunteer)=>
                          <div key={singleVolunteer?.id} className='bg-white lg:px-8 md:px-5 px-5 py-5 rounded-2xl flex flex-col justify-center items-center overflow-hidden overflow-wrap break-word' style={{boxShadow: '0px 7px 12px rgba(0, 87, 197, 0.05)'}} >
                              <div className={`mb-3`}>
                                  { singleVolunteer?.profileImageUrl &&  singleVolunteer?.profileType === 'Public' ?
                                      <div className={`lg:w-28 lg:h-28 w-20 h-20 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center`} style={{ backgroundImage: `url('${BaseUrl}${singleVolunteer?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                      :
                                      <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                  }
                              </div>
                              { singleVolunteer?.profileType === 'Public' ? 
                                  <div>
                                      { language === 'en' ?
                                          <Link to={`/volunteer-profile/${singleVolunteer?.id}`} className='bg-[#E0F0F8] px-2 py-1 rounded-full text-[#0082C4] text-xs'>View Profile</Link>
                                          :
                                          <Link to={`/volunteer-profile/${singleVolunteer?.id}`} className='bg-[#E0F0F8] px-2 py-1 rounded-full text-[#0082C4] text-xs banglaFont'>প্রোফাইল দেখুন</Link>
                                      }
                                  </div>
                                  :
                                  ''
                              }
                              <p className='text-[#2E3033] md:text-base text-sm font-semibold text-center mt-3'>{singleVolunteer?.firstName} {singleVolunteer?.lastName}</p>
                          </div>
                      )
                  }
              </div>
              {/* Pagination buttons */}
              <div className={`flex lg:justify-end md:justify-end justify-start mb-8 mt-20 mx-8`}>
                  <nav className="block">
                    <ul className="flex pl-0 rounded list-none gap-3">
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === 1}
                          onClick={() => handlePageChange(Number(currentPage) - 1)}
                        >
                          <CgChevronDoubleLeft />
                        </button>
                      </li>
                      {visiblePageNumbers.map((page) => (
                        <li key={page}>
                          <button
                            className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                              Number(currentPage) === page ? "font-bold bg-[#0082C4] text-white" : ""
                            }  focus:outline-none`}
                            onClick={() => handlePageChange(page)}
                          >
                          {page}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                          disabled={Number(currentPage) === totalPages}
                          onClick={() => handlePageChange(Number(currentPage) + 1)}
                        >
                          <CgChevronDoubleRight />
                        </button>
                      </li>
                    </ul>
                  </nav>
              </div>
            </div>
          }
        </section>

        {/* Become Volunteer */}
        <section className='mb-20'>
            <BecomeVolunteer/>
        </section>

        <Footer/>
    </div>
  )
}

export default Volunteers