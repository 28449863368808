import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from 'axios';
import BaseUrl from '../BaseURL';
// import sha256 from 'crypto-js/sha256';
import { useForm } from 'react-hook-form';
import crypto from 'crypto-js';
import { FaInfoCircle } from 'react-icons/fa';

function Unsubscribe() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState:{ errors }, reset } = useForm();
    const [isOpenPopup, setIsOpenPopup] = useState(true);
    const { hashed_email_params } = useParams();
    const [subscribers, setSubscribers] = useState([]);
    const matchedHashedEmail = subscribers?.find(subscriber => subscriber?.hashed_email === hashed_email_params);
    const [checkedValues, setCheckedValues] = useState([]);
    const [other, setOther] = useState(false);
    const [options, setOptions] = useState([
        { id: 1, value: "I am no longer want to receive these emails" },
        { id: 2, value: "I never signed up for newsletter" },
        { id: 3, value: "The emails are inappropriate" },
        { id: 4, value: "The emails are spam and should be reported" },
        { id: 5, value: "Other" }
    ]);
    const handleChange = (e) => {
        const { value } = e.target;
        if(value==='Other'){
            setOther(!other);
        }
        const index = checkedValues.indexOf(value);
        if (index === -1) {
            setCheckedValues([...checkedValues, value]);
        } else {
            checkedValues.splice(index, 1);
            setCheckedValues([...checkedValues]);
        }
    };

    // Get subscribers
    useEffect(()=>{
        Axios.get(`${BaseUrl}/subscribers`).then((response) => {
            setSubscribers(response.data);
        });
    },[]);

    // Handle submit form
    const onSubmit = data => {
        const unsubscribed_reasons_array = checkedValues?.filter(item => item !== 'Other')?.concat(data?.otherReasons);
        const unsubscribed_reasons = unsubscribed_reasons_array?.length > 0 ? unsubscribed_reasons_array?.join(",") : "";
        
        if(unsubscribed_reasons){
            Axios.put(`${BaseUrl}/unsubscribe/${matchedHashedEmail?.email}`, { 
                unsubscribed_reasons: unsubscribed_reasons, 
            }).then((response) => {
                toast.success('Unsubscribed successfully!');
            })
        }else {
            toast.error('Please specify the reason!');
        }
    }

  return (
    <div className='bg-blue-50'>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5'>
            <div className='flex justify-center'>
                <div className='h-screen py-24'>
                    <div className='inputBoxBorder rounded-lg shadow-lg md:p-12 p-5 bg-white'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <label htmlFor="volunteeredOrganizations" className='text-lg font-semibold text-[#2E3033]'>Would you mind, letting us know why you unsubscribed?</label>
                                <div className='mt-2'>
                                    {options?.map((option) => (
                                        <div key={option?.id} className='text-base text-[#777B84] mb-1'>
                                            <input
                                                type="checkbox"
                                                value={option?.value}
                                                onChange={handleChange}
                                                className='mr-2'
                                            />
                                            {option?.value}
                                        </div>
                                    ))}
                                    {other===true && (
                                        <input {...register('otherReasons')} type="text" placeholder="Please specify" className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full'  />
                                    )}
                                </div>
                            </div>
                            <input type='submit' className='cursor-pointer bg-blue-500 hover:bg-blue-600 transition-all font-semibold text-white md:text-base text-sm md:py-2 py-1 md:px-8 px-5 rounded-lg mt-5' />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Unsubscribe;
