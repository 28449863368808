import { signOut } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import auth from '../../firebase.init';
import { AiOutlineDownload, AiOutlineMenu } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { BsSearch } from "react-icons/bs";
// import { HiLanguage } from "react-icons/hi2";
import Avatar from '../../assets/avatar.png';
import LanguageIcon from '../../assets/language-icon.png';
// import Logo from '../../assets/logo.png';
import Logo from '../../assets/tele-logo.png';
import WhiteLogo from '../../assets/tele-logo-white.png';
import UkFlag from '../../assets/uk.svg';
import BdFlag from '../../assets/bd.svg';
import Axios from 'axios';
import BaseUrl from '../BaseURL';
import { GlobalContext } from '../GlobalState/GlobalState';

function Navbar() {
  const [user] = useAuthState(auth);
  const [isOpenOurWorkPhn, setIsOpenOurWorkPhn] = useState(false);
  const [isOpenLearnPhn, setIsOpenLearnPhn] = useState(false);
  const [isOpenHamburger, setIsOpenHamburger] = useState(false);
  const [isOpenOurWork, setIsOpenOurWork] = useState(false);
  const [isOpenLearn, setIsOpenLearn] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const [users, setUsers] = useState([]);
  const [userInfos, setUserInfos] = useState([]);
  const signedInUser = users?.find((person) => person?.userEmail === user?.email);
  const signedInUserInfos = userInfos?.find(userInfo => userInfo?.user_id === signedInUser?.id);
//   console.log(signedInUserInfos);
  const navigate = useNavigate();

  // Language translate
  const { language, setLanguage } = useContext(GlobalContext);
  const handleEnglishClick = () => {
      setLanguage('en');
      setIsOpenLanguage(!isOpenLanguage);
  }; 
  const handleBengaliClick = () => {
      setLanguage('bn');
      setIsOpenLanguage(!isOpenLanguage);
  };

  // Get infos
  useEffect(()=>{
      Axios.get(`${BaseUrl}/infos_for_navbar`).then((response) => {
        setUsers(response.data);
      });
  },[]);
  // Get user details
  useEffect(()=>{
    Axios.get(`${BaseUrl}/userInfos`).then((response) => {
      setUserInfos(response.data);
    });
  },[]);

  // Logout
  const logout = () => {
    signOut(auth);
    navigate('/');
  };

  const location = useLocation();
  
  useEffect(() => {
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);
    
    setIsOpenHamburger(false);
    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  }, [location]);
  
  const handleDocumentClick = (event) => {
    const isInsideComponent =
        event.target.closest(".dropdown-closing") !== null;
    if (!isInsideComponent) {
        setIsOpenOurWork(false);
        setIsOpenLearn(false);
        setIsOpenLanguage(false);
        setIsOpenProfile(false);

        setIsOpenHamburger(false);
        setIsOpenOurWorkPhn(false);
        setIsOpenLearnPhn(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
        document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  
  const handleOpenOurWork = () => {
    setIsOpenOurWork(!isOpenOurWork);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);
    
    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  };
  
  const handleOpenLearn = () => {
    setIsOpenLearn(!isOpenLearn);
    setIsOpenOurWork(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);

    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  };
  
  const handleOpenLanguage = () => {
    setIsOpenLanguage(!isOpenLanguage);
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenProfile(false);

    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  };
  
  const handleOpenProfile = () => {
    setIsOpenProfile(!isOpenProfile);
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);

    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  };
  
  const handleOpenHamburger = () => {
    setIsOpenHamburger(!isOpenHamburger);
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);

    setIsOpenOurWorkPhn(false);
    setIsOpenLearnPhn(false);
  };  

  const handleOpenOurWorkPhn = () => {
    setIsOpenOurWorkPhn(!isOpenOurWorkPhn);
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);

    setIsOpenLearnPhn(false);
  };

  const handleOpenLearnPhn = () => {
    setIsOpenLearnPhn(!isOpenLearnPhn);
    setIsOpenOurWork(false);
    setIsOpenLearn(false);
    setIsOpenLanguage(false);
    setIsOpenProfile(false);

    setIsOpenOurWorkPhn(false);
  };


  return (
    <section className='bg-[#F6FAFF]'>
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 py-4'>
            <div className='lg:block hidden'>
                <div className='flex justify-between items-center'>
                    <div>
                        { language === 'en' ?
                            <Link to='/'><img src={Logo} alt='Logo' className='w-44' /></Link>
                            :
                            <Link to='/'><img src={WhiteLogo} alt='Logo' className='w-40' /></Link>
                        }
                    </div>
                    <div className='flex gap-5 relative'>
                        <Link to='/' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Home' : 'হোম'}</Link>

                        {/* Our Work */}
                        {/* <div className='relative dropdown-closing' >
                            <li className='cursor-pointer flex items-center gap-2 font-semibold text-base' onClick={() => setIsOpenOurWork(!isOpenOurWork)} >Our Work<span style={{color: '#F74F22'}} className={`text-sm ${isOpenOurWork && 'rotate-180'} transition-all`}><FaChevronDown /></span></li>
                            <div className={`absolute w-52 top-6 -left-5 transform scale-0 ${isOpenOurWork && 'scale-100'} transition duration-150 ease-in-out origin-top pt-2 z-50`}>
                                <div className='grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg'>
                                <NavLink to='/about-us' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>About Us</NavLink>
                                <NavLink to='/vision-and-values' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Vision and values</NavLink>
                                <NavLink to='/meet-the-team' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Meet The Team</NavLink>
                                <NavLink to='/collaboration' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Collaboration</NavLink>
                                </div>
                            </div>
                        </div> */}
                        <div className="relative dropdown-closing">
                            <button
                                className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}
                                onClick={handleOpenOurWork}
                            >
                                {language === 'en' ? 'Our Work' : 'আমাদের কাজ'}
                                <span
                                    className={`text-sm text-[#F74F22] ${isOpenOurWork && "rotate-180"} transition-all`}
                                >
                                    <FaChevronDown />
                                </span>
                            </button>
                            <div
                                className={`absolute w-52 top-6 -left-5 transform scale-0 ${
                                isOpenOurWork && "scale-100"
                                } transition duration-150 ease-in-out origin-top pt-2 z-50`}
                            >
                                <div className="grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg">
                                    <NavLink to='/about-us' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'About Us' : 'আমাদের কথা'}</NavLink>

                                    {/* They don't need this now */}

                                    {/* <NavLink to='/vision-and-values' className='text-sm' style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>Vision and values</NavLink> */}

                                    <NavLink to='/meet-the-team' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Meet The Team' : 'টেলিমেন্টরিং টিম'}</NavLink>
                                    <NavLink to='/collaborators' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Collaborators' : 'সহযোগীবৃন্দ'}</NavLink>
                                    <NavLink to='/volunteers?page=1' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Volunteers' : 'স্বেচ্ছাসেবকবৃন্দ'}</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className='group'>
                            <Link to='/global-presence' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Global Presence' : 'বৈশ্বিক উপস্থিতি'}</Link>
                            {/* <Link to='' className='cursor-pointer flex items-center gap-2 font-semibold text-base'>Global Presence<span style={{color: '#F74F22'}} className='text-sm group-hover:rotate-180 transition-all'><FaChevronDown /></span></Link>
                            <div className='absolute w-52 top-6 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top pt-2 z-50'>
                                <div className='grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg'>
                                <NavLink to='/bangladesh' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Bangladesh</NavLink>
                                <NavLink to='/nepal' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Nepal</NavLink>
                                </div>
                            </div> */}
                        </div>

                        {/* Learn */}
                        {/* <div className='relative dropdown-closing' >
                            <li className='cursor-pointer flex items-center gap-2 font-semibold text-base' onClick={() => setIsOpenLearn(!isOpenLearn)}>Learn<span style={{color: '#F74F22'}} className={`text-sm ${isOpenLearn && 'rotate-180'} transition-all`}><FaChevronDown /></span></li>
                            <div className={`absolute w-52 top-6 -left-14 transform scale-0 ${isOpenLearn && 'scale-100'} transition duration-150 ease-in-out origin-top pt-2 z-50`}>
                                <div className='grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg'>
                                <NavLink to='/faq' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>FAQs</NavLink>
                                <NavLink to='/publications' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Publications</NavLink>
                                <NavLink to='/media' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Media</NavLink>
                                </div>
                            </div>
                        </div> */}
                        <div className="relative dropdown-closing">
                            <button
                                className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}
                                onClick={handleOpenLearn}
                            >
                                {language === 'en' ? 'Learn More' : 'আরো জানুন'}
                                <span
                                className={`text-sm text-[#F74F22] ${isOpenLearn && "rotate-180"} transition-all`}
                                >
                                    <FaChevronDown />
                                </span>
                            </button>
                            <div
                                className={`absolute w-52 top-6 -left-14 transform scale-0 ${
                                isOpenLearn && "scale-100"
                                } transition duration-150 ease-in-out origin-top pt-2 z-50`}
                            >
                                <div className="grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg">
                                    <NavLink to='/faq' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'FAQs' : 'সাধারণ জিজ্ঞাসা'}</NavLink>
                                    <NavLink to='/publications' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Publications' : 'গবেষণা ও প্রতিবেদন'}</NavLink>

                                    <NavLink to='/media' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Media' : 'মিডিয়া'}</NavLink>
                                    {/* Don't remove this. This will need letter */}
                                    {/* <NavLink to='/blogs' className='text-sm' style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>Blogs</NavLink> */}
                                    <NavLink to='/contact-us' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>{language === 'en' ? 'Contact Us' : 'যোগাযোগ'}</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className='group relative'>
                            <Link to='/get-involved?join=volunteer' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Get Involved' : 'যুক্ত হোন'}</Link>
                            {/* <li className='cursor-pointer flex items-center gap-2 font-semibold text-base'>Get Involved<span style={{color: '#F74F22'}} className='text-sm group-hover:rotate-180 transition-all'><FaChevronDown /></span></li>
                            <div className='absolute w-52 top-6 transform scale-0 group-hover:scale-100 transition duration-150 ease-in-out origin-top pt-2 z-50'>
                                <div className='grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg'>
                                <NavLink to='/become-a-mentor' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Become a Mentor</NavLink>
                                <NavLink to='/community-members' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Community Members</NavLink>
                                <NavLink to='/become-a-partner' className='text-sm' style={({ isActive }) => {
                                    return {
                                    color: isActive ? "#2E3033" : "#777B84",
                                    };
                                }}>Become a Partner</NavLink>
                                </div>
                            </div> */}
                        </div>

                        {/* <div>
                            {user && <Link to='/stream' className='cursor-pointer flex items-center gap-2 font-semibold text-base'>Stream</Link>}
                        </div> */}
                    </div>
                    <div className='flex gap-5 items-center'>
                        <div>
                            {user && <Link to='/newsfeed' className={`cursor-pointer flex items-center gap-2 font-semibold text-base border border-[#F74F22] bg-[#F74F22] hover:bg-transparent text-white hover:text-[#F74F22] transition-all px-3 py-1.5 rounded-md ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Newsfeed' : 'নিউজফিড'}</Link>}
                        </div>
                        
                        <div className="relative dropdown-closing">
                            <button
                                className="cursor-pointer bg-white w-8 h-8 rounded-full flex justify-center items-center"
                                onClick={handleOpenLanguage}
                            >
                                <img src={LanguageIcon} className='w-5' />
                            </button>
                            <div
                                className={`absolute w-32 -left-12 transform scale-0 ${isOpenLanguage && 'scale-100'} transition duration-150 ease-in-out origin-top pt-2 z-50`}
                            >
                                <div className="grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg">
                                    <button onClick={handleEnglishClick} disabled={language === 'en'} className={`flex items-center gap-2 text-base ${language === 'en' && 'text-gray-400'}`}><img src={UkFlag} alt='UK Flag' className='w-6 h-4' />English</button>
                                    <button onClick={handleBengaliClick} disabled={language === 'bn'} className={`flex items-center gap-2 text-base ${language === 'bn' && 'text-gray-400'}`}><img src={BdFlag} alt='UK Flag' className='w-6 h-4 banglaFont' />বাংলা</button>
                                </div>
                            </div>
                        </div>

                        { user ? 
                            // Profile
                            <div className="relative dropdown-closing">
                                <button
                                    className="flex items-center justify-center active:bg-transparent focus:bg-transparent hover:bg-transparent w-10 h-10 rounded-full cursor-pointer border-2"
                                    onClick={handleOpenProfile}
                                >
                                    { signedInUser?.profileImageUrl ?
                                        <div className='w-10 h-10 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${signedInUser?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                        :
                                        <div className='w-10 h-10 rounded-full bg-[#eaeaef]' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                                    }
                                </button>
                                <div
                                    className={`absolute w-36 -left-12 transform scale-0 ${isOpenProfile && 'scale-100'} transition duration-150 ease-in-out origin-top pt-2 z-50`}
                                >
                                    <div className="grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg">
                                        {
                                            signedInUserInfos?.birthDate && signedInUserInfos?.nationality && signedInUserInfos?.district && signedInUserInfos?.city && signedInUserInfos?.economicStatus && signedInUserInfos?.background 
                                            ?
                                            <NavLink to='/my-profile' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                            }}>{language === 'en' ? 'My Profile' : 'আমার প্রোফাইল'}</NavLink>
                                            :
                                            <NavLink to='/user-info' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                            }}>{language === 'en' ? 'My Profile' : 'আমার প্রোফাইল'}</NavLink>
                                        }
                                        <NavLink to='/user-settings' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                            color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'User Settings' : 'ইউজার সেটিংস'}</NavLink>
                                        { signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin' ?
                                            <NavLink to='/admin-settings/manage-users?page=1' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                                return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                                };
                                            }}>{language === 'en' ? 'Admin Settings' : 'এডমিন সেটিংস'}</NavLink>
                                            :
                                            ''
                                        }
                                        <span onClick={logout} className={`text-sm text-[#777B84] cursor-pointer ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Logout' : 'লগ আউট'}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <Link to="/login" className={`text-base font-medium rounded-lg flex items-center gap-2 px-6 py-2.5 bg-[#F74F22] hover:bg-[#f75022bc] transition-all text-[#FFEAE4] ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Login' : 'লগ ইন'}<span className='text-base -rotate-90'><AiOutlineDownload /></span></Link>
                        }
                    </div>
                </div>
            </div>
            <div className='lg:hidden block relative z-50 dropdown-closing'>
                <div className='flex justify-between items-center'>
                    <div>
                        { language === 'en' ?
                            <Link to='/'><img src={Logo} alt='Logo' className='w-44' /></Link>
                            :
                            <Link to='/'><img src={WhiteLogo} alt='Logo' className='w-40' /></Link>
                        }
                    </div>
                    <div className="block lg:hidden">
                        <div className='flex items-center gap-3'>
                            {user ? 
                                <div className="relative dropdown-closing">
                                    <button
                                        className="flex items-center justify-center active:bg-transparent focus:bg-transparent hover:bg-transparent w-10 h-10 rounded-full cursor-pointer border-2"
                                        onClick={handleOpenProfile}
                                    >
                                            { signedInUser?.profileImageUrl ?
                                                <img className='w-8 h-8 rounded-full' src={`${BaseUrl}${signedInUser?.profileImageUrl}`} alt="" referrerPolicy='no-referrer' />
                                                :
                                                <img className='w-full rounded-full' src={Avatar} alt="" referrerPolicy='no-referrer' />
                                            }
                                    </button>
                                    <div
                                        className={`absolute w-36 -left-12 transform scale-0 ${isOpenProfile && 'scale-100'} transition duration-150 ease-in-out origin-top pt-2 z-50`}
                                    >
                                        <div className="grid grid-cols-1 gap-2 bg-white rounded-xl px-5 py-4 shadow-lg">
                                            {
                                                signedInUserInfos?.birthDate && signedInUserInfos?.nationality && signedInUserInfos?.district && signedInUserInfos?.city && signedInUserInfos?.economicStatus && signedInUserInfos?.background 
                                                ?
                                                <NavLink to='/my-profile' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                                return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                                };
                                                }}>{language === 'en' ? 'My Profile' : 'আমার প্রোফাইল'}</NavLink>
                                                :
                                                <NavLink to='/user-info' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                                return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                                };
                                                }}>{language === 'en' ? 'My Profile' : 'আমার প্রোফাইল'}</NavLink>
                                            }
                                            <NavLink to='/user-settings' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                                return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                                };
                                            }}>{language === 'en' ? 'User Settings' : 'ইউজার সেটিংস'}</NavLink>

                                            { signedInUser?.role === 'Manager' || signedInUser?.role === 'Admin' ?
                                                <NavLink to='/admin-settings/manage-users?page=1' className={`text-sm ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                                    return {
                                                    color: isActive ? "#2E3033" : "#777B84",
                                                    };
                                                }}>{language === 'en' ? 'Admin Settings' : 'এডমিন সেটিংস'}</NavLink>
                                                :
                                                ''
                                            }
                                            <span onClick={logout} className={`text-sm text-[#777B84] cursor-pointer ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Logout' : 'লগ আউট'}</span>
                                        </div>
                                    </div>
                                </div>
                            :
                                <Link to="/login" className={`md:text-base text-xs font-medium md:rounded-lg rounded-md flex items-center md:gap-2 gap-1 px-3 md:py-1 py-2 bg-[#F74F22] hover:bg-[#f75022cf] transition-all text-[#FFEAE4] ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Login' : 'লগ ইন'}<span className='text-base -rotate-90'><AiOutlineDownload /></span></Link>
                            }
                            
                            <button
                                onClick={handleOpenHamburger}
                                className="flex items-center px-3 py-1.5 border rounded text-[#0082C4] border-[#0082C4]"
                            >
                                <AiOutlineMenu />
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`bg-white w-full rounded-lg shadow-md p-5 absolute scale-0 ${isOpenHamburger && 'scale-100'} transition duration-150 ease-in-out origin-top`}>
                        <div className='grid grid-cols-1 gap-5'>

                            <Link to='/' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Home' : 'হোম'}</Link>

                            {/* Our work */}
                            <div className='group dropdown-closing'>
                                <li className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`} onClick={handleOpenOurWorkPhn}>{language === 'en' ? 'Our Work' : 'আমাদের কাজ'}<span className={`text-[#F74F22] text-sm transition-all ${isOpenOurWorkPhn ? 'rotate-180' : 'rotate-0'}`}><FaChevronDown /></span></li>
                                <div className={`w-full top-6 transform ${isOpenOurWorkPhn ? 'block scale-100' : 'scale-0 hidden'} transition duration-150 ease-in-out origin-top pt-2 z-50`}>
                                    <div className='grid grid-cols-1 gap-3 px-4 py-1'>
                                        <NavLink to='/about-us' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'About Us' : 'আমাদের কথা'}</NavLink>

                                        {/* They don't need this now */}

                                        {/* <NavLink to='/vision-and-values' className='text-sm font-semibold bg-blue-100 rounded-md px-3 py-1' style={({ isActive }) => {
                                            return {
                                            color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>Vision and values</NavLink> */}

                                        <NavLink to='/meet-the-team' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Meet The Team' : 'টেলিমেন্টরিং টিম'}</NavLink>
                                        <NavLink to='/collaborators' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Collaborators' : 'সহযোগীবৃন্দ'}</NavLink>
                                        <NavLink to='/volunteers?page=1' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Volunteers' : 'স্বেচ্ছাসেবকবৃন্দ'}</NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='group'>
                                <Link to='/global-presence' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Global Presence' : 'বৈশ্বিক উপস্থিতি'}</Link>
                                {/* <Link to='' className='cursor-pointer flex items-center gap-2 font-semibold text-base'>Global Presence<span style={{color: '#F74F22'}} className='text-sm group-hover:rotate-180 transition-all'><FaChevronDown /></span></Link> */}
                                {/* <div className='w-full top-6 transform scale-0 hidden group-hover:block group-hover:scale-100 transition duration-150 ease-in-out origin-top pt-2 z-50'>
                                    <div className='grid grid-cols-1 gap-2 px-4 py-1'>
                                    <NavLink to='/bangladesh' className='text-sm' style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>Bangladesh</NavLink>
                                    <NavLink to='/nepal' className='text-sm' style={({ isActive }) => {
                                        return {
                                        color: isActive ? "#2E3033" : "#777B84",
                                        };
                                    }}>Nepal</NavLink>
                                    </div>
                                </div> */}
                            </div>

                            {/* Learn More */}
                            <div className='dropdown-closing'>
                                <li className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`} onClick={handleOpenLearnPhn}>{language === 'en' ? 'Learn More' : 'আরো জানুন'}<span className={`text-[#F74F22] text-sm transition-all ${isOpenLearnPhn ? 'rotate-180' : 'rotate-0'}`}><FaChevronDown /></span></li>
                                <div className={`w-full top-6 transform ${isOpenLearnPhn ? 'block scale-100' : 'scale-0 hidden'} transition duration-150 ease-in-out origin-top pt-2 z-50`}>
                                    <div className='grid grid-cols-1 gap-3 px-4 py-1'>
                                        <NavLink to='/faq' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'FAQs' : 'সাধারণ জিজ্ঞাসা'}</NavLink>
                                        <NavLink to='/publications' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Publications' : 'গবেষণা ও প্রতিবেদন'}</NavLink>
                                        <NavLink to='/media' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Media' : 'মিডিয়া'}</NavLink>
                                        {/* Don't remove this. This will need letter. */}
                                        {/* <NavLink to='/blogs' className='text-sm font-semibold bg-blue-100 rounded-md px-3 py-1' style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>Blogs</NavLink> */}
                                        <NavLink to='/contact-us' className={`text-sm font-semibold bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'banglaFont'}`} style={({ isActive }) => {
                                            return {
                                                color: isActive ? "#2E3033" : "#777B84",
                                            };
                                        }}>{language === 'en' ? 'Contact Us' : 'যোগাযোগ'}</NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='group'>
                                <Link to='/get-involved?join=volunteer' className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Get Involved' : 'যুক্ত হোন'}</Link>
                            </div>

                            {/* Language */}
                            <div className='dropdown-closing'>
                                <li className={`cursor-pointer flex items-center gap-2 font-semibold text-base ${language === 'bn' && 'banglaFont'}`} onClick={handleOpenLanguage}>{language === 'en' ? 'Language' : 'ভাষা'}<span className={`text-[#F74F22] text-sm transition-all ${isOpenLanguage ? 'rotate-180' : 'rotate-0'}`}><FaChevronDown /></span></li>
                                <div className={`w-full top-6 transform ${isOpenLanguage ? 'block scale-100' : 'scale-0 hidden'} transition duration-150 ease-in-out origin-top pt-2 z-50`}>
                                    <div className='grid grid-cols-1 gap-3 px-4 py-1'>
                                        <button onClick={handleEnglishClick} disabled={language === 'en'} className={`flex items-center gap-2 text-sm bg-blue-100 rounded-md px-3 py-1 ${language === 'en' && 'text-gray-400'}`}><img src={UkFlag} alt='UK Flag' className='w-6 h-4' />English</button>
                                        <button onClick={handleBengaliClick} disabled={language === 'bn'} className={`flex items-center gap-2 text-sm bg-blue-100 rounded-md px-3 py-1 ${language === 'bn' && 'text-gray-400'}`}><img src={BdFlag} alt='UK Flag' className='w-6 h-4 banglaFont' />বাংলা</button>
                                    </div>
                                </div>
                            </div>

                            <div className='flex'>
                                {user && <Link to='/newsfeed' className={`cursor-pointer flex items-center gap-2 font-semibold text-base border border-[#F74F22] bg-[#F74F22] hover:bg-transparent text-white hover:text-[#F74F22] transition-all px-3 py-1.5 rounded-md ${language === 'bn' && 'banglaFont'}`}>{language === 'en' ? 'Newsfeed' : 'নিউজফিড'}</Link>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Navbar