import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import NextIcon from '../../assets/AboutUs/next-icon.png';
import GlobalPresenceHeroBg from '../../assets/GlobalPresence/global-presence-hero-bg.png';
import BdFlag2 from '../../assets/GlobalPresence/bd-flag2.png';
import NepalFlag2 from '../../assets/GlobalPresence/nepal-flag2.png';
import BdStudent1 from '../../assets/GlobalPresence/bd-student1.png';
import BdStudent2 from '../../assets/GlobalPresence/bd-student2.png';
import BdStudent3 from '../../assets/GlobalPresence/bd-student3.png';
import NepalStudent1 from '../../assets/GlobalPresence/nepal-student1.png';
import NepalStudent2 from '../../assets/GlobalPresence/nepal-student2.png';
import NepalStudent4 from '../../assets/GlobalPresence/nepal-student4.png';
import ScrollToTop from '../Common/ScrollToTop';
import Footer from '../Common/Footer';
import BecomeVolunteer from '../BecomeVolunteer/BecomeVolunteer';
import {Helmet} from "react-helmet";
import { GlobalContext } from '../GlobalState/GlobalState';

function GlobalPresence() {
    const { language } = useContext(GlobalContext);
  return (
    <div>
        <Helmet>
            <title>Global Presence - Telementoring</title>
            <meta name='title' content='Global Presence - Telementoring' />
            <meta name="description" content="Learn about Telementoring's work in Bangladesh and Nepal, where our volunteer-based educational program has been providing services via audio-based telementoring delivered through IVR technology and SMS messages." />
            <meta name="keywords" content="telementoring, Bangladesh, Nepal, rural communities, IVR technology, SMS messages, volunteer-based educational program" />
        </Helmet>
        
        {/* Hero */}
        <section>
            <div className='relative' style={{"backgroundImage": `url(${GlobalPresenceHeroBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize':'cover'}}>
                <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 h-[300px] flex items-center'>
                    <div className='TextInnerBg w-full'>
                        { language === 'en' ?
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold'>Global <span className='text-[#FDBE44]'>Presence</span></p>
                            :
                            <p className='text-[#F6FAFF] md:text-4xl text-2xl font-bold banglaFont'>বৈশ্বিক <span className='text-[#FDBE44]'>উপস্থিতি</span></p>
                        }
                        <div className='flex items-center gap-2 mt-4'>
                            { language === 'en' ?
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all'>Home</Link>
                                :
                                <Link to='/' className='text-[#B7BBC1] text-base hover:text-white transition-all banglaFont'>হোম</Link>
                            }
                            <span><img src={NextIcon} alt='Next Icon' /></span>
                            { language === 'en' ?
                                <Link to='/global-presence' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all'>Global Presence</Link>
                                :
                                <Link to='/global-presence' className='text-[#FDBE44] text-base hover:text-[#f6ab15] transition-all banglaFont'>বৈশ্বিক উপস্থিতি</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 -mt-2.5'>
                <div className='w-full h-[20px] bg-[#0082C4] opacity-[0.2]'></div>
            </div>
        </section>

        {/* Content */}
        <section className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 lg:pt-20 md:pt-16 pt-12 lg:pb-12 md:pb-8 pb-8'>
            <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-12 lg:mb-20 md:mb-8 mb-8'>
                <div className='flex flex-col gap-5 md:order-1 order-2'>
                    <div className='flex'>
                        { language === 'en' ?
                            <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE]'>Global Presence</p>
                            :
                            <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>বৈশ্বিক উপস্থিতি</p>
                        }
                    </div>
                    { language === 'en' ?
                        <p className='text-[#2E3033] md:text-4xl text-2xl font-bold'>Bangladesh</p>
                        :
                        <p className='text-[#2E3033] md:text-4xl text-2xl font-bold banglaFont'>বাংলাদেশ</p>
                    }
                    <div className='text-[#777B84] text-base text-justify'>
                        { language === 'en' ?
                            <p>Telementoring was first introduced as a volunteer-based educational program in the Khulna and Satkhira districts in the southwestern part of Bangladesh. Audio-based telementoring delivered via IVR technology and SMS messages were later introduced. Since its inception in 2020, telementoring has been provided in more than 300 villages across Bangladesh.</p>
                            :
                            <p className='banglaFont'>বাংলাদেশের দক্ষিণ-পশ্চিমাঞ্চলের খুলনা ও সাতক্ষীরা জেলায় স্বেচ্ছাসেবক-ভিত্তিক শিক্ষামূলক কর্মসূচি হিসেবে টেলিমেন্টরিং প্রথম ২০২০ সালে চালু হয়। এর ধারাবাহিকতায়, ২০২১ সালে আই ভি আর (IVR) প্রযুক্তির মাধ্যমে অডিও-ভিত্তিক টেলিমেন্টরিং এবং এসএমএস সেবা চালু করা হয়েছিল। 2020 সালে এর সূচনা থেকে এখন পর্যন্ত বাংলাদেশের 300 টিরও বেশি গ্রামে টেলিমেন্টরিং সেবা প্রদান করা হয়েছে।</p>
                        }
                    </div>
                    <div className='grid grid-cols-3'>
                        <img src={BdStudent1} alt='Bd Student1' />
                        <img src={BdStudent2} alt='Bd Student2' />
                        <img src={BdStudent3} alt='Bd Student3' />
                    </div>
                </div>
                <div className='md:order-2 order-1'>
                    <img src={BdFlag2} alt='Bd flag' />
                </div>
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 items-center gap-12'>
                <div>
                    <img src={NepalFlag2} alt='Nepal Flag' />
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex'>
                        { language === 'en' ?
                            <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE]'>Global Presence</p>
                            :
                            <p className='lg:text-lg md:text-base text-sm font-bold py-1 px-5 rounded-full text-center text-[#F74F22] bg-[#F9EEEE] banglaFont'>বৈশ্বিক  উপস্থিতি</p>
                        }
                    </div>
                    { language === 'en' ?
                        <p className='text-[#2E3033] md:text-4xl text-2xl font-bold'>Nepal</p>
                        :
                        <p className='text-[#2E3033] md:text-4xl text-2xl font-bold banglaFont'>নেপাল</p>
                    }
                    <div className='text-[#777B84] text-base text-justify'>
                        { language === 'en' ?
                            <p>Following the successful model implemented in Bangladesh, an audio-based telementoring program was introduced in 100+ villages across Nepal in early 2023. Similar to the first telementoring project in Bangladesh, it was implemented as a randomized controlled trial to ensure the program could be rigorously evaluated for its impacts.</p>
                            :
                            <p className='banglaFont'>বাংলাদেশে বাস্তবায়িত টেলিমেন্টরিং সেবার সফলতায় অনুপ্রাণিত হয়ে, 2023 সালের শুরুর দিকে নেপাল জুড়ে ১০০+ গ্রামে অডিও-ভিত্তিক টেলিমেন্টরিং প্রোগ্রাম চালু করা হয়েছে। বাংলাদেশে প্রথম টেলিমেন্টরিং প্রকল্পের মতো, এই প্রকল্পটিও দৈবচয়ন ভিত্তিক সমীক্ষা হিসাবে বাস্তবায়িত হচ্ছে যাতে এই পাইলট প্রকল্পের এর প্রভাব সঠিকভাবে ও বিশ্বাসযোগ্যভাবে মূল্যায়ন করা যায়।</p>
                        }
                    </div>
                    <div className='grid grid-cols-3'>
                        <img src={NepalStudent1} alt='Nepali Student1' />
                        <img src={NepalStudent2} alt='Nepali Student2' />
                        <img src={NepalStudent4} alt='Nepali Student4' />
                    </div>
                </div>
            </div>
        </section>

        {/* Become Volunteer */}
        <section className='mb-20'>
            <BecomeVolunteer/>
        </section>

        <ScrollToTop></ScrollToTop>
        
        <Footer/>
    </div>
  );
};

export default GlobalPresence