import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import auth from '../../firebase.init';
import BaseUrl from '../BaseURL';
import Avatar from '../../assets/avatar.png';
import SingleComment from './SingleComment';
import { SlOptionsVertical } from 'react-icons/sl';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsFillPinAngleFill, BsFillPinFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import JoditEditor from "jodit-react";
import './AddBlog.css';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ProfileBg from '../../assets/profileBg.png';
import { GlobalContext } from "../GlobalState/GlobalState";
import { useUserContext } from '../GlobalState/UserContext';

function Newsfeed() {
  const { language } = useContext(GlobalContext);
  // const { signedInUser, personalInfo } = useUserContext();

  const [user] = useAuthState(auth);
  const [active, setActive]=useState(0);
  const [activePinnedPost, setActivePinnedPost]=useState(0);
  const [personalInfo, setPersonalInfo] = useState([]);
  const signedInUser = personalInfo?.find((person) => person?.userEmail === user?.email);
  const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
  const [posts, setPosts]= useState([]);
  const pinnedPosts = posts?.filter((post) => post?.pin_unpin === 'pinned');
  const unpinnedPosts = posts?.filter((post) => post?.pin_unpin === 'unpinned');

  // get all users mail
  const recipientsEmail = personalInfo?.map((e) => e?.email);
  const recipientsFirstName = personalInfo?.map((e) => e?.firstName);

  const [editPostId, setEditPostId]=useState();
  const clickedPost = posts?.find((post)=>post?.id === editPostId);

  const [smtp, setSmtp] = useState([]);
  // Get smtp
  useEffect(()=>{
    Axios.get(`${BaseUrl}/smtp`).then((response) => {
        setSmtp(response.data);
    });
  },[]);

  // popup
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  // Content
  const [content, setContent] = useState('');
  // Title
  const [title, setTitle] = useState('');
  // Checkbox
  const [isChecked, setIsChecked] = useState(false);
  // get title
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  // Reset form
  const resetForm = () => {
    setTitle('');
    setContent('');
  };

  // Get infos
  useEffect(()=>{
    Axios.get(`${BaseUrl}/infos`).then((response) => {
      setPersonalInfo(response.data);
    });
  },[])

  // Get posts
  useEffect(()=>{
    Axios.get(`${BaseUrl}/posts`).then((response) => {
      setPosts(response.data);
    });
  },[]);

  // Submit form
  const upload = async e => {
    e.preventDefault();
    const user_id = signedInUser?.id;
    const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
    const authorImg = signedInUser?.profileImageUrl;
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    const post_time =currentTime + ',' + ' ' + currentDate;

    Axios.post(`${BaseUrl}/addPost`, {
      title: title,
      content: content,
      author: author,
      authorImg: authorImg,
      user_id: user_id,
      post_time: post_time,
      pin_unpin:"unpinned",
      postType:"post",
    }).then(async(response) => {
      toast.success("Successfully posted!");

      if(isChecked){
        const convertedContent = content?.replace(/<[^>]*>?/gm, '')?.replace(/&[^\s]*;/gm, '');
        Axios.post(`${BaseUrl}/sendPostNotification`, {
          title: title,
          content: convertedContent,
          recipientsEmail: recipientsEmail,
          recipientsFirstName: recipientsFirstName,
          postDate: currentDate,
          authorFirstName: signedInUser?.firstName,
          authorFullName: author,
          postType: 'post',
          postLink: 'https://telementoring.net/newsfeed',

          host: smtp?.smtpHost,
          port: smtp?.smtpPort,
          user: smtp?.smtpEmail,
          pass: smtp?.smtpPass,
        }).then(()=>{
          setIsChecked(!isChecked);
          setTimeout(()=>{
            toast.success("Mail sent!");
          }, 4000);
        })
      };
      await Axios.get(`${BaseUrl}/posts`).then((response) => {
        setPosts(response.data);
      });
      resetForm();
      
    });
  };
  // Update post
  const inputRefs = {
    newTitle: useRef(null),
    newContent: useRef(null),
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const id = clickedPost?.id;

    const formData = {
      newTitle: inputRefs.newTitle.current.value,
      newContent: inputRefs.newContent.current.value,
    };
    Axios.put(`${BaseUrl}/updatePost`, { 
      title:formData.newTitle, 
      content:formData.newContent,
      id: id 
    }).then(
        (response) => {
          setPosts(
            posts.map((val) => {
              return val.id === id
                ? {
                    id: val.id,
                    title: val.newTitle,
                    content: val.newContent,
                  }
                : val;
            })
          );
          Axios.get(`${BaseUrl}/posts`).then((response) => {
            setPosts(response.data);
          });
          setIsOpenPopup(false);
          toast.success("Updated!");
        }
      );
  };
  // Delete Post
  const handleDeletePost = (id) => {
    const proceed = window.confirm("Are you sure?");
    if(proceed){
      Axios.delete(`${BaseUrl}/posts/${id}`).then((response) => {
        setPersonalInfo(
          personalInfo.filter((val) => {
            return val.id !== id;
          })
        );
        Axios.get(`${BaseUrl}/posts`).then((response) => {
          setPosts(response.data);
        });
        toast.success("Deleted!");
      });
    }
  };
  // Pin Post
  const handlePinPost = (id) => {
    if(signedInUser?.role==="Manager" || signedInUser?.role==="Admin"){
      Axios.put(`${BaseUrl}/updatePinUnpin`, { 
        pin_unpin:"pinned", 
        id: id 
      }).then(
          (response) => {
            setPosts(
              posts?.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      pin_unpin: val.pin_unpin,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/posts`).then((response) => {
              setPosts(response.data);
            });
            toast.success("This post is pinned now!");
          }
        );
    }else{
      toast.warn("Only manager or admin can make pinned!");
    }
  };
  // Unpin Post
  const handleUnpinPost = (id) => {
    if(signedInUser?.role==="Manager" || signedInUser?.role==="Admin"){
      Axios.put(`${BaseUrl}/updatePinUnpin`, { 
        pin_unpin:"unpinned", 
        id: id 
      }).then(
          (response) => {
            setPosts(
              posts?.map((val) => {
                return val.id === id
                  ? {
                      id: val.id,
                      pin_unpin: val.pin_unpin,
                    }
                  : val;
              })
            );
            Axios.get(`${BaseUrl}/posts`).then((response) => {
              setPosts(response.data);
            });
            toast.success("This post is Unpinned now!");
          }
        );
    }else{
      toast.warn("Only manager or admin can make Unpinned!");
    }
  };

  // handle closing for outside clicking
  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isInsideComponent = event.target.closest('.dropdown-closing') !== null;
      if (!isInsideComponent) {
        setActive(0);
        setActivePinnedPost(0);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [setActive, setActivePinnedPost]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);

    const combinedArray = [...unpinnedPosts];
    const sortedArray = combinedArray?.sort((a, b) => {
      return new Date(b?.post_time) - new Date(a?.post_time);
    });

    const itemsPerPage = 10;
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    const getVisiblePageNumbers = (currentPage, totalPages) => {
      const visiblePagesLimit = 4;
      if (totalPages <= visiblePagesLimit) {
        return Array.from(Array(totalPages).keys()).map((i) => i + 1);
      }
      const startPage = Math.max(currentPage - Math.floor(visiblePagesLimit / 2), 1);
      const endPage = Math.min(startPage + visiblePagesLimit - 1, totalPages);
      const visiblePages = Array.from(Array(endPage - startPage + 1).keys()).map((i) => i + startPage);
      return visiblePages;
    };
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = sortedArray.slice(startIndex, endIndex);
  
    const totalPages = Math.ceil(sortedArray.length / itemsPerPage);
    // const pageNumbers = Array.from(Array(totalPages).keys()).map((i) => i + 1);
    const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
      if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
      }
    }, [location.state]);


  return (
    <div>
      <Helmet>
        <title>Newsfeed - Telementoring</title>
        <meta name='title' content='Newsfeed - Telementoring' />
        <meta name="description" content="Watch all latest updates of Telementoring here." />
        <meta name="keywords" content="telementoring, Newsfeed" />
      </Helmet>

      {/* Hero */}
      <div className={`h-44 flex justify-center md:items-center ${signedInUser?.role === "Admin" || signedInUser?.role === "Manager" ? 'hidden' : 'block'}`} style={{"backgroundImage": `url(${ProfileBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize': 'cover'}}>
        { language === 'en' ?
          <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl'>Latest feeds</h2>
          :
          <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl banglaFont'>সর্বশেষ ফিডগুলো</h2>
        }
      </div>
      
      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 lg:px-8 md:py-8 py-5'>
        {/* Form section */}
        <div className={`${signedInUser?.role==="Admin" || signedInUser?.role==="Manager" ? 'block' : 'hidden'} border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3`}>
          { language === 'en' ?
            <p className='mb-5 font-semibold md:text-2xl text-xl md:text-left text-right'>Create a New Post</p>
            :
            <p className='mb-5 font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>নতুন পোস্ট তৈরি করুন</p>
          }
          <div>
            { language === 'en' ?
              <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                <div>
                  <input type="text" value={title} onChange={handleTitleChange} required placeholder='Write post title here...' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' />
                </div>
                <JoditEditor
                  value={content}
                  onChange={(newContent) => setContent(newContent)}
                  required
                />
                
                <div className='flex md:flex-row flex-col gap-4 justify-between'>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                      className="mr-2"
                    />
                    <label htmlFor="checkbox" className='text-[#777B84]' >Check to send this post notification to users email.</label>
                  </div>
                  <div className='flex gap-5'>
                    <input type="submit" value='Submit Post' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                  </div>
                </div>
              </form>
              :
              <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                <div>
                  <input type="text" value={title} onChange={handleTitleChange} required placeholder='পোস্ট টাইটেল লিখুন...' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' />
                </div>
                <JoditEditor
                  value={content}
                  onChange={(newContent) => setContent(newContent)}
                  required
                />
                
                <div className='flex md:flex-row flex-col gap-4 justify-between'>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                      className="mr-2"
                    />
                    <label htmlFor="checkbox" className='text-[#777B84] banglaFont' >ব্যবহারকারীদের ইমেলে এই পোস্ট নোটিফিকেশন পাঠাতে চেক করুন ।</label>
                  </div>
                  <div className='flex gap-5'>
                    <input type="submit" value='সাবমিট পোস্ট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                  </div>
                </div>
              </form>
            }
          </div>
        </div>
        {/* All posts */}
        <div className={`${signedInUser?.role==="Admin" || signedInUser?.role==="Manager" ? 'mt-16' : 'mt-0' }`} >
          <div className='mb-16'>
            {/* Pinned Posts */}
            {
              pinnedPosts?.map((singlePinnedPost)=> 
              <div key={singlePinnedPost?.id} className='justify-center flex flex-col gap-3 mb-5 border border-blue-500 border-opacity-20 rounded-2xl bg-[#fafcffb1]'>
                {/* Popup */}
                {isOpenPopup && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
                      </div>

                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                      <div className="inline-block align-bottom bg-white w-full md:p-8 p-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div>
                          <form onSubmit={(e) => handleUpdate(e)} className='flex flex-col justify-center gap-4'>
                            <input 
                              type='text' 
                              placeholder='Write post title here...' 
                              className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3]'
                              ref={inputRefs.newTitle}
                              defaultValue={clickedPost?.title}
                            />
                            <textarea 
                              placeholder='Write content' 
                              className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF]' 
                              rows="6" 
                              ref={inputRefs.newContent}
                              defaultValue={clickedPost?.content} 
                            />
                            <div className='flex justify-between'>
                              <div></div>
                              <div className='flex gap-5'>
                                <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Cancel</button>
                                <input type='submit' value='Update' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                }
                <div>
                  <div>
                    <div className="flex justify-between md:items-center md:px-8 px-3 md:py-5 py-3 border-b border-[#E3E6EA]">
                      <div className='flex items-center'>
                        <div className="md:w-12 md:h-12 w-10 h-10 border-2 border-blue-100 rounded-full overflow-hidden flex justify-center items-center">
                          { singlePinnedPost?.authorImg || singlePinnedPost?.authorImg !== null ?
                            <div className='md:w-10 md:h-10 w-8 h-8 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${singlePinnedPost?.authorImg}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='md:w-10 md:h-10 w-8 h-8 rounded-full' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                          }
                          {/* <img className="w-full h-full rounded-full" src={Avatar} alt="avatar"/> */}
                        </div>
                        <div className="ml-3">
                          <p className="text-[#0082C4] md:text-xl text-lg font-semibold whitespace-no-wrap">{singlePinnedPost?.author}</p>
                          <p className="text-[#A2AAB7] md:text-sm text-xs whitespace-no-wrap">{singlePinnedPost?.post_time}</p>
                          <div className={`flex ${singlePinnedPost?.postType === 'post'? 'hidden' : 'block'}`}>
                            <p className='text-xs font-semibold mb-1 border border-green-200 rounded-lg px-2 py-1 mt-2'>Automated post. Type: <span className='uppercase'>{singlePinnedPost?.postType}</span></p>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center md:gap-8 gap-3'>
                        <div className="relative inline-block group">
                          <span className="text-[#0082C4] text-lg cursor-pointer"><BsFillPinFill /></span>
                          { language === 'en' ?
                            <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Pinned</div>
                            :
                            <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">পিন</div>
                          }
                        </div>
                        {
                          signedInUser?.role==="Admin" || signedInUser?.role==="Manager"
                          ?
                          <div className='relative dropdown-closing'>
                            <button className='text-gray-500' onClick={() => {
                              // setActive(singlePinnedPost?.id)
                              setActivePinnedPost(singlePinnedPost?.id)
                              setEditPostId(singlePinnedPost?.id);
                              }}>
                              <SlOptionsVertical />
                            </button>
                            <div className={`absolute right-0 mt-2 md:w-40 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${activePinnedPost === singlePinnedPost?.id ? 'block' : 'hidden'}`}>
                              <div className="">
                                { language === 'en' ?
                                  <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeletePost(singlePinnedPost?.id)}>
                                    <RiDeleteBin6Line />Delete
                                  </button>
                                  :
                                  <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeletePost(singlePinnedPost?.id)}>
                                    <RiDeleteBin6Line />বাতিল করুন
                                  </button>
                                }
                                {
                                  singlePinnedPost?.pin_unpin === 'unpinned' ?
                                  <div>
                                    { language === 'en' ?
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handlePinPost(singlePinnedPost?.id)}>
                                        <BsFillPinAngleFill />Pin 
                                      </button>
                                      :
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handlePinPost(singlePinnedPost?.id)}>
                                        <BsFillPinAngleFill />পিন 
                                      </button>
                                    }
                                  </div>
                                  :
                                  <div>
                                    { language === 'en' ?
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handleUnpinPost(singlePinnedPost?.id)}>
                                        <BsFillPinAngleFill />Unpin 
                                      </button>
                                      :
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handleUnpinPost(singlePinnedPost?.id)}>
                                        <BsFillPinAngleFill />আনপিন 
                                      </button>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          :
                          ''
                        }
                      </div>
                    </div>
                  </div>
                  <div className='md:px-8 px-3 md:pt-5 pt-3 md:pb-10 pb-6 border-b border-[#E3E6EA]'>
                    <p className='md:text-2xl text-xl font-bold mb-1'>{singlePinnedPost?.title}</p>
                    <div className='mb-5' dangerouslySetInnerHTML={{__html: singlePinnedPost?.content}}></div>
                      { singlePinnedPost?.postType === 'media' &&
                        <div>
                          { language === 'en' ?
                            <a href={singlePinnedPost?.external_link} target='_blank' className='text-[#0082C4] underline cursor-pointer'>Read More</a>
                            :
                            <a href={singlePinnedPost?.external_link} target='_blank' className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</a>
                          }
                        </div>
                      }
                      { singlePinnedPost?.postType === 'blog' &&
                        <div>
                          { language === 'en' ?
                            <Link to={`/blog-details/${singlePinnedPost?.external_post_id}`} className='text-[#0082C4] underline'>Read More</Link>
                            :
                            <Link to={`/blog-details/${singlePinnedPost?.external_post_id}`} className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</Link>
                          }
                        </div>
                      }
                      { singlePinnedPost?.postType === 'publication' &&
                        <div>
                          { language === 'en' ?
                            <Link to={`/publication-details/${singlePinnedPost?.external_post_id}`} className='text-[#0082C4] underline'>Read More</Link>
                            :
                            <Link to={`/publication-details/${singlePinnedPost?.external_post_id}`} className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</Link>
                          }
                        </div>
                      }
                  </div>
                </div>
                <div>
                  <SingleComment 
                    signedInUser={signedInUser}
                    singlePostId={singlePinnedPost?.id} 
                    userId={signedInUser?.id} 
                    author={signedInUser?.firstName + ' ' + signedInUser?.lastName}
                    authorImg={signedInUser?.profileImageUrl}
                  />
                </div>
              </div>
              )
            }
          </div>

          {/* Unpinned posts */}
          {
            paginatedItems?.map((singlePost, index) =>
              <div key={index} className='justify-center flex flex-col gap-3 mb-5 border border-[#E3E6EA] rounded-2xl'>
                <div>
                  {/* Popup */}
                  {isOpenPopup && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                          <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block align-bottom bg-white w-full md:p-8 p-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                          <div>
                            <form onSubmit={(e) => handleUpdate(e)} className='flex flex-col justify-center gap-4'>
                              <input 
                                type='text' 
                                placeholder='Write post title here...' 
                                className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3]'
                                ref={inputRefs.newTitle}
                                defaultValue={clickedPost?.title}
                              />
                              <textarea 
                                placeholder='Write content' 
                                className='border border-blue-500 border-opacity-20 rounded-xl py-2 px-5 focus:outline-none bg-[#FAFCFF]' 
                                rows="6" 
                                ref={inputRefs.newContent}
                                defaultValue={clickedPost?.content} 
                              />
                              <div className='flex justify-between'>
                                <div></div>
                                <div className='flex gap-5'>
                                  { language === 'en' ?
                                    <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Cancel</button>
                                    :
                                    <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>বাতিল করুন</button>
                                  }
                                  { language === 'en' ?
                                    <input type='submit' value='Update' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                                    :
                                    <input type='submit' value='আপডেট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                                  }
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                  }
                  <div>
                    <div>
                      <div className="flex justify-between items-center md:px-8 px-3 md:py-5 py-3 border-b border-[#E3E6EA]">
                        <div className='flex items-center'>
                          <div className="md:w-12 md:h-12 w-10 h-10 border-2 border-blue-100 rounded-full overflow-hidden flex justify-center items-center">
                              { singlePost?.authorImg ?
                                <div className='md:w-10 md:h-10 w-8 h-8 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${singlePost?.authorImg}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                                :
                                <div className='md:w-10 md:h-10 w-8 h-8 rounded-full' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                              }
                          </div>
                          <div className="ml-3">
                              <p className="text-[#0082C4] md:text-xl text-lg font-semibold whitespace-no-wrap">{singlePost?.author}</p>
                              <p className="text-[#A2AAB7] md:text-sm text-xs whitespace-no-wrap">{singlePost?.post_time}</p>
                              <div className={`${singlePost?.postType === 'post'? 'hidden' : 'block'}`}>
                                <p className='text-xs font-semibold mb-1 border border-green-200 rounded-lg px-2 py-1 mt-2'>Automated post. Type: <span className='uppercase'>{singlePost?.postType}</span></p>
                              </div>
                          </div>
                        </div>
                        {
                          signedInUser?.role==="Admin" || signedInUser?.role==="Manager"
                          ?
                          <div className='relative dropdown-closing'>
                            <button className='text-gray-500' onClick={() => {
                              setActive(singlePost?.id)
                              setEditPostId(singlePost?.id);
                            }}>
                              <SlOptionsVertical />
                            </button>
                            <div className={`absolute right-0 mt-2 md:w-40 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${active === singlePost?.id ? 'block' : 'hidden'}`}>
                              <div className="">
                                { language === 'en' ?
                                  <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeletePost(singlePost?.id)}>
                                    <RiDeleteBin6Line />Delete
                                  </button>
                                  :
                                  <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1 banglaFont" onClick={() => handleDeletePost(singlePost?.id)}>
                                    <RiDeleteBin6Line />ডিলিট করুন
                                  </button>
                                }
                                {
                                  singlePost?.pin_unpin === 'unpinned' &&
                                  <div>
                                    { language === 'en' ?
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => handlePinPost(singlePost?.id)}>
                                        <BsFillPinAngleFill />Pin 
                                      </button>
                                      :
                                      <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1 banglaFont" onClick={() => handlePinPost(singlePost?.id)}>
                                        <BsFillPinAngleFill />পিন 
                                      </button>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          :
                          ''
                        }
                      </div>
                    </div>
                    <div className='md:px-8 px-3 md:pt-5 pt-3 md:pb-10 pb-6 border-b border-[#E3E6EA]'>
                      <p className='md:text-2xl text-xl font-bold mb-1'>{singlePost?.title}</p>

                      { singlePost?.postType === 'post' &&
                        <div dangerouslySetInnerHTML={{__html: singlePost?.content}}></div>
                      }
                      { singlePost?.postType !== 'post' &&
                        <div dangerouslySetInnerHTML={{__html: singlePost && singlePost?.content && singlePost?.content.length >= 120 ? singlePost?.content?.slice(0, 120) + "..." : singlePost?.content}} className='mb-5'></div>
                      }

                      { singlePost?.postType === 'media' &&
                        <div>
                          { language === 'en' ?
                            <a href={singlePost?.external_link} target='_blank' className='text-[#0082C4] underline cursor-pointer'>Read More</a>
                            :
                            <a href={singlePost?.external_link} target='_blank' className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</a>
                          }
                        </div>
                      }
                      { singlePost?.postType === 'blog' &&
                        <div>
                          { language === 'en' ?
                            <Link to={`/blog-details/${singlePost?.external_post_id}`} className='text-[#0082C4] underline'>Read More</Link>
                            :
                            <Link to={`/blog-details/${singlePost?.external_post_id}`} className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</Link>
                          }
                        </div>
                      }
                      { singlePost?.postType === 'publication' &&
                        <div>
                          { language === 'en' ?
                            <Link to={`/publication-details/${singlePost?.external_post_id}`} className='text-[#0082C4] underline'>Read More</Link>
                            :
                            <Link to={`/publication-details/${singlePost?.external_post_id}`} className='text-[#0082C4] underline cursor-pointer banglaFont'>আরও পড়ুন</Link>
                          }
                        </div>
                      }

                    </div>
                  </div>
                  <div>
                    <SingleComment 
                      signedInUser={signedInUser}
                      singlePostId={singlePost?.id} 
                      userId={signedInUser?.id} 
                      author={signedInUser?.firstName + ' ' + signedInUser?.lastName}
                      authorImg={signedInUser?.profileImageUrl}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {/* Pagination button */}
        <div className="flex lg:justify-end justify-start my-8">
          <nav>
            <ul className="flex pl-0 rounded list-none gap-3">
              <li>
                <button
                  className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <CgChevronDoubleLeft />
                </button>
              </li>
              {visiblePageNumbers.map((page) => (
                <li key={page}>
                  <button
                    className={`md:px-4 px-3 md:py-2 py-1 rounded-md border hover:bg-[#0082C4] hover:text-white transition-all border-[#E3E6EA] ${
                      currentPage === page ? 'font-bold bg-[#0082C4] text-white' : ''
                    } focus:outline-none`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li>
                <button
                  className="relative block md:px-2 px-1 md:py-2 py-1 leading-tight hover:bg-[#0082C4] bg-white transition-all border border-[#E3E6EA] rounded-md text-[#0082C4] hover:text-white text-2xl focus:outline-none z-10"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <CgChevronDoubleRight />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      
      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default Newsfeed