import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseUrl from '../BaseURL';
import Avatar from '../../assets/avatar.png';
import DropDownIcon from '../../assets/drop-down-icon.png';
import { SlOptionsVertical } from 'react-icons/sl';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from "../GlobalState/GlobalState";

function PublicationCommentsReply({signedInUser, singlePublicationId, singleCommentId, userId, author, authorImg}) {
    const { language } = useContext(GlobalContext);

    const navigate = useNavigate();
    const [replies, setReplies]= useState([]);
    const { register, handleSubmit, reset } = useForm();
    const [isOpenReplies, setIsOpenReplies]=useState(false);
    const [isOpenPopup, setIsOpenPopup]=useState(false);
    const [active, setActive]=useState(0);
  
    const [editReplyId, setEditReplyId]=useState();
    const clickedReply = replies.find((reply)=>reply.id === editReplyId);
    // console.log(clickedComment);

    const repliesLength = replies.filter((reply)=> reply?.publication_id === `${singlePublicationId}` && reply.comment_id === `${singleCommentId}`).length;

  // Get replies
  useEffect(()=>{
    Axios.get(`${BaseUrl}/publicationReplies`).then((response) => {
      setReplies(response.data);
    });
  },[]);

  // Add Reply
  const onSubmit = (data) => {
    // const reply_time = new Date().toLocaleString();
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    const reply_time =currentTime +','+ ' '+ currentDate;

    if(signedInUser){
      Axios.post(`${BaseUrl}/addPublicationReply`, {
        reply: data.reply,
        publication_id: singlePublicationId,
        comment_id: singleCommentId,
        user_id: userId,
        author: author,
        authorImg: authorImg,
        reply_time: reply_time,
      }).then(() => {
        Axios.get(`${BaseUrl}/publicationReplies`).then((response) => {
          setReplies(response.data);
        });
        if(isOpenReplies === false){
          setIsOpenReplies(!isOpenReplies);
        }
        reset();
        toast.success("Reply submitted!");
      });
    }else{
      toast.error("Please login!");
      navigate('/login');
    };
  }
  // Update Reply
  const inputRefs = {
    newReply: useRef(null),
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const id = clickedReply?.id;

    const formData = {
      newReply: inputRefs.newReply.current.value,
    };

    Axios.put(`${BaseUrl}/updatePublicationReply`, { 
      reply:formData.newReply,
      id: id 
    }).then(
        (response) => {
          setReplies(
            replies.map((val) => {
              return val.id === id
              ? {
                  id: val.id,
                  reply: val.newReply,
                }
              : val;
            })
          );
          Axios.get(`${BaseUrl}/publicationReplies`).then((response) => {
            setReplies(response.data);
          });
          setIsOpenPopup(false);
          toast.success("Updated!");
        }
      );
  };
  const handleKeyDown = (e) =>{
    if (e.key === 'Enter') {
      handleUpdate(e);
    }
  };
  // Delete Comment
  const handleDeleteReply = (id) => {
    const proceed = window.confirm("Are you sure?");
    if(proceed){
      Axios.delete(`${BaseUrl}/publicationReplies/${id}`).then((response) => {
        setReplies(
          replies.filter((val) => {
            return val.id !== id;
          })
        );
        Axios.get(`${BaseUrl}/publicationReplies`).then((response) => {
          setReplies(response.data);
        });
        toast.success("Deleted!");
      });
    }
  };

  // handle closing for outside clicking
  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isInsideComponent = event.target.closest('.dropdown-closing') !== null;
      if (!isInsideComponent) {
        setActive(0);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [setActive]);

  return (
    <div>
      <div className={`block ${repliesLength < 2 && 'hidden'} flex justify-end`} >
        { language === 'en' ?
          <p onClick={()=> setIsOpenReplies(!isOpenReplies)} className='flex items-center gap-1 md:text-base text-sm text-right font-semibold text-[#2E3033] transition-all cursor-pointer mt-3'>Replies ({repliesLength}) 
            <img src={DropDownIcon} alt='Drop Down Icon' className={`w-4 transition delay-100 ${isOpenReplies ? 'rotate-180' : 'rotate-0'}`} />
          </p>
          :
          <p onClick={()=> setIsOpenReplies(!isOpenReplies)} className='flex items-center gap-1 md:text-base text-sm text-right font-semibold text-[#2E3033] transition-all cursor-pointer mt-3 banglaFont'>রিপ্লাইগুলো ({repliesLength}) 
            <img src={DropDownIcon} alt='Drop Down Icon' className={`w-4 transition delay-100 ${isOpenReplies ? 'rotate-180' : 'rotate-0'}`} />
          </p>
        }
      </div>
      <div className='flex flex-col gap-2'>
        <div className={`${isOpenReplies ? 'block mt-5':'hidden mt-3'}`}>
          {
            replies?.filter((reply)=> reply?.publication_id === `${singlePublicationId}` && reply?.comment_id === `${singleCommentId}`).map((singleReply) => 
              <div key={singleReply?.id} className='md:mb-4 mb-1 md:pl-12 pl-4' >
                {isOpenPopup && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
                      </div>

                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                      <div className="inline-block align-bottom bg-white w-full md:p-8 p-5 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div>
                          <form onSubmit={(e) => handleUpdate(e)} className='flex flex-col justify-center gap-4'>
                            <input 
                              type='text' 
                              placeholder='Write here...' 
                              className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3]'
                              ref={inputRefs.newReply}
                              defaultValue={clickedReply?.reply}
                              onKeyDown={handleKeyDown}
                            />
                            <div className='flex justify-between'>
                              <div></div>
                              <div className='flex gap-5'>
                                { language === 'en' ?
                                  <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg'>Cancel</button>
                                  :
                                  <button onClick={() => setIsOpenPopup(false)} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-1.5 rounded-lg banglaFont'>বাতিল করুন</button>
                                }
                                { language === 'en' ?
                                  <input type='submit' value='Update' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                                  :
                                  <input type='submit' value='আপডেট' className='cursor-pointer px-5 lg:py-3 md:py-2 py-1.5 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                                }
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                }
                <div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="md:w-10 md:h-10 w-8 h-8 overflow-hidden border-2 border-blue-100 rounded-full flex justify-center items-center">
                          {/* { singleReply?.authorImg ?
                            <img className='w-7 h-7 rounded-full' src={`${BaseUrl}${singleReply?.authorImg}`} alt="" referrerPolicy='no-referrer' />
                            :
                            <img className='w-full rounded-full' src={Avatar} alt="" referrerPolicy='no-referrer' />
                          } */}
                          { singleReply?.authorImg ?
                            <div className='w-7 h-7 rounded-full' style={{ backgroundImage: `url('${BaseUrl}${singleReply?.authorImg}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='w-7 h-7 rounded-full' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                          }
                      </div>
                      <div className="md:ml-3 ml-2">
                          <p className="text-[#0082C4] md:text-base text-sm font-semibold whitespace-no-wrap">{singleReply?.author}</p>
                          <p className="text-[#A2AAB7] md:text-sm text-xs whitespace-no-wrap">{singleReply?.reply_time}</p>
                      </div>
                    </div>
                    {
                      signedInUser?.role==="Admin" || signedInUser?.role==="Manager" || author === singleReply?.author 
                      ?
                      <div className='relative dropdown-closing'>
                        <button className='text-gray-500 text-sm' onClick={() => {
                          setActive(singleReply?.id)
                          setEditReplyId(singleReply?.id)
                        }}>
                          <SlOptionsVertical />
                        </button>
                        <div className={`absolute right-0 mt-2 md:w-40 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 ${active === singleReply?.id ? 'block' : 'hidden'}`}>
                          <div className="">
                            <button className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-1" onClick={() => setIsOpenPopup(true)}>
                              <FaRegEdit />Edit
                            </button>
                            <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 flex items-center gap-1" onClick={() => handleDeleteReply(singleReply?.id)}>
                              <RiDeleteBin6Line />Delete
                            </button>
                          </div>
                        </div>
                      </div>
                      :
                      ''
                    }
                  </div>
                </div>
                <div className='md:ml-14 ml-10 py-2'>
                  <p className='text-[#777B84]'>{singleReply?.reply}</p>
                </div>
              </div>
            )
          }
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='flex md:justify-start justify-end items-center mt-2 md:pl-12'>
          <input
            type='text'
            placeholder='Reply here...'
            className='border border-blue-500 border-opacity-20 rounded-l-md py-1.5 px-3 focus:outline-none bg-[#FAFCFF] text-[#777B84] text-sm placeholder:text-[#B7BBC1]'
            {...register('reply')}
            required
          />
          <input
            type='submit'
            value='Reply'
            className='border border-l-0 border-blue-500 border-opacity-20 bg-[#ECF4FF] rounded-r-md text-[#0082C4] text-sm font-semibold py-1.5 px-3 cursor-pointer'
          />
        </form>
      </div>
    </div>
  );
};

export default PublicationCommentsReply