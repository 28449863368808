import React, { useContext, useEffect, useState } from 'react';
import FooterBg from '../../assets/footer-bg.png';
import Logo from '../../assets/logo.png';
import WhiteLogo from '../../assets/logo-white.png';
import MonashLogo from '../../assets/monash-university.png';
import CallIconYellow from '../../assets/call-icon-yellow.png';
import EmailIconYellow from '../../assets/emial-icon-yellow.png';
import './Footer.css';
import { Link } from 'react-router-dom';
import { BsWhatsapp } from 'react-icons/bs';
import { FiInstagram } from 'react-icons/fi';
import { RiFacebookFill } from 'react-icons/ri';
import { GrLinkedin } from 'react-icons/gr';
import { GlobalContext } from '../GlobalState/GlobalState';

function Footer() {
  const { language } = useContext(GlobalContext);

  return (
    <div>
      <div className='relative' style={{"backgroundImage":`url(${FooterBg})`}} >
        <div className='max-w-7xl lg:mx-auto md:mx-8 mx-5 lg:px-8 pt-12'>
          <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-10 md:gap-20 gap-5'>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Contact Us</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>যোগাযোগ</p>
              }
              <div className='grid grid-cols-1 gap-2 py-5'>
                <a href="tel:+8801971104077" className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm rounded-md w-64'><img src={CallIconYellow} alt='Phone icon' />+880 1971 104077</a>
                <a href='mailto:info@telementoring.net' className='bg-[#003A65] px-5 py-4 flex items-center gap-3 text-[#F6FAFF] text-sm rounded-md w-64'><img src={EmailIconYellow} alt='Email icon' />info@telementoring.net</a>
              </div>
            </div>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Quick Links</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>কুইক লিঙ্ক</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
                { language === 'en' ?
                  <Link to='/about-us' className='md:text-base text-sm text-[#E3E6EA]'>About Us</Link>
                  :
                  <Link to='/about-us' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>আমাদের কথা</Link>
                }
                { language === 'en' ?
                  <Link to='/meet-the-team' className='md:text-base text-sm text-[#E3E6EA]'>Meet the Team</Link>
                  :
                  <Link to='/meet-the-team' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>টেলিমেন্টরিং টিম</Link>
                }
                { language === 'en' ?
                  <Link to='/collaborators' className='md:text-base text-sm text-[#E3E6EA]'>Collaborators</Link>
                  :
                  <Link to='/collaborators' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>সহযোগীবৃন্দ</Link>
                }
                { language === 'en' ?
                  <Link to='/volunteers?page=1' className='md:text-base text-sm text-[#E3E6EA]'>Volunteers</Link>
                  :
                  <Link to='/volunteers?page=1' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>স্বেচ্ছাসেবকবৃন্দ</Link>
                }
                { language === 'en' ?
                  <Link to='/publications' className='md:text-base text-sm text-[#E3E6EA]'>Publications</Link>
                  :
                  <Link to='/publications' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>গবেষণা ও প্রতিবেদন</Link>
                }
                
              </div>
            </div>
            <div>
              { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Get Involved</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>যুক্ত হোন</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
                { language === 'en' ?
                  <Link to='/get-involved?join=volunteer#volunteer' className='md:text-base text-sm text-[#E3E6EA]'>Become a Mentor</Link>
                  :
                  <Link to='/get-involved?join=volunteer#volunteer' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>মেন্টর হোন</Link>
                }
                { language === 'en' ?
                  <Link to='/get-involved?join=partner#partner' className='md:text-base text-sm text-[#E3E6EA]'>Become a Partner</Link>
                  :
                  <Link to='/get-involved?join=partner#partner' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>অংশীদার হোন</Link>
                }
              </div>
            </div>
            <div>
            { language === 'en' ?
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2'>Support</p>
                :
                <p className='FooterListBorder md:text-2xl text-xl text-white font-bold pb-2 banglaFont'>সহায়তা</p>
              }
              <div className='py-5 grid grid-cols-1 gap-3'>
                { language === 'en' ?
                  <Link to='/faq' className='md:text-base text-sm text-[#E3E6EA]'>Help & FAQ</Link>
                  :
                  <Link to='/faq' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>সাহায্য এবং জিজ্ঞাসা</Link>
                }
                { language === 'en' ?
                  <Link to='/contact-us' className='md:text-base text-sm text-[#E3E6EA]'>Contact us</Link>
                  :
                  <Link to='/contact-us' className='md:text-base text-sm text-[#E3E6EA] banglaFont'>যোগাযোগ</Link>
                }
              </div>
            </div>
          </div>
          <div className='lg:flex md:flex grid grid-cols-1 justify-between items-center py-6 FooterListBorder2'>
            <div className='w-full flex items-center gap-4 md:mt-0 mt-5 '>
              <a href='https://www.monash.edu/' target='_blank'><img src={MonashLogo} className='w-44' /></a>
              <div className='w-[2px] h-10 bg-[#0082C4]'></div>
              { language === 'en' ?
                <Link to='/'><img src={Logo} className='w-44' /></Link>
                :
                <Link to='/'><img src={WhiteLogo} className='w-40' /></Link>
              }
            </div>
            <div className='flex items-center justify-center gap-4 md:mt-0 mt-4'>
              <a href='https://facebook.com/telementoring.net' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Facebook' ><RiFacebookFill /></a>
              <a href='https://www.linkedin.com/company/telementoring' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='LinkedIn' ><GrLinkedin /></a>
              <a href='https://wa.me/+880 1971 104077' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Whatsapp' ><BsWhatsapp /></a>
              <a href='https://instagram.com/telementoring' target='_blank' className='md:text-2xl text-xl text-white bg-[#0a5690] hover:bg-[#003A65] transition-all rounded-md md:p-4 p-2' title='Instagram' ><FiInstagram /></a>
            </div>
          </div>
          <div className='py-5'>
            { language === 'en' ?
              <p className='text-[#E3E6EA] md:text-sm text-xs'>Copyright © 2022 <Link to='/' className='text-[#F74F22] font-semibold'>TELEMENTORING</Link>. Developed by <a href='https://optimus.com.bd/' target='_blank' className='text-[#F74F22] font-semibold'>Optimus Technologies</a>.</p>
              :
              <p className='text-[#E3E6EA] md:text-sm text-xs banglaFont'>কপিরাইট © ২০২২ <Link to='/' className='text-[#F74F22] font-semibold'>টেলিমেন্টরিং</Link> । ডেভেলপ করেছেন <a href='https://optimus.com.bd/' target='_blank' className='text-[#F74F22] font-semibold'>অপটিমাস টেকনোলোজিস</a>.</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer