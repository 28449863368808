import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { Helmet } from 'react-helmet';
import Avatar from '../../assets/avatar.png';
import ProfileBg from '../../assets/profileBg.png';
import activeDot from '../../assets/activeDot.png';
import { GlobalContext } from '../GlobalState/GlobalState';
import ScrollToTop from '../Common/ScrollToTop';
import Footer from '../Common/Footer';
import BaseUrl from '../BaseURL'
import Axios from 'axios';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi';
import Loading from '../Common/Loading';
import { useUserContext } from '../GlobalState/UserContext';

function AdminSettings() {
    const { language } = useContext(GlobalContext);
    const { signedInUser } = useUserContext();
    const [user, loading] = useAuthState(auth);
    // console.log(auth.currentUser);
    // const [personalInfo, setPersonalInfo] = useState([]);
    // const [activeLink, setActiveLink] = useState("changeProfileType");
    const [activeLink, setActiveLink] = useState("smtp");
    const [userEmail, setUserEmail]=useState("");
    const [userName, setUserName]=useState("");
    // const signedInUser = personalInfo.find((person) => person?.userEmail === user?.email);
    // console.log(signedInUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdown, setDropdown] = useState(false);

    if (loading) {
        return <Loading></Loading>
    };

    useEffect(()=>{
        setUserEmail(user?.email);
        setUserName(user?.displayName);
    },[user?.email, user?.displayName]);

    // Get infos
    // useEffect(()=>{
    //     Axios.get(`${BaseUrl}/infos`).then((response) => {
    //       setPersonalInfo(response.data);
    //     });
    // },[]);
    
    // console.log(signedInUser?.role);
    // Redirect unauthorized user
    // if (signedInUser?.role !== "Admin" || signedInUser?.role !== "Manager") {
    //     return <Navigate to='/'></Navigate>
    // };
      
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

    const nestedRoute = location.pathname.split('/')[2];
      
  return (
    <div>
        <Helmet>
            <title>Admin Settings - Telementoring</title>
            <meta name='title' content='Admin Settings - Telementoring' />
            {/* <meta name="description" content="View and edit your user information for the Telementoring platform at Profile settings." />
            <meta name="keywords" content="telementoring, Settings" /> */}
        </Helmet>

        {/* Hero */}
        <div className='h-44 flex justify-center md:items-center' style={{"backgroundImage": `url(${ProfileBg})`, 'backgroundPosition':'center', 'backgroundRepeat':'no-repeat', 'backgroundSize': 'cover'}}>
            { language === 'en' ?
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl'>Admin Settings</h2>
                :
                <h2 className='md:mt-0 mt-8 text-white font-bold lg:text-4xl md:text-3xl text-xl banglaFont'>এডমিন সেটিংস</h2>
            }
        </div>

        <div className='max-w-7xl lg:mx-auto md:mx-5 mx-0 lg:px-8 lg:flex md:flex grid grid-cols-1 lg:gap-৮ md:gap-5 gap-5 mb-12 relative'>
            <div className='lg:w-[25%] md:w-[30%] w-[100%] -mt-12 md:px-0 px-5'>
                <div className='bg-white rounded-xl py-5 lg:px-8 md:px-3 px-4 shadow-md sticky top-20'>
                    <div className='flex justify-center lg:-mt-20 -mt-[4.5rem] relative'>
                        { signedInUser?.profileImageUrl ?
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{ backgroundImage: `url('${BaseUrl}${signedInUser?.profileImageUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                            :
                            <div className='lg:w-28 lg:h-28 w-24 h-24 rounded-full bg-[#eaeaef] border border-[#F74F22] overflow-hidden flex justify-center items-center' style={{"backgroundImage": `url(${Avatar})`, 'backgroundPosition':'center', 'backgroundSize':'cover', 'backgroundRepeat':'no-repeat'}}></div>
                        }
                    </div>
                    <div className='text-center mt-2'>
                        { signedInUser?.firstName ?
                            <p className='text-xl font-bold'>{signedInUser?.firstName} {signedInUser?.lastName}</p>
                            :
                            <p className='text-xl font-bold'>{userName}</p>
                        }
                        { signedInUser?.email ?
                            <p className='lg:text-base md:text-sm text-base text-[#777B84]'>{signedInUser?.email}</p>
                            :
                            <p className='lg:text-base md:text-sm text-base text-[#777B84]'>{userEmail}</p>
                        }
                    </div>
                    { language === 'en' ?
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-users" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-users?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-users" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Manage Users</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-blogs" || nestedRoute === "add-blog" || nestedRoute === "edit-blog" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-blogs?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-blogs" || nestedRoute === "add-blog" || nestedRoute === "edit-blog" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Manage Blogs</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-media" || nestedRoute === "add-media" || nestedRoute === "edit-media" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-media?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-media" || nestedRoute === "add-media" || nestedRoute === "edit-media" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Manage Media</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-publication" || nestedRoute === "add-publication" || nestedRoute === "edit-publication" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-publication?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-publication" || nestedRoute === "add-publication" || nestedRoute === "edit-publication" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Manage Publication</span>
                            </li>
                            {/* Dropdown */}
                            <div>
                                <li className={`text-base text-[#777B84] flex items-center justify-between gap-1 px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${dropdown && 'mb-3'}`}
                                    onClick={() => setDropdown(!dropdown)}
                                >
                                    <span className=''>Newsletter</span>
                                    <BiChevronDown className={`text-xl transition-all ${dropdown && 'rotate-180'}`} />
                                </li>
                                <div className={`${dropdown ? 'block' : 'hidden'} flex flex-col gap-3`}>
                                    <li className={`text-base text-[#777B84] flex items-center gap-1 pl-6 pr-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${nestedRoute === "manage-subscribers" ? "font-semibold bg-[#F6FAFF]" : "" }`}
                                        onClick={() => {navigate('/admin-settings/manage-subscribers?page=1')}}
                                    >
                                        <img src={activeDot} className={`${nestedRoute === "manage-subscribers" ? "block" : "hidden"}`} alt='activeDot' />
                                        <span >Manage Subscribers</span>
                                    </li>
                                    <li className={`text-base text-[#777B84] flex items-center gap-1 pl-6 pr-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                        nestedRoute === "send-newsletter" ? "font-semibold bg-[#F6FAFF]" : ""
                                        }`}
                                        onClick={() => {navigate('/admin-settings/send-newsletter?page=1')}}
                                    >
                                        <img src={activeDot} className={`${nestedRoute === "send-newsletter" ? "block" : "hidden"}`} alt='activeDot' />
                                        <span className=''>Send Newsletter</span>
                                    </li>
                                </div>
                            </div>

                            {/* <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${nestedRoute === "manage-subscribers" ? "font-semibold bg-[#F6FAFF]" : "" }`}
                                onClick={() => {navigate('/admin-settings/manage-subscribers?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-subscribers" ? "block" : "hidden"}`} alt='activeDot' />
                                <span >Manage Subscribers</span>
                            </li> */}
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "post-announcement" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/post-announcement?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "post-announcement" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Post Announcement</span>
                            </li>
                            {/* <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                nestedRoute === "send-newsletter" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/send-newsletter?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "send-newsletter" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Send Newsletter</span>
                            </li> */}
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "smtp" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/smtp?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "smtp" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>SMTP</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "export-user-data" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/export-user-data?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "export-user-data" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className=''>Export User Data</span>
                            </li>
                        </ul>
                        :
                        <ul className="mt-5 flex flex-col gap-3">
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-users" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-users?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-users" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ ইউজার</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1 px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-blogs" || nestedRoute === "add-blog" || nestedRoute === "edit-blog" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-blogs?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-blogs" || nestedRoute === "add-blog" || nestedRoute === "edit-blog" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ ব্লগ</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-media" || nestedRoute === "add-media" || nestedRoute === "edit-media" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-media?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-media" || nestedRoute === "add-media" || nestedRoute === "edit-media" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ মিডিয়া</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "manage-publication" || nestedRoute === "add-publication" || nestedRoute === "edit-publication" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/manage-publication?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "manage-publication" || nestedRoute === "add-publication" || nestedRoute === "edit-publication" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ পাব্লিকেশন</span>
                            </li>
                            {/* Dropdown */}
                            <div>
                                <li className={`text-base text-[#777B84] flex items-center justify-between gap-1 px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${dropdown && 'mb-3'}`}
                                    onClick={() => setDropdown(!dropdown)}
                                >
                                    <span className='banglaFont'>নিউজলেটার</span>
                                    <BiChevronDown className={`text-xl transition-all ${dropdown && 'rotate-180'}`} />
                                </li>
                                <div className={`${dropdown ? 'block' : 'hidden'} flex flex-col gap-3`}>
                                    <li className={`text-base text-[#777B84] flex items-center gap-1 pl-6 pr-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${nestedRoute === "manage-subscribers" ? "font-semibold bg-[#F6FAFF]" : "" }`}
                                        onClick={() => {navigate('/admin-settings/manage-subscribers?page=1')}}
                                    >
                                        <img src={activeDot} className={`${nestedRoute === "manage-subscribers" ? "block" : "hidden"}`} alt='activeDot' />
                                        <span className='banglaFont'>ম্যানেজ সাবস্ক্রাইবার</span>
                                    </li>
                                    <li className={`text-base text-[#777B84] flex items-center gap-1 pl-6 pr-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                        nestedRoute === "send-newsletter" ? "font-semibold bg-[#F6FAFF]" : ""
                                        }`}
                                        onClick={() => {navigate('/admin-settings/send-newsletter?page=1')}}
                                    >
                                        <img src={activeDot} className={`${nestedRoute === "send-newsletter" ? "block" : "hidden"}`} alt='activeDot' />
                                        <span className='banglaFont'>নিউজলেটার পাঠান</span>
                                    </li>
                                </div>
                            </div>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "post-announcement" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/post-announcement?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "post-announcement" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>অ্যানাউন্সমেন্ট পোস্ট</span>
                            </li>
                            {/* <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                nestedRoute === "send-newsletter" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/send-newsletter?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "send-newsletter" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>নিউজলেটার পাঠান</span>
                            </li> */}
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "smtp" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/smtp?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "smtp" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>এসএমটিপি</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer hover:bg-[#F6FAFF] transition-all ${
                                nestedRoute === "export-user-data" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {navigate('/admin-settings/export-user-data?page=1')}}
                            >
                                <img src={activeDot} className={`${nestedRoute === "export-user-data" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ইউজার ডেটা এক্সপোর্ট করুন</span>
                            </li>

                            {/* <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "manageUsers" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("manageUsers");
                                    navigate('?page=1');
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "manageUsers" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ ইউজার</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "manageBlogs" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("manageBlogs");
                                    navigate('?page=1');
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "manageBlogs" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ ব্লগ</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "manageMedia" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("manageMedia");
                                    navigate('?page=1');
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "manageMedia" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ মিডিয়া</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "managePublication" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("managePublication");
                                    navigate('?page=1');
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "managePublication" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ম্যানেজ পাব্লিকেশন</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "postAnnouncement" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("postAnnouncement");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "postAnnouncement" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>অ্যানাউন্সমেন্ট পোস্ট</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "smtp" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                setActiveLink("smtp");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "smtp" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>এসএমটিপি</span>
                            </li>
                            <li className={`text-base text-[#777B84] flex items-center gap-1  px-3 py-1 rounded-md cursor-pointer ${
                                activeLink === "csv" ? "font-semibold bg-[#F6FAFF]" : ""
                                }`}
                                onClick={() => {
                                    setActiveLink("csv");
                                }}
                            >
                                <img src={activeDot} className={`${activeLink === "csv" ? "block" : "hidden"}`} alt='activeDot' />
                                <span className='banglaFont'>ইউজার ডেটা এক্সপোর্ট করুন</span>
                            </li> */}
                        </ul>
                    }
                </div>
            </div>
            <div className='lg:w-[75%] md:w-[70%] w-[100%] lg:px-0 md:px-0 px-5 md:pt-10 pt-5'>
                <Outlet></Outlet>
            </div>
        </div> 

        <ScrollToTop></ScrollToTop>
        <Footer></Footer>
    </div>
  )
}

export default AdminSettings