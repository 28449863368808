import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import JoditEditor from "jodit-react";
import Axios from 'axios';
import './AddBlog.css';
import BaseUrl from '../BaseURL';
import { toast } from 'react-toastify';
import ScrollToTop from '../Common/ScrollToTop';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { GlobalContext } from "../GlobalState/GlobalState";
import Loading from '../Common/Loading';
import { useUserContext } from '../GlobalState/UserContext';

function AddBlog() {
  const { language } = useContext(GlobalContext);
  const { signedInUser, personalInfo } = useUserContext();

  // const [user, loading] = useAuthState(auth);
  // const [personalInfo, setPersonalInfo] = useState([]);
  // const signedInUser = personalInfo.find((person) => person.userEmail === user?.email);

  const [blogs, setBlogs] = useState('');
  const navigate = useNavigate();
  // get all users mail
  const recipientsEmail = personalInfo?.map((e) => e?.email);
  const recipientsFirstName = personalInfo?.map((e) => e?.firstName);

  const [smtp, setSmtp] = useState([]);

  // Get smtp
  useEffect(()=>{
    Axios.get(`${BaseUrl}/smtp`).then((response) => {
        setSmtp(response.data);
    });
  },[]);

  // Title
  const [title, setTitle] = useState('');
  // Content
  const [description, setDescription] = useState('');
  // Uploaded file
  const [file, setFile] = useState(null);
  // Checkbox
  const [isChecked, setIsChecked] = useState(false);
  // Reset form
  const resetForm = () => {
    setTitle('');
    setDescription('');
    setFile(null);
  };
  // Get infos
  // useEffect(()=>{
  //   Axios.get(`${BaseUrl}/infos`).then((response) => {
  //     setPersonalInfo(response.data);
  //   });
  // },[])
  // get title
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  // Submit form
  const upload = async e => {
    e.preventDefault();
    const author = signedInUser?.firstName + ' ' + signedInUser?.lastName;
    const authorImg = signedInUser?.profileImageUrl;
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    const post_time =currentTime +','+ ' '+ currentDate;
    const visibility = 'visible';
    const postType = 'blog';
    if(signedInUser?.role === "Admin" || signedInUser?.role === "Manager"){
      if(file !== null){
        let formData = new FormData();
        formData.append("blogImg", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("post_time", post_time);
        formData.append("visibility", visibility);
        formData.append("postType", postType);
        formData.append("author", author);
        formData.append("authorImg", authorImg);
        await Axios.post(`${BaseUrl}/upload-blog-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(async (response) => {
            let blogPost = response.data;
            toast.success("Successfully Posted!");
        
            if(isChecked){
              const convertedDescription = description?.replace(/<[^>]*>?/gm, '')?.replace(/&[^\s]*;/gm, '');
              await Axios.post(`${BaseUrl}/sendPostNotification`, {
                title: title,
                content: convertedDescription,
                recipientsEmail: recipientsEmail,
                recipientsFirstName: recipientsFirstName,
                postDate: currentDate,
                authorFirstName: signedInUser?.firstName,
                authorFullName: author,
                postType: postType,
                postLink: `https://telementoring.net/blog-details/${blogPost?.id}`,

                host: smtp?.smtpHost,
                port: smtp?.smtpPort,
                user: smtp?.smtpEmail,
                pass: smtp?.smtpPass,
              }).then(()=>{
                setIsChecked(!isChecked);
                setTimeout(()=>{
                  toast.success("Mail sent!");
                }, 4000);
              })
            };
        
            // Fetch blogs after email is sent
            await Axios.get(`${BaseUrl}/blogs`).then((response) => {
              setBlogs(response.data);
            });
        
            resetForm();
            navigate('/admin-settings/manage-blogs?page=1');
        })             
          
      }else{
        toast("Please choose an image!");
      }
    }else{
      toast.error("You don't have sufficient privilege!")
    }
    
    // console.log(title, description, imageUrl);
  };
  // clear form
  const handleCancelClick = (e) => {
    e.preventDefault();
    resetForm();
  };

  return (
    <div>
      <Helmet>
        <title>Add Blog - Telementoring</title>
        <meta name='title' content='Add Blog - Telementoring' />
        <meta name="description" content="Add new blog at Telementoring." />
        <meta name="keywords" content="telementoring, add blog" />
      </Helmet>

      <div className='max-w-7xl lg:mx-auto md:mx-5 mx-5 md:py-0 py-5'>
        <div className='border border-gray-900 border-opacity-5 shadow-md rounded-2xl lg:p-8 md:p-5 p-3'>
          <div className='flex justify-between items-center mb-5 md:mt-0 mt-3'>
            { language === 'en' ?
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right'>Create a New Blog</p>
              :
              <p className='font-semibold md:text-2xl text-xl md:text-left text-right banglaFont'>নতুন ব্লগ তৈরি করুন</p>
            }
            <div className="relative inline-block group">
              <Link to='/admin-settings/manage-blogs?page=1' className='text-3xl text-[#0082C4]'>< AiOutlineCloseCircle /></Link>
              { language === 'en' ?
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden">Close</div>
                :
                <div className="absolute -top-10 -left-5 z-10 px-2 py-1 text-white bg-gray-700 rounded shadow group-hover:block hidden banglaFont">বন্ধ</div>
              }
            </div>
          </div>
          <div>
            <div>
              { language === 'en' ?
                <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">Title</label>
                      <input type="text" value={title} onChange={handleTitleChange} required placeholder='Write here' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full' />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2">Upload Cover Photo</label>
                      <input
                        type="file"
                        name="blogImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <JoditEditor
                    value={description}
                    onChange={(newContent) => setDescription(newContent)}
                    required
                  />
                  <div className='flex md:flex-row flex-col gap-4 justify-between'>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                        className="mr-2"
                      />
                      <label htmlFor="checkbox" className='text-[#777B84]' >Check to send this blog notification to users email.</label>
                    </div>
                    { language === 'en' ?
                      <div className='flex gap-5'>
                        <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Cancel</button>
                        
                        <input type="submit" value='Submit Blog' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                      </div>
                      :
                      <div className='flex gap-5'>
                        <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>বাতিল করুন</button>
                        <input type="submit" value='সাবমিট ব্লগ' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                      </div>
                    }
                  </div>
                </form>
                :
                <form onSubmit={(e) => upload(e)} className='flex flex-col justify-center gap-4'>
                  <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">টাইটেল</label>
                      <input type="text" value={title} onChange={handleTitleChange} required placeholder='এখানে লিখুন' className='border border-blue-500 border-opacity-60 rounded-xl lg:py-3 md:py-2 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] placeholder:text-[#0083c4a3] w-full banglaFont' />
                    </div>
                    <div>
                      <label className="block font-semibold text-gray-700 mb-2 banglaFont">কভার ফটো আপলোড করুন</label>
                      <input
                        type="file"
                        name="blogImg"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        className="border border-blue-500 border-opacity-60 rounded-xl lg:py-2.5 md:py-1.5 py-2 px-5 focus:outline-none bg-[#FAFCFF] text-[#0082C4] cursor-pointer flex items-center justify-center gap-3 w-full"
                      />
                    </div>
                  </div>
                  <JoditEditor
                    value={description}
                    onChange={(newContent) => setDescription(newContent)}
                    required
                  />
                  <div className='flex md:flex-row flex-col gap-4 justify-between'>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="checkbox"
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                        className="mr-2"
                      />
                      <label htmlFor="checkbox" className='text-[#777B84] banglaFont' >ব্যবহারকারীদের ইমেলে এই ব্লগ নোটিফিকেশন পাঠাতে চেক করুন ।</label>
                    </div>
                    <div className='flex gap-5'>
                      <button onClick={handleCancelClick} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>বাতিল করুন</button>
                      <input type="submit" value='সাবমিট ব্লগ' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>

      </div>

      <ScrollToTop></ScrollToTop>
    </div>
  );
};

export default AddBlog