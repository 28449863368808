import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import { GlobalContext } from '../GlobalState/GlobalState';
import BaseUrl from '../BaseURL'
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

function SMTP() {
    const { language } = useContext(GlobalContext);
    const [user] = useAuthState(auth);
    const location = useLocation();
    const [smtp, setSmtp] = useState([]);
    // Checkbox
    const [isChecked, setIsChecked] = useState(false);

    useEffect(()=>{
        setIsChecked(smtp?.isTestChecked === '1');
    },[user?.email, user?.displayName, smtp]);

    // Get smtp
    useEffect(()=>{
        Axios.get(`${BaseUrl}/smtp`).then((response) => {
            setSmtp(response.data);
        });
    },[]);

    const [formData, setFormData]=useState({
        newSmtpHost: "",
        newSmtpPort: "",
        newSmtpEmail: "",
        newSmtpPass: "",
        newTestEmails: "",
    });
    useEffect(()=>{
        setFormData({
            newSmtpHost: smtp?.smtpHost,
            newSmtpPort: smtp?.smtpPort,
            newSmtpEmail: smtp?.smtpEmail,
            newSmtpPass: smtp?.smtpPass,
            newTestEmails: smtp?.testEmails,
        });
    },[smtp]);
    const inputChangeHandler=(e,key)=>{
        setFormData({
        ...formData, [key]:e.target.value
        });
    };
    // Submit form
    const handleSubmit = (e, id) => {
        e.preventDefault();
      
        if (smtp) {
          Axios.put(`${BaseUrl}/updateSmtp`, {
            smtpHost: formData?.newSmtpHost,
            smtpPort: formData?.newSmtpPort,
            smtpEmail: formData?.newSmtpEmail,
            smtpPass: formData?.newSmtpPass,
            id: id,
          }).then((response) => {
            setSmtp(response.data);
            toast.success("Settings updated!");
          });
        }
    };
    // Test email
    const handleTestEmail = (e) => {
        e.preventDefault();
        if(formData?.newTestEmails){
            Axios.post(`${BaseUrl}/test-email`, {
                recipientsEmail: formData?.newTestEmails,
                host: formData?.newSmtpHost,
                port: formData?.newSmtpPort,
                user: formData?.newSmtpEmail,
                pass: formData?.newSmtpPass,
            });
            toast.success("Successfully sent test mail!");
        }else{
            toast.error("Provide at least one email!");
        }
    };
      
    //  Scroll to top, when user will be redirected in this page
    useEffect(() => {
        if (!location.state?.preserveScrollPosition) {
        window.scrollTo(0, 0);
        }
    }, [location.state]);

  return (
    <div>
        <div>
            { language === 'en' ?
                <form onSubmit={(e) => handleSubmit(e, smtp?.id)} className='flex flex-col gap-4'>
                    <div>
                        <label htmlFor="smtpHost" className='text-lg font-semibold text-[#2E3033]'>Hostname</label><br/>
                        <input 
                            type='text' id='smtpHost' placeholder='Write the smtphost' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpHost}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpHost")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpPort" className='text-lg font-semibold text-[#2E3033]'>SMTP Port</label><br/>
                        <input 
                            type='text' id='smtpPort' placeholder='Write the port' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpPort}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpPort")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpEmail" className='text-lg font-semibold text-[#2E3033]'>SMTP Email</label><br/>
                        <input 
                            type='email' id='smtpEmail' placeholder='Write email' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpEmail}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpEmail")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpPass" className='text-lg font-semibold text-[#2E3033]'>SMTP Password</label><br/>
                        <input 
                            type='password' id='smtpPass' placeholder='Write password' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpPass}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpPass")}}
                        />
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="checkbox"
                            checked={isChecked}
                            onChange={(event) => setIsChecked(event.target.checked)}
                            className="mr-2"
                        />
                        <label htmlFor="checkbox" className='text-[#777B84]' >Check to send test email.</label>
                    </div>
                    { isChecked &&
                        <div>
                            <label htmlFor="testMail" className='text-lg font-semibold text-[#2E3033]'>Test Emails</label><br/>
                            <textarea 
                                id="testMail" name="testMail" 
                                rows="4" cols="50" placeholder='Write emails with comma(,) between two email' 
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                defaultValue={smtp?.testEmails}
                                onChange={(e)=>{inputChangeHandler(e,"newTestEmails")}}
                            />
                        </div>
                    }
                    <div className='w-full flex justify-end gap-5'>
                        { isChecked &&
                            <button onClick={handleTestEmail} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg'>Send Test Email</button>
                        }
                        <input type="submit" value='Apply' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg' />
                    </div>
                </form>
                :
                <form onSubmit={(e) => handleSubmit(e, smtp?.id)} className='flex flex-col gap-4'>
                    <div>
                        <label htmlFor="smtpHost" className='text-lg font-semibold text-[#2E3033] banglaFont'>হোস্টের নাম</label><br/>
                        <input 
                            type='text' id='smtpHost' placeholder='এসএমটিপি হোস্ট' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpHost}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpHost")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpPort" className='text-lg font-semibold text-[#2E3033] banglaFont'>এসএমটিপি পোর্ট</label><br/>
                        <input 
                            type='text' id='smtpPort' placeholder='এসএমটিপি পোর্ট' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpPort}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpPort")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpEmail" className='text-lg font-semibold text-[#2E3033] banglaFont'>এসএমটিপি ইমেইল</label><br/>
                        <input 
                            type='email' id='smtpEmail' placeholder='এসএমটিপি ইমেইল' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpEmail}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpEmail")}}
                        />
                    </div>
                    <div>
                        <label htmlFor="smtpPass" className='text-lg font-semibold text-[#2E3033] banglaFont'>এসএমটিপি পাসওয়ার্ড</label><br/>
                        <input 
                            type='password' id='smtpPass' placeholder='এসএমটিপি পাসওয়ার্ড' 
                            className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                            defaultValue={smtp?.smtpPass}
                            onChange={(e)=>{inputChangeHandler(e,"newSmtpPass")}}
                        />
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="checkbox"
                            checked={isChecked}
                            onChange={(event) => setIsChecked(event.target.checked)}
                            className="mr-2"
                        />
                        <label htmlFor="checkbox" className='text-[#777B84] banglaFont' >টেস্ট মেইল পাঠাতে চেক করুন</label>
                    </div>
                    { isChecked &&
                        <div>
                            <label htmlFor="testMail" className='text-lg font-semibold text-[#2E3033] banglaFont'>টেস্ট মেইল</label><br/>
                            <textarea 
                                id="testMail" name="testMail" 
                                rows="4" cols="50" placeholder='মেইল লিখুন' 
                                className='inputBoxBorder focus:outline-none rounded-xl px-5 py-3 text-base text-[#777B84] mt-2 w-full' 
                                defaultValue={smtp?.testEmails}
                                onChange={(e)=>{inputChangeHandler(e,"newTestEmails")}}
                            />
                        </div>
                    }
                    <div className='w-full flex justify-end gap-5'>
                        { isChecked &&
                            <button onClick={handleTestEmail} className='border border-[#E3E6EA] hover:bg-[#eef4fb] transition-all text-[#0082C4] font-semibold text-base px-5 lg:py-3 md:py-2 py-2 rounded-lg banglaFont'>টেস্ট ইমেইল পাঠান</button>
                        }
                        <input type="submit" value='এপ্লাই' className='cursor-pointer px-5 lg:py-3 md:py-2 py-2 bg-[#0082C4] hover:bg-[#0083c4db] transition-all text-white font-semibold rounded-lg banglaFont' />
                    </div>
                </form>
            }
        </div>
    </div>
  )
}

export default SMTP